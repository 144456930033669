import { useEffect } from "react";
import { useMap } from "react-leaflet";

//Given a pair of coordinates, this component sets the bounds of the map to fit the coordinates
//Uses northEast and southWest coordinates to set the bounds
export default function BoundsSetter({ bounds }) {
  const map = useMap();
  useEffect(() => {
    map.fitBounds(bounds);
  }, [bounds, map]);

  return null;
}
