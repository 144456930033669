import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import * as api from "../../apis/FusionAPI";
import Cookies from "js-cookie";
import TabsHeader from "../../components/TabsHeader";

import WeatherHome from "../../components/WeatherForecast/WeatherHome";
import HourlyForecast from "../../components/WeatherForecast/HourlyForecast";
import DetailedForecast from "../../components/WeatherForecast/DetailedForecast";
import DayForecast15 from "../../components/WeatherForecast/DayForecast15";
import AirQuality from "../../components/WeatherForecast/AirQuality";
import { FixOutputOfEndpointToTheCorrectFormat_AirQualityForecast } from "utils/utilsBecauseSQLSucks";
import { useAPIData } from "hooks/useAPIData";
import { usePerms } from "hooks/usePerms";
import useDataRefresh from "hooks/useDataRefresh";

export default function WeatherForecast({}) {
  const [params, setParams] = useSearchParams();
  const [currentData, setCurrentData] = useState();
  const [forecastHourlyData, setForecastHourlyData] = useState();
  const [detailedForecastData, setDetailedForecastData] = useState();
  const [airQualityData, setAirQualityData] = useState();
  const [day15Forecast, setDay15Forecast] = useState();
  const [detailedDays, setDetailedDays] = useState([]);
  const [astronomyData, setAstronomyData] = useState();
  const [tabs, setTabs] = useState([0, 0]);
  const navigate = useNavigate();
  const perms = usePerms();

  const FusionData = useAPIData({
    successCallback: () => {
      // setFetching(false);
    },
    errorCallback: () => {
      // setError({ state: true, message: "Error fetching data" });
    },
    fetchingCallback: () => {
      // setFetching(true);
    },
  });

  const dataRefresh = useDataRefresh();
  const widgetRefresh = dataRefresh.widgetRefresh;

  // console.log("airQualityData:", airQualityData);
  useEffect(() => {
    const initTab = [
      params.get("tab") ? parseInt(params.get("tab")) : 0,
      params.get("subtab") ? parseInt(params.get("subtab")) : 0,
    ];
    setTabs([...initTab]);
  }, []);

  useEffect(() => {
    (async () => {
      if (!(await perms.validatePermissions(["ForecastEnabled"]))) {
        navigate("/404");
        return;
      }

      //get hourly forecast data
      // FusionData.get("getHourlyForecast").then((res) => {
      //   // console.log("Forecast Data:", res);
      //   setForecastHourlyData(
      //     res.data.result.map((item) => {
      //       return {
      //         ...item,
      //         local_time: parseDateTime(item.iso8601.toString()),
      //       };
      //     })
      //   );
      // });

      // //get current conditons of current city
      // FusionData.get("currentCityConditions").then((res) => {
      //   setCurrentData(res.data.result[0]);
      // });

      // //get astronomy data
      // FusionData.get("getAstronomyData").then((res) => {
      //   setAstronomyData(res.data.result);
      //   // console.log("Astronomy Data:", res.data.result)
      // });

      //get detailed forecast data
      FusionData.get("getDetailedForecastData").then((res) => {
        const now = new Date();
        // console.log("Now", now.getDay());

        const dayArray = [];
        //build array
        res.data.result.map((day) => {
          // console.log("Day array before filter:", dayArray);
          if (
            dayArray.filter(
              (x) => parseInt(x.day_of_week) === parseInt(day.day_of_week)
            ).length === 0
          ) {
            const order =
              parseInt(day.day_of_week) < now.getDay()
                ? parseInt(day.day_of_week) + 7
                : parseInt(day.day_of_week);

            dayArray.push({
              day_of_week: parseInt(day.day_of_week),
              weekday: day.weekday,
              order: order,
            });
          }
        });

        setDetailedDays(
          dayArray
            .sort((a, b) => {
              return a.order - b.order;
            })
            .map((x) => {
              return x.weekday;
            })
        );

        setDetailedForecastData([...res.data.result]);
      });

      //get 15 day forecast
      // FusionData.get("get15DayForecast").then((res) => {
      //   res.data.result.map((item) => {
      //     item.loctime = new Date(item.iso8601);
      //   });

      //   setDay15Forecast([...res.data.result]);
      // });

      // //air quality
      // FusionData.get("getAirQualityObservation").then((res) => {
      //   // console.log("air quality observation Res:", res.data.result);
      //   // res.data.result.map((item) => // console.log("LocTime:", item.loctime));

      //   res.data.result.map((item) => {
      //     item.loctime = new Date(item.iso8601);
      //   });

      //   FusionData.get("getAirQualityForecast").then((innerRes) => {
      //     // console.log("air quality forecast Res:", innerRes.data.result);
      //     const fixedData =
      //       FixOutputOfEndpointToTheCorrectFormat_AirQualityForecast(innerRes);

      //     fixedData.map((outer) => {
      //       outer.forecast_report.map((item) => {
      //         item.loctime = new Date(item.iso8601);
      //       });
      //     });

      //     setAirQualityData({
      //       observations: [...res.data.result],
      //       forecast: [...fixedData],
      //     });
      //   });
      // });
    })();
  }, [widgetRefresh]);

  const parseDateTime = (datetime) => {
    // console.log("Datetime:", datetime, "parsed:", new Date(datetime));
    const result = [];
    result.push(datetime[6], datetime[7], datetime[8], datetime[9]);
    result.push("-");
    result.push(datetime[2], datetime[3]);
    result.push("-");
    result.push(datetime[4], datetime[5]);
    result.push("T", datetime[0], datetime[1], ":00:00");

    // console.log("Result datetime:", result.toString().replace(/,/g, ""));
    // console.log("Result Date:", new Date(result.toString().replace(/,/g, "")));

    return new Date(datetime);
  };

  const RenderTab = () => {
    switch (tabs[0]) {
      case 0:
        // if (currentData)
          return (
            <WeatherHome
            // currentData={currentData}
            // forecastHourlyData={forecastHourlyData}
            // astronomyData={astronomyData}
            />
          );
      case 1:
        // if (forecastHourlyData)
          return (
            <HourlyForecast
            // forecastHourlyData={forecastHourlyData}
            />
          );
      case 2:
        // if (detailedForecastData)
          return (
            <DetailedForecast
              // data={detailedForecastData}
              // days={detailedDays}
              selected={tabs[1]}
            />
          );
      case 3:
        // if (day15Forecast) 
          return <DayForecast15 
        // data={day15Forecast} 
        />;
      case 4:
        // if (airQualityData)
          return <AirQuality 
        // data={airQualityData} 
        selectedTab={tabs[1]} />;
      default:
        return <div />;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {/* header */}
      <TabsHeader //depth = 2
        onTabChange={(newTabArr) => setTabs([...newTabArr])}
        currentTabs={tabs}
        options={[
          //1st depth
          { option: "Weather Home" },
          { option: "Hourly Forecast" },
          {
            option: "Detailed Forecast",
            options: detailedDays.map((day) => {
              //2nd depth
              return { option: day };
            }),
          },
          { option: "15 Day Forecast" },
          {
            option: "Air Quality",
            //2nd depth
            options: [{ option: "Observations" }, { option: "Forecast" }],
          },
        ]}
        styles={[{}, { backgroundColor: "#436c98" }]}
      />

      {/* content */}
      {RenderTab()}
    </div>
  );
}
