import React, { useEffect, useState, useContext } from "react";
import * as api from "apis/FusionAPI";
import MixedChart from "components/Charts/MixedChart";
import DropdownSelector from "components/DropdownSelector";
import { DateToString } from "utils/stringUtils";
import * as chartUtils from "utils/ChartUtils";
import Widget from "components/Widget";
import WidgetTimeUpdate from "./WidgetTimeUpdate";
import WidgetLoadingAnimation from "./WidgetLoadingAnimation";
import { ErrorMessage } from "./ErrorMessage";
import { useAPIData } from "hooks/useAPIData";
import { ColorContext, LayoutSizeContext, UniqueWidgetRefreshContext } from "App";
import Cookies from "js-cookie";

let dataCache;
 
// let currentTab = 0;

//All tabs work with each instance of this widget,
//  'defaultTab' determines which tab gets opened to on
//  the 'Charts' page when clicking the fullscreen button.
//  And, also which tab the widget will default to when it
//  has to rerender.
export default function CombinedChartsWidget({
  defaultTab,
  widgetRefresh,
  setWidgetRefresh,
  dimensions,
}) {
  const layout = useContext(LayoutSizeContext);

  const [selectedTab, setSelectedTab] = useState(defaultTab);

  const [detailedData, setDetailedData] = useState();
  const [day7Data, setDay7Data] = useState();
  const [day15Data, setDay15Data] = useState();

  const [precipData, setPrecipData] = useState();
  const [cloudCoverData, setCloudCoverData] = useState();
  const [comfortLevelData, setComfortLevelData] = useState();
  const [dewPointData, setDewPointData] = useState();
  const [humidityData, setHumidityData] = useState();
  const [pressureData, setPressureData] = useState();
  const [precipitationData, setPrecipitationData] = useState();
  const [temperatureData, setTemperatureData] = useState();
  const [windSpeedData, setWindSpeedData] = useState();
  const [error, setError] = useState({ state: false, message: "" });
  const [fetching, setFetching] = useState(true);

  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "combinedCharts";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  
  const theme = useContext(ColorContext);

  const dataFetch = async () => {
    try {
      if (
        dataCache &&
        dataCache.precipData &&
        dataCache.comfortLevelData &&
        dataCache.dewPointData &&
        dataCache.humidityData &&
        dataCache.pressureData &&
        dataCache.precipitationData &&
        dataCache.temperatureData &&
        dataCache.windSpeedData
      ) {
        setPrecipData({
          dataSets: {
            labels: dataCache.precipData.map((item) => {
              return item.label;
            }),
            datasets: [
              {
                type: "bar",
                label: "Chance of Precip.",
                data: dataCache.precipData.map((item) => {
                  return item.dataPoint;
                }),
                backgroundColor: "#98ff18",
              },
            ],
          },
          options: {
            interaction: {
              mode: "index",
              intersect: false,
            },
            title: {
              display: true,
              text: "Chance of Precip. (%)",
            },
          },
        });
        setComfortLevelData({
          dataSets: {
            labels: dataCache.comfortLevelData.map((item) => {
              return item.label;
            }),
            datasets: [
              {
                type: "line",
                label: "Comfort Level",
                data: dataCache.comfortLevelData.map((item) => {
                  return item.dataPoint;
                }),
                borderColor: "#800080",
              },
            ],
          },
          options: {
            interaction: {
              mode: "index",
              intersect: false,
            },
            title: {
              display: true,
              text: "Comfort Temperature (°F)",
            },
          },
        });
        setDewPointData({
          dataSets: {
            labels: dataCache.dewPointData.map((item) => {
              return item.label;
            }),
            datasets: [
              {
                type: "line",
                label: "Dew Point",
                data: dataCache.dewPointData.map((item) => {
                  return item.dataPoint;
                }),
                borderColor: "#07fd08",
              },
            ],
          },
          options: {
            interaction: {
              mode: "index",
              intersect: false,
            },
            title: {
              display: true,
              text: "Dew Point (°F)",
            },
          },
        });
        setHumidityData({
          dataSets: {
            labels: dataCache.humidityData.map((item) => {
              return item.label;
            }),
            datasets: [
              {
                type: "bar",
                label: "Humidity",
                data: dataCache.humidityData.map((item) => {
                  return item.dataPoint;
                }),
                backgroundColor: "#07fd08",
              },
            ],
          },
          options: {
            interaction: {
              mode: "index",
              intersect: false,
            },
            title: {
              display: true,
              text: "Relative Humidity (%)",
            },
          },
        });
        setPressureData({
          dataSets: {
            labels: dataCache.pressureData.map((item) => {
              return item.label;
            }),
            datasets: [
              {
                type: "line",
                label: "Pressure",
                data: dataCache.pressureData.map((item) => {
                  return item.dataPoint;
                }),
                borderColor: "#000000",
              },
            ],
          },
          options: {
            interaction: {
              mode: "index",
              intersect: false,
            },
            title: {
              display: true,
              text: 'Pressure (")',
            },
          },
        });
        setPrecipitationData({
          dataSets: {
            labels: dataCache.precipitationData.map((item) => {
              return item.label;
            }),
            datasets: [
              {
                type: "bar",
                label: "Precipitation",
                data: dataCache.precipitationData.map((item) => {
                  return item.dataPoint;
                }),
                backgroundColor: "#0304e9",
              },
            ],
          },
          options: {
            interaction: {
              mode: "index",
              intersect: false,
            },
            title: {
              display: true,
              text: "Rainfall (in.)",
            },
          },
        });
        setTemperatureData({
          dataSets: {
            labels: dataCache.temperatureData.labels.map((item) => {
              return item;
            }),
            datasets: [
              {
                type: "line",
                label: "High",
                data: dataCache.temperatureData.datasets[0].data.map((item) => {
                  return item;
                }),
                borderColor: "red",
              },
              {
                type: "line",
                label: "Average",
                data: dataCache.temperatureData.datasets[1].data.map((item) => {
                  return item;
                }),
                borderColor: "yellow",
              },
              {
                type: "line",
                label: "Low",
                data: dataCache.temperatureData.datasets[2].data.map((item) => {
                  return item;
                }),
                borderColor: "blue",
              },
            ],
          },
          options: {
            interaction: {
              mode: "index",
              intersect: false,
            },
            title: {
              display: true,
              text: "Temperature (°F)",
            },
          },
        });
        setWindSpeedData({
          dataSets: {
            labels: dataCache.windSpeedData.map((item) => {
              return item.label;
            }),
            datasets: [
              {
                type: "line",
                label: "Wind Speed",
                borderColor: "#2129d6",
                data: dataCache.windSpeedData.map((item) => {
                  return item.dataPoint;
                }),
                backgroundColor: "#2129d680",
                fill: true,
              },
            ],
          },
          options: {
            interaction: {
              mode: "index",
              intersect: false,
            },
            title: {
              display: true,
              text: "Wind Speed (MPH)",
            },
          },
        });
      }
      FusionData.forceGet("get15DayForecast")
        .then((res) => {
          //// console.log("Result for 15 Day Forecast!", res);

          const data = {
            precip: [],
            cloudCover: [],
            comfortLevel: [],
            dewPoint: [],
            humidity: [],
            pressure: [],
            precipitation: [],
            temperature: {
              //temperature is special because it needs 3 lines
              labels: [],
              datasets: [
                {
                  label: "High",
                  data: [],
                  borderColor: "red",
                },
                {
                  label: "Mean",
                  data: [],
                  borderColor: "yellow",
                },
                {
                  label: "Low",
                  data: [],
                  borderColor: "blue",
                },
              ],
            },
            windSpeed: [],
          };

          //missing:
          //  cloud cover

          res.data.result.map((item) => {
            const dateStr = DateToString(new Date(item.iso8601));

            data.precip.push({
              label: `${dateStr.weekday_short}, ${dateStr.day_short} ${dateStr.month_short}`,
              dataPoint: item.precip_prob,
            });

            data.comfortLevel.push({
              label: `${dateStr.weekday_short}, ${dateStr.day_short} ${dateStr.month_short}`,
              dataPoint: item.comfort,
            });

            data.dewPoint.push({
              label: `${dateStr.weekday_short}, ${dateStr.day_short} ${dateStr.month_short}`,
              dataPoint: item.dew_point,
            });

            data.humidity.push({
              label: `${dateStr.weekday_short}, ${dateStr.day_short} ${dateStr.month_short}`,
              dataPoint: item.humidity,
            });

            data.pressure.push({
              label: `${dateStr.weekday_short}, ${dateStr.day_short} ${dateStr.month_short}`,
              dataPoint: item.baro_pressure,
            });

            data.precipitation.push({
              label: `${dateStr.weekday_short}, ${dateStr.day_short} ${dateStr.month_short}`,
              dataPoint: item.rainfall,
            });

            //temperature
            data.temperature.labels.push(
              `${dateStr.weekday_short}, ${dateStr.day_short} ${dateStr.month_short}`
            );
            data.temperature.datasets[0].data.push(item.high_temp);
            data.temperature.datasets[1].data.push(
              (parseFloat(item.high_temp) + parseFloat(item.low_temp)) / 2
            );
            data.temperature.datasets[2].data.push(item.low_temp);

            data.windSpeed.push({
              label: `${dateStr.weekday_short}, ${dateStr.day_short} ${dateStr.month_short}`,
              dataPoint: item.wind_speed,
            });
          });

          setPrecipData({
            dataSets: {
              labels: data.precip.map((item) => {
                return item.label;
              }),
              datasets: [
                {
                  type: "bar",
                  label: "Chance of Precip.",
                  data: data.precip.map((item) => {
                    return item.dataPoint;
                  }),
                  backgroundColor: "#98ff18",
                },
              ],
            },
            options: {
              maintainAspectRatio: false,
              interaction: {
                mode: "index",
                intersect: false,
              },
              title: {
                display: true,
                text: "Chance of Precip. (%)",
              },
            },
          });
          setComfortLevelData({
            dataSets: {
              labels: data.comfortLevel.map((item) => {
                return item.label;
              }),
              datasets: [
                {
                  type: "line",
                  label: "Comfort Level",
                  data: data.comfortLevel.map((item) => {
                    return item.dataPoint;
                  }),
                  borderColor: "#800080",
                },
              ],
            },
            options: {
              maintainAspectRatio: false,
              interaction: {
                mode: "index",
                intersect: false,
              },
              title: {
                display: true,
                text: "Comfort Temperature (°F)",
              },
            },
          });
          setDewPointData({
            dataSets: {
              labels: data.dewPoint.map((item) => {
                return item.label;
              }),
              datasets: [
                {
                  type: "line",
                  label: "Dew Point",
                  data: data.dewPoint.map((item) => {
                    return item.dataPoint;
                  }),
                  borderColor: "#07fd08",
                },
              ],
            },
            options: {
              maintainAspectRatio: false,
              interaction: {
                mode: "index",
                intersect: false,
              },
              title: {
                display: true,
                text: "Dew Point (°F)",
              },
            },
          });
          setHumidityData({
            dataSets: {
              labels: data.humidity.map((item) => {
                return item.label;
              }),
              datasets: [
                {
                  type: "bar",
                  label: "Humidity",
                  data: data.humidity.map((item) => {
                    return item.dataPoint;
                  }),
                  backgroundColor: "#07fd08",
                },
              ],
            },
            options: {
              maintainAspectRatio: false,
              interaction: {
                mode: "index",
                intersect: false,
              },
              title: {
                display: true,
                text: "Relative Humidity (%)",
              },
            },
          });
          setPressureData({
            dataSets: {
              labels: data.pressure.map((item) => {
                return item.label;
              }),
              datasets: [
                {
                  type: "line",
                  label: "Pressure",
                  data: data.pressure.map((item) => {
                    return item.dataPoint;
                  }),
                  borderColor: "#000000",
                },
              ],
            },
            options: {
              maintainAspectRatio: false,
              interaction: {
                mode: "index",
                intersect: false,
              },
              title: {
                display: true,
                text: 'Pressure (")',
              },
            },
          });
          setPrecipitationData({
            dataSets: {
              labels: data.precipitation.map((item) => {
                return item.label;
              }),
              datasets: [
                {
                  type: "bar",
                  label: "Precipitation",
                  data: data.precipitation.map((item) => {
                    return item.dataPoint;
                  }),
                  backgroundColor: "#0304e9",
                },
              ],
            },
            options: {
              maintainAspectRatio: false,
              interaction: {
                mode: "index",
                intersect: false,
              },
              title: {
                display: true,
                text: "Rainfall (in.)",
              },
            },
          });
          setTemperatureData({
            dataSets: {
              labels: data.temperature.labels.map((item) => {
                return item;
              }),
              datasets: [
                {
                  type: "line",
                  label: "High",
                  data: data.temperature.datasets[0].data.map((item) => {
                    return item;
                  }),
                  borderColor: "red",
                },
                {
                  type: "line",
                  label: "Average",
                  data: data.temperature.datasets[1].data.map((item) => {
                    return item;
                  }),
                  borderColor: "yellow",
                },
                {
                  type: "line",
                  label: "Low",
                  data: data.temperature.datasets[2].data.map((item) => {
                    return item;
                  }),
                  borderColor: "blue",
                },
              ],
            },
            options: {
              maintainAspectRatio: false,
              interaction: {
                mode: "index",
                intersect: false,
              },
              title: {
                display: true,
                text: "Temperature (°F)",
              },
            },
          });
          setWindSpeedData({
            dataSets: {
              labels: data.pressure.map((item) => {
                return item.label;
              }),
              datasets: [
                {
                  type: "line",
                  label: "Wind Speed",
                  borderColor: "#2129d6",
                  data: data.pressure.map((item) => {
                    return item.dataPoint;
                  }),
                  backgroundColor: "#2129d680",
                  fill: true,
                },
              ],
            },
            options: {
              maintainAspectRatio: false,
              interaction: {
                mode: "index",
                intersect: false,
              },
              title: {
                display: true,
                text: "Wind Speed (MPH)",
              },
            },
          });

          dataCache = {
            precipData: [...data.precip],
            comfortLevelData: [...data.comfortLevel],
            dewPointData: [...data.dewPoint],
            humidityData: [...data.humidity],
            pressureData: [...data.pressure],
            precipitationData: [...data.precipitation],
            temperatureData: { ...data.temperature },
            windSpeedData: [...data.windSpeed],
          };
        })
        .catch((err) => {
          // console.log("Error getting data", err);
          setError({ state: true, message: err.message });
        });

      // DETAILED FORECAST ------------------------------------------------------------------------------
      if (dataCache?.detailedData) {
        // console.log("Something wrong!!!!!!!!!!!!!!!!!!!!!!!", dataCache);
        setDetailedData(
          chartUtils.GenerateDetailedChart(
            dataCache.detailedData,
            { legend: { display: false } },
            { color: theme.fontColor },
            { color: theme.fontColor },
            "white"
          )
        );
      } else
        FusionData.forceGet("getDetailedForecastData")
          .then((res) => {
            if (!res?.data?.result || res?.data?.result?.length == 0) return;

            res = res.data.result;

            // console.log("Result from detailed forecast data:", res);

            dataCache = {
              ...dataCache,
              detailedData: res,
            };
            // console.log("Detailed forecast final:", dataCache.detailedData);
            setDetailedData(
              chartUtils.GenerateDetailedChart(
                res,
                { legend: { display: false } },
                { color: theme.fontColor },
                { color: theme.fontColor },
                "white"
              )
            );
          })
          .catch((err) => {
            // console.log("Error getting data", err);
            setError({ state: true, message: err.message });
          });

      // 7 DAY ------------------------------------------------------------------------------
      if (dataCache?.day7Data) {
        setDay7Data(
          chartUtils.Generate7DayChart(
            dataCache.day7Data,
            { legend: { display: false } },
            6,
            4,
            { color: theme.fontColor },
            { color: theme.fontColor },
            "white"
          )
        );
      } else
        FusionData.forceGet("day7HourlyForecast")
          .then((res) => {
            if (!res?.data?.result || res?.data?.result?.length == 0) return;
            res = res.data.result;

            // console.log("Result in 7 day hourly:", res);
            dataCache = {
              ...dataCache,
              day7Data: res,
            };
            // console.log("7 Day forecast final:", dataCache.day7Data);
            setDay7Data(
              chartUtils.Generate7DayChart(
                res,
                { legend: { display: false } },
                6,
                4,
                { color: theme.fontColor },
                { color: theme.fontColor },
                "white"
              )
            );
          })
          .catch((e) => {
            // console.log("Error getting 7 day forecast:", e);
          })
          .catch((err) => {
            // console.log("Error getting data", err);
            setError({ state: true, message: err.message });
          });

      // 15 DAY ------------------------------------------------------------------------------
      if (dataCache?.day15Data) {
        setDay15Data(
          chartUtils.Generate15DayChart(
            dataCache.day15Data,
            { legend: { display: false } },
            { color: theme.fontColor },
            { color: theme.fontColor },
            "white"
          )
        );
      } else
        FusionData.forceGet("get15DayForecast")
          .then((res) => {
            // console.log("15 day forecast:", res);
            if (!res?.data?.result || res?.data?.result?.length == 0) return;

            res = res.data.result;
            // // console.log("LOOK AT THIS:", res);

            //chartjs object for mixed chart
            dataCache = {
              ...dataCache,
              day15Data: res,
            };
            // console.log("15 Day forecast final:", dataCache.day15Data);

            setDay15Data(
              chartUtils.Generate15DayChart(
                res,
                { legend: { display: false } },
                { color: theme.fontColor },
                { color: theme.fontColor },
                "white"
              )
            );
          })
          .catch((err) => {
            // console.log("Error getting data", err);
            setError({ state: true, message: err.message });
          });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dataFetch();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [uwf[UNIQUEKEY]]);

  const FusionData = useAPIData({
    successCallback: () => {
      // setFetching(false);
    },
    errorCallback: () => {
      setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });

  useEffect(() => {
    if (
      dataCache &&
      dataCache.precipData &&
      dataCache.comfortLevelData &&
      dataCache.dewPointData &&
      dataCache.humidityData &&
      dataCache.pressureData &&
      dataCache.precipitationData &&
      dataCache.temperatureData &&
      dataCache.windSpeedData
    ) {
      setFetching(false);
    }
  }, [dataCache]);

  // // console.log("Selected Tab:", selectedTab);

  const updateSelectedTab = (tab) => {
    // currentTab = tab;
    setSelectedTab(tab);
  };
  useEffect(() => {
    dataCache = null;
    setFetching(true);
  }, [widgetRefresh]);

  //------------------------------------------------------------------------------------------------
  //------------------------------------------------------------------------------------------------
  //------------------------------------------------------------------------------------------------
  useEffect(() => {
    // // console.log("Logging thing Data Cache:", dataCache);

    //get data
    if (
      dataCache &&
      dataCache.precipData &&
      dataCache.comfortLevelData &&
      dataCache.dewPointData &&
      dataCache.humidityData &&
      dataCache.pressureData &&
      dataCache.precipitationData &&
      dataCache.temperatureData &&
      dataCache.windSpeedData
    ) {
      setPrecipData({
        dataSets: {
          labels: dataCache.precipData.map((item) => {
            return item.label;
          }),
          datasets: [
            {
              type: "bar",
              label: "Chance of Precip.",
              data: dataCache.precipData.map((item) => {
                return item.dataPoint;
              }),
              backgroundColor: "#98ff18",
            },
          ],
        },
        options: {
          interaction: {
            mode: "index",
            intersect: false,
          },
          title: {
            display: true,
            text: "Chance of Precip. (%)",
          },
        },
      });
      setComfortLevelData({
        dataSets: {
          labels: dataCache.comfortLevelData.map((item) => {
            return item.label;
          }),
          datasets: [
            {
              type: "line",
              label: "Comfort Level",
              data: dataCache.comfortLevelData.map((item) => {
                return item.dataPoint;
              }),
              borderColor: "#800080",
            },
          ],
        },
        options: {
          interaction: {
            mode: "index",
            intersect: false,
          },
          title: {
            display: true,
            text: "Comfort Temperature (°F)",
          },
        },
      });
      setDewPointData({
        dataSets: {
          labels: dataCache.dewPointData.map((item) => {
            return item.label;
          }),
          datasets: [
            {
              type: "line",
              label: "Dew Point",
              data: dataCache.dewPointData.map((item) => {
                return item.dataPoint;
              }),
              borderColor: "#07fd08",
            },
          ],
        },
        options: {
          interaction: {
            mode: "index",
            intersect: false,
          },
          title: {
            display: true,
            text: "Dew Point (°F)",
          },
        },
      });
      setHumidityData({
        dataSets: {
          labels: dataCache.humidityData.map((item) => {
            return item.label;
          }),
          datasets: [
            {
              type: "bar",
              label: "Humidity",
              data: dataCache.humidityData.map((item) => {
                return item.dataPoint;
              }),
              backgroundColor: "#07fd08",
            },
          ],
        },
        options: {
          interaction: {
            mode: "index",
            intersect: false,
          },
          title: {
            display: true,
            text: "Relative Humidity (%)",
          },
        },
      });
      setPressureData({
        dataSets: {
          labels: dataCache.pressureData.map((item) => {
            return item.label;
          }),
          datasets: [
            {
              type: "line",
              label: "Pressure",
              data: dataCache.pressureData.map((item) => {
                return item.dataPoint;
              }),
              borderColor: "#000000",
            },
          ],
        },
        options: {
          interaction: {
            mode: "index",
            intersect: false,
          },
          title: {
            display: true,
            text: 'Pressure (")',
          },
        },
      });
      setPrecipitationData({
        dataSets: {
          labels: dataCache.precipitationData.map((item) => {
            return item.label;
          }),
          datasets: [
            {
              type: "bar",
              label: "Precipitation",
              data: dataCache.precipitationData.map((item) => {
                return item.dataPoint;
              }),
              backgroundColor: "#0304e9",
            },
          ],
        },
        options: {
          interaction: {
            mode: "index",
            intersect: false,
          },
          title: {
            display: true,
            text: "Rainfall (in.)",
          },
        },
      });
      setTemperatureData({
        dataSets: {
          labels: dataCache.temperatureData.labels.map((item) => {
            return item;
          }),
          datasets: [
            {
              type: "line",
              label: "High",
              data: dataCache.temperatureData.datasets[0].data.map((item) => {
                return item;
              }),
              borderColor: "red",
            },
            {
              type: "line",
              label: "Average",
              data: dataCache.temperatureData.datasets[1].data.map((item) => {
                return item;
              }),
              borderColor: "yellow",
            },
            {
              type: "line",
              label: "Low",
              data: dataCache.temperatureData.datasets[2].data.map((item) => {
                return item;
              }),
              borderColor: "blue",
            },
          ],
        },
        options: {
          interaction: {
            mode: "index",
            intersect: false,
          },
          title: {
            display: true,
            text: "Temperature (°F)",
          },
        },
      });
      setWindSpeedData({
        dataSets: {
          labels: dataCache.windSpeedData.map((item) => {
            return item.label;
          }),
          datasets: [
            {
              type: "line",
              label: "Wind Speed",
              borderColor: "#2129d6",
              data: dataCache.windSpeedData.map((item) => {
                return item.dataPoint;
              }),
              backgroundColor: "#2129d680",
              fill: true,
            },
          ],
        },
        options: {
          interaction: {
            mode: "index",
            intersect: false,
          },
          title: {
            display: true,
            text: "Wind Speed (MPH)",
          },
        },
      });
    }
    FusionData.get("get15DayForecast")
      .then((res) => {
        //// console.log("Result for 15 Day Forecast!", res);

        const data = {
          precip: [],
          cloudCover: [],
          comfortLevel: [],
          dewPoint: [],
          humidity: [],
          pressure: [],
          precipitation: [],
          temperature: {
            //temperature is special because it needs 3 lines
            labels: [],
            datasets: [
              {
                label: "High",
                data: [],
                borderColor: "red",
              },
              {
                label: "Mean",
                data: [],
                borderColor: "yellow",
              },
              {
                label: "Low",
                data: [],
                borderColor: "blue",
              },
            ],
          },
          windSpeed: [],
        };

        //missing:
        //  cloud cover

        res.data.result.map((item) => {
          const dateStr = DateToString(new Date(item.iso8601));

          data.precip.push({
            label: `${dateStr.weekday_short}, ${dateStr.day_short} ${dateStr.month_short}`,
            dataPoint: item.precip_prob,
          });

          data.comfortLevel.push({
            label: `${dateStr.weekday_short}, ${dateStr.day_short} ${dateStr.month_short}`,
            dataPoint: item.comfort,
          });

          data.dewPoint.push({
            label: `${dateStr.weekday_short}, ${dateStr.day_short} ${dateStr.month_short}`,
            dataPoint: item.dew_point,
          });

          data.humidity.push({
            label: `${dateStr.weekday_short}, ${dateStr.day_short} ${dateStr.month_short}`,
            dataPoint: item.humidity,
          });

          data.pressure.push({
            label: `${dateStr.weekday_short}, ${dateStr.day_short} ${dateStr.month_short}`,
            dataPoint: item.baro_pressure,
          });

          data.precipitation.push({
            label: `${dateStr.weekday_short}, ${dateStr.day_short} ${dateStr.month_short}`,
            dataPoint: item.rainfall,
          });

          //temperature
          data.temperature.labels.push(
            `${dateStr.weekday_short}, ${dateStr.day_short} ${dateStr.month_short}`
          );
          data.temperature.datasets[0].data.push(item.high_temp);
          data.temperature.datasets[1].data.push(
            (parseFloat(item.high_temp) + parseFloat(item.low_temp)) / 2
          );
          data.temperature.datasets[2].data.push(item.low_temp);

          data.windSpeed.push({
            label: `${dateStr.weekday_short}, ${dateStr.day_short} ${dateStr.month_short}`,
            dataPoint: item.wind_speed,
          });
        });

        setPrecipData({
          dataSets: {
            labels: data.precip.map((item) => {
              return item.label;
            }),
            datasets: [
              {
                type: "bar",
                label: "Chance of Precip.",
                data: data.precip.map((item) => {
                  return item.dataPoint;
                }),
                backgroundColor: "#98ff18",
              },
            ],
          },
          options: {
            maintainAspectRatio: false,
            interaction: {
              mode: "index",
              intersect: false,
            },
            title: {
              display: true,
              text: "Chance of Precip. (%)",
            },
          },
        });
        setComfortLevelData({
          dataSets: {
            labels: data.comfortLevel.map((item) => {
              return item.label;
            }),
            datasets: [
              {
                type: "line",
                label: "Comfort Level",
                data: data.comfortLevel.map((item) => {
                  return item.dataPoint;
                }),
                borderColor: "#800080",
              },
            ],
          },
          options: {
            maintainAspectRatio: false,
            interaction: {
              mode: "index",
              intersect: false,
            },
            title: {
              display: true,
              text: "Comfort Temperature (°F)",
            },
          },
        });
        setDewPointData({
          dataSets: {
            labels: data.dewPoint.map((item) => {
              return item.label;
            }),
            datasets: [
              {
                type: "line",
                label: "Dew Point",
                data: data.dewPoint.map((item) => {
                  return item.dataPoint;
                }),
                borderColor: "#07fd08",
              },
            ],
          },
          options: {
            maintainAspectRatio: false,
            interaction: {
              mode: "index",
              intersect: false,
            },
            title: {
              display: true,
              text: "Dew Point (°F)",
            },
          },
        });
        setHumidityData({
          dataSets: {
            labels: data.humidity.map((item) => {
              return item.label;
            }),
            datasets: [
              {
                type: "bar",
                label: "Humidity",
                data: data.humidity.map((item) => {
                  return item.dataPoint;
                }),
                backgroundColor: "#07fd08",
              },
            ],
          },
          options: {
            maintainAspectRatio: false,
            interaction: {
              mode: "index",
              intersect: false,
            },
            title: {
              display: true,
              text: "Relative Humidity (%)",
            },
          },
        });
        setPressureData({
          dataSets: {
            labels: data.pressure.map((item) => {
              return item.label;
            }),
            datasets: [
              {
                type: "line",
                label: "Pressure",
                data: data.pressure.map((item) => {
                  return item.dataPoint;
                }),
                borderColor: "#000000",
              },
            ],
          },
          options: {
            maintainAspectRatio: false,
            interaction: {
              mode: "index",
              intersect: false,
            },
            title: {
              display: true,
              text: 'Pressure (")',
            },
          },
        });
        setPrecipitationData({
          dataSets: {
            labels: data.precipitation.map((item) => {
              return item.label;
            }),
            datasets: [
              {
                type: "bar",
                label: "Precipitation",
                data: data.precipitation.map((item) => {
                  return item.dataPoint;
                }),
                backgroundColor: "#0304e9",
              },
            ],
          },
          options: {
            maintainAspectRatio: false,
            interaction: {
              mode: "index",
              intersect: false,
            },
            title: {
              display: true,
              text: "Rainfall (in.)",
            },
          },
        });
        setTemperatureData({
          dataSets: {
            labels: data.temperature.labels.map((item) => {
              return item;
            }),
            datasets: [
              {
                type: "line",
                label: "High",
                data: data.temperature.datasets[0].data.map((item) => {
                  return item;
                }),
                borderColor: "red",
              },
              {
                type: "line",
                label: "Average",
                data: data.temperature.datasets[1].data.map((item) => {
                  return item;
                }),
                borderColor: "yellow",
              },
              {
                type: "line",
                label: "Low",
                data: data.temperature.datasets[2].data.map((item) => {
                  return item;
                }),
                borderColor: "blue",
              },
            ],
          },
          options: {
            maintainAspectRatio: false,
            interaction: {
              mode: "index",
              intersect: false,
            },
            title: {
              display: true,
              text: "Temperature (°F)",
            },
          },
        });
        setWindSpeedData({
          dataSets: {
            labels: data.pressure.map((item) => {
              return item.label;
            }),
            datasets: [
              {
                type: "line",
                label: "Wind Speed",
                borderColor: "#2129d6",
                data: data.pressure.map((item) => {
                  return item.dataPoint;
                }),
                backgroundColor: "#2129d680",
                fill: true,
              },
            ],
          },
          options: {
            maintainAspectRatio: false,
            interaction: {
              mode: "index",
              intersect: false,
            },
            title: {
              display: true,
              text: "Wind Speed (MPH)",
            },
          },
        });

        dataCache = {
          precipData: [...data.precip],
          comfortLevelData: [...data.comfortLevel],
          dewPointData: [...data.dewPoint],
          humidityData: [...data.humidity],
          pressureData: [...data.pressure],
          precipitationData: [...data.precipitation],
          temperatureData: { ...data.temperature },
          windSpeedData: [...data.windSpeed],
        };
      })
      .catch((err) => {
        // console.log("Error getting data", err);
        setError({ state: true, message: err.message });
      });

    // DETAILED FORECAST ------------------------------------------------------------------------------
    if (dataCache?.detailedData) {
      // console.log("Something wrong!!!!!!!!!!!!!!!!!!!!!!!", dataCache);
      setDetailedData(
        chartUtils.GenerateDetailedChart(
          dataCache.detailedData,
          { legend: { display: false } },
          { color: theme.fontColor },
          { color: theme.fontColor },
          "white"
        )
      );
    } else
      FusionData.get("getDetailedForecastData")
        .then((res) => {
          if (!res?.data?.result || res?.data?.result?.length == 0) return;

          res = res.data.result;

          // console.log("Result from detailed forecast data:", res);

          dataCache = {
            ...dataCache,
            detailedData: res,
          };
          // console.log("Detailed forecast final:", dataCache.detailedData);
          setDetailedData(
            chartUtils.GenerateDetailedChart(
              res,
              { legend: { display: false } },
              { color: theme.fontColor },
              { color: theme.fontColor },
              "white"
            )
          );
        })
        .catch((err) => {
          // console.log("Error getting data", err);
          setError({ state: true, message: err.message });
        });

    // 7 DAY ------------------------------------------------------------------------------
    if (dataCache?.day7Data) {
      setDay7Data(
        chartUtils.Generate7DayChart(
          dataCache.day7Data,
          { legend: { display: false } },
          6,
          4,
          { color: theme.fontColor },
          { color: theme.fontColor },
          "white"
        )
      );
    } else
      FusionData.get("day7HourlyForecast")
        .then((res) => {
          if (!res?.data?.result || res?.data?.result?.length == 0) return;
          res = res.data.result;

          // console.log("Result in 7 day hourly:", res);
          dataCache = {
            ...dataCache,
            day7Data: res,
          };
          // console.log("7 Day forecast final:", dataCache.day7Data);
          setDay7Data(
            chartUtils.Generate7DayChart(
              res,
              { legend: { display: false } },
              6,
              4,
              { color: theme.fontColor },
              { color: theme.fontColor },
              "white"
            )
          );
        })
        .catch((e) => {
          // console.log("Error getting 7 day forecast:", e);
        })
        .catch((err) => {
          // console.log("Error getting data", err);
          setError({ state: true, message: err.message });
        });

    // 15 DAY ------------------------------------------------------------------------------
    if (dataCache?.day15Data) {
      setDay15Data(
        chartUtils.Generate15DayChart(
          dataCache.day15Data,
          { legend: { display: false } },
          { color: theme.fontColor },
          { color: theme.fontColor },
          "white"
        )
      );
    } else
      FusionData.get("get15DayForecast")
        .then((res) => {
          // console.log("15 day forecast:", res);
          if (!res?.data?.result || res?.data?.result?.length == 0) return;

          res = res.data.result;
          // // console.log("LOOK AT THIS:", res);

          //chartjs object for mixed chart
          dataCache = {
            ...dataCache,
            day15Data: res,
          };
          // console.log("15 Day forecast final:", dataCache.day15Data);

          setDay15Data(
            chartUtils.Generate15DayChart(
              res,
              { legend: { display: false } },
              { color: theme.fontColor },
              { color: theme.fontColor },
              "white"
            )
          );
        })
        .catch((err) => {
          // console.log("Error getting data", err);
          setError({ state: true, message: err.message });
        });

     
  }, [selectedTab, widgetRefresh]);

  //------------------------------------------------------------------------------------------------
  //------------------------------------------------------------------------------------------------
  //------------------------------------------------------------------------------------------------

  if (fetching)
    return (
      <WidgetLoadingAnimation widgetName="Charts" dimensions={dimensions} />
    );

  const RenderTab = () => {
    if (!dataCache) return;

    switch (selectedTab) {
      case 0:
        if (!detailedData) return;
        try {
          return (
            <MixedChart
              style={{
                backgroundColor: "rgba(0,0,0,0)",
                color: theme.fontColor,
                height: "100%",
              }}
              type="bar"
              dataSets={detailedData.dataSets}
              options={detailedData.options}
              plugins={detailedData.plugins}
            />
          );
        } catch (e) {
          // console.log("Error rendering chart:", e);
          return <div />;
        }

      case 1:
        if (!day7Data) return;
        try {
          return (
            <MixedChart
              style={{
                backgroundColor: "rgba(0,0,0,0)",
                color: theme.fontColor,
                height: "100%",
              }}
              type="bar"
              dataSets={day7Data.dataSets}
              options={day7Data.options}
              plugins={day7Data.plugins}
            />
          );
        } catch (e) {
          // console.log("Error rendering chart:", e);
          return <div />;
        }
      case 2:
        if (!day15Data) return;
        try {
          return (
            <MixedChart
              style={{
                backgroundColor: "rgba(0,0,0,0)",
                color: theme.fontColor,
                height: "100%",
              }}
              type="bar"
              dataSets={day15Data.dataSets}
              options={day15Data.options}
              plugins={day15Data.plugins}
            />
          );
        } catch (e) {
          // console.log("Error rendering chart:", e);
          return <div />;
        }

      //chance of precipitation
      case 3:
        if (!precipData) return;
        try {
          return (
            <MixedChart
              style={{
                backgroundColor: "rgba(0,0,0,0)",
                color: theme.fontColor,
                height: "100%",
              }}
              type="bar"
              dataSets={precipData.dataSets}
              options={precipData.options}
              plugins={precipData.plugins}
            />
          );
        } catch (e) {
          // console.log("Error rendering chart:", e);
          return <div />;
        }

      //comfort level
      case 4:
        if (!comfortLevelData) return;
        try {
          return (
            <MixedChart
              style={{
                backgroundColor: "rgba(0,0,0,0)",
                color: theme.fontColor,
                height: "100%",
              }}
              type="line"
              dataSets={comfortLevelData.dataSets}
              options={comfortLevelData.options}
              plugins={comfortLevelData.plugins}
            />
          );
        } catch (e) {
          // console.log("Error rendering chart:", e);
          return <div />;
        }

      //dew point
      case 5:
        if (!dewPointData) return;
        try {
          return (
            <MixedChart
              style={{
                backgroundColor: "rgba(0,0,0,0)",
                color: theme.fontColor,
                height: "100%",
              }}
              type="line"
              dataSets={dewPointData.dataSets}
              options={dewPointData.options}
              plugins={dewPointData.plugins}
            />
          );
        } catch (e) {
          // console.log("Error rendering chart:", e);
          return <div />;
        }

      //humidity
      case 6:
        if (!humidityData) return;
        try {
          return (
            <MixedChart
              style={{
                backgroundColor: "rgba(0,0,0,0)",
                color: theme.fontColor,
                height: "100%",
              }}
              type="bar"
              dataSets={humidityData.dataSets}
              options={humidityData.options}
              plugins={humidityData.plugins}
            />
          );
        } catch (e) {
          // console.log("Error rendering chart:", e);
          return <div />;
        }

      //pressure
      case 7:
        if (!pressureData) return;
        try {
          return (
            <MixedChart
              style={{
                backgroundColor: "rgba(0,0,0,0)",
                color: theme.fontColor,
                height: "100%",
              }}
              type="line"
              dataSets={pressureData.dataSets}
              options={pressureData.options}
              plugins={pressureData.plugins}
            />
          );
        } catch (e) {
          // console.log("Error rendering chart:", e);
          return <div />;
        }

      //precipitation
      case 8:
        if (!precipitationData) return;
        try {
          return (
            <MixedChart
              style={{
                backgroundColor: "rgba(0,0,0,0)",
                color: theme.fontColor,
                height: "100%",
              }}
              type="bar"
              dataSets={precipitationData.dataSets}
              options={precipitationData.options}
              plugins={precipitationData.plugins}
            />
          );
        } catch (e) {
          // console.log("Error rendering chart:", e);
          return <div />;
        }

      //precipitation
      case 9:
        if (!temperatureData) return;
        try {
          return (
            <MixedChart
              style={{
                backgroundColor: "rgba(0,0,0,0)",
                color: theme.fontColor,
                height: "100%",
              }}
              type="line"
              dataSets={temperatureData.dataSets}
              options={temperatureData.options}
              plugins={temperatureData.plugins}
            />
          );
        } catch (e) {
          // console.log("Error rendering chart:", e);
          return <div />;
        }

      //wind speed
      case 10:
        if (!windSpeedData) return;
        try {
          return (
            <MixedChart
              style={{
                backgroundColor: "rgba(0,0,0,0)",
                color: theme.fontColor,
                height: "100%",
              }}
              type="line"
              dataSets={windSpeedData.dataSets}
              options={windSpeedData.options}
              plugins={windSpeedData.plugins}
            />
          );
        } catch (e) {
          // console.log("Error rendering chart:", e);
          return <div />;
        }
      default:
        return <div />;
    }
  };

  if (error.state && !fetching) {
    return <ErrorMessage message={error.message} />;
  }

  if (dimensions[0] == 1 && dimensions[1] == 1) {
    if (dataCache)
      return (
        <div style={{ height: "100%", overflow: "hidden" }}>
          <WidgetTimeUpdate route={ROUTE}   />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 15,
              height: layout.widgetHeight - 80,
              // background: 'linear-gradient(0deg, rgba(255,0,0,0.6), rgba(0,0,255,0.6))',
              // height: 200
            }}
          >
            <DropdownSelector
              options={[
                "6-Day Detailed Forecast",
                "7-Day Hourly Forecast",
                "15-Day Forecast",
                "Chance of Precipitation",
                "Comfort Level",
                "Dew Point",
                "Humidity",
                "Pressure",
                "Precipitation",
                "Temperature",
                "Wind Speed",
              ]}
              selectedOption={selectedTab}
              setSelected={(index) => updateSelectedTab(index)}
            />
            {RenderTab()}
          </div>
        </div>
      );
  }

  if (dimensions[0] == 1 && dimensions[1] == 2) {
    if (dataCache)
      return (
        <div style={{ height: "100%", overflow: "hidden" }}>
          <WidgetTimeUpdate  route={ROUTE}    />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 15,
              height: layout.widgetHeight - 80,
              // background: 'linear-gradient(0deg, rgba(255,0,0,0.6), rgba(0,0,255,0.6))',
              // height: 200
            }}
          >
            <DropdownSelector
              options={[
                "6-Day Detailed Forecast",
                "7-Day Hourly Forecast",
                "15-Day Forecast",
                "Chance of Precipitation",
                "Comfort Level",
                "Dew Point",
                "Humidity",
                "Pressure",
                "Precipitation",
                "Temperature",
                "Wind Speed",
              ]}
              selectedOption={selectedTab}
              setSelected={(index) => updateSelectedTab(index)}
            />
            {RenderTab()}
          </div>
        </div>
      );
  }

  if (dimensions[0] == 2 && dimensions[1] == 1) {
    if (dataCache)
      return (
        <div style={{ height: "100%", overflow: "hidden" }}>
          <WidgetTimeUpdate  route={ROUTE}   />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 15,
              height: layout.widgetHeight - 80,
              // background: 'linear-gradient(0deg, rgba(255,0,0,0.6), rgba(0,0,255,0.6))',
              // height: 200
            }}
          >
            <DropdownSelector
              options={[
                "6-Day Detailed Forecast",
                "7-Day Hourly Forecast",
                "15-Day Forecast",
                "Chance of Precipitation",
                "Comfort Level",
                "Dew Point",
                "Humidity",
                "Pressure",
                "Precipitation",
                "Temperature",
                "Wind Speed",
              ]}
              selectedOption={selectedTab}
              setSelected={(index) => updateSelectedTab(index)}
            />
            {RenderTab()}
          </div>
        </div>
      );
  }

  // return <WidgetLoadingAnimation widgetName="Charts" dimensions={dimensions}/>
}
