import React, { useContext, useEffect, useRef, useState } from "react";
import { FaExpandAlt, FaLock, FaLockOpen } from "react-icons/fa";
import { FaGears } from "react-icons/fa6";
import { IconContext } from "react-icons";
import { IoClose } from "react-icons/io5";
import Popup from "reactjs-popup";
import { usePerms } from "hooks/usePerms";
import { ColorContext, LayoutSizeContext } from "App";

export default function Widget({
  item,
  index,
  expandable,
  list,
  onListChange,
  removeWidget,
  dashboardPreview = false,
  uninteractable,
  scale,
}) {
  const ref = useRef();
  const [modal, setModal] = useState(false);
  const perms = usePerms();

  const onClose = () => {
    setModal(false);
    ref.current.close();
  };

  const layout = useContext(LayoutSizeContext);
  const theme = useContext(ColorContext);

  return (
    <div
      // className="widget"
      style={
        dashboardPreview
          ? {
              userSelect: "none",
              backgroundColor: theme.base,
              alignSelf: "center",
              justifyContent: "center",
              height: `${100 * (1 + scale)}%`,
              width: `${100 * (1 + scale)}%`,
              transform: `scale(${scale})`,
              transformOrigin: "top left",
            }
          : {
              userSelect: "none",
              background: theme.base, //theme.base,
              alignSelf: "center",
              justifyContent: "center",
              height:
                item.h === 2 ? layout.doubleWidgetHeight : layout.widgetHeight,
            }
      }
    >
      {uninteractable && (
        <div
          style={{
            position: "absolute",
            top: 65,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1001,
          }}
        />
      )}
      <div
        style={{
          // flex: 1,
          height: 40,
        }}
      >
        <div
          className={item.isDraggable ? "handle" : ""}
          style={{
            position: "absolute",
            top: 0,
            left: 12,
            right: 100,
            height: 50,
            marginTop: 5,
            fontSize: 24,
            fontWeight: "bold",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "70%",
          }}
        >
          {item.displayName}
        </div>
        <div
          style={{
            position: "absolute",
            right: 5,
            top: 0,
            marginTop: 5,
          }}
        >
          {perms.testPermission(
            [8, 10, 11, 12, 13],
            ["WidgetsAreEditable"]
          ) && (
            <button
              onClick={() => {
                const temp = [...list];
                temp[index] = {
                  ...temp[index],
                  isDraggable: !temp[index].isDraggable,
                };
                onListChange(temp);
              }}
            >
              {item.isDraggable ? (
                <IconContext.Provider
                  value={{
                    color: theme.fontColor,
                    size: 20,
                  }}
                >
                  <FaLockOpen />
                </IconContext.Provider>
              ) : (
                <IconContext.Provider
                  value={{
                    color: theme.fontColor,
                    size: 20,
                  }}
                >
                  <FaLock />
                </IconContext.Provider>
              )}
            </button>
          )}
        </div>
        {!dashboardPreview &&
          !uninteractable &&
          item.sidebarData.href &&
          item.sidebarData.href != "" && (
            <div
              style={{
                position: "absolute",
                right: perms.testPermission(
                  [8, 10, 11, 12, 13],
                  ["WidgetsAreEditable"]
                )
                  ? 31
                  : 0,
                top: 0,
                marginTop: 5,
              }}
            >
              <button
                onClick={() => {
                  if (!expandable || !item.sidebarData.href) return;

                  // console.log("Open", item.name);
                  window.open(
                    `${item.sidebarData.href}`,
                    "_blank",
                    "noreferrer"
                  );
                }}
              >
                <IconContext.Provider
                  value={{
                    color: theme.fontColor,
                    size: 20,
                  }}
                >
                  <FaExpandAlt />
                </IconContext.Provider>
              </button>
            </div>
          )}
        {!dashboardPreview &&
          !uninteractable &&
          perms.testPermission([8, 10, 11, 12, 13], ["WidgetsAreEditable"]) && (
            <div
              style={{
                position: "absolute",
                right: 57,
                top: 0,
                marginTop: 5,
              }}
            >
              <Popup
                trigger={
                  <div>
                    <IconContext.Provider
                      value={{
                        color: theme.fontColor,
                        size: 20,
                      }}
                    >
                      <FaGears />
                    </IconContext.Provider>
                  </div>
                }
                ref={ref}
                open={modal}
                onClose={() => setModal(false)}
                closeOnDocumentClick
              >
                <div
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    borderRadius: 15,
                    userSelect: "none",
                  }}
                >
                  {perms.testPermission(
                    [8, 10, 11, 12, 13],
                    ["WidgetsAreEditable"]
                  ) && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: "5px 20px 5px 20px",
                        borderBottom: "solid darkblue 1px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        removeWidget(index);
                        onClose();
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: 20,
                          height: 20,
                          marginRight: 10,
                          backgroundColor: theme.primary,
                          borderRadius: 50,
                          color: theme.fontColor,
                        }}
                      >
                        <IoClose />
                      </div>
                      <div>Delete Widget</div>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "5px 20px 5px 20px",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                    onClick={() => onClose()}
                  >
                    <div style={{ width: 20, height: 20, marginRight: 10 }} />
                    <div>Close</div>
                  </div>
                </div>
              </Popup>
            </div>
          )}
      </div>
      <div
        style={{
          height:
            item.h === 2
              ? layout.doubleWidgetHeight - 40
              : layout.widgetHeight - 40,
          // overflow: 'auto'
          // background:'red'
        }}
      >
        {item.component(item.w, item.h, dashboardPreview)}
      </div>
    </div>
  );
}
