import React, { useContext, useState } from "react";
import Popup from "reactjs-popup";
import { IconContext } from "react-icons";
import { TbCheck, TbLetterX } from "react-icons/tb";
import ContentRow from "./ContentRow";
import { ColorContext } from "App";

const DetailsTab = ({
  formData,
  setFormData,
  checks,
  membersList,
  setMembersList,
  widgetData,
  permList,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [toSelect, setToSelect] = useState([]);

  const theme = useContext(ColorContext);
  const openModal = () => {
    setToSelect([]);
    setModalOpen(true);
  };

  const closeModal = (mode) => {
    if (mode) {
      const temp = [...membersList];
      toSelect.map((index) => {
        temp[index].selected = !temp[index].selected;
      });
      setMembersList([...temp]);
    }
    setModalOpen(false);
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        style={{
          width: 251,
          height: 49,
          marginLeft: 30,
          marginTop: 22,
          color: checks.roleName ? "white" : "red",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          {!checks.roleName && <div style={{ marginRight: 5 }}>*</div>}
          <div>Role Name</div>
        </div>
        <input
          style={{
            width: 225,
            height: 31,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: 10,
            paddingRight: 10,
            backgroundColor: theme.primary,
            fontSize: 13,
            borderRadius: 10,
            border: `1px solid grey`,
          }}
          onChange={(value) =>
            setFormData({ ...formData, roleName: value.target.value })
          }
          value={formData.roleName}
        />
      </div>
      <div>
        <div style={{ marginLeft: 33, marginTop: 23 }}>
          <ContentRow
            items={[
              <div>Users</div>,
              <div>Email</div>,
              <div>Created by</div>,
              <div>Date added</div>,
            ]}
            heightOverride={37}
          />
        </div>
        <div
          style={{
            maxHeight: window.innerHeight - 500,
            marginLeft: 33,
            overflowY: "auto",
            overflowX: "clip",
          }}
        >
          {membersList &&
            membersList.map((item, index) => {
              if (item.selected)
                return (
                  <div key={index}>
                    <ContentRow
                      items={[
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div>{item.name}</div>
                          {item.activated === 0 && (
                            <div
                              style={{
                                color: "red",
                                fontSize: 15,
                                marginLeft: 10,
                              }}
                            >
                              {"(pending)"}
                            </div>
                          )}
                        </div>,
                        <div style={{ fontSize: 16, fontWeight: "normal" }}>
                          {item.email}
                        </div>,
                        <div style={{ fontSize: 16, fontWeight: "normal" }}>
                          {item.create_by_email}
                        </div>,
                        <div style={{ fontSize: 16, fontWeight: "normal" }}>
                          {item.date_added}
                        </div>,
                      ]}
                    />
                  </div>
                );
            })}
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 176,
            height: 65,
            backgroundColor: theme.primary,
            borderRadius: 5,
            marginRight: 48,
            marginTop: 20,
          }}
          onClick={() => openModal()}
        >
          <div>Add/Remove User(s)</div>
        </button>
      </div>
      <Popup
        open={modalOpen}
        onClose={() => closeModal(false)}
        closeOnDocumentClick
        closeOnEscape
        modal
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: theme.base,
            color: theme.fontColor,
            width: 1200,
            height: 600,
            borderRadius: 15,
            border: `1px solid white`,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ fontSize: 30 }}>
            Select User(s) to Add/Remove from Role
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: 1000,
              height: 500,
              alignItems: "center",
              overflowY: "auto",
              border: "solid white 1px",
              borderRadius: 5,
            }}
          >
            {membersList &&
              membersList.map((member, index) => {
                const selectChange = toSelect.includes(index);
                return (
                  <button
                    key={index}
                    style={{
                      paddingTop: 15,
                      paddingBottom: 15,
                      display: "flex",
                      flexDirection: "column",
                      width: 900,
                      alignItems: "center",
                      fontSize: 19,
                    }}
                    onClick={() => {
                      const temp = [...toSelect];
                      if (temp.includes(index)) {
                        temp.splice(temp.indexOf(index), 1);
                      } else {
                        temp.push(index);
                      }
                      setToSelect([...temp]);
                    }}
                  >
                    <ContentRow
                      styleOverride={{
                        width: 900,
                        backgroundColor: selectChange
                          ? !member.selected
                            ? theme.primary
                            : "rgba(1, 1, 1, 0)"
                          : member.selected
                          ? theme.primary
                          : "rgba(1, 1, 1, 0)",
                      }}
                      items={[
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div>{member.name}</div>
                          {member.activated === 0 && (
                            <div
                              style={{
                                color: "red",
                                fontSize: 15,
                                marginLeft: 10,
                              }}
                            >
                              {"(pending)"}
                            </div>
                          )}
                        </div>,
                        <div style={{ fontSize: 16, fontWeight: "normal" }}>
                          {member.email}
                        </div>,
                        <div style={{ fontSize: 16, fontWeight: "normal" }}>
                          {member.create_by_email}
                        </div>,
                        <div style={{ fontSize: 16, fontWeight: "normal" }}>
                          {member.role_name}
                        </div>,
                      ]}
                    />
                  </button>
                );
              })}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: 1000,
              marginBottom: 15,
              justifyContent: "space-between",
            }}
          >
            <button
              style={{
                padding: 10,
                backgroundColor: theme.background,
                marginTop: 10,
                borderRadius: 10,
                width: 100,
                border: "solid white 1px",
              }}
              onClick={() => closeModal(false)}
            >
              <div>Cancel</div>
            </button>
            <button
              style={{
                padding: 10,
                backgroundColor: theme.primaryHighlight,
                marginTop: 10,
                borderRadius: 10,
                width: 100,
                border: "solid white 1px",
              }}
              onClick={() => closeModal(true)}
            >
              <div>Submit</div>
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default DetailsTab;
