import React, { useContext, useEffect, useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import * as api from "apis/FusionAPI";
import { ColorContext } from 'App';

// const DEMO = true
// const DEMO = false

export const LicenseInformation = ({setShowLicenseInformation, inline, activationCode = "0", callback}) => {

  const [showKey, setShowKey] = useState(false)

  const [licenseDetails, setLicenseDetails] = useState()

  const [userInputKey, setUserInputKey] = useState('')

  const [validationError, setValidationError] = useState(false)

  const [fetching, setFetching] = useState(true)

  const [userInputID, setUserInputID] = useState('')

  const [userLocation, setUserLocation] = useState({
    lat: null,
    lon: null
  })

  const [orgId, setOrgID] = useState('')
  const theme = useContext(ColorContext);

  
  useEffect(() => {
    (async() => {
      const res = await api.fusionGetLicense()
      if (res.data.subscriptionInfo) {
        if (callback) {
          callback()
        }
        setLicenseDetails(res.data.subscriptionInfo[0])
      }
      else if (res.data.activation) {
        setOrgID(res.data.activation[0].gen_wp_id)
      }
      setFetching(false)
    })().catch((err) => {
      // console.log('Error getting license details')
      // console.log(err)
      setFetching(false)
    
    })
  }, []);



  // const getLicenseAmountString = (license) => {
  //   if (license === true) return "Enabled"
  //   else if (license === false) return "Disabled"
    
  //   if (license.activeLicenses === -255 && license.maxLicenses === -255) return "Unlimited"
  //   if (license.activeLicenses === -1 && license.maxLicenses === -1) return "Not available"
   
  //   return `${license.activeLicenses} / ${license.maxLicenses} licenses used`
  // }

  // const licenseTitle = (license) => {
  //   //split string based on capital letters
  //   if (license === 'SUSP') return 'SUSP'
  //   let title = license.replace(/([A-Z])/g, ' $1').trim()
  //   title = title.charAt(0).toUpperCase() + title.slice(1)
  //   return title
  // }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        // console.log("Result of permission:", result);
        if (result.state === "granted" || result.state === "prompt") {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              //on success
              // console.log(
              //   "Location: lat =",
              //   position.coords.latitude,
              //   "lng =",
              //   position.coords.longitude
              // );
              setUserLocation({
                lat: position.coords.latitude,
                lon: position.coords.longitude
              })

              // fromLatLng(
              //   position.coords.latitude,
              //   position.coords.longitude
              // ).then((result) => {
              //   // console.log(
              //     "fromLatLng Result:",
              //     result.results[0].address_components
              //   );
              //   let city = "";
              //   let state = "";

              //   result.results[0].address_components.map((item) => {
              //     if (item.types[0] === "locality") city = item.long_name;
              //     else if (item.types[0] === "administrative_area_level_1")
              //       state = item.long_name;
              //   });
              //   setUserLocation(`${city}, ${state}`);
              //   Cookies.set("city", city);
              //   Cookies.set("state", state);
              // });
            },
            (err) => {
              console.warn(`ERROR(${err.code}): ${err.message}`);
            },
            {
              enableHighAccuracy: true,
              timeout: 5000,
              maximumAge: 0,
            }
          )
        } else {
          // console.log("Geolocation permission denied");
          alert("Please enable location access and refresh this page, otherwise authorization will not be successful.");
        }
      });
    } else {
      // console.log("Geolocation is not supported by this browser");
      alert("Geolocation unavailable in this browser");
    }
  }, []);

  const verifyLicense = () => { 
    (async() => {
      if (userInputKey.length !== 29) { return }
      // if (userInputID.length !== 5) { return }
      
      if (userLocation.lat && userLocation.lon) {
        // console.log("Verifying license successful geolocation", activationCode, userLocation.lat, userLocation.lon)
      }
      else {
        // console.log("failed", userLocation.lat, userLocation.lon, activationCode)
        
        setValidationError({
          message: "Location services not enabled. Please enable location services and try again."
        })
        return
      }
      const res = await api.fusionVerifyLicense(userInputKey, activationCode, userLocation.lat, userLocation.lon)
      if (res.status === 200) {
        // console.log('License verified')
        setValidationError(false)
        setLicenseDetails(res.data.subscriptionInfo[0])
        if (callback) {
          callback()
        }
      }
      // console.log(res)
    })().catch((err) => {
      // console.log('Error verifying license')
      if (err.response && err.response.status === 500) {
        setValidationError({
          message: "Activation failed"
        })
      }
      else { 
        setValidationError({
          message: "Invalid License Key"
        })
      }
      // console.log(err)
    })
  }

  const stringToLicense = (str) => {
    //add hyphens every 5 chars
    let license = ''
    str = str.replace(/-/g, '')
    for (let i = 0; i < str.length; i++) {
      if (i % 5 === 0 && i !== 0) license += '-'
      license += str[i]
    }
    return license
  }


  return (
    <div
      style={{
        // width: "60%",
        // height: 600,
        position: "absolute",
        // top: 400,
        // left: 400,
        background: theme.base,
        padding: 20,
        borderRadius: 10,
        border: inline ? "" : "1px solid #ccc",
      }}
    >
      {<div
        style={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ fontWeight: "bold", fontSize: 24 }}>License Information</div>
        {!inline && <div
          style={{ cursor: "pointer", fontWeight: "bold", fontSize: 24 }}
          onClick={() => setShowLicenseInformation(false)}
        >
          X
        </div>}
      </div>}
      {fetching && <div style={{marginTop: 20}}>Fetching license details...</div>}
      {!fetching && !licenseDetails && <div style={{paddingTop: 20}}>
        <div style={{display:'flex'}}>
          
        <div style={{display:'flex', alignItems:'center', paddingTop: 10}}>
          <div style={{marginRight:20}}>Organization ID: {orgId}</div>
          {/* <input 
          style={{color: "#FFF", fontFamily:'monospace', fontSize: 16, background: theme.primaryHighlight, borderRadius:40, padding: 5, width: 75}} 
          onChange={(event)=>{
            setUserInputID(event.target.value)
          }}
          maxLength={5}
            value={userInputID}
          ></input>
           */}
          </div>
        </div>
        <div style={{display:'flex', alignItems:'center', paddingTop: 10}}>
          <div style={{marginRight:20}}>License Key:</div>
          <input 
          style={{color: "#FFF", fontFamily:'monospace', fontSize: 16, background: theme.primaryHighlight, borderRadius:40, padding: 5, width: 300}} 
          onChange={(event)=>{
            setUserInputKey(event.target.value)
          }}
          maxLength={29}
            value={stringToLicense(userInputKey)}
          ></input>
          {/* <div onClick={() => setShowKey(!showKey)} >
            <FaEye style={{marginLeft: 10, cursor: 'pointer'}} />
          </div> */}
          <div style={{
            cursor: (userInputKey.length === 29) ?  'pointer' : 'auto',
            backgroundColor: (userInputKey.length === 29) ? theme.primaryHighlight : "#777",
            fontWeight: "bold",
            width: "fit-content",
            padding: "5px 10px",
            borderRadius: 10,
            marginLeft: 20
          
          }}
          
          onClick={() => verifyLicense()}
          >
            Verify
          </div>
            {validationError && <div style={{marginLeft: 20, color:'red'}}>
              {validationError.message}
            </div> }
        </div></div>}
      {!fetching && licenseDetails && <div style={{display:'flex', flexDirection:'column', gap: 10, marginTop: 20}}>
        <div style={{display:'flex'}}>
          <div style={{marginRight:20}}>Organization ID:</div>
          <div style={{color: "#ccc"}}>{licenseDetails.gen_wp_id}</div>
        </div>
        
        <div style={{display:'flex'}}>
          <div style={{marginRight:20}}>License Key:</div>
          <div style={{color: "#ccc", fontFamily:'monospace', fontSize: 16}} >{ showKey ? licenseDetails.license_key : "*****-*****-*****-*****-*****"}</div>
          <div onClick={() => setShowKey(!showKey)} >
            {showKey ? <FaEyeSlash  style={{marginLeft: 10, cursor: 'pointer'}} />: <FaEye style={{marginLeft: 10, cursor: 'pointer'}} />}
          </div>
          {/* <div style={{
            cursor: 'pointer',
            backgroundColor: theme.primaryHighlight,
            fontWeight: "bold",
            width: "fit-content",
            padding: "5px 10px",
            borderRadius: 10,
            marginLeft: 20
          
          }}>
            <button
              onClick={() => verifyLicense()}
            >
              Verify
            </button>
          </div> */}
        </div>
        
        <div style={{display:'flex'}}>
          <div style={{marginRight:20}}>License Expiration:</div>
          <div style={{color: "#ccc"}}>{
          licenseDetails.license_expiration 
          // || licenseDetails.created_date
        }</div>
        </div>

        <div style={{display:'flex', flexDirection: 'column', marginTop: 50}}>
          <div style={{marginRight:20, marginBottom: 10}}>License Details:</div>
          <div style={{background: theme.primary, width: "100%", border: "1px solid white", borderBottom: 'none', 
          // height: 375, 
          overflowY: "auto"}}>
            {/* {
              Object.keys(licenseDetails.licenseDetails).map((key, index) => {
                return (
                  <div style={{display:'flex', borderBottom: "1px solid white", padding: 10}} key={index}>
                    {licenseTitle(key)}: {getLicenseAmountString(licenseDetails.licenseDetails[key])}
                  </div>
                )
              })
            } */}
            {/* {
              Object.keys(licenseDetails).map((key, index) => {
                if (typeof licenseDetails[key] === "string" || typeof licenseDetails[key] === "number" || typeof licenseDetails[key] === "boolean") return (
                  <div style={{display:'flex', borderBottom: "1px solid white", padding: 10}} key={index}>
                    {key}: {licenseDetails[key]}
                  </div>
                )
              })
            } */}
            <div style={{display:'flex', borderBottom: "1px solid white", padding: 10}}>
              Base Fusion: {licenseDetails.fusion_activations} / {licenseDetails.fusion_max_activations} licenses used
            </div>
            <div style={{display:'flex', borderBottom: "1px solid white", padding: 10}}>
              Custom Weather: {licenseDetails.custom_weather ? "Enabled" : "Disabled"}
            </div>
            <div style={{display:'flex', borderBottom: "1px solid white", padding: 10}}>
              Basic Systems: {licenseDetails.basic_system} available
            </div>
            <div style={{display:'flex', borderBottom: "1px solid white", padding: 10}}>
              Advanced Systems: {licenseDetails.advanced_system} available
            </div>
            <div style={{display:'flex', borderBottom: "1px solid white", padding: 10}}>
              Connected Devices: {licenseDetails.connected_devices} available
            </div>
            <div style={{display:'flex', borderBottom: "1px solid white", padding: 10}}>
              Connected Cameras: {licenseDetails.connected_cameras} available
            </div>
            <div style={{display:'flex', borderBottom: "1px solid white", padding: 10}}>
              SUSP: {licenseDetails.susp ? "Enabled" : "Disabled"}
            </div>
            <div style={{display:'flex', borderBottom: "1px solid white", padding: 10}}>
              Critical Mention: {licenseDetails.critical_mention} available
            </div>
          </div>
        </div>
        {/* { DEMO && <div style={{display:'flex', alignItems: 'center'}}>
          DEMO OVERRIDE ACTIVATE EXTRA KEYS HERE
          <input 
            style={{marginLeft: 20, color: "#FFF", fontFamily:'monospace', fontSize: 16, background: theme.primaryHighlight, borderRadius:40, padding: 5, width: 300}} 
            onChange={(event)=>{
              setUserInputKey(event.target.value)
            }}
            maxLength={29}
              value={stringToLicense(userInputKey)}
            >
            
          </input>
          <div style={{
            cursor: 'pointer',
            backgroundColor: theme.primaryHighlight,
            fontWeight: "bold",
            width: "fit-content",
            padding: "5px 10px",
            borderRadius: 10,
            marginLeft: 20,
            background: (userInputKey.length === 29) ? theme.primaryHighlight : "#777",
          
          }}>
            <button
              onClick={() => verifyLicense()}
            >
              Verify
            </button>
          </div>
          
          {validationError && <div style={{marginLeft: 20, color:'red'}}>
              {validationError.message}
            </div> }
        </div>} */}
      </div>}
    </div>
  )
}
