import React, { useContext, useEffect, useState } from "react";
import GlobalActionsDropdown from "./GlobalActionsDropdown";
import * as api from "apis/FusionAPI";
import Device from "./GlobalActions/If/Device";
import CreateFusionAlert from "./GlobalActions/then/CreateFusionAlert";
import DateTime from "./GlobalActions/If/DateTime";
import { ArrayToLinkedList, LinkedListToArray } from "utils/parserUtils";
import { PiWarningFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { usePerms } from "hooks/usePerms";
import { ColorContext } from "App";

export const GlobalActionsBuilder = ({
  setShowNewGlobalAction,
  setGlobalActions,
  deviceData,
  initialData, // for editing
  setInitialData,
  setDeviceData,
}) => {
  const navigate = useNavigate();
  const perms = usePerms();
  const addGlobalAction = (action) => {
    setInitialData(null);
    setSubmitting(true);
    // console.log("Submitting built action: ", action);
    if (initialData) {
      const updatedData = {
        ...action,
        title: actionTitle,
      };
      api.updateGlobalAction(initialData.id, updatedData).then((res) => {
        // Then fetch new global actions from server
        if (res.status === 200) {
          api.getGlobalActions().then((res) => {
            setGlobalActions(res.data.result);
            setShowNewGlobalAction(false);
            setSubmitting(false);
          });
        }
      });
    } else {
      api.insertGlobalAction(action).then((res) => {
        // Then fetch new global actions from server
        if (res.status === 200) {
          api.getGlobalActions().then((res) => {
            setGlobalActions(res.data.result);
            setShowNewGlobalAction(false);
            setSubmitting(false);
          });
        }
      });
    }
  };

  const [actionTitle, setActionTitle] = useState("");

  const [retrigger, setRetrigger] = useState("");

  const [anyErrors, setAnyErrors] = useState(true);
  const [error, setError] = useState(null);

  
  const theme = useContext(ColorContext);

  const [head, setHead] = useState({
    type: "if",
    selectedAction: "-Select-",
    integrationType: "-Select-",
    integrationData: {
      // coordinates: [0, 0],
      error: true,
    },
    showDropdown: false,
    next: null,
    prev: null,
  });

  const [list, setList] = useState([head]);

  const [submitting, setSubmitting] = useState(false);

  const menuItems = {
    if: ["-Select-", "Device", "Date & Time", "Date", "Time"],
    then: ["-Select-", "Device Action", "Send Email"],
    until: [
      "-Select-",
      "Original Condition Resolved",
      "Device",
      "Date & Time",
      "Date",
      "Time",
    ],
    done: ["-Select-", "submit"],
  };

  const latLongToCityState = async (lat, long) => {
    let city, state;
    try {
      const result = await api.getCityStateFromCoordinates(lat, long);
      city = result.data.result.city_name;
      state = result.data.result.state_name;
      if (city && state) {
        return { city: city, state: state };
      }
    } catch (error) {
      console.error("Error getting city and state:", error);
    }
  };

  const renderList = (listHead) => {
    setList(LinkedListToArray(listHead));
  };

  useEffect(() => {
    renderList(head);
  }, [retrigger]);

  useEffect(() => {
    (async () => {
      if (
        !(await perms.validatePermissions([
          "ConnectedDevices",
          "Create-EditGlobalActions",
        ]))
      ) {
        navigate("/404");
        return;
      }
    })();
    api.getNodes().then((res) => {
      setDeviceData(res.data.nodeData);
    });
  }, []);

  const getAnyErrors = () => {
    //check if any until date/time is before if date/time
    const ifNodes = list.filter((item) => item.type === "if");
    const untilNodes = list.filter((item) => item.type === "until");

    const ifDates = ifNodes.map((item) => {
      if (item.integrationType === "Date & Time") {
        return item.integrationData.dateTime;
      } else if (item.integrationType === "Date") {
        return item.integrationData.date;
      } else if (item.integrationType === "Time") {
        return item.integrationData.time;
      }
    });

    const untilDates = untilNodes.map((item) => {
      if (item.integrationType === "Date & Time") {
        return item.integrationData.dateTime;
      } else if (item.integrationType === "Date") {
        return item.integrationData.date;
      } else if (item.integrationType === "Time") {
        return item.integrationData.time;
      }
    });

    // // console.log("IF Dates:", ifDates);
    // // console.log("Until Dates:", untilDates);

    for (let i = 0; i < ifDates.length; i++) {
      for (let j = 0; j < untilDates.length; j++) {
        if (ifDates[i] >= untilDates[j]) {
          setError({ message: "Invalid date/time." });
          setAnyErrors(true);
          return true;
        }
      }
    }
    setError(null);
    setAnyErrors(false);

    if (
      list
        .map((item) => {
          if (item.type === "done") return true;
          if (item.integrationType === "Original Condition Resolved")
            return true;
          if (item.integrationData && item.integrationData.error) {
            setAnyErrors(true);
            // // console.log("Some error occurred in the list!");
            return false;
          } else return true;
        })
        .includes(false)
    ) {
      if (!error) setError({ message: "Action not valid, check for errors." });
      return true;
    } else {
      // // console.log("no errors");
      setError(null);
      setAnyErrors(false);
      return false;
    }
  };

  useEffect(() => {
    getAnyErrors();
  }, [list]);

  useEffect(() => {
    if (initialData) {
      // console.log("Editing global action:", initialData);

      //RECONSTRUCT DATA INTO GLOBAL ACTION
      setActionTitle(initialData.title);

      try {
        const rawDataList = JSON.parse(initialData.rawDataList);

        // console.log("Editing initial Data:", rawDataList);

        //build linked list
        const listHead = ArrayToLinkedList(rawDataList);
        // console.log("arrList", listHead);

        setList([listHead]);
        setHead(listHead);
        renderList(listHead);
      } catch (e) {
        // console.log("Error parsing raw data list:", e);
      }
    } else {
      // console.log("New glboal action");
    }
  }, []);

  async function submitHandler() {
    if (submitting) {
      // console.log("Already submitting!");
      return;
    }
    // console.log("submitting", list);

    //verify list
    if (getAnyErrors()) {
      setError({ message: "Some action has an error." });
      return false;
    }

    // if (
    //   list
    //     .map((item) => {
    //       if (item.type === "done") return true;
    //       if (item.integrationData.error ) {
    //         setAnyErrors(true);
    //         // console.log("Some error occurred in the list!");
    //         return false;
    //       } else return true;
    //     })
    //     .includes(false)
    // ) return;
    // else {
    //   // console.log("no errors")
    //   setAnyErrors(false);
    // }

    // check for create radial alert and convert latlong to citystate
    for (let i = 0; i < list.length; i++) {
      if (list[i].integrationData.selectedFunction === "Create Radial Alert") {
        const { city, state } = await latLongToCityState(
          list[i].integrationData.coordinates[0],
          list[i].integrationData.coordinates[1]
        );
        // // console.log("City and State:", city, state);
        list[i].integrationData.city = city;
        list[i].integrationData.state = state;
      }
    }

    list.map((item) => {
      // console.log(item);
    });

    setSubmitting(true);
    // console.log("Built global action:", BuildGlobalAction(list));
    // return;
    addGlobalAction(BuildGlobalAction(list));
  }

  return (
    <div
      style={{
        marginTop: 15,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: 20,
          backgroundColor: theme.primaryShadow,
          borderRadius: 5,
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: 24,
          }}
        >
          {initialData ? "Edit" : "Add"} Global Action
          {/* {error && <div style={{ color: "red" }}>{error.message}</div>} */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <input
              type="text"
              name="title"
              placeholder="Title"
              style={{
                marginTop: 10,
                padding: 5,
                borderRadius: 5,
                border: "1px solid grey",
              }}
              onChange={(e) => {
                setActionTitle(e.target.value);
              }}
              value={actionTitle}
            />
            {actionTitle.length === 0 && (
              <div style={{ marginTop: 12 }}>
                <PiWarningFill style={{ color: "yellow" }} />
              </div>
            )}
          </div>
        </div>
        <button
          style={{
            cursor: "pointer",
            fontWeight: "bold",
            width: "fit-content",
            padding: "5px 20px",
            border: "1px solid grey",
            borderRadius: 10,
          }}
          onClick={() => {
            setShowNewGlobalAction(false);
            setInitialData(null);
          }}
        >
          Cancel
        </button>
      </div>

      {/* builder */}
      <div>
        {list.map((item, index) => {
          // // console.log("Rendering following item node:", item);

          return (
            <>
              {/* {index === list.length - 1 && error && <div style={{color: 'red', margin: 10, textAlign: 'right'}}>{error.message}</div>} */}

              <GlobalActionsDropdown
                key={index}
                itemNode={item}
                // integrationTypes={integrationTypes}
                menuItems={menuItems[item.type]}
                index={index}
                setRetrigger={setRetrigger}
                submitHandler={submitHandler}
                deviceData={deviceData}
                anyErrors={anyErrors}
                submitting={submitting}
                // error={error}
                // setError={setError}
              />
            </>
          );
        })}

        {error && (
          <div style={{ color: "red", margin: 10, textAlign: "right" }}>
            {error.message}
          </div>
        )}
      </div>
    </div>
  );

  //Convert the list of actions to a global action object
  function BuildGlobalAction(arr) {
    // console.log("Built global action input:", arr);

    let mode = 0;
    let obj = {
      state: "inactive",
      title: actionTitle || "New Global Action",
      actionName: "",
      triggerName: "",
      resolutionName: "",
      triggers: [],
      actions: [],
      resolutions: [],
      rawDataList: JSON.stringify(
        arr.map((item) => {
          const itemCopy = { ...item };
          delete itemCopy.next;
          delete itemCopy.prev;
          return itemCopy;
          //remove circular structure
          return { ...item, next: null, prev: null };
        })
      ),
    };

    let trigger = [];
    let actionName = "";
    let triggerName = "";
    let resolutionName = "";
    let actionCount = 0;
    let triggerCount = 0;
    let resolutionCount = 0;

    let date;

    //create triggers
    arr.forEach((item, index) => {
      let objMap;

      if (item.type === "if") {
        if (triggerCount === 0) triggerName = item.integrationType;
        triggerCount++;
      } else if (item.type === "then") {
        if (actionCount === 0) actionName = item.integrationType;
        actionCount++;
      } else if (item.type === "until") {
        if (resolutionCount === 0) resolutionName = item.integrationType;
        resolutionCount++;
      }

      // console.log("IntegrationType", item.integrationType);

      //map object to expected values
      switch (item.integrationType) {
        //conditions
        case "Device":
          objMap = {
            value: {
              type: "device",
              connection: item.integrationData.deviceType,
              deviceId: item.integrationData.nodeId,
              value1: {
                type: "key",
                value: item.integrationData.keyToCheck,
              },
              operation: item.integrationData.operator,
              value2: {
                type:
                  item.integrationData.valueToCompare !== ""
                    ? "immediate"
                    : "key",
                value:
                  item.integrationData.valueToCompare !== ""
                    ? item.integrationData.valueToCompare
                    : item.integrationData.keyToCompare,
              },
            },
          };
          break;

        //i am trying to make this thing trigger once, then immediately resolve after
        //  with the way the backend algorithm works, it requires a resolution :(
        //  otherwise it will become 'active' and then never return to 'inactive'
        case "Date":
          date = new Date(item.integrationData.date);
          // console.log("Datetime from Date:", date.toISOString());

          objMap = {
            value: {
              type: "date",
              value: {
                value: date,
              },
            },
          };
          break;
        case "Time":
          const strParts = item.integrationData.time.split(":");
          date = new Date();
          date.setHours(strParts[0], strParts[1], 0);
          // console.log("Datetime from Time:", date.toISOString());

          objMap = {
            value: {
              type: "time",
              value: {
                value: date,
              },
            },
          };
          break;
        case "Date & Time":
          date = new Date(item.integrationData.dateTime);
          // console.log("Datetime from DateTime:", date.toISOString());

          objMap = {
            value: {
              type: "datetime",
              value: {
                value: date,
              },
            },
          };
          break;
        case "Original Condition Resolved":
          objMap = {
            value: {
              type: "triggerresolve",
              value: {},
            },
          };
          break;

        //actions
        case "Create Fusion Alert":
          objMap = {
            value: {
              params: {
                function: "createAlert",
                params: {
                  ...item.integrationData,
                },
              },
            },
          };
          break;
        case "Device Action":
          // console.log("Built global action: device action stuff", item);

          objMap = {
            value: {
              function: "deviceAction",
              params: {
                ...item.integrationData,
                deviceId: item.integrationData.nodeId,
                actionType: item.integrationData.selectedFunction,
              },
            },
          };
          break;
        case "Send Email":
          // // console.log("Send Email:", item.integrationData);
          objMap = {
            value: {
              function: "sendEmail",
              params: {
                ...item.integrationData,
              },
            },
          };
          break;
        default:
          // // console.log("Defaulting when shouldn't", item);
          objMap = {
            value: {},
          };
          break;
      }

      objMap = {
        ...objMap,
        option: index > 0 ? arr[index - 1].selectedAction : "and",
      };

      switch (mode) {
        case 0: //if mode
          // console.log("Built global action: objMap.option", objMap.option);

          if (objMap.option.toLowerCase().includes("and"))
            trigger.push(objMap.value);
          else if (objMap.option.toLowerCase().includes("or")) {
            obj.triggers.push(trigger);
            trigger = [objMap.value];
          }

          if (objMap.option.toLowerCase().includes("then")) {
            // // console.log("Increase Trigger");
            obj.triggers.push(trigger);
            mode++;
            trigger = []; //reset trigger for until block
          } else break;
        case 1: //then mode
          // console.log("Built global action: objMap.option", objMap.option);

          if (
            (objMap.option.toLowerCase().includes("and") ||
              objMap.option.toLowerCase().includes("then")) &&
            !objMap.option.toLowerCase().includes("until") &&
            !objMap.option.toLowerCase().includes("done")
          ) {
            obj.actions.push(objMap.value);
            break;
          } else if (objMap.option.toLowerCase().includes("until")) {
            // console.log("Built global action: moving to until mode!");
            mode++;
          } else if (objMap.option.toLowerCase().includes("done")) {
            obj.resolutions.push([
              {
                type: "triggerresolve",
                value: {},
              },
            ]);
            // console.log("Built global sending object on done:", obj);
            return;
          } else break;
        case 2: //until mode
          // console.log(
          //   "Built global action: objMap.option",
          //   objMap.option,
          //   objMap
          // );

          if (!objMap.option.toLowerCase().includes("done")) {
            // console.log("Not done");
            if (
              objMap.option.toLowerCase().includes("and") ||
              objMap.option.toLowerCase().includes("then") ||
              objMap.option.toLowerCase() === "until"
            ) {
              // console.log("Until and:", objMap);
              trigger.push(objMap.value);
            } else if (objMap.option.toLowerCase().includes("or")) {
              // console.log("Until or:", objMap);
              obj.resolutions.push(trigger);
              trigger = [objMap.value];
            }

            break;
          } else {
            // console.log("Finishing up:", trigger);
            obj.resolutions.push(trigger);
            return;
          }
        default:
          break;
      }
    });

    obj = {
      ...obj,
      actionName: actionCount === 1 ? actionName : "Multiple",
      triggerName: triggerCount === 1 ? triggerName : "Multiple",
      resolutionName:
        resolutionCount === 1
          ? resolutionName
          : resolutionCount === 0
          ? "none"
          : "Multiple",
    };

    return obj;
  }
};
