import React, { useState, useEffect, useContext } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import { Switch, Space, Checkbox } from "antd";
import { SidebarContext, LayoutSizeContext, ColorContext } from "App";

export default function FullScreenMap({
  children,
  mapCenter,
  zoom,
  maxBounds,
  maxBoundsViscosity,
  minZoom,
  maxZoom,
  setView,
  style,
  satelliteToggleStyle,
}) {
  const sidebarOpen = useContext(SidebarContext);
  const layout = useContext(LayoutSizeContext);

  // window.addEventListener("resize", () => {
  //   console.log("WINDOW", window);
  // });

  const theme = useContext(ColorContext);
  const [satelliteView, setSatelliteView] = useState(false);
  const [labelsEnabled, setLabelsEnabled] = useState(true);

  const mapStyle = {
    width: "100%",
    height: "100%",
    ...style,
  };

  const containerStyle = {
    display: "flex",
    height: "100%",
    width: "100%",
    backgroundColor: theme.base,
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  };

  // const InvalidateSize = ({ layout, sidebarOpen }) => {
  //   const map = useMap();
  //   useEffect(() => {
  //     if (map != null) {
  //       console.log("map invalidated :D");
  //       map.invalidateSize();
  //     }
  //   }, [map, layout, sidebarOpen]);
  //   return null;
  // };

  return (
    <div style={containerStyle}>
      <MapContainer
        // key={layout.height}
        style={mapStyle}
        center={mapCenter}
        zoom={zoom}
        maxBounds={maxBounds}
        maxBoundsViscosity={maxBoundsViscosity}
        minZoom={minZoom}
        maxZoom={maxZoom}
        setView={setView}
      >
        {satelliteView ? (
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
          />
        ) : (
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
        )}
        {labelsEnabled && satelliteView && (
          <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}" />
        )}
        {children}
        {/* <InvalidateSize layout={window} sidebarOpen={sidebarOpen} /> */}
      </MapContainer>
      <Space
        style={{
          position: "absolute",
          bottom: "10px",
          left: "10px",
          zIndex: 1000,
          ...satelliteToggleStyle,
        }}
      >
        <Switch
          checkedChildren="Map"
          unCheckedChildren="Satellite"
          checked={!satelliteView}
          onChange={() => setSatelliteView(!satelliteView)}
        />
        {satelliteView && (
          <Checkbox
            onChange={() => setLabelsEnabled(!labelsEnabled)}
            checked={labelsEnabled}
          >
            <p style={{ color: theme.fontColor }}>Labels</p>
          </Checkbox>
        )}
      </Space>
    </div>
  );
}
