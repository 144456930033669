import React, { useContext, useState } from "react";
import ToggleButton from "./ToggleButton";
import ModeButton from "./ModeButton";
import DeviceSetting from "./DeviceSetting";
import SettingButton from "./SettingButton";
import { TbCheck } from "react-icons/tb";
import * as api from "apis/FusionAPI";
import { ColorContext } from "App";

export default function DeviceSettings({
  setShowDeviceSettings,
  arrayOfDevices,
  selectedDevice,
  nodeList,
}) {
  const device = nodeList[selectedDevice].rawData;

  const theme = useContext(ColorContext);

  const [deviceSettings, setDeviceSettings] = useState({
    node_id: device.node_id,
    uuid: device.uuid,
    imei: device.IMEI,

    depthDet1: device.depthDet1,
    depthDet2: device.depthDet2,
    depthDet3: device.depthDet3,
    depthHoldHours: device.depthHoldHours,
    ffTheshold: device.ffTheshold ?? 0, // Use fallback value if null
    dropThresh: device.dropThresh,

    mode: device.mode,
    samp: device.samp,

    dDetImgEnable: device.dDetImgEnable,
    ffiImgEnable: device.ffiImgEnable ?? 0, // Use fallback value if null
    dRateImgEnable: device.dRateImgEnable,
    hResImgEnable: device.hResImgEnable,

    saltwater: 0,
    externalDevice: "Sensor only",
    calibration: device.calibration,
    resetCalibration: false,
    triggeredImagingHoldOff: 1,
    imgHoldStart: device.imgHoldStart,
    imgHoldEnd: device.imgHoldEnd,

    imageRequest: false,
    gpsSync: false,

    ipAddress: device.ipAddress,
    port: device.port,
    water_level_adj: device.water_level_adj,
    actualDepth: device.water_level_adj + device.depth1,

    reboot: false,
  });
  // console.log("deviceSettings", deviceSettings);
  const [ipParts, setIpParts] = useState(deviceSettings.ipAddress.split("."));

  const handleIpPartChange = (partIndex, newPart) => {
    const newIpParts = [...ipParts];
    newIpParts[partIndex] = newPart;
    setIpParts(newIpParts);
    setDeviceSettings({
      ...deviceSettings,
      ipAddress: newIpParts.join("."),
    });
  };

  const formatTime = (hour) => {
    if (hour === 0) {
      return "12AM";
    } else if (hour < 12) {
      return `${hour}AM`;
    } else if (hour === 12) {
      return "12PM";
    } else {
      return `${hour - 12}PM`;
    }
  };

  const parseTime = (time) => {
    if (time === "12AM") {
      return 0;
    } else if (time.endsWith("AM")) {
      return parseInt(time, 10);
    } else if (time === "12PM") {
      return 12;
    } else {
      return parseInt(time, 10) + 12;
    }
  };

  const handleInputChange = (field, value) => {
    setDeviceSettings((prevSettings) => ({
      ...prevSettings,
      [field]: value,
    }));
  };

  const reportingModesOptions = {
    0: "Auto",
    1: "Fast",
    2: "Slow",
  };
  const samplingModesOptions = { 0: "30 seconds", 1: "60 seconds" };

  const imagingModes = [
    {
      displayName: "Depth Detection Imaging",
      settingName: "dDetImgEnable",
    },
    { displayName: "Rise Rate Imaging", settingName: "ffiImgEnable" },
    { displayName: "Drop Rate Imaging", settingName: "dRateImgEnable" },
    {
      displayName: "High Resolution Imaging",
      settingName: "hResImgEnable",
    },
  ];

  const getChangedFields = (original, updated) => {
    let changes = {};

    console.log("Look at me:", original);
    console.log("Look at me 2:", updated);

    for (let key in updated) {
      if (key === "node_id" || key === "imei" || key === "uuid") {
        // Always include node_id, IMEI, and UUID
        changes[key] = updated[key];
        continue;
      }

      if (key === "saltwater") {
        // Always ignore saltwater
        continue;
      }

      if (original[key] === null && updated[key] === 0) {
        // Treat null in original and 0 in updated as unchanged for specific fields
        if (key === "ffTheshold" || key === "ffiImgEnable") {
          continue;
        }
      }

      if (updated[key] !== original[key]) {
        console.log("Diff:", key);
        changes[key] = updated[key];
      }
    }

    return changes;
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundColor: theme.base,
          padding: 30,
          maxHeight: "calc(100% - 60px)",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <h1>{nodeList[selectedDevice].nodeId} Device Settings</h1>
            <label style={{ marginLeft: 50 }}>Copy settings from: </label>
            <select
              value={deviceSettings.nodeId}
              onChange={(e) => {
                const selectedOption = arrayOfDevices.find(
                  (device) => device.nodeId == e.target.value
                ).rawData;
                // console.log("selectedOption", selectedOption);
                setDeviceSettings({
                  ...deviceSettings,
                  depthDet1: selectedOption.depthDet1,
                  depthDet2: selectedOption.depthDet2,
                  depthDet3: selectedOption.depthDet3,
                  depthHoldHours: selectedOption.depthHoldHours,
                  ffTheshold: selectedOption.ffTheshold,
                  dropThresh: selectedOption.dropThresh,
                  mode: selectedOption.mode,
                  samp: selectedOption.samp,
                  dDetImgEnable: selectedOption.dDetImgEnable,
                  ffiImgEnable: selectedOption.ffiImgEnable ?? 0, // Use fallback value if null
                  dRateImgEnable: selectedOption.dRateImgEnable,
                  hResImgEnable: selectedOption.hResImgEnable,
                });
              }}
              style={{
                backgroundColor: theme.primaryHighlight,
                padding: 5,
                borderRadius: 5,
                marginLeft: 10,
              }}
            >
              {arrayOfDevices.map((device) => {
                return (
                  <option key={device.nodeId} value={device.nodeId}>
                    {device.data[0].node_alias}
                  </option>
                );
              })}
            </select>
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setShowDeviceSettings(false)}
          >
            <h2>X</h2>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "row", marginTop: 30 }}>
          <div style={{ borderRight: "1px solid grey", width: 500 }}>
            <div style={{ marginTop: 15 }}>
              <h4>Threshold Settings</h4>
              <DeviceSetting
                settingName="Depth Detection Threshold 1"
                settingDataName="depthDet1"
                setting={deviceSettings.depthDet1}
                metric={"inches"}
                min={1}
                max={254}
                turnOffAt={255}
                deviceSettings={deviceSettings}
                handleInputChange={handleInputChange}
                device={device}
              />
              <DeviceSetting
                settingName="Depth Detection Threshold 2"
                settingDataName="depthDet2"
                setting={deviceSettings.depthDet2}
                metric={"inches"}
                min={1}
                max={254}
                turnOffAt={255}
                deviceSettings={deviceSettings}
                handleInputChange={handleInputChange}
                device={device}
              />
              <DeviceSetting
                settingName="Depth Detection Threshold 3"
                settingDataName="depthDet3"
                setting={deviceSettings.depthDet3}
                metric={"inches"}
                min={1}
                max={254}
                turnOffAt={255}
                deviceSettings={deviceSettings}
                handleInputChange={handleInputChange}
                device={device}
              />
              <DeviceSetting
                settingName="Depth Detection Holdoff"
                settingDataName="depthHoldHours"
                setting={deviceSettings.depthHoldHours}
                metric={"hours"}
                min={0}
                max={24}
                turnOffAt={0}
                deviceSettings={deviceSettings}
                handleInputChange={handleInputChange}
                device={device}
              />
              <DeviceSetting
                settingName="Rise Rate Threshold"
                settingDataName="ffTheshold"
                setting={deviceSettings.ffTheshold}
                metric={"in/min"}
                min={0}
                max={2.54}
                turnOffAt={null}
                step={0.01}
                deviceSettings={deviceSettings}
                handleInputChange={handleInputChange}
                device={device}
              />
              <DeviceSetting
                settingName="Drop Rate Threshold"
                settingDataName="dropThresh"
                setting={deviceSettings.dropThresh}
                metric={"in/min"}
                min={0}
                max={2.54}
                turnOffAt={2.55}
                step={0.01}
                deviceSettings={deviceSettings}
                handleInputChange={handleInputChange}
                device={device}
              />

              <h4 style={{ marginTop: 15 }}>Modes</h4>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 5,
                }}
              >
                <div
                  style={{ width: 10, marginRight: 15, alignItems: "center" }}
                >
                  {deviceSettings.mode !== device.mode && (
                    <TbCheck color="green" size={20} />
                  )}
                </div>
                <label>Reporting Modes:</label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: theme.primaryShadow,
                    borderRadius: 50,
                    marginLeft: 5,
                    marginBottom: 10,
                  }}
                >
                  {Object.entries(reportingModesOptions).map(
                    ([mode, modeName]) => (
                      <ModeButton
                        key={mode}
                        mode={mode}
                        modeName={modeName}
                        keyName="mode"
                        setMode={() => {
                          setDeviceSettings({
                            ...deviceSettings,
                            mode: parseInt(mode),
                          });
                        }}
                        deviceSettings={deviceSettings}
                      />
                    )
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 5,
                }}
              >
                <div
                  style={{ width: 10, marginRight: 15, alignItems: "center" }}
                >
                  {deviceSettings.samp !== device.samp && (
                    <TbCheck color="green" size={20} />
                  )}
                </div>
                <label>Sampling Modes:</label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: theme.primaryShadow,
                    borderRadius: 50,
                    marginLeft: 5,
                  }}
                >
                  {Object.entries(samplingModesOptions).map(
                    ([mode, modeName]) => (
                      <ModeButton
                        key={mode}
                        mode={mode}
                        modeName={modeName}
                        keyName="samp"
                        setMode={() => {
                          setDeviceSettings({
                            ...deviceSettings,
                            samp: parseInt(mode),
                          });
                        }}
                        deviceSettings={deviceSettings}
                      />
                    )
                  )}
                </div>
              </div>
            </div>

            <div>
              <h4 style={{ marginTop: 15 }}>Imaging Modes</h4>
              {imagingModes.map(({ displayName, settingName }) => (
                <SettingButton
                  key={settingName}
                  displayName={displayName}
                  settingName={settingName}
                  deviceSettings={deviceSettings}
                  setDeviceSettings={setDeviceSettings}
                  device={device}
                />
              ))}
            </div>
          </div>

          <div style={{ marginLeft: 30 }}>
            {/* <SettingButton
              displayName={"Saltwater Adjustment"}
              settingName={"saltwater"}
              deviceSettings={deviceSettings}
              setDeviceSettings={setDeviceSettings}
              device={device}
            /> */}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 15,
              }}
            >
              <div style={{ width: 10, marginRight: 15, alignItems: "center" }}>
                {deviceSettings.externalDevice !== device.externalDevice && (
                  <TbCheck color="green" size={20} />
                )}
              </div>
              <label>External Device:</label>
              <input
                type="text"
                value={deviceSettings.externalDevice}
                onChange={(e) => {
                  setDeviceSettings({
                    ...deviceSettings,
                    externalDevice: e.target.value,
                  });
                }}
                style={{
                  marginLeft: 5,
                  backgroundColor: theme.primaryHighlight,
                  textAlign: "center",
                  padding: 5,
                  borderRadius: 5,
                }}
              />
              <span>Vol/tip (in.)</span>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 15,
              }}
            >
              <div style={{ width: 10, marginRight: 15, alignItems: "center" }}>
                {deviceSettings?.water_level_adj !==
                  device?.water_level_adj && (
                  <TbCheck color="green" size={20} />
                )}
              </div>
              <label>Water Level Adj.:</label>
              <input
                type="number"
                value={deviceSettings?.water_level_adj}
                onChange={(e) => {
                  setDeviceSettings({
                    ...deviceSettings,
                    water_level_adj: e.target.value,
                  });
                }}
                style={{
                  marginLeft: 5,
                  backgroundColor: theme.primaryHighlight,
                  borderRadius: 5,
                  width: 50,
                  textAlign: "center",
                }}
              />
              <span>Value (in.)</span>
            </div>

            {/* <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 15,
              }}
            >
              <div style={{ width: 10, marginRight: 15, alignItems: "center" }}>
                {deviceSettings.actualDepth !== device.actual_depth && (
                  <TbCheck color="green" size={20} />
                )}
              </div>
              <label>Actual Depth:</label>
              <input
                type="number"
                value={deviceSettings?.actualDepth}
                onChange={(e) => {
                  setDeviceSettings({
                    ...deviceSettings,
                    actualDepth: e.target.value,
                  });
                }}
                style={{
                  marginLeft: 5,
                  backgroundColor: theme.primaryHighlight,
                  borderRadius: 5,
                  width: 50,
                  textAlign: "center",
                }}
              />
              <span>Value (in.)</span>
            </div> */}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 15,
              }}
            >
              <div style={{ width: 10, marginRight: 15, alignItems: "center" }}>
                {deviceSettings.calibration !== device.calibration && (
                  <TbCheck color="green" size={20} />
                )}
              </div>
              <label>Calibration Offset:</label>
              <input
                type="number"
                value={deviceSettings.calibration}
                onChange={(e) => {
                  setDeviceSettings({
                    ...deviceSettings,
                    calibration: e.target.value,
                  });
                }}
                style={{
                  marginLeft: 5,
                  backgroundColor: theme.primaryHighlight,
                  borderRadius: 5,
                  width: 50,
                  textAlign: "center",
                }}
              />
              <span>Value (in.)</span>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 15,
                marginLeft: 150,
              }}
            >
              <input
                type="checkbox"
                style={{ marginLeft: 5, padding: 10, transform: "scale(1.5)" }}
                checked={deviceSettings.resetCalibration}
                onChange={(e) => {
                  const value = e.target.checked;
                  setDeviceSettings((prevSettings) => ({
                    ...prevSettings,
                    resetCalibration: value,
                  }));
                }}
              />
              <label style={{ marginLeft: 10 }}>Reset Calibration</label>
            </div>

            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 5,
                  marginTop: 15,
                }}
              >
                <SettingButton
                  displayName={"Triggered Imaging Holdoff"}
                  settingName={"triggeredImagingHoldOff"}
                  displayCheckmark={false}
                  deviceSettings={deviceSettings}
                  setDeviceSettings={setDeviceSettings}
                  device={device}
                />
              </div>
            </div>
            <div
              style={{
                marginLeft: 200,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 5,
                }}
              >
                <div
                  style={{ width: 10, marginRight: 15, alignItems: "center" }}
                >
                  {deviceSettings.imgHoldStart !== device.imgHoldStart && (
                    <TbCheck color="green" size={20} />
                  )}
                </div>
                <select
                  value={formatTime(deviceSettings.imgHoldStart)}
                  onChange={(e) => {
                    setDeviceSettings({
                      ...deviceSettings,
                      imgHoldStart: parseTime(e.target.value),
                    });
                  }}
                  disabled={!deviceSettings.triggeredImagingHoldOff}
                  style={{
                    marginLeft: 5,
                    backgroundColor: theme.primaryHighlight,
                    borderRadius: 5,
                    width: 100,
                    textAlign: "center",
                    padding: 5,
                  }}
                >
                  {Array.from({ length: 24 }, (_, i) => formatTime(i)).map(
                    (time, i) => (
                      <option key={i} value={time}>
                        {time}
                      </option>
                    )
                  )}
                </select>
                <label style={{ marginLeft: 15 }}>Hour Start</label>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  style={{ width: 10, marginRight: 15, alignItems: "center" }}
                >
                  {deviceSettings.imgHoldEnd !== device.imgHoldEnd && (
                    <TbCheck color="green" size={20} />
                  )}
                </div>
                <select
                  value={formatTime(deviceSettings.imgHoldEnd)}
                  onChange={(e) => {
                    setDeviceSettings({
                      ...deviceSettings,
                      imgHoldEnd: parseTime(e.target.value),
                    });
                  }}
                  disabled={!deviceSettings.triggeredImagingHoldOff}
                  style={{
                    marginLeft: 5,
                    backgroundColor: theme.primaryHighlight,
                    borderRadius: 5,
                    width: 100,
                    textAlign: "center",
                    padding: 5,
                  }}
                >
                  {Array.from({ length: 24 }, (_, i) => formatTime(i)).map(
                    (time, i) => (
                      <option key={i} value={time}>
                        {time}
                      </option>
                    )
                  )}
                </select>
                <label style={{ marginLeft: 15 }}>Hour End</label>
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <h4 style={{ marginTop: 15, marginBottom: 10 }}>
                Action Requests
              </h4>
              <div style={{ marginLeft: 10 }}>
                <div>
                  <label>Image Request</label>
                  <input
                    type="checkbox"
                    style={{ marginLeft: 5, transform: "scale(1.5)" }}
                    checked={deviceSettings.imageRequest}
                    onChange={(e) => {
                      const value = e.target.checked;
                      setDeviceSettings((prevSettings) => ({
                        ...prevSettings,
                        imageRequest: value,
                      }));
                    }}
                  />
                </div>
                <div>
                  <label>GPS Sync</label>
                  <input
                    type="checkbox"
                    style={{
                      marginLeft: 5,
                      marginTop: 5,
                      transform: "scale(1.5)",
                    }}
                    checked={deviceSettings.gpsSync}
                    onChange={(e) => {
                      const value = e.target.checked;
                      setDeviceSettings((prevSettings) => ({
                        ...prevSettings,
                        gpsSync: value,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                marginTop: 20,
                padding: 10,
                borderColor: "red",
                borderWidth: 1,
                borderStyle: "solid",
              }}
            >
              <h4>Advanced Settings</h4>
              <label>Do not modify unless directed by AWARE support</label>
              <div
                style={{
                  marginTop: 15,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div>
                  <label>Destination IP Address:</label>
                  {ipParts.map((part, index) => (
                    <input
                      key={index}
                      type="text"
                      value={part}
                      onChange={(e) =>
                        handleIpPartChange(index, e.target.value)
                      }
                      style={{
                        marginLeft: 15,
                        backgroundColor: theme.primaryHighlight,
                        borderRadius: 5,
                        width: 50,
                        textAlign: "center",
                      }}
                    />
                  ))}
                </div>

                <div style={{ marginTop: 10 }}>
                  <label>Destination Port:</label>
                  <input
                    type="text"
                    value={deviceSettings.port}
                    onChange={(e) => {
                      setDeviceSettings({
                        ...deviceSettings,
                        port: e.target.value,
                      });
                    }}
                    style={{
                      marginLeft: 5,
                      backgroundColor: theme.primaryHighlight,
                      borderRadius: 5,
                      width: 50,
                      textAlign: "center",
                    }}
                  />
                </div>
                <div style={{ marginTop: 10 }}>
                  <label style={{ color: "red" }}>Reboot:</label>
                  <input
                    type="checkbox"
                    style={{ marginLeft: 5, transform: "scale(1.5)" }}
                    checked={deviceSettings.reboot}
                    onChange={(e) => {
                      const value = e.target.checked;
                      setDeviceSettings((prevSettings) => ({
                        ...prevSettings,
                        reboot: value,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button
            onClick={async () => {
              const changedFields = getChangedFields(device, deviceSettings);
              try {
                const response = await api.changeAwareDeviceSettings(
                  changedFields
                );
                alert("The server received the request.");
                setShowDeviceSettings(false);
              } catch (error) {
                console.error(error.message);
              }
            }}
            style={{ padding: 30, backgroundColor: theme.primaryHighlight }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
