import { testPermission } from "data/routeData";
import React, { useContext, useEffect, useState } from "react";
import * as api from "apis/FusionAPI";
import useDebounce from "hooks/useDebounce";
import { AlertListPage } from "components/AlertListPage";
import { useAPIData } from "hooks/useAPIData";
import { usePerms } from "hooks/usePerms";
import { useNavigate } from "react-router-dom";
import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";
import { UniqueWidgetRefreshContext } from "App";
import Cookies from "js-cookie";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import useDataRefresh from "hooks/useDataRefresh";

let dataCache;

export default function PIAlerts({
  dimensions,
  widgetRefresh,
  setWidgetRefresh,
  sidebarOpen,
}) {
  const [alertsList, setAlertsList] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState({ state: false, message: "" });

  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });
  const perms = usePerms();
  const navigate = useNavigate();

  useEffect(() => {
    dataCache = null;
    setFetching(true);
  }, [widgetRefresh]);

  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "getAllActiveAlerts";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.getAllActiveAlerts();
        //await FusionData.get(ROUTE);
        //console.log("alerts: ", response)
        setAlertsList(response.data.alerts);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [uwf[UNIQUEKEY]]);

  const dataRefresh = useDataRefresh();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.getAllActiveAlerts();
        //await FusionData.get(ROUTE);
        //console.log("alerts: ", response)
        setAlertsList(response.data.alerts);
        setFetching(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [dataRefresh.widgetRefresh]);

  useEffect(() => {
    (async () => {
      if (!(await perms.validatePermissions(["ViewAlerts"]))) {
        navigate("/404");
        return;
      }
    })();
  }, []);

  if (fetching) {
    return <WidgetLoadingAnimation widgetName={"ActiveAlerts"} dimensions={dimensions}/>;
  }

  if (perms.testPermission([8, 10, 11, 12, 13], ["ViewAlerts"])) {
    if (dimensions) {
      return (
        <AlertListPage
          sidebarOpen={sidebarOpen}
          widget
          dimensions={dimensions}
          title={"Active Public Alerts"}
          alertsList={alertsList}
          setAlertsList={setAlertsList}
          ROUTE={ROUTE}
        />
      );
    } else {
      return (
        <div style={{height: "calc(100% - 20px)"}}>
          <WidgetTimeUpdate route={ROUTE} realtime dataUpdateOnly addMargin/>
          <AlertListPage
            sidebarOpen={sidebarOpen}
            title={"Active Public Alerts"}
            alertsList={alertsList}
            setAlertsList={setAlertsList}
            ROUTE={ROUTE}
          />
        </div>
      );
    }
  }
}
