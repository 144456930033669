//NOTE: Someone will probably have to rewrite this. Probably several times. Goodluck.
//All of this is just to group the results in objects that share an 'air_station_name'.
export function FixOutputOfEndpointToTheCorrectFormat_AirQualityForecast(
  rawData
) {
  //extract correct data
  rawData = [...rawData.data.result]; //WARNING: This, ".data.result", changes A LOT. Like, an unreasonable amount...
  const fixedData = [];
  const foundStationNames = [];

  //remove unnessicary copied data, because we couldn't put it into mysql -_-
  rawData.map((item, index) => {
    if (!foundStationNames.includes(item.air_station_name)) {
      //add it to the list
      foundStationNames.push(item.air_station_name);
      fixedData.push({
        air_station_name: item.air_station_name,
        forecast_report: [
          {
            iso8601: item.iso8601,
            category: item.category,
            concentration: item.concentration,
          },
        ],
      });
    } else {
      //append item within the found list
      fixedData
        .filter((check) => {
          return check.air_station_name === item.air_station_name;
        })[0]
        .forecast_report.push({
          iso8601: item.iso8601,
          category: item.category,
          concentration: item.concentration,
        });
    }
  });

  return fixedData;
}

export function FixOutputOfEndpointToTheCorrectFormat_TideData(rawData) {
  // // console.log("Data before:", rawData);

  rawData = [...rawData.data.result];
  const fixedData = [];
  const foundTideNames = [];

  rawData.map((item) => {
    // // console.log("Item", item, "Found Cities:", foundTideNames);

    if (!foundTideNames.includes(item.tide_name)) {
      foundTideNames.push(item.tide_name);
      fixedData.push({
        location: item.tide_name,
        forecast: [{ ...item }],
      });
    } else {
      fixedData
        .filter((check) => {
          return check.location === item.tide_name;
        })[0]
        .forecast.push({ ...item });
    }
  });

  // // console.log("Data after:", fixedData);
  return fixedData;
}
