import { ColorContext } from "App";
import React, { useContext } from "react";
import Popup from "reactjs-popup";

const DropdownSelect = ({ items, setData, data }) => {
  const theme = useContext(ColorContext);
  return (
    <Popup
      trigger={
        <div
          style={{
            width: 225,
            height: 31,
            display: "flex",
            flexDirection: "row",
            // justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: 10,
            paddingRight: 10,
            backgroundColor: theme.primaryHighlight,
            fontSize: 13,
            borderRadius: 10,
            gap: 20,
            border: `1px solid grey`,
            color: theme.fontColor, // Added to ensure text is visible against the background
          }}
        >
          <div
            style={{
              width: 200,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {(() => {
              return Object.keys(data)
                .filter((item) => data[item]) // Filter roles that are true
                .join(", "); // Join with ", "
            })()}
          </div>
        </div>
      }
      position="bottom center"
      on="hover"
      closeOnDocumentClick
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          padding: 10,
          backgroundColor: theme.primaryHighlight,
          color: theme.fontColor,
          borderRadius: 10,
          cursor: "pointer",
          // gap: 5
        }}
      >
        {items &&
          items.map((item, index) => (
            <div
              key={index}
              style={{
                background: data[item] ? theme.primaryShadow : "",
                padding: 5,
                // borderRadius: 5,
              }}
              onClick={() => {
                setData((prev) => ({
                  ...prev,
                  [item]: !prev[item],
                }));
              }}
            >
              <input
                type="checkbox"
                checked={data[item]}
                readOnly
              />{" "}
              {item}
            </div>
          ))}
      </div>
    </Popup>
  );
};

export default DropdownSelect;
