import { useEffect } from "react";
import { useMap, useMapEvents } from "react-leaflet";

//Gets the center of the map when the map is moved
export default function GetCenter({ setGetMapCenter }) {
  const map = useMapEvents({
    moveend: (e) => {
      setGetMapCenter(e.target.getCenter())
    },
  });
  return null;
}
