import { ColorContext } from "App";
import React, { useContext, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import Popup from "reactjs-popup";

export default function DropdownSelector({
  options,
  selectedOption,
  setSelected,
  triggerStyle,
  contentStyle,
}) {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  
  const theme = useContext(ColorContext);

  // // console.log(
  //   "Options:",
  //   options,
  //   "Selected:",
  //   selectedOption,
  //   "Selected value:",
  //   options[selectedOption]
  // );

  return (
    <Popup
      on={"click"}
      trigger={
        <button
          style={{
            // flex: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: theme.primary,
            borderRadius: 5,
            ...triggerStyle,
          }}
        >
          <div style={{ marginLeft: 10 }}>{options[selectedOption]}</div>
          <div style={{ marginRight: 10 }}>
            <FaChevronDown />
          </div>
        </button>
      }
      open={dropDownOpen}
      onOpen={() => setDropDownOpen(true)}
      onClose={() => setDropDownOpen(false)}
      position={"bottom center"}
      closeOnDocumentClick
      closeOnEscape
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: theme.primary,
          borderRadius: 5,
          width: 400,
          padding: 5,
          color: theme.fontColor,
          ...contentStyle,
        }}
      >
        {options.map((item, index) => {
          return (
            <button
              key={index}
              style={{ padding: 5 }}
              onClick={() => {
                setSelected(index);
                setDropDownOpen(false);
              }}
            >
              {item}
            </button>
          );
        })}
      </div>
    </Popup>
  );
}
