import React, { useContext, useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, Circle } from "react-leaflet";

import aware from "assets/aware.png";
import awarecam from "assets/awarecam.png";

import L from "leaflet";
import LocationSetter from "components/AlertCreator/components/LocationSetter";
import Cookies from "js-cookie";
import { ColorContext } from "App";

const awareIcon = L.icon({
  iconUrl: aware,
  iconSize: [25, 41],
});

const awareCamIcon = L.icon({
  iconUrl: awarecam,
  iconSize: [25, 41],
});

export default function RadialAlertMap({ arrayOfDevices, selectedDevice, radius, setSelectedDevice }) {
  const [userPosition, setUserPosition] = useState(null);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setUserPosition([position.coords.latitude, position.coords.longitude]);
    });
  }, []);
  const theme = useContext(ColorContext);

  const [mapCenter, setMapCenter] = useState([Cookies.get("latitude"), Cookies.get("longitude")]);

  useEffect(() => {
    if (selectedDevice && selectedDevice.lat && selectedDevice.lon) {
      setMapCenter([selectedDevice.lat, selectedDevice.lon]);
    }
  }, [selectedDevice]);

  
  useEffect(() => {
    if (Cookies.get("latitude") && Cookies.get("longitude")) {
      setUserPosition([Cookies.get("latitude"), Cookies.get("longitude")]);
      // console.log("User position from cookies")
      return
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setUserPosition([position.coords.latitude, position.coords.longitude]);
        // console.log("User position from navigator")
      });
    }
    else {
      setUserPosition([0,0])
      // console.log("User position not found")
    }

  }, []);


  return (
    <div>
      {userPosition && (
        <MapContainer
          center={mapCenter}
          setMapCenter={setMapCenter}
          zoom={13}
          style={{ height: 400, width: 400 }}
        >
          <LocationSetter center={mapCenter} zoom={16} />
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={userPosition}>
            <Popup>Your location</Popup>
          </Marker>
          {selectedDevice && <Circle center={mapCenter} radius={radius * 0.3048} />}
          {arrayOfDevices.map((device) => (
            <Marker
              key={device.nodeId}
              position={[device.lat, device.lon]}
              icon={
                device.imgList &&
                device.imgList.length > 0 &&
                device.imgList[0].url
                  ? awareCamIcon
                  : awareIcon
              }
            >
              <Popup>
                <div>
                  <h2>Node ID: {device.nodeId}</h2>
                  <p>Status: {device.status}</p>
                  <p>Type: {device.type}</p>
                  {device.imgList &&
                    device.imgList.length > 0 &&
                    device.imgList[0].url && (
                      <img
                        src={device.imgList[0].url}
                        alt="Device"
                        style={{ width: "100px", height: "100px" }}
                      />
                    )}
                  <div style={{
                    padding: 5,
                    backgroundColor: theme.primary,
                    color: theme.fontColor,
                    borderRadius: 5,
                    textAlign: 'center',
                    cursor: 'pointer'
                  }}
                    onClick={() => {
                      setSelectedDevice(device.nodeId);
                    }}
                  >Use this device</div>
                </div>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      )}
    </div>
  );
}
