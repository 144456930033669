import React, { useContext, useEffect, useState } from 'react'
import { validatePassword } from 'utils/passwordUtils';
import * as api from "apis/FusionAPI";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import PasswordInput from 'components/UI/PasswordInput';
import { ColorContext } from 'App';

export const UpdatePassword = ({setShowChangePassword}) => {
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);
  const [currentPassword, setCurrentPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [error, setError] = useState(null);
  const theme = useContext(ColorContext);

  const handleSubmit = () => {
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return
    }
		if (!currentPassword) {
			setError("Original password is required");
			return
		}

    if(!validatePassword(newPassword).isValid){
      setError(validatePassword(newPassword).message);
      return
    }
    setError(null);
    // make api call to change password
    (async () => {
      try {
        const res = await api.updateFusionPassword(currentPassword, newPassword);
        // console.log("password changed", res);
        setError("Password changed successfully");
        // setShowChangePassword(false);
        setChangePasswordSuccess(true);

        setConfirmPassword(null)
        setCurrentPassword(null)
        setNewPassword(null)

      } catch (e) {
        setError("Error changing password: " + e.response.data.message);
        // console.log("error changing password", e);
      }
    })()
  }
  
  useEffect(() => {
    if (newPassword === null || confirmPassword === null || currentPassword == null) {
      setError(null)
      return
    }
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return
    }
    if (newPassword === currentPassword) {
      setError("New password cannot be the same as the current password");
      return
    }
    if(!validatePassword(newPassword).isValid){
      setError(validatePassword(newPassword).message);
      return
    }

    setError(null);
    
  }, [newPassword, confirmPassword])

  if (!changePasswordSuccess) return (
    <div style={{
      backgroundColor: theme.base,
      width: 'max-content',
      display:'flex',
      flexDirection: 'column',
      alignItems:'flex-start',
      padding: 10
    }}>
      <div>
        <button onClick={() => {
          setShowChangePassword(false)
          setConfirmPassword(null)
          setCurrentPassword(null)
          setNewPassword(null)

        }} style={{padding: 10, background: theme.primary, borderRadius:20, marginBottom: 10 }}>Back</button>
      </div>
      <div style={{padding: 5, display:'flex', flexDirection: 'column', justifyContent:'space-between', width: 450}}>
      Current password
      <PasswordInput 
        callback={setCurrentPassword} 
        value={currentPassword}
      />
      </div>
      <div style={{padding: 5, display:'flex', flexDirection: 'column', justifyContent:'space-between', width: 450}}>
      New password
      <PasswordInput 
        callback={setNewPassword} 
        value={newPassword}
      />
      </div>
      <div style={{padding: 5, display:'flex', flexDirection: 'column', justifyContent:'space-between', width: 450}}>
      Confirm new password
      <PasswordInput 
        callback={setConfirmPassword} 
        value={confirmPassword}
      />
      </div>
      <button onClick={handleSubmit} style={{padding: 10, background: theme.primary, borderRadius:20, marginTop: 10 }}>Update Password</button>
      {error && <div style={{color:'red', marginTop: 10}}>{error}</div>}

    </div>
  )

  if (changePasswordSuccess) return (
    <div style={{
      backgroundColor: theme.base,
      width: 'max-content',
      display:'flex',
      flexDirection: 'column',
      alignItems:'flex-start',
      padding: 10
    }}>
      Password Update Successful
      <button onClick={() => {
        setShowChangePassword(false)
        setChangePasswordSuccess(false)
        setConfirmPassword(null)
        setCurrentPassword(null)
        setNewPassword(null)
      }} style={{padding: 10, background: theme.primary, borderRadius:20, marginTop: 10 }}>Return to Account</button>

    </div>
  )
}
