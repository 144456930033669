import { SetUniqueWidgetRefreshContext, UniqueWidgetRefreshContext } from "App";
import Cookies from "js-cookie";
import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
import routeOffsetMinutes from "utils/routeOffsetMinutes.js";

const WidgetTimeUpdate = ({
  // dataTime,
  // updateOffsetMinutes = 3,
  realtime = false,
  // realtime = true,
  updateIntervalBasis = 360,
  route = null,
  dataUpdateOnly = false,
  addMargin = false
}) => {
  const updateOffsetMinutes = routeOffsetMinutes[route] || 3;
  // console.log("updateOffsetMinutes: ", updateOffsetMinutes, route);

  const setUwf = useContext(SetUniqueWidgetRefreshContext);
  const uwf = useContext(UniqueWidgetRefreshContext);
  const [nextUpdate, setNextUpdate] = useState(
    new Date().toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    })
  );
  const [currTime, setCurrTime] = useState(
    new Date().toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    })
  );
  const nextUpdateRef = useRef(nextUpdate); // Create a ref to keep track of the latest state

  const UPDATE_MINUTES = 3; //3; //interval to check for updates

  const memoizedUwf = useMemo(() => uwf, [uwf]);
  const memoizedSetUwf = useMemo(() => setUwf, [setUwf]);

  useEffect(() => {
    const getUpdateTime = () => {
      setCurrTime(
        new Date().toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: true,
        })
      );
      const newUpdateTime = calculateNextTime();
      setNextUpdate(newUpdateTime);
      nextUpdateRef.current = newUpdateTime; // Update the ref with the latest state

      if (route !== null) {
        const location = `${Cookies.get("city")}-${Cookies.get("state")}`;
        const uniqueKey = `${location}-${route}`;

        if (memoizedUwf[uniqueKey] !== newUpdateTime) {
          // console.log(
          //   "uwf found update ",
          //   uniqueKey,
          //   new Date().toLocaleTimeString()
          // );
          memoizedSetUwf({
            ...memoizedUwf,
            [uniqueKey]: newUpdateTime.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              hour12: true,
            }),
          });
        }
      }
    };

    getUpdateTime();
    const interval = setInterval(() => {
      getUpdateTime();
    }, 1000 * 60 * UPDATE_MINUTES); // every 5 minutes, calculate the next update time
    return () => clearInterval(interval);
  }, [
    // route,
    memoizedUwf[route],
    // memoizedSetUwf,
    // updateIntervalBasis,
    // updateOffsetMinutes,
    // realtime,
  ]);

  // useEffect(() => {
  //   console.log(
  //     "time update for", route
  //   )
  //   setCurrTime(
  //     new Date().toLocaleString("en-US", {
  //       hour: "numeric",
  //       minute: "numeric",
  //       second: "numeric",
  //       hour12: true,
  //     })
  //   );
  // }, [memoizedUwf[route]]);

  const calculateNextTime = () => {
    if (!route) {
      return;
    }
    if (realtime) {
      const now = new Date();
      now.setMinutes(now.getMinutes() + 1); // Add one minute
      now.setSeconds(0); // Set seconds to 0
      now.setMilliseconds(0); // Set milliseconds to 0
      return now.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
      });
    }
    const now = new Date();
    let currentMinutes = now.getUTCHours() * 60 + now.getUTCMinutes() - 300;

    let lastInterval =
      Math.floor(currentMinutes / updateIntervalBasis) * updateIntervalBasis;
    let nextUpdateMinutes = lastInterval + updateOffsetMinutes;

    if (currentMinutes >= nextUpdateMinutes) {
      nextUpdateMinutes += updateIntervalBasis;
    }

    const nextUpdate = new Date(
      now.getTime() + (nextUpdateMinutes - currentMinutes) * 60 * 1000
    );
    nextUpdate.setSeconds(0); // Set seconds to 0
    nextUpdate.setMilliseconds(0); // Set milliseconds to 0

    return nextUpdate.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
  };

  if (dataUpdateOnly) {
    // return null;
  }

  return (
    <div
      style={{
        marginTop: 2,
        borderBottom: "solid grey 1px",
        height: 16,
        marginBottom: addMargin ? 10 : 0,
      }}
    >
      <div
        style={{
          marginLeft: 15,
          marginBottom: 5,
          marginRight: 15,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", gap: 10 }}>
          <div
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {currTime.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              hour12: true,
            })}
          </div>
          <div
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            Next update: {nextUpdate}
          </div>
        </div>
        {/* <div>
          <button
            style={{}}
            onClick={() => {
              memoizedSetUwf({
                ...uwf,
                [`${Cookies.get("city")}-${Cookies.get("state")}-${route}`]:
                  new Date().toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: true,
                  }),
              });
              setCurrTime(
                new Date().toLocaleString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                  hour12: true,
                })
              );
            }}
          >
            ↺
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default WidgetTimeUpdate;
