import React, { useEffect, useState } from "react";
import TabsHeader from "../../components/TabsHeader";
import { FactOfTheDay } from "components/SpecialReports/FactOfTheDay";
import { HistoricalClimate } from "components/SpecialReports/HistoricalClimate";
import { FireDanger } from "components/SpecialReports/FireDanger";
import { EarthquakeReports } from "components/SpecialReports/EarthquakeReports";
import { SkiReports } from "components/SpecialReports/SkiReports";
import { AirportDelay } from "components/SpecialReports/AirportDelay";
import { useNavigate, useSearchParams } from "react-router-dom";
import { usePerms } from "hooks/usePerms";
import EarthquakeMap from "components/Maps/Earthquake/EarthquakeMap";

//TODO: EarthquakeReports
//TODO: SkiReports

//TODO: Widgets

export default function SpecialReports() {
  const [params, setParams] = useSearchParams();
  const [tabs, setTabs] = useState([0]);
  const perms = usePerms();
  const navigate = useNavigate();

  useEffect(() => {
    const initTab = [params.get("tab") ? parseInt(params.get("tab")) : 0];
    setTabs([...initTab]);

    (async () => {
      if (!(await perms.validatePermissions(["SpecialReportsEnabled"]))) {
        navigate("/404");
        return;
      }
    })();
  }, []);

  const RenderTab = () => {
    switch (tabs[0]) {
      // case 0:
      //   return <FactOfTheDay data={testData.facts} />;
      // case 1:
      //   return <HistoricalClimate data={testData.historicalClimate} />;
      case 0:
        return <FireDanger />;
      case 1:
        return <EarthquakeMap />;
      case 2:
        return <SkiReports />;
      case 3:
        return <AirportDelay />;

      default:
        return <div />;
    }
  };

  return (
    <div
      className="page-root"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {/* header */}
      <TabsHeader //depth = 2
        onTabChange={(newTabArr) => setTabs([...newTabArr])}
        currentTabs={tabs}
        options={[
          { option: "Fire Danger" },
          { option: "Earthquake Reports" },
          { option: "Ski Reports" },
          { option: "Airport Delay" },
        ]}
        styles={[{}, { backgroundColor: "#436c98" }]}
      />

      {/* content */}
      <div
        style={{
          height: "calc(100% - 48px)",
          // backgroundColor: "green",
        }}
      >
            {RenderTab()}
      </div>
    </div>
  );
}
