import { message } from 'antd'
import { ColorContext } from 'App'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'

const SendEmail = ({setData, data, setError}) => {

// const [storedData, setStoredData] = useState({
//   dateTime: type === "dateTime" ? new Date() : "",
//   date: type === "date" ? new Date() : "",
//   time: type === "time" ? new Date() : ""
// })

useEffect(() => {
  if (data?.subject === undefined || data?.body === undefined|| data?.recipient === undefined) {
    setData({
      subject: "",
      body: "",
      recipient: "",
      error: true
    }) 
  }
}, [])

const updateData = (item) => {
  // // console.log("UPDATEDATA:", data, item)
  
  setData({
    ...data,
    ...item,
    error: verifyData({...data, ...item})
  })
}

const theme = useContext(ColorContext);
const verifyData = (data) => {
  
  let error = false
  if (data.subject === "" || data.body === "" || data.recipient === "") { 
    error = true
    setError({message: "Action incomplete, check for missing fields."}) 
  }
  //regex to check if email is valid
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+(,\s*[^\s@]+@[^\s@]+\.[^\s@]+)*$/
  if (!emailRegex.test(data.recipient)) { 
    error = true 
    setError({message: "Invalid email address"})
  }

  
  if (!error) {
    setError(null)
  }
  return error
}

// useEffect(() => {
//   setData(storedData)
// }, [storedData])

  return (
    <div style={{padding: 20,
      borderRadius: 10,
      margin: 10,
      background: theme.primary,}}>
      <div >
        <p style={{ color: theme.fontColor }}>Recipient</p>
        <input
        
        style={{
          padding: 5,
          borderRadius: 5,
          border: "1px solid grey",
          marginLeft: 10,
        }}
          type="text"
          name="recipient"
          value={data.recipient}
          onChange={(e) => updateData({recipient: e.target.value})}
        />
      </div>
      <div>
        <p style={{ color: theme.fontColor }}>Subject</p>
        <input
        
        style={{
          padding: 5,
          borderRadius: 5,
          border: "1px solid grey",
          marginLeft: 10,
        }}
          type="text"
          name="subject"
          value={data.subject}
          onChange={(e) => updateData({subject: e.target.value})}
        />
      </div>
      <div>
        <p style={{ color: theme.fontColor }}>Body</p>
        <textarea
        
        style={{
          padding: 5,
          borderRadius: 5,
          border: "1px solid grey",
          marginLeft: 10,
          width: 400
        }}
          lines={4}
          type="text"
          name="body"
          value={data.body}
          onChange={(e) => updateData({body: e.target.value})}
        />
      </div>
    </div>
  )
}

export default SendEmail