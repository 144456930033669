import React, { useContext, useEffect, useState } from "react";
import * as d3 from "d3";
import { RiArrowRightSLine, RiArrowDownSLine } from "react-icons/ri";
import { MapContainer, TileLayer, Polygon, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import * as api from "../apis/FusionAPI";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
// import { testPermission } from "data/routeData";
import countyJSON from "data/slim_county.json";
import { calculateDistanceInMiles, flattenPolygon } from "utils/geometryUtils";
import { splitByCharacter } from "utils/stringUtils";
import Massive_Unionized_MultiPolygons from "data/Massive_Unionized_MultiPolygons.json";
import { usePerms } from "hooks/usePerms";
import { ColorContext } from "App";

export function AlertCard({
  widget,
  dimensions,
  index,
  alert,
  getAlerts,
  canCloseAlert,
  localOnly = false,
  maxDistance = -1,
  selectedAlert,
  setSelectedAlert,
  setMapCenter,
  setMapPolygon,
}) {
  const [showInfo, setShowInfo] = useState(false);
  const [polygon, setPolygon] = useState(null);
  const [center, setCenter] = useState(null);
  const theme = useContext(ColorContext);

  const perms = usePerms();

  const colorScale = d3
    .scaleLinear()
    .domain([0, 66, 100])
    .range(["#CD2C2E", "#EE8918", "#34A100"]);

  function calculateCentroid(polygon) {
    let totalX = 0;
    let totalY = 0;

    for (let i = 0; i < polygon.length; i++) {
      totalX += polygon[i][0];
      totalY += polygon[i][1];
    }

    const centroidX = totalX / polygon.length;
    const centroidY = totalY / polygon.length;

    return [centroidX, centroidY];
  }

  useEffect(() => {
    // console.log(alert);
    if (alert.polygon) {
      const singlePolygon = JSON.parse(alert.polygon);
      setPolygon([singlePolygon]);

      const centroid = calculateCentroid(singlePolygon);
      setCenter(centroid);
      // setCenter(alert.centroid);
    } else if (alert.zoneCodes) {
      const zoneCodes = [...new Set(JSON.parse(alert.zoneCodes))];
      let polygons = [];
      let centroids = [];

      zoneCodes.forEach((zoneCode) => {
        try {
          const responses = Massive_Unionized_MultiPolygons[zoneCode];
          responses.forEach((response) => {
            const innerPolygon = flattenPolygon(response).map((coord) => [
              coord[1],
              coord[0],
            ]);
            polygons.push(innerPolygon);

            const flippedForCentroid = innerPolygon.map((coord) => [
              coord[1],
              coord[0],
            ]);

            let avgLon = 0;
            let avgLat = 0;
            flippedForCentroid.forEach((coord) => {
              avgLon += coord[0];
              avgLat += coord[1];
            });
            avgLon /= flippedForCentroid.length;
            avgLat /= flippedForCentroid.length;

            const centroid = [avgLon, avgLat];
            centroids.push(centroid);
          });
        } catch (e) {
          // console.log(e);
        }
      });

      setPolygon(polygons.length > 0 ? polygons : [[0, 0]]);

      const averageLongitude = d3.mean(centroids, (d) => d[0]);
      const averageLatitude = d3.mean(centroids, (d) => d[1]);

      const averageCentroid = [averageLatitude, averageLongitude];
      setCenter(averageCentroid);
    }
  }, [alert]);

  useEffect(() => {
    if (index === 0) {
      if (polygon && center && setSelectedAlert) {
        setSelectedAlert(alert);
        setMapCenter(center);
        setMapPolygon(polygon);
      }
    }
  }, [polygon, center, setSelectedAlert]);

  const closeAlert = async () => {
    if (!perms.testPermission([8, 10, 11, 12, 13], ["DeleteAlerts"])) return;

    try {
      api.setAlertEnd(alert.message_id, parseInt(alert.workplaceId));
    } catch (e) {
      // console.log(e);
    }
  };

  function ChangeView({ center, bounds }) {
    const map = useMap();
    map.setView(center, map.getBoundsZoom(bounds));
    return null;
  }

  function getCenterDistance() {
    if (!center) return null;
    const userLat = Cookies.get("latitude");
    const userLon = Cookies.get("longitude");
    const alertLat = center[0];
    const alertLon = center[1];
    //use latlon distance formula
    const distance = calculateDistanceInMiles(
      userLat,
      userLon,
      alertLat,
      alertLon,
    );

    // console.log(distance)
    return distance;
  }

  function getSeverityLabel(severity) {
    if (severity >= 0 && severity <= 24) return "High";
    if (severity >= 25 && severity <= 49) return "Med-High";
    if (severity >= 50 && severity <= 74) return "Med";
    if (severity >= 75 && severity <= 100) return "Low";
  }

  if (
    !localOnly ||
    (maxDistance > 0 && localOnly && getCenterDistance() < maxDistance)
  ) {
    if (!widget) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: theme.primary,
            borderRadius: 10,
            marginTop: 10,
            width: "100%",
          }}
        >
          <div
            style={{
              backgroundColor: colorScale(alert.severity),
              width: 30,
              marginRight: 20,
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
            }}
          />

          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div
                onClick={() => setShowInfo(!showInfo)}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: showInfo ? "solid grey 1px" : "",
                  cursor: "pointer",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ margin: 10, marginRight: 10, width: 400 }}>
                  {/* <h1>{index}</h1> */}
                  <h1>{alert.type}</h1>
                  <h3>{alert.sender}</h3>
                  <h3>
                    In Effect {alert.sentTime} - {alert.endTime}
                  </h3>
                  {center && (
                    <h3>
                      Coordinates: {Number(center[0]).toFixed(5)}° N,{" "}
                      {Number(center[1]).toFixed(5)}° W
                    </h3>
                  )}
                </div>

                <div
                  key="map"
                  style={{ margin: 5, height: "150px", width: "200px" }}
                  onClick={(e) => e.stopPropagation()}
                >
                  {center && polygon && polygon[0][0] !== 0 && (
                    <MapContainer
                      center={center}
                      zoom={11}
                      style={{ height: "100%", width: "100%" }}
                    >
                      <ChangeView center={center} bounds={polygon} />
                      <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      />
                      {polygon.map((poly, index) => (
                        <Polygon key={index} positions={poly} />
                      ))}
                    </MapContainer>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: 80,
                  }}
                >
                  {canCloseAlert && (
                    <button
                      style={{
                        backgroundColor: "green",
                        borderRadius: 10,
                        padding: 10,
                        margin: 5,
                      }}
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you want to close this alert? This action cannot be undone.",
                          )
                        ) {
                          // console.log("Alert deleted");
                          closeAlert();
                          getAlerts();
                        }
                      }}
                    >
                      Close
                    </button>
                  )}
                  <button onClick={() => setShowInfo(!showInfo)}>
                    {showInfo ? (
                      <RiArrowDownSLine size={30} />
                    ) : (
                      <RiArrowRightSLine size={30} />
                    )}
                  </button>
                </div>
              </div>
            </div>

            {showInfo && (
              <div style={{ paddingRight: 15 }}>
                <div>
                  <div
                    style={{
                      marginTop: 10,
                      marginBottom: 15,
                      fontSize: 20,
                      fontWeight: "bold",
                    }}
                  >
                    {alert.provider?.toLowerCase().includes("fusion") && (
                      <div style={{ padding: 2 }}>
                        <div>{alert.type}</div>
                        <div>
                          Until: {new Date(alert.endTime).toLocaleString()}
                        </div>
                      </div>
                    )}
                    {splitByCharacter(alert.headline, ";").map(
                      (line, index) => {
                        if (line.includes("till:")) {
                          // Parse the date string to a Date object
                          const parsedDate = new Date(
                            line.replace("till:", ""),
                          );

                          // Get the current year
                          const currentYear = new Date().getFullYear();

                          // Set the year of the parsed date to the current year
                          parsedDate.setFullYear(currentYear);

                          // Convert the modified date back to a locale string
                          const updatedDateLocaleString =
                            parsedDate.toLocaleString();
                          return (
                            <div key={index} style={{ padding: 2 }}>
                              Until: {updatedDateLocaleString}
                            </div>
                          );
                        } else
                          return (
                            <div key={index} style={{ padding: 2 }}>
                              {line}
                            </div>
                          );
                      },
                    )}
                  </div>
                  <div style={{ marginBottom: 15 }}>
                    {splitByCharacter(alert.description, "*").map(
                      (line, index) => {
                        if (line)
                          return (
                            <li key={index} style={{ padding: 2 }}>
                              {line}
                            </li>
                          );
                      },
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }

    if (widget) {
      if (dimensions[0] === 1 && dimensions[1] === 1) {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 1,
            }}
          >
            <div
              style={{
                backgroundColor: colorScale(alert.severity),
                width: 30,
                height: 70,
                marginRight: 5,
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
              }}
            />
            <p style={{ width: 90 }}>{getSeverityLabel(alert.severity)}</p>
            <p style={{ width: 150, textAlign: "center" }}>{alert.type}</p>
            <p style={{ width: 220, textAlign: "center" }}>{alert.sender}</p>
          </div>
        );
      }

      if (dimensions[0] === 1 && dimensions[1] === 2) {
        return (
          <div
            onClick={() => {
              setSelectedAlert(alert);
              setMapCenter(center);
              setMapPolygon(polygon);
            }}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              marginBottom: 1,
              borderRadius: 10,
              backgroundColor:
                selectedAlert?.message_id === alert.message_id ? theme.primary : "",
            }}
          >
            <div
              style={{
                backgroundColor: colorScale(alert.severity),
                width: 30,
                height: 70,
                marginRight: 5,
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
              }}
            />
            <p style={{ width: 90 }}>{getSeverityLabel(alert.severity)}</p>
            <p style={{ width: 150, textAlign: "center" }}>{alert.type}</p>
            <p style={{ width: 220, textAlign: "center" }}>{alert.sender}</p>
          </div>
        );
      }

      if (dimensions[0] === 2 && dimensions[1] === 1) {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 1,
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: 140,
              }}
            >
              <div
                style={{
                  backgroundColor: colorScale(alert.severity),
                  width: 30,
                  height: 70,
                  marginRight: 5,
                  borderTopLeftRadius: 10,
                  borderBottomLeftRadius: 10,
                }}
              />
              <p>{getSeverityLabel(alert.severity)}</p>
            </div>
            <p style={{ width: 200 }}>{alert.type}</p>
            <p style={{ width: 200 }}>{alert.sender}</p>
            {canCloseAlert ? (
              <button
                style={{
                  backgroundColor: "green",
                  borderRadius: 10,
                  padding: 10,
                  width: 150,
                  height: 50,
                  marginLeft: 45,
                }}
                onClick={() => {
                  if (
                    window.confirm(
                      "Are you sure you want to close this alert? This action cannot be undone.",
                    )
                  ) {
                    // console.log("Alert deleted");
                    closeAlert();
                    getAlerts();
                  }
                }}
              >
                Close
              </button>
            ) : (
              <p style={{ width: 150 }}>Active</p>
            )}
          </div>
        );
      }
    }
  }
}
