import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as api from "../../../apis/FusionAPI";
import ConfirmPopup from "components/UI/ConfirmPopup";
import ContentRow from "./ContentRow";
import PopupMenu from "./PopupMenu";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import { ColorContext } from "App";

export default function RolesTab({
  list,
  mousePosition,
  setMousePosition,
  perms,
  setRoleList,
}) {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selected, setSelected] = useState(-1);
  const [showConfirmDeleteRole, setShowConfirmDeleteRole] = useState(false);
  const [showCantRemoveRole, setShowCantRemoveRole] = useState(false);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const res = await api.getAllRoles();
        console.log("Get all roles:", res);
        setRoleList([...res.data.result]);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchRoles();
  }, []);
  const theme = useContext(ColorContext);

  const handleDeleteRole = async (roleId) => {
    const res = await api.deleteRole(roleId);
    setShowConfirmDeleteRole(false);
    console.log("Delete role:", res);
    await api.getAllRoles().then((res) => {
      console.log("Get all roles:", res);
      setRoleList([...res.data.result]);
      setShowConfirmDeleteRole(false);
    });
  };

  const onCreateRole = async () => {
    navigate("/system/createrole");
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
      }}
    >
      {showConfirmDeleteRole && (
        <ConfirmPopup
          open={showConfirmDeleteRole}
          setOpen={setShowConfirmDeleteRole}
          message={`Are you sure you want to remove this role?`}
          options={["Cancel", "Yes"]}
          buttonStyles={[
            { backgroundColor: theme.background, color: theme.fontColor },
            { backgroundColor: theme.primaryHighlight, color: theme.fontColor },
          ]}
          callbacks={[
            () => setShowConfirmDeleteRole(false),
            () => handleDeleteRole(list[selected].role_Id),
          ]}
        />
      )}
      {showCantRemoveRole && (
        <ConfirmPopup
          open={showCantRemoveRole}
          setOpen={setShowCantRemoveRole}
          message={`The ${
            list[selected].role_name
          } Role cannot be removed with ${
            list[selected].role_count
          } assigned User${
            list[selected].role_count > 1 ? "s" : ""
          }. Remove all users from this role and try again.`}
          options={["Ok"]}
          buttonStyles={[
            { backgroundColor: theme.background, color: theme.fontColor },
          ]}
          callbacks={[() => setShowCantRemoveRole(false)]}
        />
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{
            height: 39,
            backgroundColor: theme.primary,
            borderRadius: 10,
            paddingLeft: 15,
            paddingRight: 15,
          }}
        >
          <input
            placeholder="Search..."
            style={{
              width: "100%",
              height: 39,
            }}
            onChange={(value) => setSearch(value.target.value)}
            value={search}
          />
        </div>
      </div>
      <div>
        <ContentRow
          items={[
            <div>Roles</div>,
            <div>Users</div>,
            <div>Created By</div>,
            <div />,
          ]}
          heightOverride={37}
        />
      </div>
      <div
        style={{
          width: "100%",
          height: "100%",
          overflowY: "auto",
        }}
      >
        {list.length === 0 && <WidgetLoadingAnimation />}
        {list &&
          list.map((item, index) => {
            return (
              <div key={index}>
                {item.role_name
                  .toLowerCase()
                  .replace(/ /g, "")
                  .includes(search.toLowerCase().replace(/ /g, "")) && (
                  <ContentRow
                    items={[
                      <div>{item.role_name}</div>,
                      <div>{item.role_count}</div>,
                      <div>{item.created_by_email}</div>,
                      (perms.testPermission(
                        [8, 10, 11, 12, 13],
                        ["EditRole"]
                      ) ||
                        perms.testPermission(
                          [8, 10, 11, 12, 13],
                          ["DeleteRole"]
                        )) &&
                        item.role_Id >= 0 && (
                          <button
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: 1266 / 4,
                              height: 71,
                            }}
                            onClick={(e) => {
                              setSelected(index);
                              setMousePosition({
                                x: e.clientX,
                                y: e.clientY,
                              });
                              setModalOpen(true);
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#2a3e52",
                                borderRadius: 100,
                                width: 39,
                                height: 39,
                              }}
                            >
                              ...
                            </div>
                          </button>
                        ),
                    ]}
                  />
                )}
              </div>
            );
          })}
      </div>
      {perms.testPermission([8, 10, 11, 12, 13], ["CreateRole"]) && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <button
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 176,
              height: 65,
              backgroundColor: theme.primary,
              borderRadius: 5,
            }}
            onClick={onCreateRole}
          >
            <div>Create Role</div>
          </button>
        </div>
      )}
      <PopupMenu
        contents={
          <div>
            {perms.testPermission([8, 10, 11, 12, 13], ["EditRole"]) && (
              <button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 200,
                  height: 50,
                  borderRadius: 15,
                  backgroundColor: theme.primary,
                  border: "solid black 1px",
                  color: theme.fontColor,
                }}
                onClick={() =>
                  navigate(`/system/editrole?roleid=${list[selected].role_Id}`)
                }
              >
                EDIT
              </button>
            )}
            {perms.testPermission([8, 10, 11, 12, 13], ["DeleteRole"]) && (
              <button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 200,
                  height: 50,
                  borderRadius: 15,
                  backgroundColor: "red",
                  border: "solid black 1px",
                  color: theme.fontColor,
                }}
                onClick={() => {
                  list[selected]?.role_count > 0
                    ? setShowCantRemoveRole(true)
                    : setShowConfirmDeleteRole(true);
                }}
              >
                REMOVE
              </button>
            )}
          </div>
        }
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        mousePosition={mousePosition}
      />
    </div>
  );
}
