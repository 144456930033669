import axios from "axios";
import Cookies from "js-cookie";

const LOCALHOST = "";

let logAPIuse = false;
if (process.env.NODE_ENV === "development") {
  logAPIuse = true;
}
let apiHits = 0;

let href = window.location.origin;
href = href.replace("http://", "");
href = href.replace("https://", "");
href = href.split(".")[0];
// console.log("href", href)

export let FUSION_PREFIX = "/fusion/";
let TEAM_PREFIX = "";

let CUSTOM_URL;
let hasWarned = false;

if (href.includes("localhost")) {
  //use dev route
  CUSTOM_URL = "https://devapi.overwatch.ai";
} else if (href.includes("devdemo")) {
  //use dev route
  CUSTOM_URL = "https://devapi.overwatch.ai";
} else if (href.includes("staging")) {
  //use staging route
  CUSTOM_URL = "https://aggapi.overwatch.ai";
  TEAM_PREFIX = "/staging";
} else if (href.includes("demo")) {
  //use prod route
  CUSTOM_URL = "https://aggapi.overwatch.ai";
  TEAM_PREFIX = "/demo";
} else {
  //use custom prod route
  CUSTOM_URL = `https://aggapi.overwatch.ai`;
  TEAM_PREFIX = "/" + href;
}

if (process.env.NODE_ENV === "development") {
  TEAM_PREFIX = "";
  // TEAM_PREFIX = "/stable";
  // TEAM_PREFIX = "/experimental";
  // TEAM_PREFIX = "/wip";`
  // console.log("FUSION_PREFIX", FUSION_PREFIX);

  //////// CUSTOM SPOOFING FOR TESTING
  // CUSTOM_URL = `https://aggapi.overwatch.ai`;
  // href = "mike";
  // TEAM_PREFIX = "/" + href;``
}

FUSION_PREFIX = TEAM_PREFIX + FUSION_PREFIX;

export const BASE_URL = CUSTOM_URL;

/////// CUSTOM SPOOFING CHECK HERE
// console.log("BASE_URL", BASE_URL);
// console.log("FUSION_PREFIX", FUSION_PREFIX);

export function _Environment() {
  // console.log("BASE_URL", BASE_URL);
  // if (BASE_URL.includes("pittsburghpa")) {
  //   return "PITTSBURGHPA";
  // }
  if (!BASE_URL.includes("dev") && process.env.NODE_ENV === "development") {
    // console.log(
    //   "Warning: You are in development mode but not using the dev API"
    // )
    if (!hasWarned) {
      hasWarned = true;
      alert("Warning: You are in development mode but not using the dev API");
    }
    return href.toUpperCase();
  }
  if (BASE_URL.includes("dev")) {
    return "DEV";
  } else if ((BASE_URL + FUSION_PREFIX).includes("staging")) {
    return "STAGING";
  } else {
    return href.toUpperCase();
    return "PROD";
  }
}

const MIN_REFRESH_TIME = 1000 * 60 * (10 / 60); //seconds

let loginfoTimes = {};
const loginfo = (key = null) => {
  if (logAPIuse) {
    // Create a new error stack
    const errorStack = new Error().stack;

    // Split the stack to find the route name
    const stackLines = errorStack.split("\n");
    const routeName = stackLines
      .filter((line) => line.includes("at"))[1]
      .trim()
      .split(".")[1]
      .split(" ")[0];

    // console.log("Route hit:", key || routeName, "total", ++apiHits);
    if (loginfoTimes[routeName]) {
      if (
        Date.now() - loginfoTimes[key || routeName].timestamp <
        MIN_REFRESH_TIME
      ) {
        if (process.env.NODE_ENV === "development") {
          console.warn(
            key || routeName,
            "hit too recently!",
            loginfoTimes[key || routeName].count,
            "times total"
          );
        }
      }
    }
    loginfoTimes[key || routeName] = {
      timestamp: Date.now(),
      count: loginfoTimes[key || routeName]
        ? loginfoTimes[key || routeName].count + 1
        : 1,
    };
  }
};

let cache = {
  entries: {
    base: {
      data: null,
      timestamp: null,
      status: null,
    },
  },
  get: function (key) {
    return this.entries[key];
  },
  set: function (key, value) {
    this.entries[key] = value;
  },
};

const tryCache = async (key, func) => {
  let entry = cache.get(key);
  if (entry) {
    // console.log("cache found", key);
    if (entry.status === "fetching") {
      // console.log("cache currently waiting", key);
      return new Promise((resolve, reject) => {
        const interval = setInterval(() => {
          let updatedEntry = cache.get(key);
          if (updatedEntry.status === "success") {
            // console.log("cache fetching resolved", key, updatedEntry.data);
            clearInterval(interval);
            resolve(updatedEntry.data);
          }
        }, 1000);
      });
    } else {
      // console.log("cache found already good", key);
    }
    if (entry.timestamp && Date.now() - entry.timestamp < MIN_REFRESH_TIME) {
      // console.log("not hit server, return from cache", key, entry.data);
      return entry.data;
    }
  }
  // console.log("server hit", key);
  cache.set(key, {
    ...key,
    status: "fetching",
  });
  loginfo(key);
  let data = await func();
  // console.log("server returned", key, data);
  cache.set(key, {
    data: data,
    timestamp: Date.now(),
    status: "success",
  });
  return data;
};

//Used in /src/pages/Signin.js
//unprotected
export async function login(
  email,
  password,
  gdprConsent,
  gdprTermsVersion,
  gdprPolicyVersion
) {
  try {
    // const res = await tryCache(
    //   "login",
    //   axios.post(
    //     `${BASE_URL}${FUSION_PREFIX}fusionLogin`,
    //     {
    //       email: email,
    //       password: password,
    //       gdprConsent: gdprConsent,
    //       gdprTermsVersion: gdprTermsVersion,
    //       gdprPolicyVersion: gdprPolicyVersion,
    //     },
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   )
    // );

    loginfo();
    const res = await axios.post(
      `${BASE_URL}${FUSION_PREFIX}fusionLogin`,
      {
        email: email,
        password: password,
        gdprConsent: gdprConsent,
        gdprTermsVersion: gdprTermsVersion,
        gdprPolicyVersion: gdprPolicyVersion,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    Cookies.set("workplaceId", res.data.workplaceId);
    return res;
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

//Used in /src/pages/ActivateAccount.js
export async function activate_checkVerification(code) {
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}checkvalidationcode`,
      {
        code,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

//Used in /src/pages/ActivateAccount.js
export async function activate_setPassword(code, password) {
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}updatePassword`,
      {
        code,
        password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

//Used in /src/pages/ActivateAccount.js
export async function activate_activateAccount(code) {
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}validateUser`,
      {
        code,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

//Used in /src/pages/Signin.js
//protected
export async function postRefresh(email, refreshToken) {
  return await axios
    .post(
      `${BASE_URL}${FUSION_PREFIX}fusionRefreshToken`,
      {
        refreshToken: refreshToken,
        email: email,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      // // console.log("FusionAPI.js, postRefresh(): ");
      // console.log(err);
      // console.log(err.response.data.message);
      throw err;
    });
}

//Used in /src/pages/Admin/CreateUser.js
//Used in /src/pages/Signup.js
export async function createFusionUser(
  role,
  base,
  department,
  rank,
  name,
  email,
  employeeId,
  phoneNumber,
  gender,
  birthday,
  address,
  emergencyContact,
  isAdmin,
  // roles,
  url = window.location.origin,
  avatar = null,
  widget_list = null,
  user_save = null,
  company_save = null
) {
  console.log("create roles", role);
  return await axios.post(
    `${BASE_URL}${FUSION_PREFIX}fusionCreate`,
    {
      role,
      base,
      department,
      rank,
      name,
      email,
      employeeId,
      phoneNumber,
      gender,
      birthday,
      address,
      emergencyContact,
      isAdmin,
      // roles,
      avatar,
      widget_list,
      user_save,
      company_save,
      url,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("accessToken")}`,
        AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
      },
    }
  );
}

//Used in /src/pages/Admin/EditUser.js
export async function updateUserProfile(
  phoneNumber,
  gender,
  birthday,
  address,
  emergencyContact
) {
  return await axios.post(
    `${BASE_URL}${FUSION_PREFIX}updateFusionUserProfile`,
    {
      phoneNumber,
      gender,
      birthday,
      address,
      emergencyContact,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("accessToken")}`,
        AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
      },
    }
  );
}

export async function updateUserProfileByAdmin(
  userId,
  role,
  base,
  department,
  rank,
  name,
  employeeId,
  phoneNumber,
  gender,
  birthday,
  address,
  emergencyContact,
  isAdmin
) {
  console.log("update roles:", role);
  return await axios.post(
    `${BASE_URL}${FUSION_PREFIX}updateFusionProfileByAdmin`,
    {
      editId: userId,
      role,
      base,
      department,
      rank,
      name,
      employeeId,
      phoneNumber,
      gender,
      birthday,
      address,
      emergencyContact,
      isAdmin,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("accessToken")}`,
        AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
      },
    }
  );
}

export async function updateUserProfileByAdminMulti(
  userId,
  base,
  department,
  rank,
  name,
  employeeId,
  phoneNumber,
  gender,
  birthday,
  address,
  emergencyContact,
  roles
) {
  console.log("update roles:", roles);
  return await axios.post(
    `${BASE_URL}${FUSION_PREFIX}updateFusionProfileByAdminMulti`,
    {
      editId: userId,
      base,
      department,
      rank,
      name,
      employeeId,
      phoneNumber,
      gender,
      birthday,
      address,
      emergencyContact,
      roles,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("accessToken")}`,
        AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
      },
    }
  );
}

//Used in /src/components/AlertCreator.js
export function postAlert(alert) {
  try {
    loginfo();
    return axios.post(
      `${BASE_URL}${FUSION_PREFIX}InsertFusionAlerts`,
      {
        polygon: alert.coordinates,
        city: alert.city,
        state: alert.state,
        broadcastTime: new Date().toISOString(),
        issuedTime: alert.issuedTime,
        expirationTime: alert.expirationTime,
        severity: alert.severity,
        headline: alert.headline,
        description: alert.description,
        type: alert.type,
        isInternalUse: alert.isInternalUse,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

//TODO::
//Used in /src/components/Widgets/MyAdvisoriesWidget.js
//Used in /src/pages/PublicUser/StormCenter.js
export async function getCWActiveAlerts(lat, lng) {
  try {
    const res = await tryCache(
      "getCWActiveAlerts" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        axios.post(
          `${BASE_URL}${FUSION_PREFIX}getCWActiveAlerts`,
          {
            latitude: Cookies.get("latitude"),
            longitude: Cookies.get("longitude"),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
              AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
            },
          }
        )
    );
    return res;
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

//Used in /src/components/Maps/MapForecast/MapForecast.js
export async function getAirportConditionsbyState(state) {
  try {
    const res = await tryCache(
      "getAirportConditionsbyState" + "-" + Cookies.get("state"),
      () =>
        axios.post(
          `${BASE_URL}${FUSION_PREFIX}getAirportConditionsbyState`,
          {
            state: Cookies.get("state"),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
              AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
            },
          }
        )
    );
    return res;
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

//Used in /src/pages/Dashboard.js
export async function get3DayAlertHistory() {
  try {
    const res = await tryCache("get3DayAlertHistory", () =>
      axios.post(
        `${BASE_URL}${FUSION_PREFIX}CWget3DayAlertHistory`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
            AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
          },
        }
      )
    );
    return res;
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

//TODO: Where is this used?
export async function getFIPSPolygon(fipsCode) {
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}getFIPSPolygon`,
      { fipsCode: fipsCode },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

//Used in /src/components/AlertCard.js
export async function setAlertEnd(alertId, workplaceId) {
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}setAlertEnd`,
      {
        alertId,
        workplaceId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

//TODO: Where is this used?
export async function validate() {
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}fusionValidateToken`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

//Used in /src/App.js
export async function getAccessLevel() {
  try {
    const res = await tryCache("checkAuthLevel", () =>
      axios.post(
        `${BASE_URL}${FUSION_PREFIX}checkAuthLevel`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
            AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
          },
        }
      )
    );
    return res;
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

//Used in /src/pages/Admin/CreateRole.js
export async function createRole(roleName, members, permissions, layout) {
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}createFusionRole`,
      {
        roleName,
        members,
        permissions,
        layout,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

//Used in /src/pages/Admin/EditRole.js
export async function updateRole(
  roleId,
  roleName,
  members,
  permissions,
  layout
) {
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}updateFusionRole`,
      {
        roleId,
        roleName,
        members,
        permissions,
        layout,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

//Used in /src/pages/Admin/EditRole.js
export async function getRole(roleId) {
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}getFusionRoleById`,
      {
        roleId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

//Used in /src/pages/Admin/CreateUser.js
//Used in /src/pages/Admin/EditUser.js
//Used in /src/pages/Admin/RoleManager.js
export async function getAllRoles() {
  try {
    loginfo();
    const res = await axios.post(
      `${BASE_URL}${FUSION_PREFIX}getFusionRoles`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );

    return res;
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

//Used in /src/pages/Admin/CreateRole.js
//Used in /src/pages/Admin/EditRole.js
//Used in /src/pages/Admin/RoleManager.js
export async function getWorkplaceMembers(accessLevels) {
  try {
    loginfo();
    const res = await axios.post(
      `${BASE_URL}${FUSION_PREFIX}getAllFusionMembers`,
      {
        permissionLevel: accessLevels,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
    return res;
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

//Used in /src/pages/Admin/EditUser.js
export async function getUserProfileInfo(userId = null) {
  try {
    const res = await tryCache("getUserProfileInfo" + "-" + userId, () =>
      axios.post(
        `${BASE_URL}${FUSION_PREFIX}getFusionUserProfile`,
        {
          editId: userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
            AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
          },
        }
      )
    );
    return res;
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

//Used in /src/components/Widgets/WeatherForecastWidget.js
//Used in /src/pages/PublicUser/WeatherForecast.js
export async function getHourlyForecast(limit) {
  try {
    loginfo();
    let date = new Date();
    // date = new Date(date.getHours() - 7);

    const res = await tryCache(
      "getHourlyForecast" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        axios.post(
          `${BASE_URL}${FUSION_PREFIX}customWeatherCurHourlyForecast`,
          {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            // limit: limit,
            latitude: Cookies.get("latitude"),
            longitude: Cookies.get("longitude"),
            // date: date.toISOString(),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
              AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
            },
          }
        )
    );
    return res;
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

//Used in /src/pages/PublicUser/WeatherForecast.js
export async function currentCityConditions() {
  try {
    const res = await tryCache(
      "currentCityConditions" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        axios.post(
          `${BASE_URL}${FUSION_PREFIX}getCurConditionsbyCity`,
          {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            latitude: Cookies.get("latitude"),
            longitude: Cookies.get("longitude"),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
              AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
            },
          }
        )
    );
    return res;
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

//Used in /src/pages/PublicUser/WeatherForecast.js
export async function getAstronomyData() {
  try {
    const res = await tryCache(
      "getAstronomyData" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        axios.post(
          `${BASE_URL}${FUSION_PREFIX}getAstronomyData`,
          {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            latitude: Cookies.get("latitude"),
            longitude: Cookies.get("longitude"),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
              AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
            },
          }
        )
    );
    return res;
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

//Used in /src/components/Widgets/DetailedForecast.js
//Used in /src/pages/PublicUser/WeatherForecast.js
export async function getDetailedForecastData() {
  try {
    const res = await tryCache(
      "getDetailedForecastData" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        axios.post(
          `${BASE_URL}${FUSION_PREFIX}getDetailedForecastData`,
          {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            longitude: Cookies.get("longitude"),
            latitude: Cookies.get("latitude"),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
              AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
            },
          }
        )
    );

    return res;
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

//Used in /src/components/Widgets/DayForecast15Widget.js
//Used in /src/pages/PublicUser/WeatherForecast.js
export async function get15DayForecast() {
  try {
    const res = await tryCache(
      "get15DayForecast" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        axios.post(
          `${BASE_URL}${FUSION_PREFIX}customWeatherExt15`,
          {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            latitude: Cookies.get("latitude"),
            longitude: Cookies.get("longitude"),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
              AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
            },
          }
        )
    );
    return res;
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

//Used in /src/components/Widgets/AirQualityObservationsWidget.js
//Used in /src/pages/PublicUser/WeatherForecast.js
export async function getAirQualityObservation() {
  try {
    const res = await tryCache(
      "getAirQualityObservation" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        axios.post(
          `${BASE_URL}${FUSION_PREFIX}CWW_getAirQualityObservation`,
          {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            longitude: Cookies.get("longitude"),
            latitude: Cookies.get("latitude"),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
              AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
            },
          }
        )
    );
    return res;
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

//Used in /src/components/Widgets/AirQualityForecastWidget.js
//Used in /src/pages/PublicUser/WeatherForecast.js
export async function getAirQualityForecast() {
  try {
    const res = await tryCache(
      "getAirQualityForecast" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        axios.post(
          `${BASE_URL}${FUSION_PREFIX}CWW_getAirQualityForecast`,
          {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            longitude: Cookies.get("longitude"),
            latitude: Cookies.get("latitude"),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
              AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
            },
          }
        )
    );
    return res;
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

//Used in /src/componentsWidgets/WaveAndWindConditionsWidget.js
//Used in /src/pages/PublicUser/MarineForecast.js
export async function getDetailedMarine7() {
  try {
    const res = await tryCache(
      "getDetailedMarine7" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        axios.post(
          `${BASE_URL}${FUSION_PREFIX}getDetailedMarine7`,
          {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            longitude: Cookies.get("longitude"),
            latitude: Cookies.get("latitude"),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
              AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
            },
          }
        )
    );
    return res;
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

//Used in /src/components/SpecialReports/FireDanger.js
export async function getExtendedFireDanger() {
  try {
    const res = await tryCache(
      "getExtendedFireDanger" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        axios.post(
          `${BASE_URL}${FUSION_PREFIX}getExtendedFireDanger`,
          {
            city: Cookies.get("city"),
            state: Cookies.get("state"),

            latitude: Cookies.get("latitude"),
            longitude: Cookies.get("longitude"),
          },

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
              AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
            },
          }
        )
    );
    return res;
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

//Used in /src/components/SpecialReports/FireDanger.js
export async function getDetailedFireDanger() {
  try {
    const res = await tryCache(
      "getDetailedFireDanger" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        axios.post(
          `${BASE_URL}${FUSION_PREFIX}getDetailedFireDanger`,
          {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            latitude: Cookies.get("latitude"),
            longitude: Cookies.get("longitude"),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
              AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
            },
          }
        )
    );
    return res;
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

//Used in /src/components/SpecialReports/SkiReports.js
export async function getSkiReports() {
  try {
    const res = await tryCache(
      "getSkiReports" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        axios.post(
          `${BASE_URL}${FUSION_PREFIX}getSkiSearch`,
          {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            latitude: Cookies.get("latitude"),
            longitude: Cookies.get("longitude"),
            // latitude: "51.01",
            // longitude: "-113.98"
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
              AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
            },
          }
        )
    );
    return res;
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

//Used in /src/components/SpecialReports/AirportDelay.js
export async function getAirportDelays() {
  try {
    const res = await tryCache(
      "getAirportDelays" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        axios.post(
          `${BASE_URL}${FUSION_PREFIX}getAirportDelayForecast`,
          {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            latitude: Cookies.get("latitude"),
            longitude: Cookies.get("longitude"),
            // city: "Vesta",
            // state: "Georgia",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
              AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
            },
          }
        )
    );
    return res;
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

//Used in /src/componentsWidgets/SurfAlertsWidget.js
//Used in /src/pages/PublicUser/MarineForecast.js
export async function getSurfAlerts() {
  try {
    const res = await tryCache(
      "getSurfAlerts" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        axios.post(
          `${BASE_URL}${FUSION_PREFIX}getExtendedMarine5`, //FILL IN!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
          {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            longitude: Cookies.get("longitude"),
            latitude: Cookies.get("latitude"),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
              AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
            },
          }
        )
    );
    return res;
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

//Used in /src/components/Widgets/BuoyConditionsWidget.js
//Used in /src/pages/PublicUser/MarineForecast.js
export async function getBuoyConditions() {
  try {
    const res = await tryCache(
      "getBuoyConditions" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        axios.post(
          `${BASE_URL}${FUSION_PREFIX}getBuoyData`,
          {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            longitude: Cookies.get("longitude"),
            latitude: Cookies.get("latitude"),
            limit: 1000,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
              AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
            },
          }
        )
    );
    return res;
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

//Used in /src/componentsWidgets/TideTableWidget.js
//Used in /src/pages/PublicUser/MarineForecast.js
export async function getTideTable() {
  try {
    const res = await tryCache(
      "getTideTable" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        axios.post(
          `${BASE_URL}${FUSION_PREFIX}getTideData`,
          {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            longitude: Cookies.get("longitude"),
            latitude: Cookies.get("latitude"),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
              AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
            },
          }
        )
    );
    return res;
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

export async function day7HourlyForecast() {
  try {
    const res = await tryCache(
      "day7HourlyForecast" +
        "-" +
        Cookies.get("latitude") +
        "-" +
        Cookies.get("longitude"),
      () =>
        axios.post(
          `${BASE_URL}${FUSION_PREFIX}customWeather7DayHourlyForecast`,
          {
            city: Cookies.get("city"),
            state: Cookies.get("state"),
            longitude: Cookies.get("longitude"),
            latitude: Cookies.get("latitude"),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
              AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
            },
          }
        )
    );
    return res;
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

//Used in /src/components/Maps/Earthquake/EarthquakeMap.js
export async function getEarthQuake() {
  try {
    const res = await tryCache("getEarthQuake", () =>
      axios.get(`${BASE_URL}${FUSION_PREFIX}getEarthQuake`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      })
    );
    return res;
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

//Used in /src/components/StormCenter/HurricanesTropicalStorms.js
export async function getHurricanesHistorical() {
  try {
    const res = await tryCache("getHurricanesHistorical", () =>
      axios.get(`${BASE_URL}${FUSION_PREFIX}getHurricanesHistorical`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      })
    );
    return res;
  } catch (error) {
    // console.log(error);
    throw error;
  }
}
//Used in /src/components/StormCenter/HurricanesTropicalStorms.js
export async function getHurricanesLive() {
  try {
    const res = await tryCache("getHurricanesLive", () =>
      axios.get(`${BASE_URL}${FUSION_PREFIX}getHurricanesLive`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      })
    );
    return res;
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

export async function getRadarMapTile() {
  try {
    loginfo();
    return await axios.get(
      `${BASE_URL}${FUSION_PREFIX}getRadarMapTile/${new Date().toISOString()}/${new Date().toISOString()}/future_radar/{quadkey}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

//Used in /src/pages/Dashboard.js
//Used in /src/pages/ViewAlerts.js
export async function getWorkPlaceAlerts() {
  try {
    const res = await tryCache("getWorkPlaceAlerts", () =>
      axios.get(`${BASE_URL}${FUSION_PREFIX}getWorkPlaceAlerts`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      })
    );
    return res;
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

//Used in /src/components/Maps/SevereWeatherAlertsMap/components/MapPanel.js
//Used in /src/components/Widgets/NWSAlertsWidget.js
//Used in /src/pages/PublicUser/StormCenter.js
export async function getAllActiveAlerts() {
  try {
    const res = await tryCache("getAllActiveAlerts", () =>
      axios.get(`${BASE_URL}${FUSION_PREFIX}getAllActiveAlerts`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      })
    );
    return res;
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

// export async function getAllActiveCWAlerts() {
//   try {
//     const res = await tryCache(
//       "getAllActiveCWAlerts",
//       axios.get(`${BASE_URL}${FUSION_PREFIX}getAllActiveCWAlerts`, {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${Cookies.get("accessToken")}`,
//           AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
//         },
//       })
//     );
//     return res;
//   } catch (error) {
//     // console.log(error);
//     throw error;
//   }
// }

//Used in /src/App.js
export async function getUserWidgetData(userId) {
  // // console.log("Getting user widget list");

  try {
    const res = await tryCache("getUserWidgetData" + "-" + userId, () =>
      axios.get(
        `${BASE_URL}${FUSION_PREFIX}getWidgets/${userId ? userId : ""}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
            AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
          },
        }
      )
    );
    return res;
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

//used in /src/components/PlacesSearch.js
export async function getCoordinatesFromCityState(city, state) {
  try {
    loginfo();
    return await axios.get(
      `${BASE_URL}${FUSION_PREFIX}getGeoLocation/${state}/${city}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

export async function getCityStateFromCoordinates(lat, lon) {
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}getCityandStateAtCoordinate/`,
      {
        long: lon,
        lat: lat,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

export async function updateUserWidgetLayout(layout) {
  // // console.log("Updating User Widget Layout:", layout);
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}updateUserWidgetLayout`,
      {
        widgetLayout: JSON.stringify(layout),
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
}

export async function getUserPerms() {
  try {
    const res = await tryCache("getUserPerms", () =>
      axios.get(`${BASE_URL}${FUSION_PREFIX}getfusionUserPerms`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      })
    );
    console.log("Perm result:", res);
    return res;
  } catch (error) {
    throw error;
  }
}

export async function updateUserPlacesData(places) {
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}addFusionPlaces`,
      {
        places: places,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
}

export async function getUserPlacesData() {
  try {
    const res = await tryCache("getUserPlacesData", () =>
      axios.get(`${BASE_URL}${FUSION_PREFIX}getFusionPlaces/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      })
    );
    return res;
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

export async function getNodes() {
  try {
    const res = await tryCache("getNodes", () =>
      axios.get(`${BASE_URL}${FUSION_PREFIX}getNodes/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      })
    );
    return res;
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

export async function getNodeDataById(nodeId) {
  try {
    const res = await tryCache("getNodeDataById" + "-" + nodeId, () =>
      axios.get(`${BASE_URL}${FUSION_PREFIX}getNodeDataById/${nodeId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      })
    );
    return res;
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

export async function getNodeDataByTimestamps(
  startTime,
  endTime,
  checkAgainstNodeTsInstead = false
) {
  try {
    const res = await tryCache(
      // "getNodeDataByTimestamps" + "-" + startTime + "-" + endTime,
      "getNodeDataByTimestamps",
      () =>
        axios.get(
          `${BASE_URL}${FUSION_PREFIX}getNodeDataByTimestamps?startTime=${startTime}&endTime=${endTime}&checkAgainstNodeTsInstead=${checkAgainstNodeTsInstead}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
              AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
            },
          }
        )
    );
    return res;
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

export async function addConnectedDevice(device) {
  // // console.log("addConnectedDevice: API", device);
  return {};
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}addConnectedDevice`,
      {
        device: device,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

export async function getGlobalActions() {
  try {
    const res = await tryCache("getGlobalActions", () =>
      axios.get(`${BASE_URL}${FUSION_PREFIX}getGlobalActionsByWPId/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      })
    );
    return res;
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

export async function insertGlobalAction(action) {
  // // console.log("insertGlobalAction: API", action);
  //return {}
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}insertGlobalActions`,
      {
        globalAction: action,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

export async function updateGlobalAction(id, action) {
  // // console.log("insertGlobalAction: API", action);
  //return {}
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}updateGlobalActions`,
      {
        globalAction: action,
        globalActionId: id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

export async function setGlobalActionEnabled(id, status) {
  // // console.log("insertGlobalAction: API", action);
  //return {}
  // console.log("setGlobalActionEnabled: API", id, status)
  // return
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}setGlobalActionEnabled`,
      {
        id,
        status,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

export async function changeAwareDeviceSettings(deviceSettings) {
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}changeAwareDeviceSettings`,
      {
        deviceSettings: deviceSettings,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

export async function fusionVerifyLicense(key, code, lat, lon) {
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}fusionActivateLicense`,
      {
        licenseKey: key,
        code: code,
        lat: lat,
        lon: lon,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

export async function fusionGetLicense() {
  // return {}

  try {
    const res = await tryCache("fusionGetLicense", () =>
      axios.get(`${BASE_URL}${FUSION_PREFIX}fusionFetchLicense`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      })
    );
    return res;
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

export async function fusionAddIntegration(system) {
  //return {}

  // return { data: { message: "Integration added successfully" } };
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}fusionAddIntegration`,
      {
        system: system,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

export async function fusionUpdateIntegration(system) {
  //return {}

  // return { data: { message: "Integration added successfully" } };
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}fusionUpdateIntegration`,
      {
        system: system,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

export async function fusionGetIntegrations() {
  try {
    const res = await tryCache("fusionGetIntegrations", () =>
      axios.post(
        `${BASE_URL}${FUSION_PREFIX}fusionGetIntegrations`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
            AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
          },
        }
      )
    );
    return res;
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

export async function getGlobalWidgetPermList() {
  try {
    const res = await tryCache("getGlobalWidgetPermList", () =>
      axios.get(`${BASE_URL}${FUSION_PREFIX}getGlobalWidgetPermList`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      })
    );
    return res;
  } catch (error) {
    // console.log(error);
    throw error;
  }
}
export async function updateFusionPassword(oldPassword, newPassword) {
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}updateFusionPassword`,
      {
        newPassword: newPassword,
        oldPassword: oldPassword,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

//needs to send a localhost:3000/resetPassword?code={code}
export async function sendResetEmail(email) {
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}requestFusionPasswordLink`,
      {
        email: email,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

export async function updatePasswordWithCode(password, code) {
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}updateForgottenPassword`,
      {
        password: password,
        code: code,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

export async function submitFusionInquiry(name, email, phone, notes) {
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}fusionInquiries`,
      {
        name,
        email,
        phone,
        notes,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

export async function setFusionUserInfo(userInfo) {
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}setFusionUserInfo`,
      {
        userInfo,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

export async function getFusionUserInfo() {
  try {
    const res = await tryCache("getFusionUserInfo", () =>
      axios.get(`${BASE_URL}${FUSION_PREFIX}getFusionUserInfo`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      })
    );
    return res;
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

export async function getJurisdictions() {
  try {
    const res = await tryCache("getJurisdictions", () =>
      axios.get(`${BASE_URL}${FUSION_PREFIX}getJurisdictions`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      })
    );
    return res;
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

export async function getAlarmHistory() {
  try {
    const res = await tryCache("getAlarmHistory", () =>
      axios.get(`${BASE_URL}${FUSION_PREFIX}getAlarmHistory`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      })
    );
    return res;
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

export async function getAlarmHistoryPaginated(limit, offset) {
  try {
    loginfo();
    return axios.get(
      `${BASE_URL}${FUSION_PREFIX}getAlarmHistoryPaginated?limit=${limit}&offset=${offset}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

export async function getPendingAlarmsPaginated(limit, offset) {
  try {
    loginfo();
    return axios.get(
      `${BASE_URL}${FUSION_PREFIX}getPendingAlarmsPaginated?limit=${limit}&offset=${offset}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

export async function getPendingAlarms() {
  try {
    const res = await tryCache("getPendingAlarms", () =>
      axios.get(`${BASE_URL}${FUSION_PREFIX}getPendingAlarms`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      })
    );
    return res;
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

export async function acknowledgeAlarm(alarmId) {
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}acknowledgeAlarm`,
      {
        alarmId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

export async function silenceAlarm(alarmId) {
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}updateSilence`,
      {
        alarmId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

export function connectToLiveAlarms() {
  try {
    loginfo();
    return new EventSource(
      `${BASE_URL}${FUSION_PREFIX}live-alarms?token=${encodeURIComponent(
        Cookies.get("accessToken")
      )}`
    );
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

export async function deleteGlobalAction(actionId) {
  // // console.log("deleteGlobalAction: API", actionId);
  //return {}
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}delGlobalActions`,
      {
        globalActionId: actionId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

export async function autoCompleteSearch(latitude, longitude, term) {
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}googleAutocompleteSearch`,
      {
        latitude,
        longitude,
        term,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

// export async function cityLookupForParser (location) {
//   try {
// loginfo();
//     return await axios.post(
//       `${BASE_URL}${FUSION_PREFIX}cityLookupForParser`,
//       {
//         location
//       },
//       {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${Cookies.get("accessToken")}`,
//           AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
//         },
//       }
//     );
//   } catch (error) {
//     // console.log(error);
//     throw error;
//   }
// }

export function connectToIsDataReadyForLocation(location) {
  const country = location.place_data.address_components.find(
    (item) => item.types[0] === "country"
  ).short_name;

  const locationInfo = {
    city: location.city,
    state_long: location.state_long,
    term: location.term,
    latitude: location.latitude,
    longitude: location.longitude,
    country: country,
  };
  try {
    loginfo();
    const url = `${BASE_URL}${FUSION_PREFIX}live-location-ready?token=${encodeURIComponent(
      Cookies.get("accessToken")
    )}&location=${JSON.stringify(locationInfo)}`;
    // console.log(url.length);
    return new EventSource(url);
  } catch (e) {
    // console.log(e);
    throw e;
  }
}

export async function validateRoutePerms(perms) {
  // // console.log("deleteGlobalAction: API", actionId);
  //return {}
  try {
    const res = await tryCache("validateRoutePerms" + "-" + perms, () =>
      axios.post(
        `${BASE_URL}${FUSION_PREFIX}validateRoutePerms`,
        {
          perms,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
            AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
          },
        }
      )
    );
    return res;
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

export async function deleteUser(deleteId) {
  // // console.log("deleteGlobalAction: API", actionId);
  //return {}
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}deleteUser`,
      {
        deleteId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

export async function deleteRole(deleteId) {
  // // console.log("deleteGlobalAction: API", actionId);
  //return {}
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}deleteRole`,
      {
        deleteId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

export async function informLocationChange(location) {
  // // console.log("deleteGlobalAction: API", actionId);
  //return {}
  try {
    // const res = await tryCache(
    //   "informLocationChange" + "-" + location.latitude + "-" + location.longitude,

    const res = await axios.post(
      `${BASE_URL}${FUSION_PREFIX}informLocationChange`,
      {
        location,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
    // );
    return res;
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

export async function updateOrgName(name) {
  // // console.log("deleteGlobalAction: API", actionId);
  //return {}
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}updateOrganizationName`,
      {
        newOrganizationName: name,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

export async function getOrgName() {
  // // console.log("deleteGlobalAction: API", actionId);
  //return {}
  try {
    const res = await tryCache("getOrgName", () =>
      axios.get(`${BASE_URL}${FUSION_PREFIX}getOrganizationName`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      })
    );
    return res;
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

export async function getAuditLogs(
  pageIndex,
  PAGE_LENGTH,
  query,
  sortKey,
  sortOrder
) {
  // // console.log("deleteGlobalAction: API", actionId);
  //return {}
  try {
    const res = await tryCache(
      "getAuditLogs" +
        "-" +
        pageIndex +
        "-" +
        PAGE_LENGTH +
        "-" +
        query +
        "-" +
        sortKey +
        "-" +
        sortOrder,
      () =>
        axios.post(
          `${BASE_URL}${FUSION_PREFIX}getAuditLogs`,
          {
            index: pageIndex,
            batchSize: PAGE_LENGTH,
            offset: (pageIndex - 1) * PAGE_LENGTH,
            searchTerm: query,
            orderBy: sortKey,
            orderDir: sortOrder,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
              AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
            },
          }
        )
    );
    return res;
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

export async function editAwareAlias(nodeId, newAlias) {
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}editAwareAlias`,
      {
        nodeId,
        newAlias,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
}

export async function logout() {
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}logout`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
}

export async function exitBrowserLogout() {
  try {
    loginfo();
    return navigator.sendBeacon(
      `${BASE_URL}${FUSION_PREFIX}logout`,
      JSON.stringify({
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      })
    )
  } catch (error) {
    throw error;
  }
}

export async function updatePrivacyPolicy(value) {
  try {
    loginfo();
    return await axios.post(
      `${BASE_URL}${FUSION_PREFIX}updatePrivacyPolicy`,
      {
        value,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
}

export async function getTermsAndConditions() {
  // return {
  //   version: "1.0.0",
  //   page: "This is the Terms And Conditions page",
  // }
  try {
    const res = await tryCache("getTermsAndConditions", () =>
      axios.get(`${BASE_URL}${FUSION_PREFIX}getTermsAndConditions`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      })
    );
    return res;
  } catch (error) {
    throw error;
  }
}

export async function getPolicyAndCookies() {
  // return {
  //   version: "1.0.0",
  //   page: "This is the Policy And Cookies page",
  // }
  try {
    const res = await tryCache("getPolicyAndCookies", () =>
      axios.get(`${BASE_URL}${FUSION_PREFIX}getPolicyAndCookies`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      })
    );
    return res;
  } catch (error) {
    throw error;
  }
}
