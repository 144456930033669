import React, { useContext, useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { FaPlus, FaMinus } from "react-icons/fa";
import DashboardPreview from "components/DashboardPreview";
import GetWidgetData from "data/widgetData";
import { usePerms } from "hooks/usePerms";
import WidgetSelector from "components/WidgetSelector";
import { ColorContext } from "App";

const DashboardEditTab = ({
  formData,
  setFormData,
  checks,
  widgetData,
  permList,
  onNext,
}) => {
  const [widgetSelectorOpen, setWidgetSelectorOpen] = useState(false);
  
  const theme = useContext(ColorContext);

  const addWidget = (widget) => {
    const temp = [...formData.layout];
    temp.push(widget);
    setFormData({ ...formData, layout: [...temp] });
  };

  const removeWidget = (index) => {
    const temp = formData.layout
      .filter((filter, i) => {
        return i !== index;
      })
      .map((item) => item);

    setFormData({ ...formData, layout: [...temp] });
  };

  const onListChange = (newList) => {
    setFormData({ ...formData, layout: [...newList] });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        userSelect: "none",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: theme.background,
          flex: 1,
          flexGrow: 1,
          overflow: "auto",
          border: "solid white 1px",
        }}
      >
        <DashboardPreview
          definedWidgetData={GetWidgetData()}
          list={formData.layout}
          setList={onListChange}
          onRemoveWidget={removeWidget}
          openWidgetSelector={() => setWidgetSelectorOpen(true)}
        />
      </div>
      <button
        style={{
          position: "absolute",
          bottom: 50,
          right: 80,
          padding: 15,
          borderRadius: 15,
          backgroundColor: theme.primaryHighlight,
        }}
        onClick={() => onNext()}
      >
        Save Widget View
      </button>
      <Popup
        open={widgetSelectorOpen}
        onClose={() => setWidgetSelectorOpen(false)}
        closeOnDocumentClick={false}
        closeOnEscape
        modal
      >
        {widgetData && (
          <WidgetSelector
            closeSelector={() => {
              setWidgetSelectorOpen(false);
            }}
            widgetData={widgetData}
            userList={Object.keys(widgetData)
              .filter((filter) => {
                let fCheck = true;

                widgetData[filter].permissions.forEach((item) => {
                  if (!fCheck) return;
                  let index = -1;

                  permList.forEach((perm, i) => {
                    if (index >= 0) return;
                    if (
                      perm.name
                        .replace(/\s/g, "")
                        .replace(/\//g, "-")
                        .replace(/:/g, "_") === item
                    )
                      index = i;
                  });

                  if (index === -1) fCheck = false;
                  else fCheck = formData.permissions[index];
                });

                return fCheck;
              })
              .map((widgetId) => {
                return widgetData[widgetId];
              })}
            onAddWidget={addWidget}
          />
        )}
      </Popup>
    </div>
  );
};

export default DashboardEditTab;
