import React, { useContext, useEffect, useState } from "react";
import * as api from "apis/FusionAPI";
import Cookies from "js-cookie";
import OvalDisplay from "components/OvalDisplay";
import ConditionIcons from "components/ConditionIcons";
import ForecastSpread from "components/ForecastSpread";
import { IconContext } from "react-icons";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { DateToString } from "utils/stringUtils";
import { RiDropFill, RiDropLine, RiWindyFill } from "react-icons/ri";
import { FaHouseChimney, FaTemperatureHalf } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import { WiSprinkle } from "react-icons/wi";
import WidgetTimeUpdate from "./WidgetTimeUpdate";
import WidgetLoadingAnimation from "./WidgetLoadingAnimation";
import { ErrorMessage } from "./ErrorMessage";
import { useAPIData } from "hooks/useAPIData";
import { Range } from "utils/mathUtils";
import { ColorContext, LayoutSizeContext, UniqueWidgetRefreshContext } from "App";
import { fontSize } from "@mui/system";

let dataCache;
 

export default function DayForecast15Widget({
  widgetRefresh,
  setWidgetRefresh,
  dimensions,
}) {
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState();
  const [selected, setSelected] = useState(0);
  const [strDate, setStrDate] = useState();
  const [error, setError] = useState({ state: false, message: "" });

  const layout = useContext(LayoutSizeContext);

  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });
  // // console.log("data in 15 day forecast:", data);

  const theme = useContext(ColorContext);
  useEffect(() => {
    dataCache = null;
    setFetching(true);
  }, [widgetRefresh]);

  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "get15DayForecast";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  useEffect(() => {
    const fetchData = async () => {
      try {
        FusionData.forceGet("get15DayForecast").then((res) => {
          // console.log("Result from 15 day forecast:", res.data.result);

          setFetching(false);
          if (!res?.data?.result || res.data.result.length == 0) return;

          res.data.result.map((item) => {
            // item.loctime = new Date(item.iso8601.split(".00")[0]);
            item.loctime = new Date(item.utc_time);
          });

          dataCache = res.data.result;

          setData(dataCache);
           
          setStrDate(DateToString(res.data.result[0].loctime));
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [uwf[UNIQUEKEY]]);
  useEffect(() => {
    //get data from api
    (async () => {
      if (dataCache) {
        setData(dataCache);
        setFetching(false);
        return;
      }
      FusionData.get("get15DayForecast")
        .then((res) => {
          // console.log("Result from 15 day forecast:", res.data.result);

          setFetching(false);
          if (!res?.data?.result || res.data.result.length == 0) return;

          res.data.result.map((item) => {
            // item.loctime = new Date(item.iso8601.split(".00")[0]);
            item.loctime = new Date(item.utc_time);
          });

          dataCache = res.data.result;

          setData(dataCache);
           
          setStrDate(DateToString(res.data.result[0].loctime));
        })
        .catch((err) => {
          // console.log("Error getting data", err);
          setError({ state: true, message: err.message });
        });
    })();
  }, [widgetRefresh]);

  const updateSelected = (newSelected) => {
    setSelected(newSelected);
    setStrDate(DateToString(data[newSelected].loctime));
  };

  if (error.state && !fetching) {
    return <ErrorMessage message={error.message} />;
  }

  // if (true)
  if (fetching)
    return (
      <WidgetLoadingAnimation
        widgetName={"15DayForecast"}
        dimensions={dimensions}
      />
    );

  if (data && data.length === 0) {
    return (
      <div>
        <WidgetTimeUpdate route={ROUTE} />
        <div style={{ padding: 15 }}>No data found.</div>
      </div>
    );
  }

  const RightArrowComponent = () => {
    return (
      <button
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: 50,
          opacity: selected < data.length - 1 ? 1 : 0,
          cursor: selected < data.length - 1 ? "pointer" : "default",
        }}
        onClick={() => {
          if (selected < data.length - 1) updateSelected(selected + 1);
        }}
      >
        <IconContext.Provider
          value={{
            color: theme.fontColor,
            size: 20,
          }}
        >
          <FaChevronRight />
        </IconContext.Provider>
      </button>
    );
  };

  const LeftArrowComponent = () => {
    return (
      <button
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: 50,
          opacity: selected > 0 ? 1 : 0,
          cursor: selected > 0 ? "pointer" : "default",
        }}
        onClick={() => {
          if (selected > 0) updateSelected(selected - 1);
        }}
      >
        <IconContext.Provider
          value={{
            color: theme.fontColor,
            size: 20,
          }}
        >
          <FaChevronLeft />
        </IconContext.Provider>
      </button>
    );
  };

  if (dimensions[0] == 2 && dimensions[1] == 1) {
    if (data)
      return (
        <div
          style={{
            height: "100%",
          }}
        >
          <WidgetTimeUpdate route={ROUTE}   />
          {data.length && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 10,
                paddingTop: 5,
                height: "90%",
              }}
            >
              {strDate && (
                <div
                  style={{
                    fontSize: 20,
                    borderBottom: "solid grey 1px",
                    flex: 1,
                  }}
                >
                  {`${strDate.weekday_short} ${strDate.month_short} ${strDate.day}, ${strDate.year}`}
                </div>
              )}
              {/* Oval spreads */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  // justifyContent: "space-between",
                  alignContent: "center",
                  justifyContent: "center",
                  gap: 10,
                  overflow: "hidden",
                  paddingLeft: 10,
                  paddingRight: 10,
                  flex: 15,
                }}
              >
                <LeftArrowComponent />

                {data.map((item, index) => {
                  const length = data.length;
                  const numDisplayed = 7;
                  const halfNum = numDisplayed / 2;

                  if (
                    !Range(
                      Math.max(Math.min(selected, length - halfNum), halfNum) -
                        halfNum,
                      Math.min(Math.max(selected, halfNum), length - halfNum) +
                        halfNum,
                      index
                    )
                  )
                    return;

                  return (
                    <button
                      key={index}
                      onClick={() => updateSelected(index)}
                      style={{
                        height: "100%",
                      }}
                    >
                      <OvalDisplay
                        key={index}
                        style={
                          {
                            // marginLeft: 15,
                            // height: 275,
                            // margin: 10,
                          }
                        }
                        time={`${
                          item.loctime.getMonth() + 1
                        }/${item.loctime.getDate()}`}
                        temp={
                          <div>
                            <div>{`${parseFloat(item.high_temp).toFixed(
                              0
                            )}°F`}</div>
                            <div>{`${parseFloat(item.low_temp).toFixed(
                              0
                            )}°F`}</div>
                          </div>
                        }
                        icon={<ConditionIcons conditionId={item.icon} />}
                        selected={selected === index}
                      />
                    </button>
                  );
                })}

                <RightArrowComponent />
              </div>

              {/* Forecast spread */}
              <ForecastSpread
                style={{ flex: 5, fontSize: layout.scaleMult * 10 }}
                data={{
                  "": data[selected].description,
                  Wind: `${data[selected].wind_speed} MPH, ${data[selected].wind_short}`,
                  Humidity: `${data[selected].humidity}%`,
                  "Comfort Level": `${data[selected].comfort}°F`,
                  Precipitation: `${data[selected].precip_prob}%`,
                  // "Visibility": `${data[selected].visibility} mi`,
                  "Dew Point": `${data[selected].dew_point}`,
                  "Avg. Temp": `${(
                    (parseFloat(data[selected].high_temp) +
                      parseFloat(data[selected].low_temp)) /
                    2
                  ).toFixed(2)}°F`,
                  "Precip. Total": `${
                    data[selected].rainfall === "*"
                      ? "0"
                      : data[selected].rainfall
                  }"`,
                }}
                dataIcons={[
                  <ConditionIcons conditionId={6} />,
                  <RiWindyFill />,
                  <RiDropLine />,
                  <FaHouseChimney />,
                  <ConditionIcons conditionId={19} />,
                  // <FaEye/>,
                  <WiSprinkle />,
                  <FaTemperatureHalf />,
                  <RiDropFill />,
                ]}
              />
            </div>
          )}
        </div>
      );
  }
  if (dimensions[0] == 1 && dimensions[1] == 1) {
    if (data)
      return (
        <div style={{ height: "100%" }}>
          <WidgetTimeUpdate route={ROUTE}   />
          {data.length && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // padding: 15,
                height: "90%",
                padding: 5,
                // background:'red',
              }}
            >
              {/* Oval spreads */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 10,
                }}
              >
                <LeftArrowComponent />

                {data &&
                  data.map((item, index) => {
                    const length = data.length;
                    const numDisplayed = 3;
                    const halfNum = numDisplayed / 2;

                    if (
                      !Range(
                        Math.max(
                          Math.min(selected, length - halfNum),
                          halfNum
                        ) - halfNum,
                        Math.min(
                          Math.max(selected, halfNum),
                          length - halfNum
                        ) + halfNum,
                        index
                      )
                    )
                      return;

                    return (
                      <button
                        key={index}
                        onClick={() => setSelected(index)}
                        style={{
                          height: "100%",
                        }}
                      >
                        <OvalDisplay
                          key={index}
                          style={
                            {
                              // marginLeft: 15,
                              // height: 275,
                              // margin: 10,
                            }
                          }
                          time={`${
                            item.loctime.getMonth() + 1
                          }/${item.loctime.getDate()}`}
                          temp={
                            <div>
                              <div>{`${parseFloat(item.high_temp).toFixed(
                                0
                              )}°F`}</div>
                              <div>{`${parseFloat(item.low_temp).toFixed(
                                0
                              )}°F`}</div>
                            </div>
                          }
                          icon={<ConditionIcons conditionId={item.icon} />}
                          selected={selected === index}
                        />
                      </button>
                    );
                  })}

                <RightArrowComponent />
              </div>

              {/* Forecast spread */}
              <ForecastSpread
                style={{ flex: 5, fontSize: layout.scaleMult * 10 }}
                data={{
                  "": data[selected].description,
                  Wind: `${data[selected].wind_speed} MPH, ${data[selected].wind_short}`,
                  Humidity: `${data[selected].humidity}%`,
                  "Comfort Level": `${data[selected].comfort}°F`,
                  Precipitation: `${data[selected].precip_prob}%`,
                  // "Visibility": `${data[selected].visibility} mi`,
                  "Dew Point": `${data[selected].dew_point}`,
                  "Avg. Temp": `${(
                    (parseFloat(data[selected].high_temp) +
                      parseFloat(data[selected].low_temp)) /
                    2
                  ).toFixed(2)}°F`,
                  "Precip. Total": `${
                    data[selected].rainfall === "*"
                      ? "0"
                      : data[selected].rainfall
                  }"`,
                }}
                dataIcons={[
                  <ConditionIcons conditionId={6} />,
                  <RiWindyFill />,
                  <RiDropLine />,
                  <FaHouseChimney />,
                  <ConditionIcons conditionId={19} />,
                  // <FaEye/>,
                  <WiSprinkle />,
                  <FaTemperatureHalf />,
                  <RiDropFill />,
                ]}
              />
            </div>
          )}
        </div>
      );
  }

  if (dimensions[0] == 1 && dimensions[1] == 2) {
    if (data)
      return (
        <div style={{ height: "100%", width: "100%" }}>
          <WidgetTimeUpdate route={ROUTE}   />
          {data.length && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 3,
                paddingTop: 5,
                height: "90%",
                width: "100%",
              }}
            >
              {/* Oval spreads */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 0,
                }}
              >
                <LeftArrowComponent />

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr",
                    gap: 5,
                    height: "100%",
                    width: "90%",
                    // background: "blue",
                  }}
                >
                  {data &&
                    data.map((item, index) => {
                      const length = data.length;
                      const numDisplayed = 8;
                      const halfNum = numDisplayed / 2;

                      if (
                        !Range(
                          Math.max(
                            Math.min(selected, length - halfNum),
                            halfNum
                          ) - halfNum,
                          Math.min(
                            Math.max(selected, halfNum),
                            length - halfNum
                          ) + halfNum,
                          index
                        )
                      )
                        return;

                      return (
                        <button
                          key={index}
                          onClick={() => setSelected(index)}
                          style={{
                            height: "100%",
                            width: "100%",
                          }}
                        >
                          <OvalDisplay
                            key={index}
                            style={
                              {
                                // height: 275,
                                // margin: 5,
                              }
                            }
                            time={`${
                              item.loctime.getMonth() + 1
                            }/${item.loctime.getDate()}`}
                            temp={
                              <div>
                                <div>{`${parseFloat(item.high_temp).toFixed(
                                  0
                                )}°F`}</div>
                                <div>{`${parseFloat(item.low_temp).toFixed(
                                  0
                                )}°F`}</div>
                              </div>
                            }
                            icon={<ConditionIcons conditionId={item.icon} />}
                            selected={selected === index}
                          />
                        </button>
                      );
                    })}
                </div>

                <RightArrowComponent />
              </div>

              {/* Forecast spread */}
              <ForecastSpread
                style={{ flex: 5, fontSize: layout.scaleMult * 10 }}
                data={{
                  "": data[selected].description,
                  Wind: `${data[selected].wind_speed} MPH, ${data[selected].wind_short}`,
                  Humidity: `${data[selected].humidity}%`,
                  "Comfort Level": `${data[selected].comfort}°F`,
                  Precipitation: `${data[selected].precip_prob}%`,
                  // "Visibility": `${data[selected].visibility} mi`,
                  "Dew Point": `${data[selected].dew_point}`,
                  "Avg. Temp": `${(
                    (parseFloat(data[selected].high_temp) +
                      parseFloat(data[selected].low_temp)) /
                    2
                  ).toFixed(2)}°F`,
                  "Precip. Total": `${
                    data[selected].rainfall === "*"
                      ? "0"
                      : data[selected].rainfall
                  }"`,
                }}
                dataIcons={[
                  <ConditionIcons conditionId={6} />,
                  <RiWindyFill />,
                  <RiDropLine />,
                  <FaHouseChimney />,
                  <ConditionIcons conditionId={19} />,
                  // <FaEye/>,
                  <WiSprinkle />,
                  <FaTemperatureHalf />,
                  <RiDropFill />,
                ]}
              />
            </div>
          )}
        </div>
      );
  }

  return (
    <div>
      <WidgetTimeUpdate updateIntervalMinutes={60} />
      <div style={{ padding: 15 }}>No data found.</div>
    </div>
  );
}
