import React, { useContext, useEffect, useState } from "react";
import AWARE_ICON from "assets/icons/AWAREICON.png";
import { ColorContext } from "App";

const CurrentIntegrations = ({
  data,
  setInitialData,
  setShowNewConnection,
}) => {
  const deviceImages = {
    AWARE: AWARE_ICON,
  };

  const theme = useContext(ColorContext);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100% - 57px)",
      }}
    >
      <div style={{ fontWeight: "bold", fontSize: 24 }}>Connected Systems</div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr 1fr",
          marginTop: 20,
          color: theme.fontColor,
          fontWeight: "bold",
          borderBottom: "1px solid grey",
          padding: 10,
        }}
      >
        <div>System</div>
        <div>Type</div>
        <div>Available Licenses</div>
        <div>Status</div>
        <div>Date Integrated</div>
        <div></div>
      </div>
      {/* TOP */}
      <div
        style={{
          overflow: "auto",
        }}
      >
        {data &&
          data.length > 0 &&
          data.map((integration, index) => {
            return (
              <div
                key={index}
                style={{
                  display: "grid",
                  gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr 1fr",
                  marginTop: 10,
                  borderBottom: "1px solid grey",
                  padding: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",

                    alignItems: "center",
                  }}
                >
                  {/* TODO: replace with system icon */}
                  {deviceImages[integration.system_type] ? (
                    <img
                      src={deviceImages[integration.system_type]}
                      style={{
                        height: 50,
                        width: 50,
                        borderRadius: 10,
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        background: theme.primary,
                        height: 50,
                        width: 50,
                        borderRadius: 10,
                      }}
                    ></div>
                  )}

                  <div style={{ marginLeft: 20 }}>
                    <div>{integration.system_name}</div>
                    <div style={{ color: "#aaa" }}>
                      {integration.user_email}
                    </div>
                  </div>
                </div>
                <div>{integration.system_type}</div>
                <div>
                  <div>
                    {integration.licensesUsed}/{integration.licensesTotal}{" "}
                    Available
                  </div>
                </div>

                <div>
                  <div
                    style={{
                      backgroundColor: integration.status ? "#01B574" : "",
                      border: integration.status ? "0px" : "1px solid #ccc",
                      borderRadius: 8,
                      padding: 5,
                      width: 80,
                      textAlign: "center",
                      boxSizing: "border-box",
                    }}
                  >
                    {integration.status ? "Online" : "Offline"}
                  </div>
                </div>
                <div>{integration.date_integration}</div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setInitialData(integration);
                    setShowNewConnection(true);
                  }}
                >
                  Manage
                </div>
              </div>
            );
          })}
      </div>
      {/* bottom */}
    </div>
  );
};

export default CurrentIntegrations;
