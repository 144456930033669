import React, { useContext, useEffect, useRef, useState } from "react";
import { BsCheck } from "react-icons/bs";
import { FaEllipsis } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import Popup from "reactjs-popup";
import { capitalizeWord } from "utils/stringUtils";
import * as api from "apis/FusionAPI";
import Toggle from "components/UI/Toggle";
import Cookies from "js-cookie";
// import { testPermission } from "data/routeData";
import { usePerms } from "hooks/usePerms";
import TextToggle from "components/UI/TextToggle";
import { height, width } from "@mui/system";
import { ColorContext } from "App";

const CurrentGlobalActions = ({
  data,
  setData,
  setInitialData,
  setShowNewGlobalAction,
}) => {
  const ref = useRef();
  const ref2 = useRef();
  const [lastSortedType, setLastSortedType] = useState("");
  const [sortAmount, setSortAmount] = useState(0);

  const [modal, setModal] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const perms = usePerms();
  const theme = useContext(ColorContext);

  const sortData = (data, key) => {
    if (lastSortedType !== key) {
      setSortAmount(0);
    }

    if (sortAmount % 2 === 0) {
      data.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
      setSortAmount(sortAmount + 1);
      return;
    }

    setLastSortedType(key);
    setSortAmount(sortAmount + 1);

    data.sort((a, b) => {
      return a[key] > b[key] ? 1 : -1;
    });
  };

  const onEdit = (globalAction) => {
    setInitialData(globalAction);
    setShowNewGlobalAction(true);
    ref.current.close();
  };

  const onRemove = (globalAction) => {
    setSelectedItem(globalAction);
    setConfirmOpen(true);
    ref.current.close();
  };

  const onConfirmDelete = () => {
    api
      .deleteGlobalAction(selectedItem.id)
      .then((res) => {
        // console.log("Deleting item", selectedItem);
        setConfirmOpen(false);
        setSelectedItem(null);
        setData(data.filter((item) => item.id !== selectedItem.id));
      })
      .catch((err) => {
        console.error("Error deleting global action", err);
      });
    ref.current.close();
  };

  const onClose = () => {
    setModal(false);
    ref.current.close();
  };

  async function setGlobalActionEnabled(id, value) {
    try {
      const res = await api.setGlobalActionEnabled(id, value);
      if (res.status === 200) {
        // console.log("Set global action enabled", res);
        setData(
          data.map((action) => {
            if (action.id === id) {
              return { ...action, status: value };
            }
            return action;
          })
        );
      }
    } catch (error) {
      // console.log("Error setting global action enabled", error);
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        // backgroundColor: "purple",
      }}
    >
      <div>
        <div style={{ fontWeight: "bold", fontSize: 24 }}>
          Current Global Actions
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1.5fr 1fr 1fr 1fr 1fr",
            marginTop: 20,
            color: theme.fontColor,
            fontWeight: "bold",
            borderBottom: "1px solid grey",
            padding: 10,
          }}
        >
          <div
            onClick={() => {
              sortData(data, "state");
            }}
          >
            Current State
          </div>
          <div
            onClick={() => {
              sortData(data, "title");
            }}
          >
            Title
          </div>
          <div
            onClick={() => {
              sortData(data, "triggerName");
            }}
          >
            Trigger
          </div>
          <div
            onClick={() => {
              sortData(data, "actionName");
            }}
          >
            Action
          </div>
          <div
            onClick={() => {
              sortData(data, "resolutionName");
            }}
          >
            Resolution
          </div>
          <div
            onClick={() => {
              sortData(data, "status");
            }}
          >
            Status
          </div>
        </div>
      </div>
      {/* TOP */}
      <div
        style={{
          overflow: "auto",
        }}
      >
        {data.length > 0 &&
          data.map((item, index) => (
            <div
              key={index}
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1.5fr 1fr 1fr 1fr 0.5fr 0.5fr",
                marginTop: 10,
                borderBottom: "1px solid grey",
                padding: 10,
              }}
            >
              <div>{capitalizeWord(item.state)}</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                }}
              >
                <div style={{ marginLeft: 10 }}> {item.title} </div>
              </div>
              <div>{item.triggerName}</div>
              <div>{item.actionName}</div>
              <div>{item.resolutionName}</div>

              <div>
                {perms.testPermission(
                  [8, 10, 11, 12, 13],
                  ["Enable-DisableGlobalActions"]
                ) ? (
                  <TextToggle
                    value={!item.status}
                    setValue={(value) => {
                      setGlobalActionEnabled(item.id, !value);
                    }}
                    options={["Online", "Offline"]}
                    optionColorOn={theme.primaryHighlight}
                    optionTextColorOn="white"
                    style={{
                      width: "120%",
                      height: "100%",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      backgroundColor: item.status ? "#01B574" : "",
                      border: item.status ? "0px" : "1px solid #ccc",
                      borderRadius: 8,
                      padding: 5,
                      width: 80,
                      textAlign: "center",
                      boxSizing: "border-box",
                    }}
                  >
                    {item.status ? "Online" : "Offline"}
                  </div>
                )}
              </div>
              <div>
                <Popup
                  trigger={
                    <div
                      style={{
                        display: "flex",
                        width: 30,
                        height: 30,
                        marginLeft: 35,
                        backgroundColor: theme.primary,
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 50,
                      }}
                      onClick={() => setModal(true)}
                    >
                      <FaEllipsis />
                    </div>
                  }
                  ref={ref}
                  open={modal}
                  onClose={() => setModal(false)}
                  closeOnDocumentClick
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      borderRadius: 15,
                      userSelect: "none",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: "5px 20px 5px 20px",
                        borderBottom: "solid darkblue 1px",
                        cursor: "pointer",
                      }}
                      onClick={() => onEdit(item)}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: 20,
                          height: 20,
                          marginRight: 10,
                          backgroundColor: theme.primary,
                          borderRadius: 50,
                          color: theme.fontColor,
                        }}
                      >
                        <BsCheck />
                      </div>
                      <div>Edit</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: "5px 20px 5px 20px",
                        borderBottom: "solid darkblue 1px",
                        cursor: "pointer",
                      }}
                      onClick={() => onRemove(item)}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: 20,
                          height: 20,
                          marginRight: 10,
                          backgroundColor: theme.primary,
                          borderRadius: 50,
                          color: theme.fontColor,
                        }}
                      >
                        <IoClose />
                      </div>
                      <div>Remove</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: "5px 20px 5px 20px",
                        cursor: "pointer",
                        fontWeight: "bold",
                      }}
                      onClick={() => onClose(item)}
                    >
                      <div style={{ width: 20, height: 20, marginRight: 10 }} />
                      <div>Close</div>
                    </div>
                  </div>
                </Popup>
              </div>
            </div>
          ))}
      </div>

      {/* Confirm delete popup */}
      {selectedItem && (
        <Popup
          open={confirmOpen}
          ref={ref2}
          onClose={() => setConfirmOpen(false)}
          closeOnDocumentClick
          closeOnEscape
        >
          <div
            style={{
              backgroundColor: "white",
              color: "black",
              borderRadius: 15,
              userSelect: "none",
              padding: 30,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <div>
                Are you sure you want
                <br />
                to delete
                <br />
                {selectedItem.title}
              </div>
            </div>
            <div
              style={{
                marginTop: 15,
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  color: theme.fontColor,
                  padding: "7px 20px 7px 20px",
                  backgroundColor: theme.background,
                  borderRadius: 15,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setConfirmOpen(false);
                  setSelectedItem(null);
                }}
              >
                No
              </div>
              <div
                style={{
                  color: theme.fontColor,
                  padding: "7px 20px 7px 20px",
                  backgroundColor: theme.primaryHighlight,
                  borderRadius: 15,
                  cursor: "pointer",
                }}
                onClick={(e) => onConfirmDelete(e)}
              >
                Yes
              </div>
            </div>
          </div>
        </Popup>
      )}
    </div>
  );
};

export default CurrentGlobalActions;
