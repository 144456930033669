import React, { useContext } from "react";
import ReactGridLayout from "react-grid-layout";

import "../pages/Dashboard.css";
import Widget from "components/Widget";
import { IoTrash } from "react-icons/io5";
import AddWidgetComponent from "./AddWidgetComponent";

import { SidebarContext, LayoutSizeContext, ColorContext } from "App";

export default function DashboardPreview({
  definedWidgetData,
  list,
  setList,
  onRemoveWidget,
  openWidgetSelector,
  uninteractable,
}) {
  const sidebarOpen = useContext(SidebarContext);
  const layoutSize = useContext(LayoutSizeContext);

  const onListChange = (newList) => {
    setList(newList);
  };

  
  const theme = useContext(ColorContext);

  return (
    <div
      // className="scrollbar-hidden"
      style={{
        userSelect: "none",
        display: "flex",
        backgroundColor: "red",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ height: window.innerHeight - 300 }}>
          {/* Widgets */}

          <ReactGridLayout
            className="layout"
            cols={3}
            rowHeight={layoutSize.widgetHeight}
            width={layoutSize.width - 55}
            onDragStop={(e, last, curr) => {
              //update list with new positions
              const temp = [...list];
              for (let i = 0; i < e.length; i++) {
                if (e[i].i !== "a")
                  temp[i] = { ...temp[i], x: e[i].x, y: e[i].y };
              }

              onListChange(temp);
            }}
            draggableHandle=".handle"
            // containerPadding={[15, 15]}
          >
            {list &&
              list.map((item, index) => {
                if (item?.displayed === false || item?.active === false) return;

                return (
                  <div
                    key={index}
                    data-grid={
                      uninteractable
                        ? {
                            ...definedWidgetData[item.id],
                            ...item,
                            i: index,
                            static: !item.isDraggable,
                            isDraggable: false,
                            locked: true,
                          }
                        : {
                            ...definedWidgetData[item.id],
                            ...item,
                            i: index,
                            static: !item.isDraggable,
                          }
                    }
                  >
                    <Widget
                      key={0}
                      item={{ ...definedWidgetData[item.id], ...item }}
                      index={index}
                      expandable
                      list={list}
                      onListChange={onListChange}
                      uninteractable
                      dashboardPreview = {true}
                      // scale={0.62}
                    />

                    {onRemoveWidget && (
                      <button
                        style={{
                          position: "absolute",
                          top: 5,
                          right: 50,
                          width: 30,
                          backgroundColor: "red",
                          borderRadius: 50,
                          padding: 5,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => onRemoveWidget(index)}
                      >
                        <IoTrash color="white" size={15} />
                      </button>
                    )}
                  </div>
                );
              })}
            <div
              key="a"
              style={{
                width: layoutSize.widgetWidth,
                height: layoutSize.widgetHeight,
                backgroundColor: theme.base,
              }}
              data-grid={{
                i: "default",
                displayed: true,
                isDraggable: false,
                locked: true,
                static: false,
                x: 0,
                y: Infinity,
                w: 1,
                h: 1,
              }}
            >
              <AddWidgetComponent
                openWidgetSelector={() => openWidgetSelector(true)}
              />
            </div>
          </ReactGridLayout>
        </div>
      </div>
    </div>
  );
}

function TotalAlerts({ alertCount, alertDiff }) {
  
  const theme = useContext(ColorContext);
  return (
    <div
      style={{
        backgroundColor: theme.primaryShadow,
        width: 310,
        height: 43,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        marginRight: 15,
      }}
    >
      <div
        style={{
          fontSize: 9.4,
          marginLeft: 5,
        }}
      >
        Total Alerts
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: 310 - 10,
          marginLeft: 5,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: 14.1,
            }}
          >
            {alertCount}
          </div>
          <div
            style={{
              marginLeft: 10,
              fontSize: 10.97,
              color: "green",
            }}
          >
            {`+${alertDiff}%`}
          </div>
        </div>
        <div
          style={{
            fontSize: 10.97,
          }}
        >
          Increase over past 3 days
        </div>
      </div>
    </div>
  );
}

function TodaysIncidents({ todaysAlertCount, todaysAlertDiff }) {
  const theme = useContext(ColorContext);
  return (
    <div
      style={{
        backgroundColor: theme.primaryShadow,
        width: 310,
        height: 43,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        marginRight: 15,
      }}
    >
      <div
        style={{
          fontSize: 9.4,
          marginLeft: 5,
        }}
      >
        Today's Incidents
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: 310 - 10,
          marginLeft: 5,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: 14.1,
            }}
          >
            {todaysAlertCount}
          </div>
          {todaysAlertDiff > -Infinity && todaysAlertDiff < Infinity && (
            <div
              style={{
                marginLeft: 10,
                fontSize: 10.97,
                color: todaysAlertDiff >= 0 ? "red" : "green",
              }}
            >
              {todaysAlertDiff < 0
                ? `${todaysAlertDiff}%`
                : `+${todaysAlertDiff}%`}
            </div>
          )}
        </div>
        {todaysAlertDiff > -Infinity && todaysAlertDiff < Infinity && (
          <div
            style={{
              fontSize: 10.97,
            }}
          >
            {todaysAlertDiff >= 0
              ? "Increase from the past 3 days"
              : "Decrease from the past 3 days"}
          </div>
        )}
      </div>
    </div>
  );
}

function Instances() {
  const theme = useContext(ColorContext);
  return (
    <div
      style={{
        backgroundColor: theme.primaryShadow,
        width: 310,
        height: 43,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        marginRight: 15,
      }}
    >
      <div
        style={{
          fontSize: 9.4,
          marginLeft: 5,
        }}
      >
        Instances
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: 310 - 10,
          marginLeft: 5,
        }}
      >
        Coming Soon
      </div>
    </div>
  );
}

function SevereWeatherAlerts({ weatherAlertCount, weatherAlertDiff }) {
  const theme = useContext(ColorContext);
  return (
    <div
      style={{
        backgroundColor: theme.primaryShadow,
        width: 310,
        height: 43,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        marginRight: 15,
      }}
    >
      <div
        style={{
          fontSize: 9.4,
          marginLeft: 5,
        }}
      >
        Today's Incidents
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: 310 - 10,
          marginLeft: 5,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: 14.1,
            }}
          >
            {weatherAlertCount}
          </div>
          {weatherAlertDiff > -Infinity && weatherAlertDiff < Infinity && (
            <div
              style={{
                marginLeft: 10,
                fontSize: 10.97,
                color: weatherAlertDiff >= 0 ? "red" : "green",
              }}
            >
              {weatherAlertDiff < 0
                ? `${weatherAlertDiff.toFixed(2)}%`
                : `+${weatherAlertDiff.toFixed(2)}%`}
            </div>
          )}
        </div>
        {weatherAlertDiff > -Infinity && weatherAlertDiff < Infinity && (
          <div
            style={{
              fontSize: 10.97,
            }}
          >
            {weatherAlertDiff >= 0
              ? "Increase from the past 3 days"
              : "Decrease from the past 3 days"}
          </div>
        )}
      </div>
    </div>
  );
}
