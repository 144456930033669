import OvalDisplayHorizontal from "components/OvalDisplayHorizontal";
import React, { useContext, useEffect, useState } from "react";
import { PiWavesBold } from "react-icons/pi";
import * as colorUtils from "utils/colorUtils";
import * as api from "apis/FusionAPI";
import WidgetTimeUpdate from "./WidgetTimeUpdate";
import WidgetLoadingAnimation from "./WidgetLoadingAnimation";
import { ErrorMessage } from "./ErrorMessage";
import DropdownSelector from "components/DropdownSelector";

//images inports
import GoodImg from "../../assets/airquality/1.png";
import ModerateImg from "../../assets/airquality/2.png";
import UnhealthySImg from "../../assets/airquality/3.png";
import UnhealthyImg from "../../assets/airquality/4.png";
import VeryUnhealthyImg from "../../assets/airquality/5.png";
import HazardousImg from "../../assets/airquality/6.png";
import { useAPIData } from "hooks/useAPIData";
import { LayoutSizeContext, UniqueWidgetRefreshContext } from "App";
import { BasePlatform } from "chart.js";
import Cookies from "js-cookie";

const imageMap = {
  G: { img: GoodImg, text: "Good" },
  M: { img: ModerateImg, text: "Moderate" },
  S: { img: UnhealthySImg, text: "Unhealthy for Sensitive Groups" },
  U: { img: UnhealthyImg, text: "Unhealthy" },
  V: { img: VeryUnhealthyImg, text: "Very Unhealthy" },
  H: { img: HazardousImg, text: "Hazardous" },
};

let dataCache;
 

export default function AirQualityObservationsWidget({
  widgetRefresh,
  setWidgetRefresh,
  dimensions,
}) {
  const [data, setData] = useState();
  const [selected, setSelected] = useState(0);
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState({ state: false, message: "" });
  const layout = useContext(LayoutSizeContext);
  // const [widgetRefresh, setWidgetRefresh] = useState(false);
  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });

  useEffect(() => {
    dataCache = null;
    setFetching(true);
  }, [widgetRefresh]);

  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "getAirQualityObservation"
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  useEffect(() => {
    const fetchData = async () => {
      try {
        FusionData.forceGet(ROUTE)
          .then((res) => {
            // console.log("result!!!!!!!!", res);
            dataCache = [...res.data.result];
            setData(dataCache);
             
            setFetching(false);
          })
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [uwf[UNIQUEKEY]]);

  useEffect(() => {
    (async () => {
      if (dataCache) {
        setData(dataCache);
        setFetching(false);
        return;
      }
      FusionData.get(ROUTE)
        .then((res) => {
          // console.log("result!!!!!!!!", res);
          dataCache = [...res.data.result];
          setData(dataCache);
           
          setFetching(false);
        })
        .catch((err) => {
          // console.log("Error getting data", err);
          setError({ state: true, message: err.message });
        });
    })();
  }, [widgetRefresh]);

  if (error.state && !fetching) {
    return <ErrorMessage message={error.message} />;
  }

  // if (true)
  if (fetching)
    return (
      <WidgetLoadingAnimation
        widgetName={"AirQualityObservation"}
        dimensions={dimensions}
      />
    );
  if (data && data.length === 0) {
    return (
      <div>
        <WidgetTimeUpdate route={ROUTE}  />
        <div style={{ padding: 15 }}>No data found.</div>
      </div>
    );
  }
  if (dimensions[0] == 1 && dimensions[1] == 2)
    if (data)
      return (
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <WidgetTimeUpdate route={ROUTE}   />
          {data.length && (
            <div
              // className="scrollbar-hidden"
              style={{ padding: 15 }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  overflow: "auto",
                  maxHeight: 950,
                }}
              >
                <div style={{ flex: 1 }}>
                  {data.map((item, index) => {
                    return (
                      <OvalDisplayHorizontal
                        key={index}
                        style={{ margin: 15 }}
                        header={item.air_station_name}
                        headerStyle={{ fontSize: 15 }}
                        content={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              justifyContent: "space-between",
                              alignItems: "center",
                              fontSize: 12,
                            }}
                          >
                            <div>{`Concentration: ${item.concentration}ppb`}</div>
                            <PiWavesBold
                              size={25}
                              color={colorUtils.GetQualityColor(item.category)}
                            />
                          </div>
                        }
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      );
  if (dimensions[0] == 1 && dimensions[1] == 1) {
    if (data)
      return (
        <div style={{ height: "100%" }}>
          <WidgetTimeUpdate route={ROUTE}   />

          {data.length && (
            <div
              className="scrollbar-hidden"
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 15,
                // maxHeight: "88%",
                height: "83%",
                // background: "red",
              }}
            >
              <div style={{ zIndex: 1000 }}>
                <DropdownSelector
                  options={data.map((item) => {
                    return item.air_station_name;
                  })}
                  selectedOption={selected}
                  setSelected={(index) => setSelected(index)}
                  triggerStyle={{ width: "100%", padding: 5, zIndex: 1000 }}
                  contentStyle={{ border: "solid black 2px" }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  overflowY: "auto",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      marginTop: 15,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "auto",
                    }}
                  >
                    <img
                      src={imageMap[data[selected].category].img}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      flex: 5,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "end",
                      alignItems: "center",
                      marginTop: `calc(-45% - ${
                        layout.scaleMult * 150 - 150
                      }px)`,
                    }}
                  >
                    <PiWavesBold
                      size={80 * layout.scaleMult}
                      color={colorUtils.GetQualityColor(
                        data[selected].category
                      )}
                    />
                    <div style={{ fontSize: 50 * layout.scaleMult }}>
                      {imageMap[data[selected].category].text}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      borderTop: "solid grey 1px",
                    }}
                  >
                    <div
                      style={{ marginBottom: 5, fontSize: layout.baseFontSize }}
                    >
                      {`Concentration: ${data[selected].concentration}ppb`}
                    </div>
                    <div>{data[selected].description}</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      );
  }

  if (dimensions[0] == 2 && dimensions[1] == 1) {
    if (data)
      return (
        <div style={{height: "100%"}}>
          <WidgetTimeUpdate route={ROUTE}    />
          {data.length && (
            <div
              // className="scrollbar-hidden"
              style={{ padding: 15, maxHeight: "85%" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  overflowY: "auto",
                  // height: 400,
                }}
              >
                <div style={{ flex: 1 }}>
                  {data
                    .filter((item, index) => {
                      return index % 2 == 0;
                    })
                    .map((item, index) => {
                      return (
                        <OvalDisplayHorizontal
                          key={index}
                          style={{ margin: 15 }}
                          header={item.air_station_name}
                          headerStyle={{ fontSize: 15 }}
                          content={
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontSize: 12,
                              }}
                            >
                              <div>{`Concentration: ${item.concentration}ppb`}</div>
                              <PiWavesBold
                                size={25}
                                color={colorUtils.GetQualityColor(
                                  item.category
                                )}
                              />
                            </div>
                          }
                        />
                      );
                    })}
                </div>
                <div style={{ flex: 1 }}>
                  {data
                    .filter((item, index) => {
                      return index % 2 == 1;
                    })
                    .map((item, index) => {
                      return (
                        <OvalDisplayHorizontal
                          key={index}
                          style={{ margin: 15 }}
                          header={item.air_station_name}
                          headerStyle={{ fontSize: 15 }}
                          content={
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontSize: 12,
                              }}
                            >
                              <div>{`Concentration: ${item.concentration}ppb`}</div>
                              <PiWavesBold
                                size={25}
                                color={colorUtils.GetQualityColor(
                                  item.category
                                )}
                              />
                            </div>
                          }
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          )}
        </div>
      );
  }

  return (
    <div>
      <div style={{ padding: 15 }}>No data found</div>
    </div>
  );
}
