import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CurrentDevices from "components/Connections/CurrentDevices";
import IntegrationUsage from "components/Connections/IntegrationUsage";
import NewConnection from "components/Connections/NewConnection";
import DevicesMap from "./DevicesMap";
import DeviceSettings from "./DeviceSettings/DeviceSettings.js";
import AwareDeviceGraphs from "./AwareDeviceGraphs";
import * as api from "apis/FusionAPI";
import { width } from "@mui/system";
import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";
import TextToggle from "components/UI/TextToggle";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import { usePerms } from "hooks/usePerms";
import { FaChevronLeft } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa";
import {
  ColorContext,
  LayoutSizeContext,
  UniqueWidgetRefreshContext,
} from "App";
import Cookies from "js-cookie";
import { useAPIData } from "hooks/useAPIData";

export default function Devices({
  widget = false,
  widgetType = "",
  dimensions,
  uninteractable = false,
  sidebarOpen,
  preview = false,
}) {
  const [deviceData, setDeviceData] = useState([]);
  const [simpleDeviceData, setSimpleDeviceData] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [showNewConnection, setShowNewConnection] = useState(false);
  const [selectedDevice, setSelectedDeviceIndex] = useState(0);
  const [selectedDeviceData, setSelectedDeviceData] = useState([]);
  const [timeMultiplier, setTimeMultiplier] = useState(
    widgetType === "map" ? 30 * 1440 : 1440
  );
  const [startTime, setStartTime] = useState(
    new Date().getTime() - timeMultiplier * 60000
  );
  const [endTime, setEndTime] = useState(new Date().getTime());
  const [updatedSelectedDevice, setUpdatedSelectedDevice] = useState(false);

  const [showDeviceSettings, setShowDeviceSettings] = useState(false);
  const [showCurrentDevice, setShowCurrentDevice] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);

  const [viewImageInWidget, setViewImageInWidget] = useState(false);

  const [mapWidth, setMapWidth] = useState(501);
  const [mapHeight, setMapHeight] = useState(451);
  const [fetching, setFetching] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");

  const [imageNodes, setImageNodes] = useState([]);
  const [selectedImg, setSelectedImg] = useState(0);
  const [showExportModal, setShowExportModal] = useState(false);
const [loadingMessage, setLoadingMessage] = useState("");
  const theme = useContext(ColorContext);

  const layout = useContext(LayoutSizeContext);
  const perms = usePerms();
  const navigate = useNavigate();
  const location = useLocation();

  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });

  const setSelectedDevice = (index) => {
    setSelectedDeviceIndex(index);
    setUpdatedSelectedDevice(!updatedSelectedDevice);
  };

  useEffect(() => {
    getNodeData();
  }, []);

  useEffect(() => {
    if (!deviceData) return;

    if (!deviceData[selectedDevice]?.data) {
      setSelectedDevice(0);
      return;
    }

    const foundImg = [];

    deviceData[selectedDevice].data.forEach((data, index) => {
      if (
        !foundImg
          .map((img) => {
            return deviceData[selectedDevice].data[img]?.pict == data?.pict;
          })
          .includes(true)
      ) {
        //new entry
        foundImg.push(index);
      }
    });

    setImageNodes(foundImg);
    setSelectedImg(0);
  }, [selectedDevice, deviceData]);

  async function getNodeData() {
    if (!(await perms.validatePermissions(["ConnectionsEnabled"]))) {
      navigate("/404");
      return;
    }
    FusionData.get("getNodes").then((res) => {
      // console.log("Node res:", res.data.nodeData);
      if (res.data.nodeData) setSelectedDevice(0);
      setNodes(
        res.data.nodeData.sort((a, b) => {
          return a.nodeId - b.nodeId;
        })
      );
      setFetching(false);
    });
  }
  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "getNodes";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  useEffect(() => {
    const fetchData = async () => {
      try {
        FusionData.forceGet("getNodes").then((res) => {
          // console.log("Node res:", res.data.nodeData);
          // console.log("nodes update")
          if (res.data.nodeData) setSelectedDevice(0);
          setNodes(
            res.data.nodeData.sort((a, b) => {
              return a.nodeId - b.nodeId;
            })
          );
          setFetching(false);
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [uwf[UNIQUEKEY]]);

  const addDevice = (device) => {
    api.addConnectedDevice(device).then((res) => {
      if (res.status === 200) {
        api.getNodes().then((res) => {
          setNodes(res.data.nodeData);
        });
      }
    });
  };

  function extractDateTimeFromUrl(url) {
    // Regular expression to match the date and time in the URL
    const regex = /_(\d{4}-\d{2}-\d{2})_(\d{6})\.jpg$/;

    // Use the regex to extract the date and time parts
    const match = url.match(regex);

    if (match) {
      const date = match[1]; // Extracted date part (YYYY-MM-DD)
      const time = match[2]; // Extracted time part (HHMMSS)

      // Combine date and time into a single string
      const dateTimeString = `${date}T${time.substring(0, 2)}:${time.substring(
        2,
        4
      )}:${time.substring(4, 6)}Z`;

      // Create a Date object from the combined date and time string
      const dateTime = new Date(dateTimeString);

      // Return the formatted date and time string in UTC
      return dateTime.toUTCString();
    } else {
      // Return an error message if the date and time are not found
      return "Date and time not found in the URL";
    }
  }

  useEffect(() => {
    handleSubmit();
  }, []);

  const handleSubmit = () => {
    setLoadingMessage("Fetching Data...");
    api.getNodeDataByTimestamps(startTime, endTime).then((res) => {
      setSuccessMessage("");
      setLoadingMessage("")

      const simpleData = [];
      const data = res.data.result.map((node, index) => {
        const simpleDataPoints = [];
        const datapoints = node.data.map((datapoint, dIndex) => {
          if (dIndex > 0) {
            const diffs = [];
            const exceptions = ["aware_id", "created_utc", "data_hash", "ts"];

            Object.keys(datapoint).forEach((key) => {
              if (
                datapoint[key] !== node.data[dIndex - 1][key] &&
                !exceptions.includes(key)
              ) {
                diffs.push(key);
              }
            });

            if (diffs.length > 0)
              simpleDataPoints.push({
                ...datapoint,
                timestamp_links: [datapoint.created_utc],
              });
            else
              simpleDataPoints[
                simpleDataPoints.length - 1
              ].timestamp_links.push(datapoint.created_utc);
          } else {
            simpleDataPoints.push({
              ...datapoint,
              timestamp_links: [datapoint.created_utc],
            });
          }

          return datapoint;
        });

        simpleData.push({ ...node, data: simpleDataPoints });
        return { ...node, data: datapoints };
      });

      setDeviceData(
        data.sort((a, b) => {
          return parseInt(a.nodeId) - parseInt(b.nodeId);
        })
      );
      setSimpleDeviceData(
        simpleData.sort((a, b) => {
          return parseInt(a.nodeId) - parseInt(b.nodeId);
        })
      );

      setPageIndex(data.length - 1);
      setSelectedDevice(0);
      setShowCurrentDevice(false);
    });
  };

  if (fetching) {
    return <WidgetLoadingAnimation />;
  }

  const renderDeviceSettings = () =>
    showDeviceSettings && (
      <DeviceSettings
        setShowDeviceSettings={setShowDeviceSettings}
        arrayOfDevices={deviceData}
        selectedDevice={selectedDevice}
        nodeList={nodes}
      />
    );

  const renderMainView = () => (
    <div
      style={{
        height:
          location.pathname === "/system/integrations"
            ? layout.height - 117
            : layout.height - 80,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            paddingRight: 10,
          }}
        >
          {!showExportModal && (
            <DevicesMap
              sidebarOpen={sidebarOpen}
              nodeData={deviceData}
              selectedDevice={
                deviceData[selectedDevice].data[
                  deviceData[selectedDevice].data.length - 1
                ]
              }
              arrayOfDevices={deviceData}
              setSelectedDevice={setSelectedDevice}
              setShowCurrentDevice={setShowCurrentDevice}
              selectorMargin={"27%"}
              nodeList={nodes}
              simpleDeviceData={simpleDeviceData}
              setPageIndex={setPageIndex}
              preview={preview}
              // widget
            />
          )}
          <div
            style={{
              marginTop: 15,
              backgroundColor: theme.primaryShadow,
              borderRadius: 10,
            }}
          >
            {deviceData.length > 0 && (
              <AwareDeviceGraphs
                arrayOfDevices={deviceData}
                nodeData={deviceData}
                nodeList={nodes}
                simpleNodeData={simpleDeviceData}
                selectedDevice={selectedDevice}
                setSelectedDevice={setSelectedDevice}
                setPageIndex={setPageIndex}
                setShowCurrentDevice={setShowCurrentDevice}
                updatedSelectedDevice={updatedSelectedDevice}
              />
            )}
          </div>
        </div>
        <div style={{ width: 550 }}>
          <CurrentDevices
          loadingMessage={loadingMessage}
            data={simpleDeviceData}
            setData={setDeviceData}
            setSimpleData={setSimpleDeviceData}
            setShowDeviceSettings={setShowDeviceSettings}
            setSelectedDevice={setSelectedDevice}
            selectedDevice={selectedDevice}
            setShowCurrentDevice={setShowCurrentDevice}
            showCurrentDevice={showCurrentDevice}
            setSelectedDeviceData={setSelectedDeviceData}
            nodeList={nodes}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            handleSubmit={handleSubmit}
            getNodeData={getNodeData}
            startTime={startTime}
            setStartTime={setStartTime}
            endTime={endTime}
            setEndTime={setEndTime}
            timeMultiplier={timeMultiplier}
            setTimeMultiplier={setTimeMultiplier}
            setSuccessMessage={setSuccessMessage}
            successMessage={successMessage}
            showExportModal={showExportModal}
            setShowExportModal={setShowExportModal}
          />
        </div>
      </div>
      {/* {renderNewConnection()} */}
      {renderDeviceSettings()}
    </div>
  );

  if (widget && fetching && widgetType === "map") {
    return (
      <div
        style={{
          height: "100%",
        }}
      >
        <WidgetLoadingAnimation
          widgetName="DevicesMap"
          dimensions={dimensions}
        />
      </div>
    );
  }

  if (widget && fetching && widgetType === "graph") {
    return (
      <WidgetLoadingAnimation
        widgetName="DevicesGraph"
        dimensions={dimensions}
      />
    );
  }

  const renderWidgetGraph = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <WidgetTimeUpdate route={ROUTE} realtime />
      <div style={{ height: "100%", overflow: "hidden", padding: 15 }}>
        {deviceData.length > 0 && (
          <AwareDeviceGraphs
            arrayOfDevices={deviceData}
            nodeData={deviceData}
            nodeList={nodes}
            simpleNodeData={simpleDeviceData}
            selectedDevice={selectedDevice}
            setSelectedDevice={setSelectedDevice}
            setPageIndex={setPageIndex}
            setShowCurrentDevice={setShowCurrentDevice}
            updatedSelectedDevice={updatedSelectedDevice}
            widget
            noTitle
          />
        )}
      </div>
    </div>
  );

  const renderWidgetMap = (sidebarOpen) => {
    if (widget && dimensions[0] == 2 && dimensions[1] == 1) {
      return (
        <div>
          <WidgetTimeUpdate route={ROUTE} realtime />
          <div
            style={{
              height: layout.widgetHeight - 60,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                <DevicesMap
                  sidebarOpen={sidebarOpen}
                  setViewImageInWidget={setViewImageInWidget}
                  setPageIndex={setPageIndex}
                  selectorMargin={50}
                  widget
                  nodeData={nodes}
                  selectedDevice={
                    deviceData[selectedDevice].data[
                      deviceData[selectedDevice].data.length - 1
                    ]
                  }
                  arrayOfDevices={deviceData}
                  setSelectedDevice={setSelectedDevice}
                  setShowCurrentDevice={setShowCurrentDevice}
                  nodeList={nodes}
                  longMap={viewImageInWidget ? false : true}
                  preview={preview}
                />
              </div>

              {viewImageInWidget && (
                <div
                  style={{
                    width: layout.widgetWidth,
                    height: layout.widgetHeight - 90,
                    display: "flex",
                    flexDirection: "column",
                    margin: 15,
                  }}
                >
                  {deviceData &&
                  deviceData[selectedDevice] &&
                  deviceData[selectedDevice].data &&
                  deviceData[selectedDevice].data[imageNodes[selectedImg]] &&
                  deviceData[selectedDevice].data[imageNodes[selectedImg]]
                    .pict ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src={`https://flashflood.info:8282/${
                          deviceData[selectedDevice]?.data[
                            imageNodes[selectedImg]
                          ].IMEI
                        }/${
                          deviceData[selectedDevice]?.data[
                            imageNodes[selectedImg]
                          ].pict
                        }`}
                        alt="Device"
                        style={{
                          height: layout.widgetHeight - 110,
                          objectFit: "fill",
                        }}
                      />
                      <div
                        style={{
                          color: theme.fontColor,
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        Image Timestamp:{" "}
                        {extractDateTimeFromUrl(
                          deviceData[selectedDevice]?.data[
                            imageNodes[selectedImg]
                          ].pict
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "490px",
                        height: "430px",
                        marginLeft: 5,
                        marginTop: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: theme.primaryHighlight,
                      }}
                    >
                      No Image Found
                    </div>
                  )}

                  {/* Buttons */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <button
                      style={{
                        zIndex: 1000,
                        position: "relative",
                        bottom: layout.widgetHeight / 2,
                        left: 10,
                      }}
                      onClick={() => {
                        if (selectedImg > 0) setSelectedImg(selectedImg - 1);
                        else setSelectedImg(imageNodes.length - 1);
                      }}
                    >
                      <FaChevronLeft size={25} />
                    </button>

                    <button
                      style={{
                        zIndex: 1000,
                        position: "relative",
                        bottom: layout.widgetHeight / 2,
                        right: 10,
                      }}
                      onClick={() => {
                        if (selectedImg < imageNodes.length - 1)
                          setSelectedImg(selectedImg + 1);
                        else setSelectedImg(0);
                      }}
                    >
                      <FaChevronRight size={25} />
                    </button>
                  </div>
                </div>
              )}

              <div
                style={{
                  position: "absolute",
                  bottom: viewImageInWidget ? 40 : 25,
                  right: 25,
                  zIndex: 1000,
                }}
              >
                <TextToggle
                  options={["Map", "Camera"]}
                  value={viewImageInWidget}
                  setValue={setViewImageInWidget}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (widget && dimensions[0] == 1 && dimensions[1] == 2) {
      return (
        <div>
          <WidgetTimeUpdate route={ROUTE} realtime />
          <div style={{ height: layout.widgetHeight - 60 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                <DevicesMap
                  sidebarOpen={sidebarOpen}
                  setViewImageInWidget={setViewImageInWidget}
                  setPageIndex={setPageIndex}
                  selectorMargin={50}
                  height={mapHeight}
                  width={mapWidth}
                  widget
                  nodeData={nodes}
                  selectedDevice={
                    deviceData[selectedDevice].data[
                      deviceData[selectedDevice].data.length - 1
                    ]
                  }
                  arrayOfDevices={deviceData}
                  setSelectedDevice={setSelectedDevice}
                  setShowCurrentDevice={setShowCurrentDevice}
                  nodeList={nodes}
                  tallMap={viewImageInWidget ? false : true}
                  preview={preview}
                />
              </div>

              {viewImageInWidget && (
                <div
                  style={{
                    width: layout.widgetWidth - 43,
                    // height: layout.widgetHeight - 40,
                    display: "flex",
                    flexDirection: "column",
                    margin: 15,
                  }}
                >
                  {deviceData &&
                  deviceData[selectedDevice] &&
                  deviceData[selectedDevice].data &&
                  deviceData[selectedDevice].data[imageNodes[selectedImg]] &&
                  deviceData[selectedDevice].data[imageNodes[selectedImg]]
                    .pict ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src={`https://flashflood.info:8282/${
                          deviceData[selectedDevice]?.data[
                            imageNodes[selectedImg]
                          ].IMEI
                        }/${
                          deviceData[selectedDevice]?.data[
                            imageNodes[selectedImg]
                          ].pict
                        }`}
                        alt="Device"
                        style={{
                          height: layout.widgetHeight - 60,
                          objectFit: "fill",
                        }}
                      />
                      <div
                        style={{
                          color: theme.fontColor,
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        Image Timestamp:{" "}
                        {extractDateTimeFromUrl(
                          deviceData[selectedDevice]?.data[
                            imageNodes[selectedImg]
                          ].pict
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "490px",
                        height: "430px",
                        marginLeft: 5,
                        marginTop: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: theme.primaryHighlight,
                      }}
                    >
                      No Image Found
                    </div>
                  )}

                  {/* Buttons */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <button
                      style={{
                        zIndex: 1000,
                        position: "relative",
                        bottom: layout.widgetHeight / 2,
                        left: 10,
                      }}
                      onClick={() => {
                        if (selectedImg > 0) setSelectedImg(selectedImg - 1);
                        else setSelectedImg(imageNodes.length - 1);
                      }}
                    >
                      <FaChevronLeft size={25} />
                    </button>

                    <button
                      style={{
                        zIndex: 1000,
                        position: "relative",
                        bottom: layout.widgetHeight / 2,
                        right: 10,
                      }}
                      onClick={() => {
                        if (selectedImg < imageNodes.length - 1)
                          setSelectedImg(selectedImg + 1);
                        else setSelectedImg(0);
                      }}
                    >
                      <FaChevronRight size={25} />
                    </button>
                  </div>
                </div>
              )}
              <div
                style={{
                  position: "absolute",
                  bottom: viewImageInWidget ? 70 : 25,
                  right: 25,
                  zIndex: 1000,
                }}
              >
                <TextToggle
                  options={["Map", "Camera"]}
                  value={viewImageInWidget}
                  setValue={setViewImageInWidget}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (widget && dimensions[0] == 1 && dimensions[1] == 1) {
      return (
        <div>
          <WidgetTimeUpdate route={ROUTE} realtime />
          <div style={{ height: layout.widgetHeight - 60 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {!viewImageInWidget && (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                  }}
                >
                  <DevicesMap
                    sidebarOpen={sidebarOpen}
                    setViewImageInWidget={setViewImageInWidget}
                    setPageIndex={setPageIndex}
                    selectorMargin={50}
                    height={mapHeight}
                    width={mapWidth / 2}
                    widget
                    nodeData={nodes}
                    selectedDevice={
                      deviceData[selectedDevice].data[
                        deviceData[selectedDevice].data.length - 1
                      ]
                    }
                    arrayOfDevices={deviceData}
                    setSelectedDevice={setSelectedDevice}
                    setShowCurrentDevice={setShowCurrentDevice}
                    nodeList={nodes}
                    preview={preview}
                  />
                </div>
              )}
              {viewImageInWidget && (
                <div
                  style={{
                    width: layout.widgetWidth - 43,
                    height: layout.widgetHeight - 90,
                    display: "flex",
                    flexDirection: "column",
                    margin: 15,
                  }}
                >
                  {deviceData &&
                  deviceData[selectedDevice] &&
                  deviceData[selectedDevice].data &&
                  deviceData[selectedDevice].data[imageNodes[selectedImg]] &&
                  deviceData[selectedDevice].data[imageNodes[selectedImg]]
                    .pict ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src={`https://flashflood.info:8282/${
                          deviceData[selectedDevice]?.data[
                            imageNodes[selectedImg]
                          ].IMEI
                        }/${
                          deviceData[selectedDevice]?.data[
                            imageNodes[selectedImg]
                          ].pict
                        }`}
                        alt="Device"
                        style={{
                          height: layout.widgetHeight - 110,
                          objectFit: "fill",
                        }}
                      />
                      <div
                        style={{
                          color: theme.fontColor,
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        Image Timestamp:{" "}
                        {extractDateTimeFromUrl(
                          deviceData[selectedDevice]?.data[
                            imageNodes[selectedImg]
                          ].pict
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "490px",
                        height: "430px",
                        marginLeft: 5,
                        marginTop: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: theme.primaryHighlight,
                      }}
                    >
                      No Image Found
                    </div>
                  )}

                  {/* Buttons */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <button
                      style={{
                        zIndex: 1000,
                        position: "relative",
                        bottom: layout.widgetHeight / 2,
                        left: 10,
                      }}
                      onClick={() => {
                        if (selectedImg > 0) setSelectedImg(selectedImg - 1);
                        else setSelectedImg(imageNodes.length - 1);
                      }}
                    >
                      <FaChevronLeft size={25} />
                    </button>

                    <button
                      style={{
                        zIndex: 1000,
                        position: "relative",
                        bottom: layout.widgetHeight / 2,
                        right: 10,
                      }}
                      onClick={() => {
                        if (selectedImg < imageNodes.length - 1)
                          setSelectedImg(selectedImg + 1);
                        else setSelectedImg(0);
                      }}
                    >
                      <FaChevronRight size={25} />
                    </button>
                  </div>
                </div>
              )}
              <div
                style={{
                  position: "absolute",
                  bottom: viewImageInWidget ? 70 : 25,
                  right: 25,
                  zIndex: 1000,
                }}
              >
                <TextToggle
                  options={["Map", "Camera"]}
                  value={viewImageInWidget}
                  setValue={setViewImageInWidget}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  // console.log("aint got no gas innit", widget, deviceData);

  if (widget && deviceData.length > 0) {
    if (widgetType === "graph") return renderWidgetGraph();
    if (widgetType === "map") return renderWidgetMap(sidebarOpen);
  }

  if (!widget && deviceData.length > 0) return renderMainView();

  return <WidgetLoadingAnimation />;
}
