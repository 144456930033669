import CurrentIntegrations from "components/Connections/CurrentIntegrations";
import IntegrationUsage from "components/Connections/IntegrationUsage";
import NewConnection from "components/Connections/NewConnection";
import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LicenseInformation } from "./LicenseInformation";
import { active } from "d3";
import * as api from "apis/FusionAPI";
// import { perms.testPermission } from "data/routeData";
import { usePerms } from "hooks/usePerms";
import Popup from "reactjs-popup";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import { ColorContext } from "App";

export default function ConnectedSystems() {
  const [integrations, setIntegrations] = useState([]);
  const [showNewConnection, setShowNewConnection] = useState(false);
  const [showLicenseInformation, setShowLicenseInformation] = useState(false);
  const [licenseInfo, setLicenseInfo] = useState({});
  const [initialData, setInitialData] = useState();
  const perms = usePerms();

  const [fetching, setFetching] = useState(true);
  useEffect(() => {
    (async () => {
      const res = await api.fusionGetIntegrations();
      console.log("integrations", res);
      setIntegrations(res.data.result);
      setFetching(false);
    })().catch((err) => {});
  }, []);

  const addIntegraton = (integration) => {
    (async () => {
      const res = await api.fusionAddIntegration(integration);
    })()
      .then()
      .catch((err) => {});
  };
  const theme = useContext(ColorContext);
  if (fetching) {
    return <WidgetLoadingAnimation />;
  }
  

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      {perms.testPermission([8, 10, 11, 12, 13], ["ViewConnections"]) && (
        <CurrentIntegrations
          data={integrations}
          setInitialData={setInitialData}
          setShowNewConnection={setShowNewConnection}
        />
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {perms.testPermission(
          [8, 10, 11, 12, 13],
          ["Create-EditConnections"]
        ) && (
          <div
            style={{
              cursor: "pointer",
              backgroundColor: theme.primaryHighlight,
              fontWeight: "bold",
              width: "fit-content",
              padding: "20px 40px",
              borderRadius: 10,
              display: "flex",
              alignSelf: "flex-end",
            }}
            onClick={() => {
              setShowNewConnection(true);

              setInitialData(null);
            }}
          >
            New System
          </div>
        )}
      </div>

      {showNewConnection &&
        perms.testPermission(
          [8, 10, 11, 12, 13],
          ["Create-EditConnections"]
        ) && (
          <div
            style={{
              position: "absolute",
              top: 200,
              left: 350,
              width: "calc(100% - 590px)",
              height: "calc(100% - 400px)",
            }}
          >
            <NewConnection
              integrations={integrations}
              setIntegrations={setIntegrations}
              setShowNewConnection={setShowNewConnection}
              initialData={initialData}
              setInitialData={setInitialData}
            />
          </div>
        )}
    </div>
  );
}
