import React, { useContext, useEffect, useState } from "react";
import ForecastSpread from "../ForecastSpread";
import ConditionIcons from "components/ConditionIcons";
import { DateToString } from "utils/stringUtils";
import { RiDropFill, RiDropLine, RiWindyFill } from "react-icons/ri";
import { FaHouseChimney, FaTemperatureHalf } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import { WiSprinkle } from "react-icons/wi";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import { ColorContext, UniqueWidgetRefreshContext } from "App";
import { useAPIData } from "hooks/useAPIData";
import Cookies from "js-cookie";
import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";
import useDataRefresh from "hooks/useDataRefresh";

export default function DayForecast15({ 
  // data 
}) {
  const [dates, setDates] = useState();
  const [data, setData] = useState();

  useEffect(() => {
    const now = new Date();
    const later = new Date();
    later.setDate(later.getDate() + 14);

    // console.log("Test:", now.toLocaleString());
    setDates({ start: now, end: later });
  }, []);

  const theme = useContext(ColorContext);
  
  const [fetching, setFetching] = useState(true);
  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      // setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });
  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "get15DayForecast";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  useEffect(() => {
    async function fetchData() {
      try {
      //get 15 day forecast
      FusionData.forceGet(ROUTE).then((res) => {
        res.data.result.map((item) => {
          item.loctime = new Date(item.iso8601);
        });

        setData([...res.data.result]);
        setFetching(false);
      });
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [uwf[UNIQUEKEY]]);

  
  const dataRefresh = useDataRefresh()
  useEffect(() => {
    const fetchData = async () => {
      try {
        FusionData.get(ROUTE).then((res) => {
          res.data.result.map((item) => {
            item.loctime = new Date(item.iso8601);
          });
  
          setData([...res.data.result]);
          setFetching(false);
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [dataRefresh.widgetRefresh]);


  if (fetching) {
    return <WidgetLoadingAnimation/>
  }

  if (data && data.length > 0) return (
    <div
      style={{
        backgroundColor: theme.base,
        // padding: 15,
        overflow: "scroll",
      }}
    >
      <WidgetTimeUpdate route={ROUTE} dataUpdateOnly/>
      {/* Headder */}
      {dates && dates.start && dates.end && (
        <div
          style={{
            fontSize: 20,
            borderBottom: "solid grey 2px",
          }}
        >
          {`${DateToString(dates.start).month} ${
            DateToString(dates.start).day
          } - ${DateToString(dates.end).month} ${
            DateToString(dates.end).day
          }, ${dates.start.getFullYear()} Weather Forecast`}
        </div>
      )}

      {/* Content */}
      <div
        style={{
          display: "flex",
          marginTop: 15,
        }}
      >
        <div style={{ flex: 1 }}>
          {data &&
            data
              .filter((item, index) => index < data.length / 2)
              .map((item, index) => {
                return (
                  <ForecastSpread
                    key={index}
                    header={`${DateToString(item.loctime).weekday_long}, ${
                      DateToString(item.loctime).month
                    } ${DateToString(item.loctime).day}`}
                    data={{
                      "": item.description,
                      Wind: `${item.wind_speed} MPH, ${item.wind_short}`,
                      Humidity: `${item.humidity}%`,
                      "Comfort Level": `${item.comfort}°F`,
                      Precipitation: `${item.precip_prob}%`,
                      // "Visibility": `${item.visibility} mi`,
                      "Dew Point": `${item.dew_point}`,
                      "Avg. Temp": `${(
                        (parseFloat(item.high_temp) +
                          parseFloat(item.low_temp)) /
                        2
                      ).toFixed(2)}°F`,
                      "Precip. Total": `${
                        item.rainfall === "*" ? "0" : item.rainfall
                      }"`,
                    }}
                    dataIcons={[
                      <ConditionIcons conditionId={6} />,
                      <RiWindyFill />,
                      <RiDropLine />,
                      <FaHouseChimney />,
                      <ConditionIcons conditionId={19} />,
                      // <FaEye/>,
                      <WiSprinkle />,
                      <FaTemperatureHalf />,
                      <RiDropFill />,
                    ]}
                    icon={
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div>{`${item.high_temp}°/${item.low_temp}°`}</div>
                        <ConditionIcons conditionId={item.icon} />
                      </div>
                    }
                    style={{ height: "20%", marginRight: 10 }}
                  />
                );
              })}
        </div>
        <div style={{ flex: 1 }}>
          {data &&
            data
              .filter((item, index) => index > data.length / 2)
              .map((item, index) => {
                return (
                  <ForecastSpread
                    key={index}
                    header={`${DateToString(item.loctime).weekday_long}, ${
                      DateToString(item.loctime).month
                    } ${DateToString(item.loctime).day}`}
                    data={{
                      "": item.description,
                      Wind: `${item.wind_speed} MPH, ${item.wind_short}`,
                      Humidity: `${item.humidity}%`,
                      "Comfort Level": `${item.comfort}°F`,
                      Precipitation: `${item.precip_prob}%`,
                      // "Visibility": `${item.visibility} mi`,
                      "Dew Point": `${item.dew_point}`,
                      "Avg. Temp": `${(
                        (parseFloat(item.high_temp) +
                          parseFloat(item.low_temp)) /
                        2
                      ).toFixed(2)}°F`,
                      "Precip. Total": `${
                        item.rainfall === "*" ? "0" : item.rainfall
                      }"`,
                    }}
                    dataIcons={[
                      <ConditionIcons conditionId={6} />,
                      <RiWindyFill />,
                      <RiDropLine />,
                      <FaHouseChimney />,
                      <ConditionIcons conditionId={19} />,
                      // <FaEye/>,
                      <WiSprinkle />,
                      <FaTemperatureHalf />,
                      <RiDropFill />,
                    ]}
                    icon={
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div>{`${item.high_temp}°/${item.low_temp}°`}</div>
                        <ConditionIcons conditionId={item.icon} />
                      </div>
                    }
                    style={{ height: "20%", marginLeft: 10 }}
                  />
                );
              })}
        </div>
      </div>
    </div>
  );

  return <WidgetLoadingAnimation />;
}
