import React, { useContext, useEffect, useState } from "react";
import * as api from "apis/FusionAPI";
import Cookies from "js-cookie";
import ConditionIcons from "components/ConditionIcons";
import { IconContext } from "react-icons";
import OvalDisplay from "../OvalDisplay";
import ForecastSpread from "components/ForecastSpread";
import DropdownSelector from "components/DropdownSelector";

import { index } from "d3";
import { RiDropFill, RiDropLine, RiWindyFill } from "react-icons/ri";
import { FaHouseChimney, FaTemperatureHalf } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import { WiSprinkle } from "react-icons/wi";
import WidgetTimeUpdate from "./WidgetTimeUpdate";
import WidgetLoadingAnimation from "./WidgetLoadingAnimation";
import { ErrorMessage } from "./ErrorMessage";
import { useAPIData } from "hooks/useAPIData";
import { Layout } from "antd";
import { LayoutSizeContext, UniqueWidgetRefreshContext } from "App";

export default function DetailedForecast({
  widgetRefresh,
  setWidgetRefresh,
  dimensions,
}) {
  let dataCache;
  let dataCacheDays;
  let dataTime;

  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState();
  const [days, setDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState(
    new Date().toLocaleString("en-us", { weekday: "long" })
  );
  const [selectedTime, setSelectedTime] = useState("Morning");
  const [dateTime, setDateTime] = useState(new Date());
  const [error, setError] = useState({ state: false, message: "" });

  const layout = useContext(LayoutSizeContext);

  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });

  useEffect(() => {
    dataCache = null;
    setFetching(true);
  }, [widgetRefresh]);

  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "getDetailedForecastData";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  useEffect(() => {
    const fetchData = async () => {
      try {
        FusionData.forceGet(ROUTE).then((res) => {
          const now = new Date();

          const dayArray = [];

          //build array
          res.data.result.map((day) => {
            if (
              dayArray.filter(
                (x) => parseInt(x.day_of_week) === parseInt(day.day_of_week)
              ).length === 0
            ) {
              const order =
                parseInt(day.day_of_week) < now.getDay()
                  ? parseInt(day.day_of_week) + 7
                  : parseInt(day.day_of_week);

              dayArray.push({
                day_of_week: parseInt(day.day_of_week),
                weekday: day.weekday,
                order: order,
              });
            }
          });

          dataCacheDays = dayArray
            .sort((a, b) => {
              return a.order - b.order;
            })
            .map((x) => {
              return x.weekday;
            });

          setDays(dataCacheDays);

          dataCache = [...res.data.result];

           
          setData(dataCache);
          setFetching(false);
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [uwf[UNIQUEKEY]]);

  useEffect(() => {
    (async () => {
      if (dataCache) {
        setData(dataCache);
        setDays(dataCacheDays);
        setFetching(false);
        return;
      }
      FusionData.get(ROUTE)
        .then((res) => {
          // console.log("Detailed Forecast Widget Data:", res.data.result);
          const now = new Date();
          // // console.log("Now", now.getDay());

          const dayArray = [];
          //build array
          res.data.result.map((day) => {
            if (
              dayArray.filter(
                (x) => parseInt(x.day_of_week) === parseInt(day.day_of_week)
              ).length === 0
            ) {
              const order =
                parseInt(day.day_of_week) < now.getDay()
                  ? parseInt(day.day_of_week) + 7
                  : parseInt(day.day_of_week);

              dayArray.push({
                day_of_week: parseInt(day.day_of_week),
                weekday: day.weekday,
                order: order,
              });
            }
          });

          // // console.log(
          //   "DayArray:",
          //   dayArray.sort((a, b) => {
          //     return a.order - b.order;
          //   })
          // );
          dataCacheDays = dayArray
            .sort((a, b) => {
              return a.order - b.order;
            })
            .map((x) => {
              return x.weekday;
            });

          setDays(dataCacheDays);

          dataCache = [...res.data.result];

           
          setData(dataCache);
          setFetching(false);
        })
        .catch((err) => {
          // console.log("Error getting data", err);
          setError({ state: true, message: err.message });
        });
    })();
  }, [widgetRefresh]);

  const getSelected = (day, segment) => {
    return data.filter((item) => {
      return (
        (day ? item.weekday === day : true) &&
        (segment ? item.day_segment === segment : true)
      );
    })[0];
  };
  if (error.state && !fetching) {
    return <ErrorMessage message={error.message} />;
  }

  if (fetching)
    return (
      <WidgetLoadingAnimation
        widgetName={"DetailedForecast"}
        dimensions={dimensions}
      />
    );

  // // console.log("Data when rendered:", data);
  if (data && data.length === 0) {
    return (
      <div>
        <WidgetTimeUpdate route={ROUTE} />
        <div style={{ padding: 15 }}>No data found.</div>
      </div>
    );
  }

  const InfoArray = ({}) => {
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100%",
          gap: 10,
        }}
      >
        {getSelected(selectedDay, "Morning") && (
          <button
            onClick={() => setSelectedTime("Morning")}
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <OvalDisplay
              time={"Morning"}
              temp={getSelected(selectedDay, "Morning").temperature}
              selected={selectedTime === "Morning"}
              icon={
                <ConditionIcons
                  conditionId={parseInt(
                    getSelected(selectedDay, "Morning").icon
                  )}
                  size={layout.widgetWidth}
                />
              }
            />
          </button>
        )}

        {getSelected(selectedDay, "Afternoon") && (
          <button
            onClick={() => setSelectedTime("Afternoon")}
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <OvalDisplay
              time={"Afternoon"}
              temp={getSelected(selectedDay, "Afternoon").temperature}
              selected={selectedTime === "Afternoon"}
              icon={
                <ConditionIcons
                  conditionId={parseInt(
                    getSelected(selectedDay, "Afternoon").icon
                  )}
                  size={layout.widgetWidth}
                />
              }
            />
          </button>
        )}

        {getSelected(selectedDay, "Evening") && (
          <button
            onClick={() => setSelectedTime("Evening")}
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <OvalDisplay
              time={"Evening"}
              temp={getSelected(selectedDay, "Evening").temperature}
              selected={selectedTime === "Evening"}
              icon={
                <ConditionIcons
                  conditionId={parseInt(
                    getSelected(selectedDay, "Evening").icon
                  )}
                  size={layout.widgetWidth}
                />
              }
            />
          </button>
        )}

        {getSelected(selectedDay, "Night") && (
          <button
            onClick={() => setSelectedTime("Night")}
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <OvalDisplay
              time={"Night"}
              temp={getSelected(selectedDay, "Night").temperature}
              selected={selectedTime === "Night"}
              icon={
                <ConditionIcons
                  conditionId={parseInt(getSelected(selectedDay, "Night").icon)}
                  size={layout.widgetWidth}
                />
              }
            />
          </button>
        )}
      </div>
    );
  };

  const InfoArray2 = ({}) => {
    if (getSelected(selectedDay, selectedTime))
      return (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            flexDirection: "column",
            maxHeight: 200,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: layout.baseFontSize,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "10%",
            }}
          >
            {`${selectedDay} ${selectedTime}`}
            <div>
              {" "}
              {`${getSelected(selectedDay, selectedTime).temperature}°F`}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconContext.Provider
                value={{
                  size: 40 * layout.scaleMult,
                }}
              >
                <ConditionIcons
                  conditionId={getSelected(selectedDay, selectedTime).icon}
                  size={layout.widgetWidth / 5}
                />
              </IconContext.Provider>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                fontSize: layout.baseFontSize,
              }}
            >
              <div>{getSelected(selectedDay, selectedTime).precip}</div>
              <div>{getSelected(selectedDay, selectedTime).sky}</div>
              <div>{getSelected(selectedDay, selectedTime).temp}</div>
            </div>
          </div>
        </div>
      );
  };

  if (dimensions[0] == 1 && dimensions[1] == 2) {
    if (data && data.length > 0 && days) {
      return (
        <div
          style={{
            height: "100%",
          }}
        >

          <WidgetTimeUpdate
            route={ROUTE}
             
          />

          {data.length && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                padding: 10,
              }}
            >
              {/* Center content */}
              <div
                style={{
                  height: "35%",
                }}
              >
                <DropdownSelector
                  options={days}
                  selectedOption={days.indexOf(selectedDay)}
                  setSelected={(index) => setSelectedDay(days[index])}
                  triggerStyle={{ width: "100%" }}
                />

                {/* Info array (Oval displays) */}
                <div
                  style={{
                    paddingTop: 10,
                    height: "85%",
                  }}
                >
                  <InfoArray />
                </div>
              </div>

              {/* Bottom content */}
              <div
                style={{
                  flex: 0.8,
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: 5,
                  paddingBottom: 25,
                }}
              >
                {/* Day / time info display */}
                <InfoArray2 />

                {getSelected(selectedDay, selectedTime) && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                      padding: 10,
                      fontSize: layout.baseFontSize,
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <ConditionIcons
                        conditionId={6}
                        size={layout.widgetWidth / 15}
                        style={{ marginRight: "10" }}
                      />
                      {getSelected(selectedDay, selectedTime).description}
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <RiWindyFill
                        size={layout.widgetWidth / 15}
                        style={{ marginRight: "10" }}
                      />{" "}
                      Wind SP:{" "}
                      {getSelected(selectedDay, selectedTime).wind_speed}mph
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <RiDropLine
                        size={layout.widgetWidth / 15}
                        style={{ marginRight: "10" }}
                      />{" "}
                      Humidity:{" "}
                      {getSelected(selectedDay, selectedTime).humidity}
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FaHouseChimney
                        size={layout.widgetWidth / 15}
                        style={{ marginRight: "10" }}
                      />{" "}
                      Comfort Level:{" "}
                      {getSelected(selectedDay, selectedTime).comfort}°F
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <ConditionIcons
                        conditionId={19}
                        size={layout.widgetWidth / 15}
                        style={{ marginRight: "10" }}
                      />{" "}
                      Precipitation:{" "}
                      {getSelected(selectedDay, selectedTime).precip_prob}
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FaEye
                        size={layout.widgetWidth / 15}
                        style={{ marginRight: "10" }}
                      />{" "}
                      Visibility:{" "}
                      {getSelected(selectedDay, selectedTime).visibility} mi
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <WiSprinkle
                        size={layout.widgetWidth / 15}
                        style={{ marginRight: "10" }}
                      />{" "}
                      Dew Point:{" "}
                      {getSelected(selectedDay, selectedTime).dew_point}°F
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FaTemperatureHalf
                        size={layout.widgetWidth / 15}
                        style={{ marginRight: "10" }}
                      />{" "}
                      Temperature:{" "}
                      {getSelected(selectedDay, selectedTime).temperature}°F
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <RiDropFill
                        size={layout.widgetWidth / 15}
                        style={{ marginRight: "10" }}
                      />{" "}
                      Precip. Total:{" "}
                      {getSelected(selectedDay, selectedTime).rainfall === "*"
                        ? "0" // put '0' if there is no value in the data
                        : getSelected(selectedDay, selectedTime).rainfall}
                      "
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      );
    }
  }
  if (dimensions[0] == 1 && dimensions[1] == 1) {
    if (data && data.length > 0 && days) {
      // if (data && days && selectedDay) {
      // if (data && days && selectedDay && selectedTime) {
      return (
        <div
          style={{
            height: "100%",
          }}
        >

          <WidgetTimeUpdate
            route={ROUTE}
             
          />

          <div style={{ height: "75%", padding: 10 }}>
            <DropdownSelector
              options={days}
              selectedOption={days.indexOf(selectedDay)}
              // selectedOption={0}
              setSelected={(index) => setSelectedDay(days[index])}
              triggerStyle={{ width: "100%" }}
            />

            {/* Info array (Oval displays) */}

            <div
              style={{
                paddingTop: 10,
                height: "100%",
              }}
            >
              <InfoArray />
            </div>
          </div>
        </div>
      );
    }
  }
  if (dimensions[0] == 2 && dimensions[1] == 1) {
    if (data && data.length > 0 && days) {
      // if (data && days && selectedDay) {
      // if (data && days && selectedDay && selectedTime) {
      return (
        <div
          style={{
            height: layout.widgetHeight - 40,
          }}
        >

          <WidgetTimeUpdate
            route={ROUTE}
             
          />

          {data.length && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                padding: 10,
              }}
            >
              {/* Center content */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "55%",
                }}
              >
                {/* Selected info */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                    flex: 1,
                  }}
                >
                  {/* Drop-down day selector */}
                  <DropdownSelector
                    options={days}
                    selectedOption={days.indexOf(selectedDay)}
                    setSelected={(index) => setSelectedDay(days[index])}
                    triggerStyle={{ width: "100%" }}
                  />

                  {/* Day / time info display */}
                  <div
                    style={{
                      paddingTop: 10,
                      height: "100%",
                    }}
                  >
                    <InfoArray2 />
                  </div>
                </div>

                {/* Info array (Oval displays) */}

                <div
                  style={{
                    height: "100%",
                  }}
                >
                  <InfoArray />
                </div>
              </div>

              {/* Bottom content */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontSize: 18,
                  marginTop: 10,
                  overflow: "auto",
                  height: "calc(35% - 10px)",
                }}
              >
                {getSelected(selectedDay, selectedTime) && (
                  <ForecastSpread
                    header={""}
                    icon={""}
                    data={{
                      "": getSelected(selectedDay, selectedTime).description,
                      "Wind SP": `${
                        getSelected(selectedDay, selectedTime).wind_speed
                      }mph`,

                      Humidity: `${
                        getSelected(selectedDay, selectedTime).humidity
                      }%`,
                      "Comfort Level": `${
                        getSelected(selectedDay, selectedTime).comfort
                      }°F`,
                      Precipitation: `${
                        getSelected(selectedDay, selectedTime).precip_prob
                      }%`,
                      Visibility: `${
                        getSelected(selectedDay, selectedTime).visibility
                      } mi`,
                      "Dew Point": `${
                        getSelected(selectedDay, selectedTime).dew_point
                      }°F`,
                      Temperature: `${
                        getSelected(selectedDay, selectedTime).temperature
                      }°F`,
                      "Precip. Total": `${
                        getSelected(selectedDay, selectedTime).rainfall === "*"
                          ? "0" // put '0' if there is no value in the data
                          : getSelected(selectedDay, selectedTime).rainfall
                      }"`,
                    }}
                    dataIcons={[
                      <ConditionIcons
                        conditionId={6}
                        size={layout.widgetWidth / 15}
                        style={{ marginRight: "10" }}
                      />,
                      <RiWindyFill
                        size={layout.widgetWidth / 15}
                        style={{ marginRight: "10" }}
                      />,
                      <RiDropLine
                        size={layout.widgetWidth / 15}
                        style={{ marginRight: "10" }}
                      />,
                      <FaHouseChimney
                        size={layout.widgetWidth / 15}
                        style={{ marginRight: "10" }}
                      />,
                      <ConditionIcons
                        conditionId={19}
                        size={layout.widgetWidth / 15}
                        style={{ marginRight: "10" }}
                      />,
                      <FaEye
                        size={layout.widgetWidth / 15}
                        style={{ marginRight: "10" }}
                      />,
                      <WiSprinkle
                        size={layout.widgetWidth / 15}
                        style={{ marginRight: "10" }}
                      />,
                      <FaTemperatureHalf
                        size={layout.widgetWidth / 15}
                        style={{ marginRight: "10" }}
                      />,
                      <RiDropFill
                        size={layout.widgetWidth / 15}
                        style={{ marginRight: "10" }}
                      />,
                    ]}
                    style={{ width: "100%", height: "100%" }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      );
    }
  }
}
