import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import openLogo from "../assets/Sidebar_Logo_Open.png";
import closedLogo from "../assets/Sidebar_Logo_Closed.png";
import openLogoDEV from "../assets/Sidebar_Logo_Open_DEV.png";
import closedLogoDEV from "../assets/Sidebar_Logo_Closed_DEV.png";
import openLogoSTAGING from "../assets/Sidebar_Logo_Open_STAGING.png";
import openLogoPITTSBURGHPA from "../assets/Sidebar_Logo_Open_PittsburghPA.png";
import closedLogoSTAGING from "../assets/Sidebar_Logo_Closed_STAGING.png";
import { GetLocationData, GetAllLocations } from "../data/routes";
import * as api from "apis/FusionAPI";

import { IconContext } from "react-icons";
import { FaHome } from "react-icons/fa";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { LiaToggleOnSolid, LiaToggleOffSolid } from "react-icons/lia";
import Cookies from "js-cookie";
import { GetRouteData } from "data/routeData";
import { usePerms } from "hooks/usePerms";
import { ColorContext } from "App";
/**
 * @param {function} onOpenChanged a funciton listening for when the sidebar open state changes.
 * @param {string} selected the name of the screen that is currently selected. This is so we know which button to highlight as 'selected'
 * @returns
 */
export default function Sidebar({
  onOpenChanged,
  accessLevel,
  isAuthenticated,
  updateSidebar,
  
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useContext(ColorContext);

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [pageButtons, setPageButtons] = useState();
  const [routeData, setRouteData] = useState(null);
  const [selectedPage, setSelectedPage] = useState("/dashboard");

  const perms = usePerms();

  const getLogo = () => {
    const environment = api._Environment();
    
    if (environment === "PITTSBURGHPA") {
      return sidebarOpen ? openLogoPITTSBURGHPA : closedLogo;
    }
    if (environment === "DEV") {
      return sidebarOpen ? openLogoDEV : closedLogoDEV;
    } else if (environment === "STAGING") {
      return sidebarOpen ? openLogoSTAGING : closedLogoSTAGING;
    } else return sidebarOpen ? openLogo : closedLogo;
  };

  useEffect(() => {
    setTimeout(() => {
      const sidebarData = [...GetRouteData(perms)];
      sidebarData.forEach((item) => {
        if (item.permission()) {
          item.options.forEach((option) => {
            //set enabled by perm
            if (!option.hideSidebar) option.enabled = option.permission();

            //set active based on location
            if (window.location.pathname === option.href) item.opened = true;
          });
        } else {
          item.enabled = false;
          item.options.forEach((option) => {
            option.enabled = false;
          });
        }
      });

      setSelectedPage(window.location.pathname);
      setPageButtons(sidebarData);
    }, 1000);
  }, [isAuthenticated, updateSidebar, perms.sData]);

  useEffect(() => {
    perms.reset();
  }, [isAuthenticated]);

  const toggleSidebar = () => {
    if (sidebarOpen) closeAllDropdowns();

    onOpenChanged(!sidebarOpen);
    setSidebarOpen(!sidebarOpen);
    // sb.toggleOpen();
  };

  const toggleDropdown = (x) => {
    //if the sidebar is not open, we want to open it before activating drop down menu
    if (!sidebarOpen) {
      toggleSidebar();
    }
  };

  const closeAllDropdowns = () => {
    if (!sidebarOpen) {
      toggleSidebar();
    }

    const temp = pageButtons.map((item, iIndex) => {
      return { ...item, opened: false };
    });

    setPageButtons(temp);
  };

  ////////////
  // Styles //
  ////////////
  const ButtonStyleClosed = {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: 63,
    alignItems: "center",
  };

  const ButtonStyleOpen = {
    backgroundColor: theme.primary,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: 63,
    alignItems: "center",
  };

  const DropButtonStyleOpen = {
    backgroundColor: theme.primary,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    paddingLeft: 50,
    height: 63,
    alignItems: "center",
  };

  const DropButtonStyleSelected = {
    backgroundColor: theme.primaryHighlight,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    paddingLeft: 50,
    height: 63,
    alignItems: "center",
  };

  const ButtonIconStyle = {
    padding: 12,
    alignItems: "center",
    justifyContent: "space-around",
  };

  const ButtonTextStyle = {
    paddingBottom: 7,
  };

  const DropdownIconStyle = {
    display: "flex",
    justifyContent: "flex-end",
    flex: 1,
    paddingRight: 15,
  };

  const PageButtonStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };

  if (isAuthenticated && pageButtons)
    return (
      <div
        // className="sidebar-scrollbar"
        style={{
          backgroundColor: theme.primaryShadow,
          width: sidebarOpen ? 300 : 65,
          color: theme.fontColor,
          height: "100%",
          overflowY: "auto",
          overflowX: "hidden"
        }}
      >
        <div style={{
          
          marginBottom: 100,
        }}>
          {/* Icon header */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: theme.primary,
              width: sidebarOpen ? /*259*/ "100%" : 65, //removed the value 259, can put it back if something looks wrong
              height: 107,
            }}
          >
            <div
              style={{
                display: "flex",
                width: 172,
                height: 75,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{
                  objectFit: true,
                  height: sidebarOpen ? "100%" : "75%",
                }}
                src={getLogo()}
                atl="Logo"
              />
            </div>
          </div>

          {/* Toggle menu */}
          <button style={ButtonStyleClosed} onClick={() => toggleSidebar()}>
            <div style={ButtonIconStyle}>
              <IconContext.Provider
                value={{
                  color: theme.fontColor,
                  size: 30,
                }}
              >
                {sidebarOpen ? <LiaToggleOnSolid /> : <LiaToggleOffSolid />}
              </IconContext.Provider>
            </div>
            {sidebarOpen && <div style={ButtonTextStyle}>Toggle Menu</div>}
          </button>

          {/* Home dashboard */}
          <button
            style={
              selectedPage === "/dashboard"
                ? { ...DropButtonStyleSelected, paddingLeft: 0 }
                : ButtonStyleClosed
            }
            onClick={() => {
              closeAllDropdowns();
              setSelectedPage("/dashboard");
              navigate("/dashboard");
            }}
          >
            <div style={ButtonIconStyle}>
              <IconContext.Provider
                value={{
                  color: theme.fontColor,
                  size: 30,
                }}
              >
                <FaHome />
              </IconContext.Provider>
            </div>
            {sidebarOpen && <div style={ButtonTextStyle}>Dashboard</div>}
          </button>

          {pageButtons.map((dropdown, index) => {
            if (dropdown.enabled)
              return (
                <div key={index}>
                  <button
                    style={
                      dropdown.options
                        .map((option) => {
                          return selectedPage === option.href;
                        })
                        .includes(true)
                        ? { ...DropButtonStyleSelected, paddingLeft: 0 }
                        : dropdown.opened
                        ? ButtonStyleOpen
                        : ButtonStyleClosed
                    }
                    onClick={() => {
                      const temp = pageButtons.map((item, iIndex) => {
                        if (iIndex === index) {
                          return { ...item, opened: !item.opened };
                        } else {
                          return { ...item, opened: false };
                        }
                      });

                      setPageButtons(temp);
                      if (!sidebarOpen) toggleSidebar();
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ marginLeft: 15, marginRight: 15 }}>
                          {dropdown.icon()}
                          {/* <img src="src/assets/icons/brand-small 2.png" /> */}
                        </div>
                        {sidebarOpen && <div>{dropdown.name}</div>}
                      </div>
                      {sidebarOpen && (
                        <div style={{ marginRight: 15 }}>
                          {dropdown.opened ? (
                            <FaChevronUp />
                          ) : (
                            <FaChevronDown />
                          )}
                        </div>
                      )}
                    </div>
                  </button>
                  {dropdown.opened && sidebarOpen && (
                    <div >
                      {dropdown.options.map((option, index) => {
                        if (option.enabled)
                          return (
                            <button
                              key={index}
                              style={
                                selectedPage === option.href
                                  ? DropButtonStyleSelected
                                  : DropButtonStyleOpen
                              }
                              onClick={() => {
                                setSelectedPage(option.href);
                                navigate(option.href);
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: 15,
                                    width: 30,
                                  }}
                                >
                                  {option.icon()}
                                </div>
                                <div>{option.name}</div>
                              </div>
                            </button>
                          );
                      })}
                    </div>
                  )}
                </div>
              );
          })}
        </div>
      </div>
    );
}
