import React, { useContext, useState } from 'react'
import * as api from '../apis/FusionAPI'
import { ColorContext } from 'App';

export default function Signup() {
	
  const theme = useContext(ColorContext);
	const [formData, setFormData] = useState({});
	const [checks, setChecks] = useState({
		email: true,
		role: true,
		base: true,
		department: true,
		rank: true,
		name: true,
		dob: true,
		address: true,
		zipcode: true,
		country: true
	});
	const [apiResult, setApiResult] = useState();

	const onCreateAccount = () => {
		const newChecks = {
			email: formData.email != null && formData.email != undefined && formData.email !== "",
			role: formData.role != null && formData.role != undefined && formData.role !== "",
			base: formData.base != null && formData.base != undefined && formData.base !== "",
			department: formData.department != null && formData.department != undefined && formData.department !== "",
			rank: formData.rank != null && formData.rank != undefined && formData.rank !== "",
			name: formData.name != null && formData.name != undefined && formData.name !== "",
			dob: formData.dob != null && formData.dob != undefined && formData.dob !== "",
			address: formData.address != null && formData.address != undefined && formData.address !== "",
			zipcode: formData.zipcode != null && formData.zipcode != undefined && formData.zipcode !== "",
			country: formData.country != null && formData.country != undefined && formData.country !== ""
		};

		if (
			newChecks.email &&
			newChecks.role &&
			newChecks.base &&
			newChecks.department &&
			newChecks.rank &&
			newChecks.name &&
			newChecks.dob &&
			newChecks.address &&
			newChecks.zipcode &&
			newChecks.country
		) {
			//make api call
			// console.log(
			// 	"name:", formData.name,
			// 	" | username:", formData.name.replace(/ /g, '.'),   //username
			// 	" | email:", formData.email,
			// 	" | dob:", formData.dob,
			// 	" | address:", {                                   //address
			// 	address: formData.address,
			// 	zipcode: formData.zipcode,
			// 	country: formData.country,
			// },
			// 	" | team:", "0", //formData.team,
			// 	" | rank:", formData.rank,
			// 	" | role:", formData.role,
			// 	" | base:", formData.base,
			// 	" | dept:", formData.department
			// );

			api.createFusionUser(
				formData.name,
				formData.name.replace(/ /g, '.'),   //username
				formData.email,
				formData.dob,
				{                                   //address
					address: formData.address,
					zipcode: formData.zipcode,
					country: formData.country,
				},
				"0", //formData.team,
				formData.rank,
				formData.role,
				formData.base,
				formData.department,
			).then((result) => {
				// console.log("RESULT FROM API CALL!", result);
				if (result.data.message === "User creation successful") {
					setApiResult("success");
				}
			}).catch((e) => {
				// console.log("RESULT FROM API CALL!", e);
				setApiResult(e.response.data.message);
			});
		}

		setChecks(newChecks)
	}

	return (
		<div style={{
			margin: 15,
			color: theme.fontColor
		}}>
			<div style={{
				backgroundColor: "#062358",
				width: 1586,
				height: 50,
				borderRadius: 15,
				marginBottom: 7,
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center'
			}}>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 25, color: checks.email ? 'white' : 'red' }}>
					{!checks.email &&
						<div style={{ color: 'red', fontSize: 10, marginRight: 5 }}>
							* Required field
						</div>
					}
					<div>Email:</div>
					<div
						style={{
							display: "flex",
							backgroundColor: "#0f122d",
							alignItems: "center",
							justifyContent: "center",
							width: 313,
							height: 40,
							borderRadius: 15,
							marginLeft: 10,
						}}
					>
						<input
							type="text"
							style={{
								padding: 15,
								width: 280,
								fontSize: 12,
								width: 275,
								color: "rgba(255, 255, 255, 0.5)",
							}}
							placeholder="Email"
							onChange={(e) => setFormData({ ...formData, email: e.target.value, username: e.target.value })}
						/>
					</div>
				</div>
			</div>
			<div style={{ width: 1586 }}>
				<div style={{ userSelect: 'none', display: 'flex', flexDirection: "row" }}>
					<div>
						{/* top row */}
						<div style={{ display: "flex", flexDirection: "row" }}>
							{/* CELL 1 */}
							<div
								style={{
									backgroundColor: "#062358",
									width: 335,
									height: 365,
									marginRight: 10,
									borderRadius: 15,
								}}
							>
								<div style={{ marginTop: 18, marginLeft: 11 }}>Responder Image</div>

								{/* Image uploader */}
								<div
									style={{
										display: "flex",
										backgroundColor: "#0f122d",
										marginTop: 19,
										marginLeft: 30,
										width: 277,
										height: 235,
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<div style={{ color: "rgba(255, 255, 255, 0.5)" }}>
										Drop files here to upload
									</div>
								</div>

								{/* Buttons */}
								<div
									style={{
										display: "flex",
										flexDirection: "row",
									}}
								>
									<button
										style={{
											color: "black",
											backgroundColor: "white",
											width: 83,
											height: 26,
											borderRadius: 15,
											marginTop: 31,
											marginLeft: 13,
											fontSize: 10,
										}}
										// onClick={() => // console.log("Edit button")}
										// 	{}}
									>
										EDIT
									</button>
									<button
										style={{
											color: theme.fontColor,
											backgroundColor: "#0075ff",
											width: 83,
											height: 26,
											borderRadius: 15,
											marginTop: 31,
											marginLeft: 12,
											fontSize: 10,
										}}
										onClick={() => // console.log("Remove button")}
											{}}
									>
										REMOVE
									</button>
								</div>
							</div>
							{/* CELL 2 */}
							<div
								style={{
									backgroundColor: "#062358",
									width: 335,
									height: 365,
									marginRight: 10,
									borderRadius: 15,
								}}
							>
								<div style={{ marginTop: 18, marginLeft: 11 }}>
									Responder Job Description
									{/* role */}
									<div style={{ fontSize: 12, marginTop: 10, color: checks.role ? 'white' : 'red' }}>
										<div style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
										}}>
											<div>Role</div>
											{!checks.role &&
												<div style={{ color: 'red', fontSize: 10, marginLeft: 15 }}>
													* Required field
												</div>
											}
										</div>

										<div
											style={{
												display: "flex",
												backgroundColor: "#0f122d",
												alignItems: "center",
												justifyContent: "center",
												width: 313,
												height: 40,
												borderRadius: 15,
												marginTop: 10,
											}}
										>
											<input
												type="text"
												style={{
													padding: 15,
													width: 280,
													fontSize: 12,
													width: 275,
													color: "rgba(255, 255, 255, 0.5)",
												}}
												placeholder="Role"
												onChange={(e) => setFormData({ ...formData, role: e.target.value })}
											/>
										</div>
									</div>
									{/* job base */}
									<div style={{ fontSize: 12, marginTop: 10, color: checks.base ? 'white' : 'red' }}>
										<div style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
										}}>
											<div>Base</div>
											{!checks.base &&
												<div style={{ color: 'red', fontSize: 10, marginLeft: 15 }}>
													* Required field
												</div>
											}
										</div>
										<div
											style={{
												display: "flex",
												backgroundColor: "#0f122d",
												alignItems: "center",
												justifyContent: "center",
												width: 313,
												height: 40,
												borderRadius: 15,
												marginTop: 10,
											}}
										>
											<input
												type="text"
												style={{
													padding: 15,
													width: 280,
													fontSize: 12,
													width: 275,
													color: "rgba(255, 255, 255, 0.5)",
												}}
												placeholder="Base"
												onChange={(e) => setFormData({ ...formData, base: e.target.value })}
											/>
										</div>
									</div>
									{/* job department */}
									<div style={{ fontSize: 12, marginTop: 10, color: checks.department ? 'white' : 'red' }}>
										<div style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
										}}>
											<div>Department</div>
											{!checks.department &&
												<div style={{ color: 'red', fontSize: 10, marginLeft: 15 }}>
													* Required field
												</div>
											}
										</div>
										<div
											style={{
												display: "flex",
												backgroundColor: "#0f122d",
												alignItems: "center",
												justifyContent: "center",
												width: 313,
												height: 40,
												borderRadius: 15,
												marginTop: 10,
											}}
										>
											<input
												type="text"
												style={{
													padding: 15,
													width: 280,
													fontSize: 12,
													width: 275,
													color: "rgba(255, 255, 255, 0.5)",
												}}
												placeholder="Department"
												onChange={(e) => setFormData({ ...formData, department: e.target.value })}
											/>
										</div>
									</div>
									{/* job rank */}
									<div style={{ fontSize: 12, marginTop: 10, color: checks.rank ? 'white' : 'red' }}>
										<div style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
										}}>
											<div>Rank</div>
											{!checks.rank &&
												<div style={{ color: 'red', fontSize: 10, marginLeft: 15 }}>
													* Required field
												</div>
											}
										</div>
										<div
											style={{
												display: "flex",
												backgroundColor: "#0f122d",
												alignItems: "center",
												justifyContent: "center",
												width: 313,
												height: 40,
												borderRadius: 15,
												marginTop: 10,
											}}
										>
											<input
												type="text"
												style={{
													padding: 15,
													width: 280,
													fontSize: 12,
													width: 275,
													color: "rgba(255, 255, 255, 0.5)",
												}}
												placeholder="Rank"
												onChange={(e) => setFormData({ ...formData, rank: e.target.value })}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* row two */}
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								marginTop: 8,
								marginBottom: 10,
							}}
						>
							<div>
								{/* CELL 3 */}
								<div
									style={{
										backgroundColor: "#062358",
										width: 680,
										height: 289,
										marginRight: 10,
										borderRadius: 15,
										paddingTop: 18
									}}
								>
									<div style={{ marginLeft: 11 }}>Responder Info</div>
									<div style={{ marginLeft: 11 }}>
										<div style={{ display: "flex", flexDirection: "row" }}>
											{/* name */}
											<div style={{ fontSize: 12, marginTop: 9, color: checks.name ? 'white' : 'red' }}>
												<div style={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
												}}>
													<div>Name</div>
													{!checks.name &&
														<div style={{ color: 'red', fontSize: 10, marginLeft: 15 }}>
															* Required field
														</div>
													}
												</div>
												<div
													style={{
														display: "flex",
														backgroundColor: "#0f122d",
														alignItems: "center",
														justifyContent: "center",
														width: 312,
														height: 33,
														borderRadius: 15,
														marginTop: 7,
													}}
												>
													<input
														type="text"
														style={{
															padding: 15,
															flex: 0.9,
															fontSize: 12,
															width: 275,
															color: "rgba(255, 255, 255, 0.5)",
														}}
														placeholder="Name"
														onChange={(e) => setFormData({ ...formData, name: e.target.value })}
													/>
												</div>
											</div>
											{/* birthday */}
											<div style={{ fontSize: 12, marginTop: 9, marginLeft: 24, color: checks.dob ? 'white' : 'red' }}>
												<div style={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
												}}>
													<div>Birthday</div>
													{!checks.dob &&
														<div style={{ color: 'red', fontSize: 10, marginLeft: 15 }}>
															* Required field
														</div>
													}
												</div>
												<div
													style={{
														display: "flex",
														backgroundColor: "#0f122d",
														alignItems: "center",
														justifyContent: "center",
														width: 312,
														height: 33,
														borderRadius: 15,
														marginTop: 7,
													}}
												>
													<input
														type="text"
														style={{
															padding: 15,
															flex: 0.9,
															fontSize: 12,
															width: 275,
															color: "rgba(255, 255, 255, 0.5)",
														}}
														placeholder="Birthday"
														onChange={(e) => setFormData({ ...formData, dob: e.target.value })}
													/>
												</div>
											</div>
										</div>
										<div style={{ fontSize: 12, marginTop: 21 }}>Home Address</div>
										<div style={{ display: "flex", flexDirection: "row", marginTop: -15 }}>
											{/* street */}
											<div style={{ fontSize: 12, marginTop: 21, color: checks.address ? 'white' : 'red' }}>
												<div style={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
												}}>
													<div>Street, City, State</div>
													{!checks.address &&
														<div style={{ color: 'red', fontSize: 10, marginLeft: 15 }}>
															* Required field
														</div>
													}
												</div>
												<div
													style={{
														display: "flex",
														backgroundColor: "#0f122d",
														alignItems: "center",
														justifyContent: "center",
														width: 650,
														height: 33,
														borderRadius: 15,
														marginTop: 10,
													}}
												>
													<input
														type="text"
														style={{
															padding: 15,
															flex: 0.9,
															fontSize: 12,
															width: 625,
															color: "rgba(255, 255, 255, 0.5)",
														}}
														placeholder="Street, City, State"
														onChange={(e) => setFormData({ ...formData, address: e.target.value })}
													/>
												</div>
											</div>
											{/* city */}
											{/* <div style={{ fontSize: 12, marginTop: 21, marginLeft: 24 }}>
                                                <div>City</div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        backgroundColor: "#0f122d",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        width: 312,
                                                        height: 33,
                                                        borderRadius: 15,
                                                        marginTop: 10,
                                                    }}
                                                >
                                                    <input
                                                        type="text"
                                                        style={{
                                                            padding: 15,
                                                            flex: 0.9,
                                                            fontSize: 12,
                                                            width: 275,
                                                            color: "rgba(255, 255, 255, 0.5)",
                                                        }}
                                                        placeholder="City"
                                                        onChange={(e) => setFormData({...formData, city: e.target.value})}
                                                    />
                                                </div>
                                            </div> */}
										</div>
										<div style={{ display: "flex", flexDirection: "row" }}>
											{/* zipcode */}
											<div style={{ fontSize: 12, marginTop: 21, color: checks.zipcode ? 'white' : 'red' }}>
												<div style={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
												}}>
													<div>Zipcode</div>
													{!checks.zipcode &&
														<div style={{ color: 'red', fontSize: 10, marginLeft: 15 }}>
															* Required field
														</div>
													}
												</div>
												<div
													style={{
														display: "flex",
														backgroundColor: "#0f122d",
														alignItems: "center",
														justifyContent: "center",
														width: 312,
														height: 33,
														borderRadius: 15,
														marginTop: 10,
													}}
												>
													<input
														type="text"
														style={{
															padding: 15,
															flex: 0.9,
															fontSize: 12,
															width: 275,
															color: "rgba(255, 255, 255, 0.5)",
														}}
														placeholder="Zipcode"
														onChange={(e) => setFormData({ ...formData, zipcode: e.target.value })}
													/>
												</div>
											</div>
											{/* country */}
											<div style={{ fontSize: 12, marginTop: 21, marginLeft: 24, color: checks.zipcode ? 'white' : 'red' }}>
												<div style={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
												}}>
													<div>Country</div>
													{!checks.country &&
														<div style={{ color: 'red', fontSize: 10, marginLeft: 15 }}>
															* Required field
														</div>
													}
												</div>
												<div
													style={{
														display: "flex",
														backgroundColor: "#0f122d",
														alignItems: "center",
														justifyContent: "center",
														width: 312,
														height: 33,
														borderRadius: 15,
														marginTop: 10,
													}}
												>
													<input
														type="text"
														style={{
															padding: 15,
															flex: 0.9,
															fontSize: 12,
															width: 275,
															color: "rgba(255, 255, 255, 0.5)",
														}}
														placeholder="Country"
														onChange={(e) => setFormData({ ...formData, country: e.target.value })}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* CELL 5 */}
								<div
									style={{
										backgroundColor: "#062358",
										width: 680,
										height: 248,
										marginRight: 10,
										marginTop: 8,
										borderRadius: 15,
									}}
								>
									<div style={{ marginLeft: 15 }}>
										<div style={{
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
											flexDirection: 'row',
											paddingTop: 5
										}}>
											<div>
												Team
											</div>
											<div
												style={{
													display: 'flex',
													backgroundColor: '#0f1535',
													width: 313,
													height: 42,
													borderRadius: 15,
													marginRight: 5,
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<input style={{ width: 293 }} type="text" placeholder="Search..." />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div>
						<div
							style={{
								backgroundColor: "#062358",
								width: 896,
								height: 922,
								borderRadius: 15,
								paddingTop: 15,
							}}
						>
							<div style={{ marginLeft: 15 }}>
								Role Widget View
							</div>
						</div>
					</div>
				</div>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						fontSize: 10
					}}
				>
					<button
						style={{
							backgroundColor: '#830909',
							width: 120,
							height: 60,
							borderRadius: 15,
						}}
						onClick={() => // console.log("Delete on click")}
							{}}
					>
						Delete Responder
					</button>

					{apiResult &&
						<div
							style={{
								fontSize: 20,
								color: apiResult === "success" ? 'green' : 'red'
							}}
						>
							{apiResult === "success" ? "User created successfully" : "Failed to create user: " + apiResult}
						</div>
					}

					<button
						style={{
							backgroundColor: '#06264f',
							width: 200,
							height: 60,
							borderRadius: 15,
						}}
						onClick={() => onCreateAccount()}
					>
						Create Fusion Account
					</button>
				</div>
			</div>
		</div>
	)
}
