import { fontSize, fontWeight } from "@mui/system";
import { ColorContext, UniqueWidgetRefreshContext } from "App";
import ForecastSpread from "components/ForecastSpread";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";
import { useAPIData } from "hooks/useAPIData";
import useDataRefresh from "hooks/useDataRefresh";
import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { DateToString } from "utils/stringUtils";

export default function SurfAlerts(
  {
    // data
  }
) {
  const currentDateStr = DateToString(new Date());

  const [data, setData] = useState();
  const [fetching, setFetching] = useState(true);
  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      // setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });
  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "getDetailedMarine7";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  const theme = useContext(ColorContext);
  useEffect(() => {
    async function fetchData() {
      try {
        //get 15 day forecast
        FusionData.forceGet(ROUTE).then((res) => {
          let daySorted = {};

          res.data.result.forEach((item) => {
            let rawDate = new Date(item.iso8601);
            let adjDate = new Date(
              rawDate.setHours(rawDate.getHours() - 2)
            ).setHours(0);
            let date = new Date(adjDate);

            if (
              Object.keys(daySorted)
                .map((key) => {
                  return key == date;
                })
                .includes(true)
            ) {
              daySorted[date] = {
                ...daySorted[date],
                [item.day_segment]: item,
              };
            } else {
              daySorted = {
                ...daySorted,
                [date]: {
                  [item.day_segment]: item,
                },
              };
            }
          });

          setData(daySorted);
          setFetching(false);
        });
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [uwf[UNIQUEKEY]]);

  const dataRefresh = useDataRefresh();
  useEffect(() => {
    const fetchData = async () => {
      try {
        FusionData.forceGet(ROUTE).then((res) => {
          let daySorted = {};

          res.data.result.forEach((item) => {
            let rawDate = new Date(item.iso8601);
            let adjDate = new Date(
              rawDate.setHours(rawDate.getHours() - 2)
            ).setHours(0);
            let date = new Date(adjDate);

            if (
              Object.keys(daySorted)
                .map((key) => {
                  return key == date;
                })
                .includes(true)
            ) {
              daySorted[date] = {
                ...daySorted[date],
                [item.day_segment]: item,
              };
            } else {
              daySorted = {
                ...daySorted,
                [date]: {
                  [item.day_segment]: item,
                },
              };
            }
          });

          setData(daySorted);
          setFetching(false);
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [dataRefresh.widgetRefresh]);

  if (fetching) {
    return <WidgetLoadingAnimation />;
  }

  if (data)
    return (
      <div
        className="scrollbar-hidden"
        style={{
          ddisplay: "flex",
          flexDirection: "column",
          backgroundColor: theme.base,
          // marginTop: 15,
          // padding: 15,
          overflow: "auto",
          height: "100%",
        }}
      >
        <WidgetTimeUpdate route={ROUTE} dataUpdateOnly addMargin/>
        {/* Header */}
        <div
          style={{
            fontSize: 20,
            borderBottom: "solid grey 1px",
            paddingBottom: 5,
          }}
        >
          {`${currentDateStr.month} ${currentDateStr.day}, ${currentDateStr.year} Current Surf Alerts`}
        </div>

        {/* Content */}
        <div style={{ marginTop: 15 }}>
          {Object.keys(data).map((day, i) => {
            console.log("Outter loop:", day);
            const dayItem = data[day];
            const date = new Date(day);

            const segmentStr = dayItem["Morning"]
              ? "Morning"
              : dayItem["Afternoon"]
              ? "Afternoon"
              : dayItem["Evening"]
              ? "Evening"
              : "Night";

            return (
              <div
                style={{
                  marginTop: i > 0 ? 50 : 0,
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    textJustify: "middle",
                    fontWeight: "bold",
                    fontSize: 25,
                  }}
                >
                  {dayItem[segmentStr].weekday +
                    " " +
                    date.toLocaleDateString()}
                </div>
                <div>
                  {Object.keys(dayItem).map((segment, j) => {
                    console.log("Inner most loop:", segment);
                    const segmentItem = data[day][segment];
                    const dateStr = DateToString(date);
                    return (
                      <ForecastSpread
                        key={i * j}
                        style={{ marginTop: j > 0 ? 20 : 0 }}
                        header={`${segment}`}
                        headerStyle={{ fontWeight: "normal", fontSize: 15 }}
                        data={{
                          Wave: segmentItem.wave,
                          Alert: segmentItem.surf,
                          Wind: segmentItem.wind,
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
}
