import React, { useContext } from "react";
import ReactGridLayout from "react-grid-layout";
import Widget from "components/Widget";
import GetWidgetData from "data/widgetData";
import DashboardPreview from "components/DashboardPreview";
import { ColorContext } from "App";

const DashboardViewTab = ({
  formData,
  setFormData,
  checks,
  widgetData,
  permList,
}) => {
  const numColumns = 3;
  const rowHeight = 519;

  const theme = useContext(ColorContext);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: theme.background,
          backgroundSize: "cover",
          backgroundRepeat: "repeat",
          height: "100vh",
          flex: 1,
          flexGrow: 1,
          overflow: "hidden",
        }}
      >
        <div
          className="scrollbar-hidden"
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: theme.background,
            flex: 1,
            flexGrow: 1,
            overflow: "auto",
            border: "solid white 1px",
          }}
        >
          <DashboardPreview
            definedWidgetData={GetWidgetData()}
            list={formData.layout}
            setList={() => {}}
            openWidgetSelector={() => {}}
            uninteractable
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardViewTab;
