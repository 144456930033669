import React, { useState, useEffect, useContext } from "react";
import {
  Circle,
  LayersControl,
  MapContainer,
  Marker,
  Polygon,
  Polyline,
  Popup,
  SVGOverlay,
  TileLayer,
  useMap,
} from "react-leaflet";
import * as api from "../../apis/FusionAPI";
import Cookies from "js-cookie";
import { capitalizeWord } from "utils/stringUtils";
import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import { ErrorMessage } from "components/Widgets/ErrorMessage";
import LocationSetter from "components/AlertCreator/components/LocationSetter";
import { useAPIData } from "hooks/useAPIData";
import TextToggle from "components/UI/TextToggle";
import WidgetMap from "components/WidgetMap";
import FullScreenMap from "components/FullScreenMap";
import DropdownSelector from "components/DropdownSelector";
import zIndex from "@mui/material/styles/zIndex";
import { maxHeight } from "@mui/system";
import L from "leaflet";
import { FaHurricane } from "react-icons/fa6";
import ReactDOMServer from "react-dom/server";
import { TbChevronsDownLeft } from "react-icons/tb";
import { toDegrees, toRadians } from "utils/mathUtils";
import * as d3 from "d3";

import hurricaneIcon from "assets/icons/red-hurricane.png";

import * as turf from "@turf/turf";
import { ColorContext, LayoutSizeContext, UniqueWidgetRefreshContext } from "App";
import useDataRefresh from "hooks/useDataRefresh";

const { BaseLayer } = LayersControl;

let dataCache;
 

const HurricanesTropicalStorms = ({
  widget,
  widgetRefresh,
  setWidgetRefresh,
  dimensions,
  preview,
  uninteractable,
  sidebarOpen,
}) => {
  const nauticalMilesToMetersConstant = 1852;

  const [fetching, setFetching] = useState(true);
  const [stormString, setStormString] = useState("");
  const [stormNames, setStormNames] = useState([]);
  const [mapCenter, setMapCenter] = useState({
    lat: Cookies.get("latitude"),
    lng: Cookies.get("longitude"),
  });
  const [historicalData, setHistoricalData] = useState([]);
  const [liveData, setLiveData] = useState([]);
  const [showHistorical, setShowHistorical] = useState(false);

  const [error, setError] = useState({ state: false, message: "" });
  const [hurricaneData, setHurricaneData] = useState([]);
  const [zoom, setZoom] = useState(4);
  // const [selectedItem, setSelectedItem] = useState(0);
  // const [options, setOptions] = useState();

  const layout = useContext(LayoutSizeContext);
  const theme = useContext(ColorContext);

  const useZoomLevel = () => {
    const map = useMap();
    const [zoomLevel, setZoomLevel] = useState(map.getZoom());

    useEffect(() => {
      const handleZoom = () => {
        setZoomLevel(map.getZoom());
      };
      map.on("zoomend", handleZoom);
      return () => {
        map.off("zoomend", handleZoom);
      };
    }, [map]);

    return zoomLevel;
  };

  const ZoomComponent = () => {
    const zoom = useZoomLevel();
    return null;
  };

  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });
  useEffect(() => {
    dataCache = null;
    setFetching(true);
  }, [widgetRefresh]);

  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "getHurricanesLive";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  useEffect(() => {
    const fetchData = async () => {
      try {
        FusionData.forceGet(ROUTE).then((res) => {
          // console.log("Hurricane data:", res);

          let fixedData = res.data.result.map((item) => {
            if (
              item.name.includes("LIVE:") ||
              item.local_storm_name.includes("LIVE:")
            )
              return item;
            item.name = "LIVE: " + item.name;
            item.local_storm_name = "LIVE: " + item.local_storm_name;
            return item;
          });
          // dataCache = fixedData;
          if (fixedData.length > 0) {
            setLiveData(fixedData);
            setHurricaneData(fixedData);
            getStormNames(fixedData);
          }
          // setHurricaneData(dataCache);
          // getStormNames(dataCache);
           
          FusionData.forceGet("getHurricanesHistorical").then((res) => {
            // console.log("Hurricane data historical:", res);

            // dataCache = fixedData.concat(res.data.result)
            // // console.log("CONCAT: ", dataCache)
            // return

            // setHurricaneData(dataCache);
            // getStormNames(dataCache);
            if (fixedData.length === 0) {
              // console.log("NO LIVE DATA");
              // setShowHistorical(true);
              // setHurricaneData(res.data.result);
              // getStormNames(res.data.result);
              // setLiveData([res.data.result[0]]);
            } else {
              // // console.log("LIVE DATA", hurricaneData, liveData);
              // setStormString(fixedData[0].name);
              setStormString("All Live Storms");
              setMapCenter([Cookies.get("latitude"), Cookies.get("longitude")]);
              // setMapCenter([fixedData[0].latitude, fixedData[0].longitude]);
            }
            setHistoricalData(res.data.result);
             
            setFetching(false);
          });
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [uwf[UNIQUEKEY]]);

  useEffect(() => {
    // console.log("useeffect:", showHistorical)
    if (showHistorical) {
      setHurricaneData(historicalData);
      getStormNames(historicalData);
    } else {
      setHurricaneData(liveData);
      getStormNames(liveData);
    }
    setStormString(stormNames[0]);
  }, [showHistorical]);
  const dataRefresh = useDataRefresh();
  useEffect(() => {
    (async () => {
      setMapCenter([Cookies.get("latitude"), Cookies.get("longitude")]);
      if (dataCache) {
        setHurricaneData(dataCache);
        getStormNames(dataCache);
        setFetching(false);
        return;
      }
      //get hurricane data
      FusionData.get(ROUTE)
        .then((res) => {
          // console.log("Hurricane data:", res);

          let fixedData = res.data.result.map((item) => {
            if (
              item.name.includes("LIVE:") ||
              item.local_storm_name.includes("LIVE:")
            )
              return item;
            item.name = "LIVE: " + item.name;
            item.local_storm_name = "LIVE: " + item.local_storm_name;
            return item;
          });
          // dataCache = fixedData;
          if (fixedData.length > 0) {
            setLiveData(fixedData);
            setHurricaneData(fixedData);
            getStormNames(fixedData);
          }
          // setHurricaneData(dataCache);
          // getStormNames(dataCache);
           
          FusionData.get("getHurricanesHistorical").then((res) => {
            // console.log("Hurricane data historical:", res);

            // dataCache = fixedData.concat(res.data.result)
            // // console.log("CONCAT: ", dataCache)
            // return

            // setHurricaneData(dataCache);
            // getStormNames(dataCache);
            if (fixedData.length === 0) {
              // console.log("NO LIVE DATA");
              // setShowHistorical(true);
              // setHurricaneData(res.data.result);
              // getStormNames(res.data.result);
              // setLiveData([res.data.result[0]]);
            } else {
              // // console.log("LIVE DATA", hurricaneData, liveData);
              // setStormString(fixedData[0].name);
              setStormString("All Live Storms");
              setMapCenter([Cookies.get("latitude"), Cookies.get("longitude")]);
              // setMapCenter([fixedData[0].latitude, fixedData[0].longitude]);
            }
            setHistoricalData(res.data.result);
             
            setFetching(false);
          });
        })
        .catch((err) => {
          // console.log("Error getting data", err);
          setError({ state: true, message: err.message });
        });
    })();
  }, [dataRefresh.widgetRefresh]);

  // useEffect(() => {
  //   if (!hurricaneData) return;
  //   setSelectedItem(0);

  //   //get all unique names
  //   const names = [];

  //   hurricaneData.forEach((item) => {
  //     if (!names.includes(item.name)) names.push(item.name);
  //   });

  //   setOptions(names);
  // }, [hurricaneData]);

  const getUpdatedStormName = (name) => {};

  const getStormNames = (data) => {
    let stormList = [];
    data.map((item) => {
      // // console.log("storm: ", item)
      const nameAndYear = item.name + " (" + item.code.split("_")[0] + ")";
      if (!stormList.includes(nameAndYear)) {
        stormList.push(nameAndYear);
      }
    });
    stormList.sort((a, b) => {
      //sort base on year, use string.split("(")[1].split(")")[0]
      return (
        parseInt(b.split("(")[1].split(")")[0]) -
        parseInt(a.split("(")[1].split(")")[0])
      );
    });
    setStormNames(stormList);
  };

  const getHurricaneSeaSize = (instance) => {
    let count = 0;
    let sizes = [];
    if (instance.NE_12ft_sea_radii > 0) {
      count++;
      sizes.push(instance.NE_12ft_sea_radii);
    }
    if (instance.NW_12ft_sea_radii > 0) {
      count++;
      sizes.push(instance.NE_12ft_sea_radii);
    }
    if (instance.SE_12ft_sea_radii > 0) {
      count++;
      sizes.push(instance.NE_12ft_sea_radii);
    }
    if (instance.SW_12ft_sea_radii > 0) {
      count++;
      sizes.push(instance.NE_12ft_sea_radii);
    }
    if (count) return sizes[0] * 100;
    return 10;
  };

  const getHurricaneWindSize = (instance) => {
    // let quads = {
    //   northeast: 0,
    //   southeast: 0,
    //   northwest: 0,
    //   southwest: 0,
    // };
    // console.log(instance);
    // let radii = JSON.parse(instance.wind_radii);

    // radii.forEach((radius) => {
    //   Object.keys(radius).forEach((key) => {
    //     if (radius[key] > quads[key]) quads[key] = radius[key];
    //   });
    // });

    // let r = 1;
    // Object.keys(quads).map((quad) => {
    //   r += parseFloat(quads[quad]);
    // });
    // r = r / 4;

    // Parse the wind_radii field to JSON
    const windRadii = JSON.parse(instance.wind_radii);
    // console.log("Wind radii:", windRadii);

    // Calculate the maximum radius for each wind speed zone
    const maxRadii = windRadii.map((zone) =>
      Math.max(
        zone.northeast,
        zone.southeast,
        zone.southwest,
        zone.northwest,
        zone.minimum_wind_speed
      )
    );

    // console.log("Max radii:", maxRadii);

    // Estimate the overall storm size
    const stormSize = Math.max(...maxRadii);

    // // console.log(`Estimated storm size (maximum radius from center): ${stormSize} miles`);

    // // console.log("Calc'd radius: ", quads, r)

    return stormSize * nauticalMilesToMetersConstant;
  };

  useEffect(() => {
    let instances = hurricaneData.filter(
      (item) => item.name + " (" + item.code.split("_")[0] + ")" === stormString
    );

    if (instances.length > 0)
      setMapCenter([instances[0].latitude, instances[0].longitude]);
    // console.log("instances", instances);
    // console.log(instances[0])
  }, [stormString]);

  const generateHurricanePath = (stormName) => {
    let instances = hurricaneData.filter(
      (item) => item.name + " (" + item.code.split("_")[0] + ")" === stormName
    );

    // console.log(instances[0])
    // if (instances.length > 0) setMapCenter([instances[0].latitude, instances[0].longitude]);
    // console.log("instances", instances);
    let lines = [];
    for (let i = 0; i < instances.length; i++) {
      if (i === instances.length - 1) break;
      let lat1 = instances[i].latitude;
      let lon1 = instances[i].longitude;
      let lat2 = instances[i + 1].latitude;
      let lon2 = instances[i + 1].longitude;

      lines.push([
        [lat1, lon1],
        [lat2, lon2],
      ]);
    }

    // console.log("lines:", lines);
    return lines;
  };

  const getHurricaneInfo = () => {
    let instances = hurricaneData.filter(
      (item) => item.name + " (" + item.code.split("_")[0] + ")" === stormString
    );
    let lastUpdate = instances[instances.length - 1];
    // // console.log("Last update:", lastUpdate);
    if (lastUpdate) {
      return (
        <div>
          <div style={{ padding: layout.widgetHeight / 100 }}>
            Type: {capitalizeWord(lastUpdate.type)} <br />
          </div>
          <div style={{ padding: layout.widgetHeight / 100 }}>
            Date: {lastUpdate.locale_time} <br />
          </div>
          <div style={{ padding: layout.widgetHeight / 100 }}>
            Category: {lastUpdate.category} <br />
          </div>
          <div style={{ padding: layout.widgetHeight / 100 }}>
            Max Wind Speed: {parseFloat(lastUpdate.wind_speed).toFixed(1)} mph{" "}
            <br />
          </div>
          <div style={{ padding: layout.widgetHeight / 100 }}>
            Latitude: {lastUpdate.latitude} ° <br />
          </div>
          <div style={{ padding: layout.widgetHeight / 100 }}>
            Longitude: {lastUpdate.longitude} ° <br />
          </div>
          <div style={{ padding: layout.widgetHeight / 100 }}>
            Baro Pressure: {lastUpdate.central_pressure} in.
            <br />
          </div>
        </div>
      );
    } else {
      return;
    }

    return stormString;
  };

  const createCustomIcon = () => {
    return L.divIcon({
      // TODO: replace <FaHurricane /> with proper image
      html: ReactDOMServer.renderToString(<FaHurricane color="black" />),
    });
  };

  const getLocalStormName = (stormName) => {
    // console.log("stormName", stormName);
    let yearStr = stormName.split("(")[1].split(")")[0];
    let instances = hurricaneData.filter((item) => {
      // console.log("item", item);
      return item.name === stormName.split("(")[0].trim();
    });
    // console.log("instances", instances);
    let lastUpdate = instances[instances.length - 1];
    // console.log("lastUpdate", lastUpdate);
    if (lastUpdate?.local_storm_name)
      return lastUpdate.local_storm_name + " (" + yearStr + ")";
    else return stormName;
    // if (lastUpdate.local_storm_name) return lastUpdate.local_storm_name;
    // else return "No name found"
  };

  const addColonToStormName = (name) => {
    const types = [
      "Tropical Depression",
      "Tropical Storm",
      "Hurricane",
      "Typhoon",
      "Severe Tropical Storm",
    ];
    let newName = name;
    types.forEach((type) => {
      if (name.includes(type)) {
        newName = name.replace(type, type + ":");
      }
    });
    return newName;
  };

  const generateLineToOutermostPoint = (currentPoint, prevPoint, radius) => {
    radius *= 0.0176229;
    const lat1 = toRadians(currentPoint[0]);
    const lon1 = toRadians(currentPoint[1]);
    const lat2 = toRadians(prevPoint[0]);
    const lon2 = toRadians(prevPoint[1]);

    // Calculate bearing from prevPoint to currentPoint
    const dLon = lon2 - lon1;
    const y = Math.sin(dLon) * Math.cos(lat2);
    const x =
      Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
    const initialBearing = Math.atan2(y, x);

    // Perpendicular bearing
    const perpBearing = initialBearing + Math.PI / 2;

    // Length of the perpendicular line in degrees
    const lineLength = (radius / 6378137) * (180 / Math.PI);

    const point1 = [
      toDegrees(lat1 + lineLength * Math.cos(perpBearing)),
      toDegrees(lon1 + lineLength * Math.sin(perpBearing)),
    ];

    const point2 = [
      toDegrees(lat1 - lineLength * Math.cos(perpBearing)),
      toDegrees(lon1 - lineLength * Math.sin(perpBearing)),
    ];

    return [point1, point2];
  };

  const getOutermostSinglePoint = (radius, currentPoint, prevPoint) => {
    radius *= 0.0176229;
    // radius = 10000;
    const lat1 = toRadians(currentPoint[0]);
    const lon1 = toRadians(currentPoint[1]);
    const lat2 = toRadians(prevPoint[0]);
    const lon2 = toRadians(prevPoint[1]);

    // Calculate bearing from prevPoint to currentPoint
    const dLon = lon2 - lon1;
    const y = Math.sin(dLon) * Math.cos(lat2);
    const x =
      Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
    const initialBearing = Math.atan2(y, x);
    const lBearing = initialBearing + Math.PI / 4;
    const rBearing = initialBearing + (7 * Math.PI) / 4;

    const lineLength = (radius / 6378137) * (180 / Math.PI);
    const point1 = [
      toDegrees(lat1 - lineLength * Math.cos(initialBearing)),
      toDegrees(lon1 - lineLength * Math.sin(initialBearing)),
    ];
    const point2 = [
      toDegrees(lat1 - lineLength * Math.cos(lBearing)),
      toDegrees(lon1 - lineLength * Math.sin(lBearing)),
    ];
    const point3 = [
      toDegrees(lat1 - lineLength * Math.cos(rBearing)),
      toDegrees(lon1 - lineLength * Math.sin(rBearing)),
    ];

    return [point3, point1, point2];
  };

  const getInnermostSinglePoint = (radius, currentPoint, prevPoint) => {
    // console.log("radius", radius);
    // console.log("currentPoint", currentPoint);
    // console.log("prevPoint", prevPoint);
    radius *= 0.0176229 * 0.5;

    // radius = 5000;
    const lat1 = toRadians(currentPoint[0]);
    const lon1 = toRadians(currentPoint[1]);
    const lat2 = toRadians(prevPoint[0]);
    const lon2 = toRadians(prevPoint[1]);

    // Calculate bearing from prevPoint to currentPoint
    const dLon = lon2 - lon1;
    const y = Math.sin(dLon) * Math.cos(lat2);
    const x =
      Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
    const initialBearing = Math.atan2(y, x);
    const lBearing = initialBearing + Math.PI / 4;
    const rBearing = initialBearing + (7 * Math.PI) / 4;

    const lineLength = (radius / 6378137) * (180 / Math.PI);
    const point1 = [
      toDegrees(lat1 - lineLength * Math.cos(initialBearing)),
      toDegrees(lon1 - lineLength * Math.sin(initialBearing)),
    ];
    const point2 = [
      toDegrees(lat1 - lineLength * Math.cos(lBearing)),
      toDegrees(lon1 - lineLength * Math.sin(lBearing)),
    ];
    const point3 = [
      toDegrees(lat1 - lineLength * Math.cos(rBearing)),
      toDegrees(lon1 - lineLength * Math.sin(rBearing)),
    ];
    // console.log("points", point3, point1, point2);

    return [point3, point1, point2];
  };

  const getHurricaneLivePredictSize = (item, time, baseTime) => {
    // console.log("size", item);
    const t = new Date(time);
    const bt = new Date(baseTime);
    //base time to hours diff
    const timeDiff = (t - bt) / 1000 / 60 / 60;
    // console.log("timeDiffHrs", timeDiff);
    const tdToMi = (td) => {
      return d3
        .scaleLinear()
        .domain([0, 12, 24, 36, 48, 60, 72, 96, 120])
        .range([0, 30, 45, 61, 77, 93, 114, 167, 236])(td);
    };
    const size = 1609.34 * tdToMi(timeDiff) + 50000;
    // console.log("size", size);
    return size;
    // const result = Math.min(
    //   getHurricaneWindSize(item) *
    //     0.033
    //     * 1.2
    //     * Math.min(parseFloat(item.wind_speed), 50)
    //     * .75
    //     * index
    //     ,
    //   300000
    // )
    // // * index / maxIndex;

    // console.log("size", result);
    // return result;
  };

  const generateHurricanePathOutline = (stormName) => {
    let instances = hurricaneData.filter(
      (item) => item.name + " (" + item.code.split("_")[0] + ")" === stormName
    );
    let lines = [];

    for (let i = 1; i < instances.length; i++) {
      const line = generateLineToOutermostPoint(
        [instances[i].latitude, instances[i].longitude],
        [instances[i - 1].latitude, instances[i - 1].longitude],
        400000
      );
      lines.push(line);
    }

    if (lines.length == 0) return [];

    // map lines1 and lines2
    let line1 = lines.map((line) => line[0]);
    let line2 = lines.map((line) => line[1]);
    let farEdge = getOutermostSinglePoint(
      10000 / 0.0176229,
      [
        instances[instances.length - 1].latitude,
        instances[instances.length - 1].longitude,
      ],
      [
        instances[instances.length - 1 - 1].latitude,
        instances[instances.length - 1 - 1].longitude,
      ]
    );
    let closeEdge = getInnermostSinglePoint(
      10000 / 0.0176229,
      [instances[0].latitude, instances[0].longitude],
      [instances[1].latitude, instances[1].longitude]
    );

    return [
      [closeEdge[0][0], closeEdge[0][1]],
      ...closeEdge,
      ...line1,
      ...farEdge,
      ...line2.reverse(),
      [closeEdge[0][0], closeEdge[0][1]],
    ];
  };

  const generateHurricaneCone = (stormName) => {
    let instances = hurricaneData.filter(
      (item) =>
        item.name + " (" + item.code.split("_")[0] + ")" === stormName &&
        // item.iso_date >=
        //   new Date(new Date().getTime() - 1000 * 60 * 60 * 6).toISOString()
        item.type === "forecast"
    );

    // console.log("instances", instances);
    let lines = [];

    for (let i = 1; i < instances.length; i++) {
      const line = generateLineToOutermostPoint(
        [instances[i].latitude, instances[i].longitude],
        [instances[i - 1].latitude, instances[i - 1].longitude],
        getHurricaneLivePredictSize(
          instances[i],
          instances[i].utc_time,
          instances[0].utc_time
        )
      );
      lines.push(line);
    }

    if (lines.length == 0) return [];

    // map lines1 and lines2
    let line1 = lines.map((line) => line[0]);
    let line2 = lines.map((line) => line[1]);
    let farEdge = getOutermostSinglePoint(
      getHurricaneLivePredictSize(
        instances[instances.length - 1],
        instances[instances.length - 1].utc_time,
        instances[0].utc_time
      ),
      // * instances[instances.length - 1].wind_speed * .05
      [
        instances[instances.length - 1].latitude,
        instances[instances.length - 1].longitude,
      ],
      [
        instances[instances.length - 1 - 1].latitude,
        instances[instances.length - 1 - 1].longitude,
      ]
    );

    return [
      [instances[0].latitude, instances[0].longitude],
      ...line1,
      ...farEdge,
      ...line2.reverse(),
      [instances[0].latitude, instances[0].longitude],
    ];
  };

  const smoothShape = (shape) => {
    if (shape.length < 3) return shape;
    const originPoint = shape[0];
    let polygon = turf.polygon([shape]);

    let smoothed = turf.polygonSmooth(polygon, { iterations: 3 });
    const smoothPoly = smoothed.features[0].geometry.coordinates[0];

    //append the origin point to the end of the smoothed shape

    let simple = turf.simplify(smoothed, {
      tolerance: 0.5,
      highQuality: true,
    });
    let smoothed2 = turf.polygonSmooth(simple, { iterations: 3 });
    const bufferedShape = turf.buffer(smoothed2, 0.01, {
      units: "kilometers",
    });

    const cleanedShape = turf.union(
      turf.featureCollection([
        turf.polygon(smoothed2.features[0].geometry.coordinates),
        turf.polygon(smoothed2.features[0].geometry.coordinates),
      ])
    );

    const simple2 = turf.simplify(cleanedShape, {
      tolerance: 0.8,
      highQuality: true,
    });
    const smoothed3 = turf.polygonSmooth(simple2, { iterations: 3 });
    return smoothed3.features[0].geometry.coordinates[0];

    return cleanedShape.geometry.coordinates[0];
  };

  const smoothPolyWithOrigin = (shape) => {
    if (shape.length < 3) return shape;
    const originPoint = shape[0];
    let polygon = turf.polygon([shape]);

    let smoothed = turf.polygonSmooth(polygon, { iterations: 3 });
    const smoothPoly = smoothed.features[0].geometry.coordinates[0];

    return smoothPoly;

    //append the origin point to the end of the smoothed shape
    smoothPoly.push(originPoint);
    smoothPoly[0] = originPoint;

    //this is causing no worky
    let convex2 = turf.convex(turf.polygon([smoothPoly]), { concavity: 0 });
    let convex2Poly = convex2.geometry.coordinates[0];
    return convex2Poly;
  };

  if (error.state && !fetching) {
    return <ErrorMessage message={error.message} />;
  }

  // if (true && widget) {
  if (fetching && widget) {
    return (
      <WidgetLoadingAnimation
        widgetName={"HurricanesTropicalStorms"}
        dimensions={dimensions}
      />
    );
  }

  if (fetching) {
    return <WidgetLoadingAnimation />;
  }

  if (liveData.length === 0 && historicalData.length === 0) {
    return (
      <div>
        {widget && (
          <WidgetTimeUpdate
            route={ROUTE}
            updateIntervalBasis={180}
             
          />
        )}
        <div style={{ padding: 15 }}>No data found.</div>
      </div>
    );
  }

  const LiveStorm = () => {
    if (stormString === "All Live Storms") {
      const names = hurricaneData
        .map((item) => item.name)
        .reduce((acc, curr) => {
          if (!acc.includes(curr)) acc.push(curr);
          return acc;
        }, []);
      // console.log("names", names);

      return names.map((item, index) => {
        const code = hurricaneData.find((storm) => storm.name === item).code;
        return (
          <Polygon
            key={index}
            positions={smoothPolyWithOrigin(
              generateHurricaneCone(item + " (" + code.split("_")[0] + ")")
            )}
            color="red"
          />
        );
      });
    }
    return (
      <Polygon
        positions={smoothPolyWithOrigin(generateHurricaneCone(stormString))}
        color="red"
      />
    );
  };

  const HistoricalStorm = () => {
    return (
      <Polygon
        positions={smoothShape(generateHurricanePathOutline(stormString))}
        color="red"
      />
    );
  };

  const HurricanePath = () => {
    if (stormString === "All Live Storms") {
      //find all live storms by name
      const names = hurricaneData
        .map((item) => item.name)
        .reduce((acc, curr) => {
          if (!acc.includes(curr)) acc.push(curr);
          return acc;
        }, []);
      // console.log("names", names);

      return names.map((item, index) => {
        const code = hurricaneData.find((storm) => storm.name === item).code;
        return (
          <Polyline
            key={`polyline-${index}`}
            positions={generateHurricanePath(
              item + " (" + code.split("_")[0] + ")"
            )}
            color="gray"
          />
        );
      });
    }
    return (
      <Polyline
        key={`polyline-1`}
        positions={generateHurricanePath(stormString)}
        color="gray"
      />
    );
  };

  const HurricaneIcons = () => {
    if (stormString === "All Live Storms") {
      return hurricaneData.map((item, index) => {
        return (
          <Marker
            key={`circle-${index}`}
            position={[item.latitude, item.longitude]}
            icon={L.icon({
              iconUrl: hurricaneIcon,
              iconSize: [20, 20],
              iconAnchor: [10, 10],
              html: `<span style="filter: invert(100%) sepia(100%) saturate(1000%) hue-rotate(-50deg);" />`,
            })}
            color="red"
          >
            <Popup>
              <div style={{ fontWeight: "bold" }}>{item.local_storm_name} </div>
              <div>Type: {capitalizeWord(item.type)} </div>
              <div>Date: {item.locale_time} </div>
              <div>Category: {item.category} </div>
              <div>
                Max Wind Speed: {parseFloat(item.wind_speed).toFixed(1)} mph{" "}
              </div>
              <div>Latitude: {item.latitude} ° </div>
              <div>Longitude: {item.longitude} ° </div>
              <div>Baro Pressure: {item.central_pressure} in. </div>
            </Popup>
          </Marker>
        );
      });
    }
    return hurricaneData
      .filter((item, index) => {
        return item.name + " (" + item.code.split("_")[0] + ")" === stormString;
      })
      .map((item, index) => {
        return (
          <Marker
            key={`circle-${index}`}
            position={[item.latitude, item.longitude]}
            icon={L.icon({
              iconUrl: hurricaneIcon,
              iconSize: [20, 20],
              iconAnchor: [10, 10],
              html: `<span style="filter: invert(100%) sepia(100%) saturate(1000%) hue-rotate(-50deg);" />`,
            })}
            color="red"
          >
            <Popup>
              <div style={{ fontWeight: "bold" }}>{item.local_storm_name} </div>
              <div>Type: {capitalizeWord(item.type)} </div>
              <div>Date: {item.locale_time} </div>
              <div>Category: {item.category} </div>
              <div>
                Max Wind Speed: {parseFloat(item.wind_speed).toFixed(1)} mph{" "}
              </div>
              <div>Latitude: {item.latitude} ° </div>
              <div>Longitude: {item.longitude} ° </div>
              <div>Baro Pressure: {item.central_pressure} in. </div>
            </Popup>
          </Marker>
        );
      });
  };

  const StormSelector = (width = null) => {
    // console.log("Storm selector width:", width);

    const theme = useContext(ColorContext);
    return stormNames.length ? (
      <select
        style={{
          padding: 10,
          display: "flex",
          borderRadius: 5,
          backgroundColor: theme.primary,
          marginTop: 5,
          marginBottom: 5,
          width: "100%",
        }}
        onChange={(e) => {
          if (e.target.value === "") return;
          setStormString(e.target.value);
        }}
        value={stormString}
      >
        <option value="">- Select a storm -</option>
        {!showHistorical && (
          <option value="All Live Storms">All Live Storms</option>
        )}
        {stormNames.map((item, index) => {
          return (
            <option key={index} value={item}>
              {addColonToStormName(getLocalStormName(item))}
            </option>
          );
        })}
      </select>
    ) : (
      <div>No storms found</div>
    );
  };

  if (!dimensions)
    return (
      <div
        style={{
          // height: window.innerHeight - 221,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <WidgetTimeUpdate route={ROUTE} updateIntervalBasis={180} addMargin />
        <div
          style={{
            overflowY: "auto",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FullScreenMap
            mapCenter={[Cookies.get("latitude"), Cookies.get("longitude")]}
            zoom={5}
            satelliteToggleStyle={{ bottom: "25px", left: "30px" }}
          >
            <LocationSetter center={mapCenter} zoom={zoom} />

            {showHistorical && <HistoricalStorm />}
            {!showHistorical && <LiveStorm />}
            <HurricaneIcons />
            <HurricanePath />
          </FullScreenMap>

          <div style={{ width: "100%" }}>
            <StormSelector />
            <div>{getHurricaneInfo()}</div>
            <TextToggle
              options={["Live", "Historical"]}
              value={showHistorical}
              setValue={setShowHistorical}
            />
          </div>
        </div>
      </div>
    );

  if (dimensions[0] == 2 && dimensions[1] == 1)
    return (
      // <div style={{ height: layout.widgetHeight }}>
      <div style={{ height: "100%" }}>
        <WidgetTimeUpdate
          route={ROUTE}
          updateIntervalBasis={180}
           
        />
        {hurricaneData && (
          <div
            style={{
              fontSize: layout.baseFontSize,
              width: "100%",
              height: layout.widgetHeight - 100,
              display: "flex",
              flexDirection: "row",
              // backgroundColor: "red",
            }}
          >
            <div
              style={{
                padding: 10,
                // backgroundColor: "green",
                width: layout.doubleWidgetWidth,
              }}
            >
              {StormSelector()}

              <div
                style={{
                  position: "relative",
                  bottom: -layout.widgetHeight + 170,
                  height: 0,
                  fontSize: 15,
                }}
              >
                <TextToggle
                  options={["Live", "Historical"]}
                  value={showHistorical}
                  setValue={setShowHistorical}
                />
              </div>
              <div>{getHurricaneInfo()}</div>
            </div>
            <div
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              <WidgetMap
                sidebarOpen={sidebarOpen}
                mapCenter={mapCenter}
                zoom={5}
                preview={preview}
              >
                <LocationSetter center={mapCenter} zoom={zoom} />

                {showHistorical && <HistoricalStorm />}
                {!showHistorical && <LiveStorm />}
                <HurricaneIcons />
                <HurricanePath />
              </WidgetMap>
            </div>
          </div>
        )}
      </div>
      // </div>
    );

  if (dimensions[0] == 1 && dimensions[1] == 1)
    return (
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <WidgetTimeUpdate
          route={ROUTE}
          updateIntervalBasis={180}
           
        />
        <div
          style={{
            width: "100%",
            padding: 20,
            position: "absolute",
            marginTop: 20,
            marginLeft: 45,
            zIndex: 1000,
          }}
        >
          <div
            style={{
              width: layout.widgetWidth - 120,
            }}
          >
            {StormSelector()}
          </div>
        </div>
        {hurricaneData && hurricaneData.length && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              <div
                style={{
                  position: "relative",
                  bottom: -layout.widgetHeight + 125,
                  left: 20,
                  zIndex: 1000,
                  height: 0,
                }}
              >
                <TextToggle
                  options={["Live", "Historical"]}
                  value={showHistorical}
                  setValue={setShowHistorical}
                />
              </div>
              <div
                style={{
                  height: "45%",
                  width: "100%",
                }}
              >
                <WidgetMap
                  sidebarOpen={sidebarOpen}
                  mapCenter={mapCenter}
                  zoom={5}
                  satelliteToggleStyle={{ bottom: "60px" }}
                  preview={preview}
                >
                  <LocationSetter center={mapCenter} zoom={zoom} />

                  {showHistorical && <HistoricalStorm />}
                  {!showHistorical && <LiveStorm />}
                  <HurricaneIcons />
                  <HurricanePath />
                </WidgetMap>
              </div>
            </div>
          </div>
        )}
      </div>
    );

  if (dimensions[0] == 1 && dimensions[1] == 2)
    return (
      <div style={{ height: "100%" }}>
        <WidgetTimeUpdate
          route={ROUTE}
          updateIntervalBasis={180}
           
        />
        {hurricaneData && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                height: "45%",
                width: "100%",
              }}
            >
              <WidgetMap
                sidebarOpen={sidebarOpen}
                mapCenter={mapCenter}
                zoom={5}
                preview={preview}
              >
                <LocationSetter center={mapCenter} zoom={zoom} />

                {showHistorical && <HistoricalStorm />}
                {!showHistorical && <LiveStorm />}
                <HurricaneIcons />
                <HurricanePath />
              </WidgetMap>
            </div>
            <div style={{ width: "100%", padding: 10 }}>
              {/* <StormSelector /> */}
              <div
                style={{
                  width: layout.widgetWidth - 50,
                }}
              >
                {StormSelector()}
              </div>
              <div>{getHurricaneInfo()}</div>
              <div style={{ position: "absolute", bottom: 20 }}>
                <TextToggle
                  options={["Live", "Historical"]}
                  value={showHistorical}
                  setValue={setShowHistorical}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
};

export default HurricanesTropicalStorms;
