import React, { useContext, useEffect, useState } from "react";
import * as api from "apis/FusionAPI";
import { DateToString } from "utils/stringUtils";
import DropdownSelector from "components/DropdownSelector";
import ForecastSpread from "components/ForecastSpread";
import { RiWindyLine } from "react-icons/ri";
import ConditionIcons from "components/ConditionIcons";
import BeaufortIcons from "components/BeaufortIcons";
import WidgetTimeUpdate from "./WidgetTimeUpdate";
import moment from "moment";
import WidgetLoadingAnimation from "./WidgetLoadingAnimation";
import { ErrorMessage } from "./ErrorMessage";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useAPIData } from "hooks/useAPIData";
import { ColorContext, UniqueWidgetRefreshContext } from "App";
import Cookies from "js-cookie";

const weekdays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

let dataCache;
 

export default function WaveAndWindConditionsWidget({
  widgetRefresh,
  setWidgetRefresh,
  dimensions,
}) {
  const dateTime = new Date();

  const theme = useContext(ColorContext);
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState();
  const [selectedDay, setSelectedDay] = useState(new Date().getDay());
  const [selectedCityId, setSelectedCityId] = useState(0);
  const [cityIDs, setCityIDs] = useState([]);
  const [error, setError] = useState({ state: false, message: "" });
  const [waveDropdown, setWaveDropdown] = useState(false);
  const [windDropdown, setWindDropdown] = useState(false);
  useEffect(() => {
    dataCache = null;
    setFetching(true);
  }, [widgetRefresh]);

  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });

  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "getDetailedMarine7";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  useEffect(() => {
    // console.log("Wave and Wind Conditions Widget Refreshed");
    const fetchData = async () => {
      try {
        FusionData.forceGet(ROUTE).then((res) => {
          // console.log("Wave and Wind Conditions:", res);

          dataCache = res.data.result;
          setData(dataCache);
          setCityIDs(findCityIDs(dataCache));
           
          // setFetching(false);
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [uwf[UNIQUEKEY]]);

  useEffect(() => {
    (async () => {
      if (dataCache) {
        setData(dataCache);
        setCityIDs(findCityIDs(dataCache));
        setFetching(false);
        return;
      }

      FusionData.get(ROUTE)
        .then((res) => {
          // console.log("Wave and Wind Conditions:", res);

          dataCache = [...res.data.result];
          setData(dataCache);
          setCityIDs(findCityIDs(dataCache));
           
          setFetching(false);
        })
        .catch((err) => {
          // console.log("Error getting data", err);
          setError({ state: true, message: err.message });
        });
    })();
  }, [widgetRefresh]);

  

  const findCityIDs = (data) => {
    let cityIDs = [];
    let uniqueCityIDs = [];
    data.forEach((item) => {
      if (!uniqueCityIDs.includes(item.city_id)) {
        let displayName = "";
        if (parseInt(item.city_id)) {
          displayName = item.city_name;
        } else {
          displayName = "Near " + item.city_name;
        }
        cityIDs.push({ id: item.city_id, display: displayName });
        uniqueCityIDs.push(item.city_id);
      }
    });
    cityIDs.sort((a, b) => {
      if (parseInt(a.id)) return -1;
      else return 1;
    });
    return cityIDs;
  };
  if (error.state && !fetching) {
    return <ErrorMessage message={error.message} />;
  }

  // if (true)
  if (fetching)
    return (
      <WidgetLoadingAnimation
        widgetName={"WaveAndWindCondition"}
        dimensions={dimensions}
      />
    );

  if (data && data.length === 0) {
    return (
      <div>
        <WidgetTimeUpdate route={ROUTE}  />
        <div style={{ padding: 15 }}>No data found.</div>
      </div>
    );
  }

  if (dimensions[0] == 2 && dimensions[1] == 1) {
    if (data)
      return (
        <div style={{ height: "100%" }}>
          <WidgetTimeUpdate route={ROUTE}    />
          {data.length && (
            <div
              // className="scrollbar-hidden"
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 10,
                height: "calc(90% - 20px)",
                overflow: "auto",
              }}
            >
              {/* Header */}
              {/* <div style={{ borderBottom: "solid grey 1px" }}>
          {dateTime.toLocaleString()}
        </div> */}

              {/* Drop-down */}
              <div style={{ display: "flex", gap: 20 }}>
                <DropdownSelector
                  options={weekdays}
                  selectedOption={selectedDay}
                  setSelected={(newSelected) => setSelectedDay(newSelected)}
                  triggerStyle={{ width: 300, padding: 10 }}
                  contentStyle={{ width: 300 }}
                />
                <DropdownSelector
                  options={cityIDs.map((item) => item.display)}
                  selectedOption={selectedCityId}
                  setSelected={(newSelected) => setSelectedCityId(newSelected)}
                  triggerStyle={{ width: 300, padding: 10 }}
                  contentStyle={{ width: 300 }}
                />
              </div>

              {/* Content */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 15,
                  // minHeight: "100%",
                  // height: 350,

                  height: "calc(100% - 80px)",
                }}
              >
                {/* Wave */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    backgroundColor: theme.primary,
                    marginRight: 5,
                    overflowY: "scroll",
                    padding: 10,
                    width: "50%",
                  }}
                  className="scrollbar-hidden"
                >
                  {/* Title */}
                  <div
                    style={{
                      padding: 5,
                      fontSize: 20,
                      borderBottom: "solid grey 1px",
                      fontWeight: "bold",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>Wave</div>
                    <div>
                      {(() => {
                        const filteredData = data.filter((item) => {
                          return (
                            item.weekday === weekdays[selectedDay] &&
                            item.city_id === cityIDs[selectedCityId].id
                          );
                        });
                        return filteredData[0]?.utc_time
                          ? moment(filteredData[0]?.utc_time).format(
                              "MM/DD/YYYY"
                            )
                          : "No data";
                      })()}
                    </div>
                  </div>

                  {/* Data */}
                  <div style={{ flex: 1, padding: 2 }}>
                    {data.filter((item) => {
                      return (
                        item.weekday === weekdays[selectedDay] &&
                        item.city_id === cityIDs[selectedCityId].id
                      );
                    }).length === 0 && "No data"}
                    {data
                      .filter((item) => {
                        return (
                          item.weekday === weekdays[selectedDay] &&
                          item.day_segment === "Morning" &&
                          item.city_id === cityIDs[selectedCityId].id
                        );
                      })
                      .map((item, index) => {
                        return (
                          <ForecastSpread
                            key={index}
                            header={
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                  fontWeight: "bold",
                                  fontSize: 20,
                                }}
                              >
                                <div>Morning</div>
                                <div style={{ marginRight: 10 }}>
                                  {item.beaufort_desc}
                                </div>
                              </div>
                            }
                            data={{
                              "Wave Height": `${item.wave_height} ft`,
                              "Mean Wave Dir./Period": `${item.mean_wave_direction_short} / ${item.mean_wave_period} sec`,
                              "Wind Wave Dir./Period": `${item.wind_wave_direction_short} / ${item.wind_wave_period} sec`,
                              "Peak Wave Dir./Period": `${item.peak_wave_direction_short} / ${item.peak_wave_period} sec`,
                            }}
                            icon={<BeaufortIcons conditionId={item.beaufort} />}
                            style={{
                              marginTop: 15,
                              height: "100%",
                              fontSize: 14,
                            }}
                          />
                        );
                      })}
                  </div>
                  <div style={{ flex: 1, padding: 3 }}>
                    {data
                      .filter((item) => {
                        return (
                          item.weekday === weekdays[selectedDay] &&
                          item.day_segment === "Afternoon" &&
                          item.city_id === cityIDs[selectedCityId].id
                        );
                      })
                      .map((item, index) => {
                        return (
                          <ForecastSpread
                            key={index}
                            header={
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                  fontWeight: "bold",
                                  fontSize: 20,
                                }}
                              >
                                <div>Afternoon</div>
                                <div style={{ marginRight: 10 }}>
                                  {item.beaufort_desc}
                                </div>
                              </div>
                            }
                            data={{
                              "Wave Height": `${item.wave_height}ft`,
                              "Mean Wave Dir./Period": `${item.mean_wave_direction_short} / ${item.mean_wave_period} sec`,
                              "Wind Wave Dir./Period": `${item.wind_wave_direction_short} / ${item.wind_wave_period} sec`,
                              "Peak Wave Dir./Period": `${item.peak_wave_direction_short} / ${item.peak_wave_period} sec`,
                            }}
                            icon={<BeaufortIcons conditionId={item.beaufort} />}
                            style={{
                              marginTop: 15,
                              height: "100%",
                              fontSize: 14,
                            }}
                          />
                        );
                      })}
                  </div>
                  <div style={{ flex: 1, padding: 3 }}>
                    {data
                      .filter((item) => {
                        return (
                          item.weekday === weekdays[selectedDay] &&
                          item.day_segment === "Evening" &&
                          item.city_id === cityIDs[selectedCityId].id
                        );
                      })
                      .map((item, index) => {
                        return (
                          <ForecastSpread
                            key={index}
                            header={
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                  fontWeight: "bold",
                                  fontSize: 20,
                                }}
                              >
                                <div>Evening</div>
                                <div style={{ marginRight: 10 }}>
                                  {item.beaufort_desc}
                                </div>
                              </div>
                            }
                            data={{
                              "Wave Height": `${item.wave_height}ft`,
                              "Mean Wave Dir./Period": `${item.mean_wave_direction_short} / ${item.mean_wave_period} sec`,
                              "Wind Wave Dir./Period": `${item.wind_wave_direction_short} / ${item.wind_wave_period} sec`,
                              "Peak Wave Dir./Period": `${item.peak_wave_direction_short} / ${item.peak_wave_period} sec`,
                            }}
                            icon={<BeaufortIcons conditionId={item.beaufort} />}
                            style={{
                              marginTop: 15,
                              height: "100%",
                              fontSize: 14,
                            }}
                          />
                        );
                      })}
                  </div>
                  <div style={{ flex: 1, padding: 3 }}>
                    {data
                      .filter((item) => {
                        return (
                          item.weekday === weekdays[selectedDay] &&
                          item.day_segment === "Night" &&
                          item.city_id === cityIDs[selectedCityId].id
                        );
                      })
                      .map((item, index) => {
                        return (
                          <ForecastSpread
                            key={index}
                            header={
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                  fontWeight: "bold",
                                  fontSize: 20,
                                }}
                              >
                                <div>Night</div>
                                <div style={{ marginRight: 10 }}>
                                  {item.beaufort_desc}
                                </div>
                              </div>
                            }
                            data={{
                              "Wave Height": `${item.wave_height}ft`,
                              "Mean Wave Dir./Period": `${item.mean_wave_direction_short} / ${item.mean_wave_period} sec`,
                              "Wind Wave Dir./Period": `${item.wind_wave_direction_short} / ${item.wind_wave_period} sec`,
                              "Peak Wave Dir./Period": `${item.peak_wave_direction_short} / ${item.peak_wave_period} sec`,
                            }}
                            icon={<BeaufortIcons conditionId={item.beaufort} />}
                            style={{
                              marginTop: 15,
                              height: "100%",
                              fontSize: 14,
                            }}
                          />
                        );
                      })}
                  </div>
                </div>

                {/* Wind */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    backgroundColor: theme.primary,
                    marginLeft: 5,
                    // height: 350,
                    overflowY: "scroll",
                    width: "50%",

                    padding: 10,
                  }}
                  className="scrollbar-hidden"
                >
                  {/* Title */}
                  <div
                    style={{
                      padding: 5,
                      fontSize: 20,
                      borderBottom: "solid grey 1px",
                      fontWeight: "bold",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>Wind</div>{" "}
                    <div>
                      {(() => {
                        const filteredData = data.filter((item) => {
                          return (
                            item.weekday === weekdays[selectedDay] &&
                            item.city_id === cityIDs[selectedCityId].id
                          );
                        });

                        // if (filteredData.length === 0) {

                        //   setError({
                        //     state: true,
                        //     message: "Data unavailable for this location"
                        //   });
                        //   return null;
                        // }

                        return filteredData[0]?.utc_time
                          ? moment(filteredData[0]?.utc_time).format(
                              "MM/DD/YYYY"
                            )
                          : "No data";
                      })()}
                    </div>
                  </div>

                  {/* Data */}
                  <div style={{ flex: 1, padding: 3 }}>
                    {data.filter((item) => {
                      return (
                        item.weekday === weekdays[selectedDay] &&
                        item.city_id === cityIDs[selectedCityId].id
                      );
                    }).length === 0 && "No data"}
                    {data
                      .filter((item) => {
                        return (
                          item.weekday === weekdays[selectedDay] &&
                          item.day_segment === "Morning" &&
                          item.city_id === cityIDs[selectedCityId].id
                        );
                      })
                      .map((item, index) => {
                        return (
                          <ForecastSpread
                            key={index}
                            header={
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                  fontWeight: "bold",
                                  fontSize: 20,
                                }}
                              >
                                <div>Morning</div>
                                <div style={{ marginRight: 10 }}>
                                  {item.beaufort_desc}
                                </div>
                              </div>
                            }
                            data={{
                              "Wind Speed & Direction": `${item.wind_speed} / ${item.wind_short}`,
                              "Wind Gusts": `${item.wind_gusts}`,
                              "Beaufort Description": `${item.beaufort_desc}`,
                              "Sea Surface Temp": `${item.sea_surface_temp}°F`,
                            }}
                            icon={<BeaufortIcons conditionId={item.beaufort} />}
                            style={{
                              marginTop: 15,
                              height: "100%",
                              fontSize: 14,
                            }}
                          />
                        );
                      })}
                  </div>
                  <div style={{ flex: 1, padding: 3 }}>
                    {data
                      .filter((item) => {
                        return (
                          item.weekday === weekdays[selectedDay] &&
                          item.day_segment === "Afternoon" &&
                          item.city_id === cityIDs[selectedCityId].id
                        );
                      })
                      .map((item, index) => {
                        return (
                          <ForecastSpread
                            key={index}
                            header={
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                  fontWeight: "bold",
                                  fontSize: 20,
                                }}
                              >
                                <div>Afternoon</div>
                                <div style={{ marginRight: 10 }}>
                                  {item.beaufort_desc}
                                </div>
                              </div>
                            }
                            data={{
                              "Wind Speed & Direction": `${item.wind_speed} / ${item.wind_short}`,
                              "Wind Gusts": `${item.wind_gusts}`,
                              "Beaufort Description": `${item.beaufort_desc}`,
                              "Sea Surface Temp": `${item.sea_surface_temp}°F`,
                            }}
                            icon={<BeaufortIcons conditionId={item.beaufort} />}
                            style={{
                              marginTop: 15,
                              height: "100%",
                              fontSize: 14,
                            }}
                          />
                        );
                      })}
                  </div>
                  <div style={{ flex: 1, padding: 3 }}>
                    {data
                      .filter((item) => {
                        return (
                          item.weekday === weekdays[selectedDay] &&
                          item.day_segment === "Evening" &&
                          item.city_id === cityIDs[selectedCityId].id
                        );
                      })
                      .map((item, index) => {
                        return (
                          <ForecastSpread
                            key={index}
                            header={
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                  fontWeight: "bold",
                                  fontSize: 20,
                                }}
                              >
                                <div>Evening</div>
                                <div style={{ marginRight: 10 }}>
                                  {item.beaufort_desc}
                                </div>
                              </div>
                            }
                            data={{
                              "Wind Speed & Direction": `${item.wind_speed} / ${item.wind_short}`,
                              "Wind Gusts": `${item.wind_gusts}`,
                              "Beaufort Description": `${item.beaufort_desc}`,
                              "Sea Surface Temp": `${item.sea_surface_temp}°F`,
                            }}
                            icon={<BeaufortIcons conditionId={item.beaufort} />}
                            style={{
                              marginTop: 15,
                              height: "100%",
                              fontSize: 14,
                            }}
                          />
                        );
                      })}
                  </div>
                  <div style={{ flex: 1, padding: 3 }}>
                    {data
                      .filter((item) => {
                        return (
                          item.weekday === weekdays[selectedDay] &&
                          item.day_segment === "Night" &&
                          item.city_id === cityIDs[selectedCityId].id
                        );
                      })
                      .map((item, index) => {
                        return (
                          <ForecastSpread
                            key={index}
                            header={
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                  fontWeight: "bold",
                                  fontSize: 20,
                                  marginRight: 10,
                                }}
                              >
                                <div>Night</div>
                                <div style={{ marginRight: 10 }}>
                                  {item.beaufort_desc}
                                </div>
                              </div>
                            }
                            data={{
                              "Wind Speed & Direction": `${item.wind_speed} / ${item.wind_short}`,
                              "Wind Gusts": `${item.wind_gusts}`,
                              "Beaufort Description": `${item.beaufort_desc}`,
                              "Sea Surface Temp": `${item.sea_surface_temp}°F`,
                            }}
                            icon={<BeaufortIcons conditionId={item.beaufort} />}
                            style={{
                              marginTop: 15,
                              height: "100%",
                              fontSize: 14,
                            }}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      );
  }
  if (dimensions[0] == 1 && dimensions[1] == 1) {
    if (data)
      return (
        <div style={{ height: "100%" }}>
          <WidgetTimeUpdate route={ROUTE}    />
          {data.length && (
            <div
              className="scrollbar-hidden"
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 10,
                height: "calc(90% - 20px)",
                overflow: "auto",
              }}
            >
              {/* Header */}
              {/* <div style={{ borderBottom: "solid grey 1px" }}>
          {dateTime.toLocaleString()}
        </div> */}

              {/* Drop-down */}
              <div style={{ display: "flex", gap: 20 }}>
                <DropdownSelector
                  options={weekdays}
                  selectedOption={selectedDay}
                  setSelected={(newSelected) => setSelectedDay(newSelected)}
                  triggerStyle={{ width: 300, padding: 10 }}
                  contentStyle={{ width: 300 }}
                />
                <DropdownSelector
                  options={cityIDs.map((item) => item.display)}
                  selectedOption={selectedCityId}
                  setSelected={(newSelected) => setSelectedCityId(newSelected)}
                  triggerStyle={{ width: 300, padding: 10 }}
                  contentStyle={{ width: 300 }}
                />
              </div>

              {/* Content */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 15,
                  height: "calc(100% - 80px)",
                  // height: 350,
                }}
              >
                {/* Wave */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // height: "100%",
                    backgroundColor: theme.primary,
                    marginRight: 5,
                    overflowY: "scroll",
                    padding: 10,
                    height: waveDropdown ? "50%" : "12%",
                    width: "96%",
                  }}
                  className="scrollbar-hidden"
                  onClick={() => setWaveDropdown(!waveDropdown)}
                >
                  {/* Title */}
                  <div
                    style={{
                      padding: 5,
                      fontSize: 20,
                      borderBottom: waveDropdown && "solid grey 1px",
                      fontWeight: "bold",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>Wave</div>{" "}
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {(() => {
                        const filteredData = data.filter((item) => {
                          return (
                            item.weekday === weekdays[selectedDay] &&
                            item.city_id === cityIDs[selectedCityId].id
                          );
                        });

                        return filteredData[0]?.utc_time
                          ? moment(filteredData[0]?.utc_time).format(
                              "MM/DD/YYYY"
                            )
                          : "No data";
                      })()}{" "}
                      <div style={{ marginLeft: 15 }}>
                        {(() => {
                          return waveDropdown ? (
                            <FaChevronUp />
                          ) : (
                            <FaChevronDown />
                          );
                        })()}
                      </div>
                    </div>
                  </div>

                  {/* Data */}
                  {waveDropdown && (
                    <div>
                      <div style={{ flex: 1, padding: 2 }}>
                        {data.filter((item) => {
                          return (
                            item.weekday === weekdays[selectedDay] &&
                            item.city_id === cityIDs[selectedCityId].id
                          );
                        }).length === 0 && "No data"}
                        {data
                          .filter((item) => {
                            return (
                              item.weekday === weekdays[selectedDay] &&
                              item.day_segment === "Morning" &&
                              item.city_id === cityIDs[selectedCityId].id
                            );
                          })
                          .map((item, index) => {
                            return (
                              <ForecastSpread
                                key={index}
                                header={
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    <div>Morning</div>
                                    <div style={{ marginRight: 10 }}>
                                      {item.beaufort_desc}
                                    </div>
                                  </div>
                                }
                                data={{
                                  "Wave Height": `${item.wave_height} ft`,
                                  "Mean Wave Dir./Period": `${item.mean_wave_direction_short} / ${item.mean_wave_period} sec`,
                                  "Wind Wave Dir./Period": `${item.wind_wave_direction_short} / ${item.wind_wave_period} sec`,
                                  "Peak Wave Dir./Period": `${item.peak_wave_direction_short} / ${item.peak_wave_period} sec`,
                                }}
                                icon={
                                  <BeaufortIcons conditionId={item.beaufort} />
                                }
                                style={{
                                  marginTop: 15,
                                  height: "100%",
                                  fontSize: 14,
                                }}
                              />
                            );
                          })}
                      </div>
                      <div style={{ flex: 1, padding: 3 }}>
                        {data
                          .filter((item) => {
                            return (
                              item.weekday === weekdays[selectedDay] &&
                              item.day_segment === "Afternoon" &&
                              item.city_id === cityIDs[selectedCityId].id
                            );
                          })
                          .map((item, index) => {
                            return (
                              <ForecastSpread
                                key={index}
                                header={
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    <div>Afternoon</div>
                                    <div style={{ marginRight: 10 }}>
                                      {item.beaufort_desc}
                                    </div>
                                  </div>
                                }
                                data={{
                                  "Wave Height": `${item.wave_height}ft`,
                                  "Mean Wave Dir./Period": `${item.mean_wave_direction_short} / ${item.mean_wave_period} sec`,
                                  "Wind Wave Dir./Period": `${item.wind_wave_direction_short} / ${item.wind_wave_period} sec`,
                                  "Peak Wave Dir./Period": `${item.peak_wave_direction_short} / ${item.peak_wave_period} sec`,
                                }}
                                icon={
                                  <BeaufortIcons conditionId={item.beaufort} />
                                }
                                style={{
                                  marginTop: 15,
                                  height: "100%",
                                  fontSize: 14,
                                }}
                              />
                            );
                          })}
                      </div>
                      <div style={{ flex: 1, padding: 3 }}>
                        {data
                          .filter((item) => {
                            return (
                              item.weekday === weekdays[selectedDay] &&
                              item.day_segment === "Evening" &&
                              item.city_id === cityIDs[selectedCityId].id
                            );
                          })
                          .map((item, index) => {
                            return (
                              <ForecastSpread
                                key={index}
                                header={
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    <div>Evening</div>
                                    <div style={{ marginRight: 10 }}>
                                      {item.beaufort_desc}
                                    </div>
                                  </div>
                                }
                                data={{
                                  "Wave Height": `${item.wave_height}ft`,
                                  "Mean Wave Dir./Period": `${item.mean_wave_direction_short} / ${item.mean_wave_period} sec`,
                                  "Wind Wave Dir./Period": `${item.wind_wave_direction_short} / ${item.wind_wave_period} sec`,
                                  "Peak Wave Dir./Period": `${item.peak_wave_direction_short} / ${item.peak_wave_period} sec`,
                                }}
                                icon={
                                  <BeaufortIcons conditionId={item.beaufort} />
                                }
                                style={{
                                  marginTop: 15,
                                  height: "100%",
                                  fontSize: 14,
                                }}
                              />
                            );
                          })}
                      </div>
                      <div style={{ flex: 1, padding: 3 }}>
                        {data
                          .filter((item) => {
                            return (
                              item.weekday === weekdays[selectedDay] &&
                              item.day_segment === "Night" &&
                              item.city_id === cityIDs[selectedCityId].id
                            );
                          })
                          .map((item, index) => {
                            return (
                              <ForecastSpread
                                key={index}
                                header={
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    <div>Night</div>
                                    <div style={{ marginRight: 10 }}>
                                      {item.beaufort_desc}
                                    </div>
                                  </div>
                                }
                                data={{
                                  "Wave Height": `${item.wave_height}ft`,
                                  "Mean Wave Dir./Period": `${item.mean_wave_direction_short} / ${item.mean_wave_period} sec`,
                                  "Wind Wave Dir./Period": `${item.wind_wave_direction_short} / ${item.wind_wave_period} sec`,
                                  "Peak Wave Dir./Period": `${item.peak_wave_direction_short} / ${item.peak_wave_period} sec`,
                                }}
                                icon={
                                  <BeaufortIcons conditionId={item.beaufort} />
                                }
                                style={{
                                  marginTop: 15,
                                  height: "100%",
                                  fontSize: 14,
                                }}
                              />
                            );
                          })}
                      </div>
                    </div>
                  )}
                </div>

                {/* Wind */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    backgroundColor: theme.primary,
                    // marginLeft: 5,
                    marginTop: 5,
                    height: 350,
                    overflowY: "scroll",
                    height: windDropdown ? "50%" : "10%",
                    width: "96%",

                    padding: 10,
                  }}
                  className="scrollbar-hidden"
                  onClick={() => setWindDropdown(!windDropdown)}
                >
                  {/* Title */}
                  <div
                    style={{
                      padding: 5,
                      fontSize: 20,
                      borderBottom: windDropdown && "solid grey 1px",
                      fontWeight: "bold",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>Wind</div>{" "}
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {(() => {
                        const filteredData = data.filter((item) => {
                          return (
                            item.weekday === weekdays[selectedDay] &&
                            item.city_id === cityIDs[selectedCityId].id
                          );
                        });

                        return filteredData[0]?.utc_time
                          ? moment(filteredData[0]?.utc_time).format(
                              "MM/DD/YYYY"
                            )
                          : "No data";
                      })()}{" "}
                      <div style={{ marginLeft: 15 }}>
                        {(() => {
                          return waveDropdown ? (
                            <FaChevronUp />
                          ) : (
                            <FaChevronDown />
                          );
                        })()}
                      </div>
                    </div>
                  </div>

                  {/* Data */}
                  {windDropdown && (
                    <div>
                      <div style={{ flex: 1, padding: 3 }}>
                        {data.filter((item) => {
                          return (
                            item.weekday === weekdays[selectedDay] &&
                            item.city_id === cityIDs[selectedCityId].id
                          );
                        }).length === 0 && "No data"}
                        {data
                          .filter((item) => {
                            return (
                              item.weekday === weekdays[selectedDay] &&
                              item.day_segment === "Morning" &&
                              item.city_id === cityIDs[selectedCityId].id
                            );
                          })
                          .map((item, index) => {
                            return (
                              <ForecastSpread
                                key={index}
                                header={
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    <div>Morning</div>
                                    <div style={{ marginRight: 10 }}>
                                      {item.beaufort_desc}
                                    </div>
                                  </div>
                                }
                                data={{
                                  "Wind Speed & Direction": `${item.wind_speed} / ${item.wind_short}`,
                                  "Wind Gusts": `${item.wind_gusts}`,
                                  "Beaufort Description": `${item.beaufort_desc}`,
                                  "Sea Surface Temp": `${item.sea_surface_temp}°F`,
                                }}
                                icon={
                                  <BeaufortIcons conditionId={item.beaufort} />
                                }
                                style={{
                                  marginTop: 15,
                                  height: "100%",
                                  fontSize: 14,
                                }}
                              />
                            );
                          })}
                      </div>
                      <div style={{ flex: 1, padding: 3 }}>
                        {data
                          .filter((item) => {
                            return (
                              item.weekday === weekdays[selectedDay] &&
                              item.day_segment === "Afternoon" &&
                              item.city_id === cityIDs[selectedCityId].id
                            );
                          })
                          .map((item, index) => {
                            return (
                              <ForecastSpread
                                key={index}
                                header={
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    <div>Afternoon</div>
                                    <div style={{ marginRight: 10 }}>
                                      {item.beaufort_desc}
                                    </div>
                                  </div>
                                }
                                data={{
                                  "Wind Speed & Direction": `${item.wind_speed} / ${item.wind_short}`,
                                  "Wind Gusts": `${item.wind_gusts}`,
                                  "Beaufort Description": `${item.beaufort_desc}`,
                                  "Sea Surface Temp": `${item.sea_surface_temp}°F`,
                                }}
                                icon={
                                  <BeaufortIcons conditionId={item.beaufort} />
                                }
                                style={{
                                  marginTop: 15,
                                  height: "100%",
                                  fontSize: 14,
                                }}
                              />
                            );
                          })}
                      </div>
                      <div style={{ flex: 1, padding: 3 }}>
                        {data
                          .filter((item) => {
                            return (
                              item.weekday === weekdays[selectedDay] &&
                              item.day_segment === "Evening" &&
                              item.city_id === cityIDs[selectedCityId].id
                            );
                          })
                          .map((item, index) => {
                            return (
                              <ForecastSpread
                                key={index}
                                header={
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    <div>Evening</div>
                                    <div style={{ marginRight: 10 }}>
                                      {item.beaufort_desc}
                                    </div>
                                  </div>
                                }
                                data={{
                                  "Wind Speed & Direction": `${item.wind_speed} / ${item.wind_short}`,
                                  "Wind Gusts": `${item.wind_gusts}`,
                                  "Beaufort Description": `${item.beaufort_desc}`,
                                  "Sea Surface Temp": `${item.sea_surface_temp}°F`,
                                }}
                                icon={
                                  <BeaufortIcons conditionId={item.beaufort} />
                                }
                                style={{
                                  marginTop: 15,
                                  height: "100%",
                                  fontSize: 14,
                                }}
                              />
                            );
                          })}
                      </div>
                      <div style={{ flex: 1, padding: 3 }}>
                        {data
                          .filter((item) => {
                            return (
                              item.weekday === weekdays[selectedDay] &&
                              item.day_segment === "Night" &&
                              item.city_id === cityIDs[selectedCityId].id
                            );
                          })
                          .map((item, index) => {
                            return (
                              <ForecastSpread
                                key={index}
                                header={
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                      marginRight: 10,
                                    }}
                                  >
                                    <div>Night</div>
                                    <div style={{ marginRight: 10 }}>
                                      {item.beaufort_desc}
                                    </div>
                                  </div>
                                }
                                data={{
                                  "Wind Speed & Direction": `${item.wind_speed} / ${item.wind_short}`,
                                  "Wind Gusts": `${item.wind_gusts}`,
                                  "Beaufort Description": `${item.beaufort_desc}`,
                                  "Sea Surface Temp": `${item.sea_surface_temp}°F`,
                                }}
                                icon={
                                  <BeaufortIcons conditionId={item.beaufort} />
                                }
                                style={{
                                  marginTop: 15,
                                  height: "100%",
                                  fontSize: 14,
                                }}
                              />
                            );
                          })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      );
  }

  if (dimensions[0] == 1 && dimensions[1] == 2) {
    if (data)
      return (
        <div style={{ height: "100%" }}>
          <WidgetTimeUpdate  route={ROUTE}   />
          {data.length && (
            <div
              className="scrollbar-hidden"
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 10,
                height: "calc(90% - 40px)",
                overflow: "auto",
              }}
            >
              {/* Header */}
              {/* <div style={{ borderBottom: "solid grey 1px" }}>
          {dateTime.toLocaleString()}
        </div> */}

              {/* Drop-down */}
              <div style={{ display: "flex", gap: 20 }}>
                <DropdownSelector
                  options={weekdays}
                  selectedOption={selectedDay}
                  setSelected={(newSelected) => setSelectedDay(newSelected)}
                  triggerStyle={{ width: 300, padding: 10 }}
                  contentStyle={{ width: 300 }}
                />
                <DropdownSelector
                  options={cityIDs.map((item) => item.display)}
                  selectedOption={selectedCityId}
                  setSelected={(newSelected) => setSelectedCityId(newSelected)}
                  triggerStyle={{ width: 300, padding: 10 }}
                  contentStyle={{ width: 300 }}
                />
              </div>

              {/* Content */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 15,
                  height: "calc(100% - 80px)",
                  // height: 875,
                }}
              >
                {/* Wave */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // height: "100%",
                    backgroundColor: theme.primary,
                    marginRight: 5,
                    overflowY: "scroll",
                    padding: 10,
                    height: waveDropdown ? "50%" : "5%",
                    width: "96%",
                  }}
                  className="scrollbar-hidden"
                  onClick={() => setWaveDropdown(!waveDropdown)}
                >
                  {/* Title */}
                  <div
                    style={{
                      padding: 5,
                      fontSize: 20,
                      borderBottom: waveDropdown && "solid grey 1px",
                      fontWeight: "bold",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>Wave</div>{" "}
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {(() => {
                        const filteredData = data.filter((item) => {
                          return (
                            item.weekday === weekdays[selectedDay] &&
                            item.city_id === cityIDs[selectedCityId].id
                          );
                        });

                        return filteredData[0]?.utc_time
                          ? moment(filteredData[0]?.utc_time).format(
                              "MM/DD/YYYY"
                            )
                          : "No data";
                      })()}{" "}
                      <div style={{ marginLeft: 15 }}>
                        {(() => {
                          return waveDropdown ? (
                            <FaChevronUp />
                          ) : (
                            <FaChevronDown />
                          );
                        })()}
                      </div>
                    </div>
                  </div>

                  {/* Data */}
                  {waveDropdown && (
                    <div>
                      <div style={{ flex: 1, padding: 2 }}>
                        {data.filter((item) => {
                          return (
                            item.weekday === weekdays[selectedDay] &&
                            item.city_id === cityIDs[selectedCityId].id
                          );
                        }).length === 0 && "No data"}
                        {data
                          .filter((item) => {
                            return (
                              item.weekday === weekdays[selectedDay] &&
                              item.day_segment === "Morning" &&
                              item.city_id === cityIDs[selectedCityId].id
                            );
                          })
                          .map((item, index) => {
                            return (
                              <ForecastSpread
                                key={index}
                                header={
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    <div>Morning</div>
                                    <div style={{ marginRight: 10 }}>
                                      {item.beaufort_desc}
                                    </div>
                                  </div>
                                }
                                data={{
                                  "Wave Height": `${item.wave_height} ft`,
                                  "Mean Wave Dir./Period": `${item.mean_wave_direction_short} / ${item.mean_wave_period} sec`,
                                  "Wind Wave Dir./Period": `${item.wind_wave_direction_short} / ${item.wind_wave_period} sec`,
                                  "Peak Wave Dir./Period": `${item.peak_wave_direction_short} / ${item.peak_wave_period} sec`,
                                }}
                                icon={
                                  <BeaufortIcons conditionId={item.beaufort} />
                                }
                                style={{
                                  marginTop: 15,
                                  height: "100%",
                                  fontSize: 14,
                                }}
                              />
                            );
                          })}
                      </div>
                      <div style={{ flex: 1, padding: 3 }}>
                        {data
                          .filter((item) => {
                            return (
                              item.weekday === weekdays[selectedDay] &&
                              item.day_segment === "Afternoon" &&
                              item.city_id === cityIDs[selectedCityId].id
                            );
                          })
                          .map((item, index) => {
                            return (
                              <ForecastSpread
                                key={index}
                                header={
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    <div>Afternoon</div>
                                    <div style={{ marginRight: 10 }}>
                                      {item.beaufort_desc}
                                    </div>
                                  </div>
                                }
                                data={{
                                  "Wave Height": `${item.wave_height}ft`,
                                  "Mean Wave Dir./Period": `${item.mean_wave_direction_short} / ${item.mean_wave_period} sec`,
                                  "Wind Wave Dir./Period": `${item.wind_wave_direction_short} / ${item.wind_wave_period} sec`,
                                  "Peak Wave Dir./Period": `${item.peak_wave_direction_short} / ${item.peak_wave_period} sec`,
                                }}
                                icon={
                                  <BeaufortIcons conditionId={item.beaufort} />
                                }
                                style={{
                                  marginTop: 15,
                                  height: "100%",
                                  fontSize: 14,
                                }}
                              />
                            );
                          })}
                      </div>
                      <div style={{ flex: 1, padding: 3 }}>
                        {data
                          .filter((item) => {
                            return (
                              item.weekday === weekdays[selectedDay] &&
                              item.day_segment === "Evening" &&
                              item.city_id === cityIDs[selectedCityId].id
                            );
                          })
                          .map((item, index) => {
                            return (
                              <ForecastSpread
                                key={index}
                                header={
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    <div>Evening</div>
                                    <div style={{ marginRight: 10 }}>
                                      {item.beaufort_desc}
                                    </div>
                                  </div>
                                }
                                data={{
                                  "Wave Height": `${item.wave_height}ft`,
                                  "Mean Wave Dir./Period": `${item.mean_wave_direction_short} / ${item.mean_wave_period} sec`,
                                  "Wind Wave Dir./Period": `${item.wind_wave_direction_short} / ${item.wind_wave_period} sec`,
                                  "Peak Wave Dir./Period": `${item.peak_wave_direction_short} / ${item.peak_wave_period} sec`,
                                }}
                                icon={
                                  <BeaufortIcons conditionId={item.beaufort} />
                                }
                                style={{
                                  marginTop: 15,
                                  height: "100%",
                                  fontSize: 14,
                                }}
                              />
                            );
                          })}
                      </div>
                      <div style={{ flex: 1, padding: 3 }}>
                        {data
                          .filter((item) => {
                            return (
                              item.weekday === weekdays[selectedDay] &&
                              item.day_segment === "Night" &&
                              item.city_id === cityIDs[selectedCityId].id
                            );
                          })
                          .map((item, index) => {
                            return (
                              <ForecastSpread
                                key={index}
                                header={
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    <div>Night</div>
                                    <div style={{ marginRight: 10 }}>
                                      {item.beaufort_desc}
                                    </div>
                                  </div>
                                }
                                data={{
                                  "Wave Height": `${item.wave_height}ft`,
                                  "Mean Wave Dir./Period": `${item.mean_wave_direction_short} / ${item.mean_wave_period} sec`,
                                  "Wind Wave Dir./Period": `${item.wind_wave_direction_short} / ${item.wind_wave_period} sec`,
                                  "Peak Wave Dir./Period": `${item.peak_wave_direction_short} / ${item.peak_wave_period} sec`,
                                }}
                                icon={
                                  <BeaufortIcons conditionId={item.beaufort} />
                                }
                                style={{
                                  marginTop: 15,
                                  height: "100%",
                                  fontSize: 14,
                                }}
                              />
                            );
                          })}
                      </div>
                    </div>
                  )}
                </div>

                {/* Wind */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    backgroundColor: theme.primary,
                    // marginLeft: 5,
                    marginTop: 5,
                    height: 350,
                    overflowY: "scroll",
                    height: windDropdown ? "50%" : "5%",
                    width: "96%",

                    padding: 10,
                  }}
                  className="scrollbar-hidden"
                  onClick={() => setWindDropdown(!windDropdown)}
                >
                  {/* Title */}
                  <div
                    style={{
                      padding: 5,
                      fontSize: 20,
                      borderBottom: windDropdown && "solid grey 1px",
                      fontWeight: "bold",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>Wind</div>{" "}
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {(() => {
                        const filteredData = data.filter((item) => {
                          return (
                            item.weekday === weekdays[selectedDay] &&
                            item.city_id === cityIDs[selectedCityId].id
                          );
                        });

                        return filteredData[0]?.utc_time
                          ? moment(filteredData[0]?.utc_time).format(
                              "MM/DD/YYYY"
                            )
                          : "No data";
                      })()}{" "}
                      <div style={{ marginLeft: 15 }}>
                        {(() => {
                          return waveDropdown ? (
                            <FaChevronUp />
                          ) : (
                            <FaChevronDown />
                          );
                        })()}
                      </div>
                    </div>
                  </div>

                  {/* Data */}
                  {windDropdown && (
                    <div>
                      <div style={{ flex: 1, padding: 3 }}>
                        {data.filter((item) => {
                          return (
                            item.weekday === weekdays[selectedDay] &&
                            item.city_id === cityIDs[selectedCityId].id
                          );
                        }).length === 0 && "No data"}
                        {data
                          .filter((item) => {
                            return (
                              item.weekday === weekdays[selectedDay] &&
                              item.day_segment === "Morning" &&
                              item.city_id === cityIDs[selectedCityId].id
                            );
                          })
                          .map((item, index) => {
                            return (
                              <ForecastSpread
                                key={index}
                                header={
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    <div>Morning</div>
                                    <div style={{ marginRight: 10 }}>
                                      {item.beaufort_desc}
                                    </div>
                                  </div>
                                }
                                data={{
                                  "Wind Speed & Direction": `${item.wind_speed} / ${item.wind_short}`,
                                  "Wind Gusts": `${item.wind_gusts}`,
                                  "Beaufort Description": `${item.beaufort_desc}`,
                                  "Sea Surface Temp": `${item.sea_surface_temp}°F`,
                                }}
                                icon={
                                  <BeaufortIcons conditionId={item.beaufort} />
                                }
                                style={{
                                  marginTop: 15,
                                  height: "100%",
                                  fontSize: 14,
                                }}
                              />
                            );
                          })}
                      </div>
                      <div style={{ flex: 1, padding: 3 }}>
                        {data
                          .filter((item) => {
                            return (
                              item.weekday === weekdays[selectedDay] &&
                              item.day_segment === "Afternoon" &&
                              item.city_id === cityIDs[selectedCityId].id
                            );
                          })
                          .map((item, index) => {
                            return (
                              <ForecastSpread
                                key={index}
                                header={
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    <div>Afternoon</div>
                                    <div style={{ marginRight: 10 }}>
                                      {item.beaufort_desc}
                                    </div>
                                  </div>
                                }
                                data={{
                                  "Wind Speed & Direction": `${item.wind_speed} / ${item.wind_short}`,
                                  "Wind Gusts": `${item.wind_gusts}`,
                                  "Beaufort Description": `${item.beaufort_desc}`,
                                  "Sea Surface Temp": `${item.sea_surface_temp}°F`,
                                }}
                                icon={
                                  <BeaufortIcons conditionId={item.beaufort} />
                                }
                                style={{
                                  marginTop: 15,
                                  height: "100%",
                                  fontSize: 14,
                                }}
                              />
                            );
                          })}
                      </div>
                      <div style={{ flex: 1, padding: 3 }}>
                        {data
                          .filter((item) => {
                            return (
                              item.weekday === weekdays[selectedDay] &&
                              item.day_segment === "Evening" &&
                              item.city_id === cityIDs[selectedCityId].id
                            );
                          })
                          .map((item, index) => {
                            return (
                              <ForecastSpread
                                key={index}
                                header={
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    <div>Evening</div>
                                    <div style={{ marginRight: 10 }}>
                                      {item.beaufort_desc}
                                    </div>
                                  </div>
                                }
                                data={{
                                  "Wind Speed & Direction": `${item.wind_speed} / ${item.wind_short}`,
                                  "Wind Gusts": `${item.wind_gusts}`,
                                  "Beaufort Description": `${item.beaufort_desc}`,
                                  "Sea Surface Temp": `${item.sea_surface_temp}°F`,
                                }}
                                icon={
                                  <BeaufortIcons conditionId={item.beaufort} />
                                }
                                style={{
                                  marginTop: 15,
                                  height: "100%",
                                  fontSize: 14,
                                }}
                              />
                            );
                          })}
                      </div>
                      <div style={{ flex: 1, padding: 3 }}>
                        {data
                          .filter((item) => {
                            return (
                              item.weekday === weekdays[selectedDay] &&
                              item.day_segment === "Night" &&
                              item.city_id === cityIDs[selectedCityId].id
                            );
                          })
                          .map((item, index) => {
                            return (
                              <ForecastSpread
                                key={index}
                                header={
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                      marginRight: 10,
                                    }}
                                  >
                                    <div>Night</div>
                                    <div style={{ marginRight: 10 }}>
                                      {item.beaufort_desc}
                                    </div>
                                  </div>
                                }
                                data={{
                                  "Wind Speed & Direction": `${item.wind_speed} / ${item.wind_short}`,
                                  "Wind Gusts": `${item.wind_gusts}`,
                                  "Beaufort Description": `${item.beaufort_desc}`,
                                  "Sea Surface Temp": `${item.sea_surface_temp}°F`,
                                }}
                                icon={
                                  <BeaufortIcons conditionId={item.beaufort} />
                                }
                                style={{
                                  marginTop: 15,
                                  height: "100%",
                                  fontSize: 14,
                                }}
                              />
                            );
                          })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      );
  }
}
