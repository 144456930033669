import React from "react";
import ToggleButton from "./ToggleButton";
import { TbCheck } from "react-icons/tb";

const SettingButton = ({
  displayName,
  settingName,
  displayCheckmark = true,
  deviceSettings,
  device,
  setDeviceSettings,
}) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 5,
      justifyContent: "space-between",
      width: 330,
    }}
  >
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <div style={{ width: 10, marginRight: 15, alignItems: "center" }}>
        {displayCheckmark &&
          !(
            deviceSettings[settingName] === 0 && device[settingName] === null
          ) &&
          deviceSettings[settingName] !== device[settingName] && (
            <TbCheck color="green" size={20} />
          )}
      </div>
      <label>{displayName}:</label>
    </div>

    <div>
      <ToggleButton
        isOff={!deviceSettings[settingName]}
        onClick={() => {
          const newValue = deviceSettings[settingName] === 0 ? 1 : 0;
          setDeviceSettings({
            ...deviceSettings,
            [settingName]: newValue,
          });
        }}
      />
    </div>
  </div>
);

export default SettingButton;
