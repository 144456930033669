import { FaExclamation } from "react-icons/fa";
import * as Weather from "react-icons/wi";

export default function ConditionIcons({ conditionId, size, style }) {
  // console.log("Size:", size);
  // Define the mapping of weather conditions to icons
  const conditionIcons = [
    <Weather.WiDaySunny size={size} style={style} />,
    <Weather.WiDaySunnyOvercast size={size} style={style} />,
    <Weather.WiDayHaze size={size} style={style} />,
    <Weather.WiDayCloudy size={size} style={style} />,
    <Weather.WiDayCloudyHigh size={size} style={style} />,
    <Weather.WiCloud size={size} style={style} />,
    <Weather.WiCloudy size={size} style={style} />,
    <Weather.WiDayFog size={size} style={style} />,
    <Weather.WiFog size={size} style={style} />,
    <Weather.WiFog size={size} style={style} />,
    <Weather.WiSandstorm size={size} style={style} />,
    <Weather.WiDayFog size={size} style={style} />,
    <Weather.WiNightClear size={size} style={style} />,
    <Weather.WiNightAltPartlyCloudy size={size} style={style} />,
    <Weather.WiNightPartlyCloudy size={size} style={style} />,
    <Weather.WiNightCloudyHigh size={size} style={style} />,
    <Weather.WiCloud size={size} style={style} />,
    <Weather.WiSprinkle size={size} style={style} />,
    <Weather.WiShowers size={size} style={style} />,
    <Weather.WiRain size={size} style={style} />,
    <Weather.WiStormShowers size={size} style={style} />,
    <Weather.WiThunderstorm size={size} style={style} />,
    <Weather.WiThunderstorm size={size} style={style} />,
    <Weather.WiHail size={size} style={style} />,
    <Weather.WiTornado size={size} style={style} />,
    <Weather.WiHurricane size={size} style={style} />,
    <Weather.WiSleet size={size} style={style} />,
    <Weather.WiRainMix size={size} style={style} />,
    <Weather.WiSnow size={size} style={style} />,
    <Weather.WiSnow size={size} style={style} />,
    <Weather.WiSnowWind size={size} style={style} />,
    <Weather.WiFlood size={size} style={style} />,
    <Weather.WiDayShowers size={size} style={style} />,
    <Weather.WiNightShowers size={size} style={style} />,
    <Weather.WiNightStormShowers size={size} style={style} />,
  ];

  // Directly use the input to check for the icon
  const icon = conditionIcons[conditionId - 1] ? (
    conditionIcons[conditionId - 1]
  ) : (
    <FaExclamation size={size} style={style} />
  );

  return icon;
}
