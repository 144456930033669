import WaveAndWind from "components/MarineForecast/WaveAndWind";
import TabsHeader from "components/TabsHeader";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as api from "apis/FusionAPI";
import SurfAlerts from "components/MarineForecast/SurfAlerts";
import BuoyConditions from "components/MarineForecast/BuoyConditions";
import TideTable from "components/MarineForecast/TideTable";
import { FixOutputOfEndpointToTheCorrectFormat_TideData } from "utils/utilsBecauseSQLSucks";
import { useAPIData } from "hooks/useAPIData";
import { usePerms } from "hooks/usePerms";
import useDataRefresh from "hooks/useDataRefresh";

const segmentSort = {
  Morning: 1,
  Afternoon: 2,
  Evening: 3,
  Night: 4,
};

export default function MarineForecast() {
  const [params, setParams] = useSearchParams();
  const [tabs, setTabs] = useState();


  const [waveWindData, setWaveWindData] = useState();
  const [surfAlertData, setSurfAlertData] = useState();
  const [buoyConditionsData, setBuoyConditionsData] = useState();
  const [tideData, setTideData] = useState();

  const FusionData = useAPIData();
  const perms = usePerms();
  const navigate = useNavigate();
  const dataRefresh = useDataRefresh();

  useEffect(() => {
    const initTab = [
      params.get("tab") ? parseInt(params.get("tab")) : 0,
      params.get("subtab") ? parseInt(params.get("subtab")) : new Date().getDay(),
    ];
    setTabs([...initTab]);
  }, []);

  useEffect(() => {
    (async () => {
      if (!(await perms.validatePermissions(["MarineForecastEnabled"]))) {
        navigate("/404");
        return;
      }
    })();
  }, []);

  // console.log(tideData);

  const RenderTab = () => {
    if (!tabs) return <div />;

    switch (tabs[0]) {
      case 0:
        return <WaveAndWind 
        // data={waveWindData}
         tab={tabs[1]} />;
      case 1:
        return <SurfAlerts 
        // data={surfAlertData} 
        />;
      case 2:
        return <BuoyConditions 
        // data={buoyConditionsData} 
        />;
      case 3:
        return <TideTable
        //  data={tideData} 
         />;
      default:
        return <div />;
    }
  };

  if (tabs)
    return (
      <div
        className="page-root"
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        {/* header */}
        <TabsHeader //depth = 2
          onTabChange={(newTabArr) => setTabs([...newTabArr])}
          currentTabs={tabs}
          options={[
            //1st depth
            {
              option: "Wave and Wind Conditions",
              options: [
                { option: "Sunday" },
                { option: "Monday" },
                { option: "Tuesday" },
                { option: "Wednesday" },
                { option: "Thursday" },
                { option: "Friday" },
                { option: "Saturday" },
              ],
            },
            {
              option: "Surf Alerts",
            },
            {
              option: "Buoy Conditions",
            },
            {
              option: "Tide Table",
            },
          ]}
          styles={[{}, { backgroundColor: "#436c98" }]}
        />
        <div
          style={{
            height: "calc(100% - 48px)",
            // backgroundColor: "green",
          }}
        >
            {RenderTab()}
        </div>
      </div>
    );
}
