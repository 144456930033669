import React, { useContext, useState } from "react";
import { FaFireAlt } from "react-icons/fa";
import { FaFireFlameSimple } from "react-icons/fa6";
import { BsFire } from "react-icons/bs";
import { FaExclamationCircle } from "react-icons/fa";
import { FaLongArrowAltDown } from "react-icons/fa";
import { FaLongArrowAltUp } from "react-icons/fa";
import moment from "moment";
import { ColorContext, LayoutSizeContext } from "App";

export const FireDangerDay = ({
  data,
  index,
  widgetInfo,
  vertical,
  spacing,
}) => {
  const [expanded, setExpanded] = useState(true);
  // // console.log({data})
  const layout = useContext(LayoutSizeContext);
  const theme = useContext(ColorContext);

  const fireIcons = {
    "0": <FaFireFlameSimple size={64} />,
    "1": <FaFireAlt size={64} />,
    "2": <BsFire size={64} />,
    "3": <BsFire size={64} />,
    "4": <BsFire size={64} />,
  };

  return widgetInfo ? (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: vertical ? "column" : "row",
          justifyContent: "space-around",
          alignItems: "center",
          gap: spacing,
          fontSize: layout.baseFontSize
          
        }}
      >
        <div style={{ marginTop: 10 }}>
          <FaExclamationCircle /> Risk Level: {data["level"]}
        </div>
        <div style={{ marginTop: 10 }}>
          <FaLongArrowAltUp /> Upper Soil Moisture:{" "}
          {data["upper_soil_moisture"]}
        </div>
        <div style={{ marginTop: 10 }}>
          <FaLongArrowAltDown /> Lower Soil Moisture:{" "}
          {data["lower_soil_moisture"]}
        </div>
      </div>
    </div>
  ) : (
    <div
      // key={}
      style={{
        // marginBottom: 15,
        background: theme.base,
        padding: 15,
      }}
    >
      <div
        onClick={() => setExpanded(!expanded)}
        style={{
          fontSize: 25,
          borderBottom: "solid grey 1px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {moment(data.utc_time).format("MM/DD/YYYY")}
        <button>{data["level"]}</button>
      </div>
      {expanded && (
        <div
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <div style={{ marginTop: 10 }}>
                <FaExclamationCircle /> {data["level"]}
              </div>
              <div style={{ marginTop: 10 }}>
                <FaLongArrowAltUp /> Upper Soil Moisture:{" "}
                {data["upper_soil_moisture"]}
              </div>
              <div style={{ marginTop: 10 }}>
                <FaLongArrowAltDown /> Lower Soil Moisture:{" "}
                {data["lower_soil_moisture"]}
              </div>
            </div>

            <div style={{ marginTop: 10 }}>
              {fireIcons[data["danger_level"]]}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
