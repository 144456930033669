import React, { useEffect, useState } from "react";
import { Polygon, Tooltip } from "react-leaflet";
import * as d3 from "d3";
import { flattenPolygon } from "utils/geometryUtils";
import { alertColorScale } from "utils/alertUtils";
import countyJSON from "data/slim_county.json";
import Massive_Unionized_MultiPolygons from "data/Massive_Unionized_MultiPolygons.json";

export default function ZonePolygonAlerts({
  zoneCodeOBJ,
  setSelectedZone,
  setSelectedAlert,
  setMapCenter,
  setSelectedPosition = () => {},
}) {
  const [zonePolygon, setZonePolygon] = useState(null);
  const [highestSeverity, setHighestSeverity] = useState(100);

  useEffect(() => {
    (async () => {
      try {
        const response =
          Massive_Unionized_MultiPolygons[zoneCodeOBJ.zoneCode][0];
        const innerPolygon = flattenPolygon(response);
        const swappedPolygon = innerPolygon.map((coord) => [
          coord[1],
          coord[0],
        ]);
        setZonePolygon(swappedPolygon);
      } catch (e) {
        // console.log(e);
      }
    })();
  }, []);

  useEffect(() => {
    if (zoneCodeOBJ.alerts && zoneCodeOBJ.alerts.length > 0) {
      const highest = zoneCodeOBJ.alerts.reduce((prev, current) => {
        return prev.severity < current.severity ? prev : current;
      });
      setHighestSeverity(highest.severity);
    } else {
      setHighestSeverity(100);
    }
  }, [zoneCodeOBJ.alerts]);

  return (
    zonePolygon && (
      <Polygon
        positions={zonePolygon}
        pathOptions={{
          fillColor: alertColorScale(highestSeverity),
          fillOpacity: 0.5,
        }}
        title={zoneCodeOBJ.zoneCode}
        eventHandlers={{
          click: () => {
            setSelectedAlert(null);
            // console.log("setting zonecodeobj", zoneCodeOBJ);
            setSelectedZone(zoneCodeOBJ);
            const positions = zonePolygon.map(([lat, lng]) => [lng, lat]);
            let xSum = 0;
            let ySum = 0;
            positions.forEach(([x, y]) => {
              xSum += x;
              ySum += y;
            });
            const centroid = [xSum / positions.length, ySum / positions.length];
            setMapCenter(centroid.reverse());
            setSelectedPosition(centroid.reverse());
          },
        }}
      >
        {/* <Tooltip permanent direction="center">
          {zoneCodeOBJ.zoneCode}
        </Tooltip> */}
      </Polygon>
    )
  );
}
