import { MapContainer, TileLayer, Marker, Polygon } from "react-leaflet";
import LocationSetter from "./LocationSetter";
import BoundsSetter from "./BoundsSetter";
import CreatePolygon from "./CreatePolygon";
import L from "leaflet";
import { alertColorScale } from "utils/alertUtils";
import GetCenter from "./GetCenter";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default function MapComponent({
  mapCenter,
  zoom,
  alertData,
  setAlertData,
  isMobile,
  bounds,
  setGetMapCenter,
  jurisdictions,
  widget,
}) {
  const styles = {
    mapStyle: {
      height: widget ? "430px" : "100%",
      width: widget ? "480px" : "100%",
      borderRadius: 5,
      marginLeft: "auto",
      marginRight: "auto",
    },
  };

  return (
    <MapContainer center={mapCenter} zoom={zoom} style={styles.mapStyle}>
      <TileLayer
        url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
        noWrap={true}
      />
      {alertData.coordinates.map((coord, index) => (
        <Marker key={index} position={coord} />
      ))}
      {alertData.coordinates.length > 2 && (
        <Polygon
          pathOptions={{ color: alertColorScale(alertData.severity) }}
          positions={alertData.coordinates}
        />
      )}
      <LocationSetter center={mapCenter} zoom={zoom} />
      <BoundsSetter bounds={bounds} />
      <GetCenter setGetMapCenter={setGetMapCenter} />
      <CreatePolygon alertData={alertData} setAlertData={setAlertData} />
      {jurisdictions &&
        jurisdictions.result.map((jurisdiction) => (
          <Polygon
            positions={JSON.parse(jurisdiction.original_coordinates)[0].map(
              ([longitude, latitude]) => [latitude, longitude]
            )}
            color="purple"
            fillOpacity={0}
          />
        ))}
    </MapContainer>
  );
}
