import { ColorContext } from "App";
import React, { useContext, useEffect, useState } from "react";

const Device = ({ deviceList, setData, data, setError }) => {
  // const [storedData, setStoredData] = useState({
  //   nodeId: "",
  //   deviceType: "",
  //   keyToCheck: "",
  //   keyToCompare: "",
  //   operator: "",
  //   valueToCompare: ""
  // })

  // const [selectedDevice, setSelectedDevice] = useState()
  // const [inputValueType, setInputValueType] = useState(true)

  // const updateData = (item) => {
  //   setStoredData({
  //     ...storedData,
  //     ...item
  //   })
  // }
  // const buildData = (data) => {
  //   setData(data)
  //   return
  //   const builtData =  {
  //     nodeId: data.nodeId,
  //     deviceType: data.deviceType
  //   }
  //   setData(builtData)
  // }

  // useEffect(() => {
  //   buildData(storedData)
  // }, [storedData])

  const theme = useContext(ColorContext);
  
  const AWAREmap = {
    'depth1': 'Depth',
    'baro': 'Pressure',
    'lat': 'Latitude',
    'lon': 'Longitude',
    'elev': 'Elevation',
    'samp': 'Device Sampling mode',
    'mode': 'Device Reporting Mode',
    'calibration': 'Sensor Calibration Offset Value',
    'rssi': 'Signal Strength',
    'hex': 'Raw Data (N/A)',
    'IMEI': 'IMEI number (Non-Unique)',
    'NI': 'Node Indicator',
    'battery': 'Battery Percentage',
    'ffi1': 'Flash Flood Indicator',
    'airTemp': 'Air Temperature',
    // 'ffThreshold': 'Device Threshold (Water Level Rise)',
    'ffTheshold': 'Device Threshold (Water Level Rise)',
    'h2oTemp': 'Water Temperature',
    'calType': 'Device Calibration Type (N/A)',
    'dDetImgEnable': 'Depth detection Imaging (Enabled/Disabled)',
    'depthDet1': 'Depth Detection level 1',
    'depthDet2': 'Depth Detection level 2',
    'depthDet3': 'Depth Detection level 3',
    'depthHoldHours': 'Depth Duration (Hours)',
    'depthInd': 'Depth Detection Indicator (Threshold Met)',
    'dRateImgEnable': 'Drop Rate Imaging (Enabled/Disabled)',
    'drInd': 'Drop Rate Indicator (Threshold Met)',
    'dropThresh': 'Drop Rate Threshold',
    'dropSDI': 'Device Drop Count (SDI-12) Tipping bucket',
    'ffiImgEnable': 'Flash Flood Detection Imaging (Enabled/Disabled)',
    'firstPkt': 'First Packet Indicator (True/False)',
    'gatewayType': 'Network Type (Satellite/Iridium)',
    'gpsSync': 'GPS Sync Success Indicator (Unsuccessful/successful)',
    'hResImgEnable': 'High-Resolution Imaging (Enabled/Disabled)',
    'imgHoldStart': 'Triggered Imaging Hold-Off Start',
    'imgHoldEnd': 'Triggered Imaging Hold-Off End',
    'intensity': 'Rain Intensity',
    'ipAddress': 'IP Address (Manually Set)',
    'port': 'Port (Manually Set)',
    'pCommand': 'Last Command Sent',
    'protocol': 'Last Packet Protocol (N/A)',
    'rainfall': 'Rain Fall Amount (Tipping Bucket)',
    'soilSDI': 'Soil Moisture Reading (SDI-12)',
    'soilTempSDI': 'Soil Temperature Reading (SDI-12)',
    'saltwater': 'Saltwater Adjustment (Enabled/Disabled)',
    'tips': 'Number of Bucket Tips (Tipping Bucket)',
    'pict': 'Latest Picture Name'
  }

  const AWAREUnits = {
    'depth1': 'Inches',
    'baro': 'mBar',
    'lat': 'Coordinate',
    'lon': 'Coordinate',
    'elev': 'Feet',
    'rssi': 'dB',
    'NI': 'Unique integer identifier',
    'battery': 'Voltage',
    'airTemp': '°C',
    'ffThreshold': 'Inches/second',
    'h2oTemp': '°C',
    'depthDet1': 'Inches',
    'depthDet2': 'Inches',
    'depthDet3': 'Inches',
    'depthHoldHours': 'Hours',
    'dropThresh': 'Inches/second',
    'dropSDI': 'Drops',
    'imgHoldStart': 'Hours (UTC)',
    'imgHoldEnd': 'Hours (UTC)',
    'intensity': 'Inches/Hour',
    'rainfall': 'Inches',
    'soilSDI': 'ADC (Analog to Digital Converter)',
    'soilTempSDI': '°C',
    'tips': 'Number of Bucket Tips',
  }

  const AWARErangesAndSteps = {
    'depth1': { range: [0, 100], step: 1 },
  }

  useEffect(() => {
    if (
      data?.nodeId === undefined ||
      data?.deviceType === undefined ||
      data?.keyToCheck === undefined ||
      data?.keyToCompare === undefined ||
      data?.operator === undefined ||
      data?.valueToCompare === undefined ||
      data?.selectedDevice === undefined ||
      data?.inputValueType === undefined
    ) {
      setData({
        nodeId: "",
        deviceType: "",
        keyToCheck: "",
        keyToCompare: "",
        operator: "",
        valueToCompare: "",
        selectedDevice: "",
        inputValueType: false,
        error: true
      });
    }
  }, []);

  const updateData = (item) => {
    setData({
      ...data,
      ...item,
    error: verifyData({...data, ...item})
  })
}

const verifyData = (data) => {
  
  let error = false
  if (data.nodeId === "" || data.deviceType === "" || data.keyToCheck === "" || (data.keyToCompare === "" && data.valueToCompare === "") || data.operator === "" ) {
    error = true
    setError({message: "Action incomplete, check for missing fields."})
  }
  if (!error) {
    setError(null)
  }
  return error
}

  const operators = ["==", "!=", ">", "<", ">=", "<="];

  const deviceTypes = [...new Set(deviceList.map((device) => device.type))];

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div  style={{
            padding: 20,
            borderRadius: 10,
            margin: 10,
            background: theme.primary,
            display: 'flex',
            flexDirection: 'row',
            gap: 20
          }}>
        <div>
        Device Type:{" "}
        <select
          style={{
            background: theme.base,
            padding: 5,
          }}
          onChange={(e) => {
            updateData({ deviceType: e.target.value });
          }}
          value={data.deviceType}
        >
          <option value="">-Select-</option>
          {deviceTypes.map((type) => {
            return <option value={type}>{type}</option>;
          })}
        </select></div>
        {data.deviceType && (
          <div >Device ID:{" "}
          <select
            style={{
              background: theme.base,
              padding: 5,
            }}
            onChange={(e) => {
              // find device by nodeId
              const device = deviceList.find((device) => {
                return device.nodeId.toString() === e.target.value;
              });

              updateData({ nodeId: e.target.value, selectedDevice: device });
            }}
            value={data.nodeId}
          >
            <option value="">-Select-</option>
            {deviceList.map((device) => {
              if (device.type === data.deviceType)
                return <option value={device.nodeId}>{device.nodeId}</option>;
            })}
          </select></div>
        )}
      </div>
      {data.selectedDevice && (
        <div style={{
          display: 'flex',
          padding: 20,
          borderRadius: 10,
          margin: 10,
          marginTop: 0,
          background: theme.primary,
          flexDirection: 'row',
          gap: 20,
          alignItems: 'center'
        }}>
          Value:
          <select
            style={{
              background: theme.base,
              padding: 5,
              width: 200
            }}
            onChange={(e) => {
              updateData({ keyToCheck: e.target.value });
            }}
            value={data.keyToCheck}
          >
            <option value="">-Select-</option>
            {Object.keys(data.selectedDevice.rawData).map((key) => {
              if (AWAREmap[key]) return <option value={key}>{AWAREmap[key]}</option>;
            })}
          </select>
          {data.keyToCheck && (
            <select
              style={{
                background: theme.base,
                padding: 5,
              }}
              onChange={(e) => {
                updateData({ operator: e.target.value });
              }}
              value={data.operator}
            >
              <option value="">-Select-</option>
              {operators.map((operator) => {
                if (operator === "==") return <option value={operator}>=</option>;
                return <option value={operator}>{operator}</option>;
              })}
            </select>
          )}
          {data.operator &&
            (data.inputValueType ? (<div>
              <input
                type="number"
                
                style={{
                  padding: 5,
                  background: theme.base,
                  borderRadius: 5,
                  border: "1px solid grey",
                  marginLeft: 10,
                  width: 100

                }}
                onChange={(e) => {
                  updateData({ valueToCompare: e.target.value });
                }}
                value={data.valueToCompare}
              /> {AWAREUnits[data.keyToCheck] || ""} </div>
            ) : (
              <select
                style={{
                  background: theme.base,
                  padding: 5,
                  width: 200
                }}
                onChange={(e) => {
                  updateData({ keyToCompare: e.target.value });
                }}
                value={data.keyToCompare}
              >
                <option value="">-Select-</option>
                {Object.keys(data.selectedDevice.rawData).map((key) => {
                  if (AWAREmap[key]) return <option value={key}>{AWAREmap[key]}</option>;
           
                })}
              </select>
            ))}
          {data.operator && (
            <div
              onClick={(e) => {
                updateData({ valueToCompare: "", keyToCompare: "", inputValueType: !data.inputValueType });
              }}
              style={{
                cursor: "pointer",
                padding: 5,
                borderRadius: 5,
                border: "1px solid grey",
              }}
            >
              {data.inputValueType
                ? "Change to compare"
                : "Change to custom value"}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Device;
