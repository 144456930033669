import React, { useContext, useEffect, useState } from "react";
import * as api from "apis/FusionAPI";
import PasswordInput from "components/UI/PasswordInput";
import { usePerms } from "hooks/usePerms";
import { ColorContext } from "App";

const NewConnection = ({
  setShowNewConnection,
  setIntegrations,
  initialData,
  setInitialData,
}) => {
  
  const theme = useContext(ColorContext);
  const [data, setData] = useState({
    system_type: "",
    system_name: "",
    user_email: "",
    password: "",
    device_type: "",
    status: 1,
    thumbnail: "",
    name: "",
    alias: "",
    uuid: "",
    nodeId: "",
  });

  const [password, setPassword] = useState("");
  const perms = usePerms();

  useEffect(() => {
    setData({
      ...data,
      password: password,
    });
  }, [password]);

  const systemTypes = ["AWARE"];

  const deviceTypes = {
    "ZENTRA Cloud": [
      "TEROS 12",
      "TEROS 21",
      "TEROS 32",
      "PARIO",
      "HYPROP2",
      "WP4C",
    ],
    Intellisense: ["Micro Weather Station (MWS)"],
    AWARE: ["Flood"],
    USGS: ["Rain", "Stage", "Lake Elevation"],
  };

  const createConnection = async () => {
    if (
      data.user_email.indexOf("@") === -1 ||
      data.user_email.indexOf(".") === -1
    )
      return alert("Invalid email");
    if (
      !data.system_name ||
      !data.user_email ||
      !data.password ||
      !data.system_name ||
      !data.system_type ||
      !data.device_type
    )
      return alert("Please fill all fields");

    let res;
    if (initialData) {
      res = await api.fusionUpdateIntegration(data);
    } else {
      res = await api.fusionAddIntegration(data);
    }
    if (res.status === 200) {
      setShowNewConnection(false);
      setInitialData(null);

      const res = await api.fusionGetIntegrations();
      setIntegrations(res.data.result);
    } else {
      alert("Error creating connection");
    }
  };

  useEffect(() => {
    if (initialData) {
      setData(initialData);
      setPassword(initialData.password);
    }
  }, []);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        color: theme.fontColor,
        background: theme.base,
        padding: 20,
        borderRadius: 10,
        border: "1px solid #ccc",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <div style={{ fontWeight: "bold", fontSize: "1.5rem" }}>New System</div>
        <div
          style={{ cursor: "pointer", fontWeight: "bold", fontSize: "1.5rem" }}
          onClick={() => setShowNewConnection(false)}
        >
          X
        </div>
      </div>

      <form style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <div
          style={{ flex: 1, display: "flex", flexDirection: "column", gap: 10 }}
        >
          <label style={{ fontSize: "1rem" }}>System Type</label>
          <select
            value={data.system_type}
            onChange={(e) => setData({ ...data, system_type: e.target.value })}
            style={{
              background: theme.primary,
              height: 40,
              borderRadius: 10,
              paddingLeft: 10,
              fontSize: "1rem",
            }}
          >
            <option value="">Select System Type</option>
            {systemTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>

          {data.system_type in deviceTypes && (
            <>
              <label style={{ fontSize: "1rem", marginTop: 10 }}>
                Device Type
              </label>
              <select
                value={data.device_type}
                onChange={(e) =>
                  setData({ ...data, device_type: e.target.value })
                }
                style={{
                  background: theme.primary,
                  height: 40,
                  borderRadius: 10,
                  paddingLeft: 15,
                  fontSize: "1rem",
                }}
              >
                <option value="">Select Device Type</option>
                {deviceTypes[data.system_type].map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </>
          )}

          <label style={{ fontSize: "1rem", marginTop: 10 }}>System Name</label>
          <input
            type="text"
            style={{
              background: theme.primary,
              height: 40,
              borderRadius: 10,
              paddingLeft: 15,
              fontSize: "1rem",
            }}
            value={data.system_name}
            onChange={(e) =>
              setData({
                ...data,
                system_name: e.target.value,
              })
            }
          />

          <label style={{ fontSize: "1rem", marginTop: 10 }}>Email</label>
          <input
            type="text"
            style={{
              background: theme.primary,
              height: 40,
              borderRadius: 10,
              paddingLeft: 15,
              fontSize: "1rem",
            }}
            value={data.user_email}
            onChange={(e) =>
              setData({
                ...data,
                user_email: e.target.value,
              })
            }
          />

          <label style={{ fontSize: "1rem", marginTop: 10 }}>Password</label>
          <PasswordInput value={password} callback={setPassword} />

          {process.env.NODE_ENV === "development" &&
            perms.accessLevel === 13 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 30,
                  backgroundColor: "rgba(255,0,0,.5)",
                }}
              >
                <h1>DEV ONLY</h1>
                <label>UUID</label>
                <input
                  type="text"
                  style={{
                    background: theme.primary,
                    height: 40,
                    marginTop: 10,
                    marginBottom: 30,
                    borderRadius: 10,
                    paddingLeft: 15,
                  }}
                  value={data.uuid}
                  onChange={(e) =>
                    setData({
                      ...data,
                      uuid: e.target.value,
                    })
                  }
                />

                <label>Node ID</label>
                <input
                  type="text"
                  style={{
                    background: theme.primary,
                    height: 40,
                    marginTop: 10,
                    marginBottom: 30,
                    borderRadius: 10,
                    paddingLeft: 15,
                  }}
                  value={data.nodeId}
                  onChange={(e) =>
                    setData({
                      ...data,
                      nodeId: e.target.value,
                    })
                  }
                />
                <label>Name</label>
                <input
                  type="text"
                  style={{
                    background: theme.primary,
                    height: 40,
                    marginTop: 10,
                    marginBottom: 30,
                    borderRadius: 10,
                    paddingLeft: 15,
                  }}
                  value={data.name}
                  onChange={(e) =>
                    setData({
                      ...data,
                      name: e.target.value,
                    })
                  }
                />
                <label>Alias</label>
                <input
                  type="text"
                  style={{
                    background: theme.primary,
                    height: 40,
                    marginTop: 10,
                    marginBottom: 30,
                    borderRadius: 10,
                    paddingLeft: 15,
                  }}
                  value={data.alias}
                  onChange={(e) =>
                    setData({
                      ...data,
                      alias: e.target.value,
                    })
                  }
                />
              </div>
            )}

          <div
            style={{
              backgroundColor: data.status === "Online" ? "#01B574" : "",
              border: data.status === "Online" ? "0px" : "1px solid #ccc",
              borderRadius: 8,
              padding: 5,
              width: "fit-content",
              textAlign: "center",
              boxSizing: "border-box",
              cursor: "pointer",
              marginTop: 20,
              fontSize: "1rem",
            }}
            onClick={() =>
              setData({
                ...data,
                status: data.status === "Online" ? "Offline" : "Online",
              })
            }
          >
            {data.status ? "Online" : "Offline"}
          </div>
        </div>
      </form>

      <div
        style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}
      >
        <div
          style={{
            cursor: "pointer",
            backgroundColor: theme.primaryHighlight,
            fontWeight: "bold",
            width: "fit-content",
            padding: "15px 30px",
            borderRadius: 10,
            fontSize: "1rem",
          }}
          onClick={() => createConnection()}
        >
          Create
        </div>
      </div>

      <style jsx>{`
        @media (max-width: 768px) {
          div[style*="flex-direction: column"] {
            flex-direction: column;
          }
          div[style*="flex-direction: row"] {
            flex-direction: column;
          }
          select,
          input {
            width: 100%;
          }
          div[style*="flex-direction: row"] > div {
            margin-top: 10px;
          }
          div[style*="justify-content: flex-end"] {
            justify-content: center;
          }
        }

        @media (max-width: 480px) {
          div[style*="font-size: 1.5rem"] {
            font-size: 1.2rem;
          }
          div[style*="font-size: 1rem"] {
            font-size: 0.9rem;
          }
          input,
          select {
            font-size: 0.9rem;
            height: 35px;
          }
          div[style*="padding: 15px 30px"] {
            padding: 10px 20px;
          }
        }
      `}</style>
    </div>
  );
};

export default NewConnection;
