import React, { useState, useEffect, useRef, useContext } from "react";
import { TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Controls from "./components/Controls";
import { Slider } from "antd";
import BingMapLayer from "./components/BingMapLayer";
import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";
import WidgetMap from "components/WidgetMap";
import FullScreenMap from "components/FullScreenMap";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import { BASE_URL, FUSION_PREFIX } from "apis/FusionAPI";
import Popup from "reactjs-popup";
import { ColorContext } from "App";

const Doppler = ({ widget, dimensions, preview, sidebarOpen }) => {
  const [sliderValue, setSliderValue] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [opacity, setOpacity] = useState(0.7);

  const southWest = [24.396308, -125.0];
  const northEast = [49.384358, -66.93457];
  const bounds = [southWest, northEast];

  const [fetching, setFetching] = useState(false);

  const [userLocation, setUserLocation] = useState(null);
  
  const theme = useContext(ColorContext);

  function roundToNearestTenMinutes(date) {
    const coeff = 1000 * 60 * 10;
    return new Date(Math.round(date.getTime() / coeff) * coeff);
  }

  const handleSliderChange = (value) => {
    setSliderValue(value);
  };

  const frames = Array.from({ length: 12 }, (_, i) => {
    const newDate = roundToNearestTenMinutes(new Date());
    newDate.setMinutes(newDate.getMinutes() + i * 10);
    return newDate;
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setUserLocation([position.coords.latitude, position.coords.longitude]);
      },
      (error) => {
        console.error("Error getting user location", error);
      }
    );
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPlaying) {
        setSliderValue((prevValue) => (prevValue + 1) % frames.length);
      }
    }, 1000 / playbackSpeed);
    return () => clearInterval(interval);
  }, [isPlaying, sliderValue, playbackSpeed]);
  // console.log("Frames and such:", frames, sliderValue);

  if (!widget)
    return (
      <div
        style={{
          // height: window.innerHeight - 226,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          // backgroundColor: "red",
        }}
      >
        <WidgetTimeUpdate route={"Doppler"} updateIntervalBasis={10} addMargin/>
        <div
          style={{
            position: "relative",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            <h1
              style={{
                fontSize: 40,
                textShadow: "2px 2px 4px #000000",
                zIndex: 1000,
                position: "absolute",
                bottom: 100,
                right: 40,
              }}
            >
              {frames[sliderValue].toLocaleTimeString(undefined, {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </h1>
            <div
              style={{
                position: "absolute",
                display: "flex",
                zIndex: 1000,
                backgroundColor: theme.primaryShadow,
                bottom: "100px",
                left: "50px",
                borderRadius: 5,
                padding: 20,
              }}
            >
              <Controls
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                playbackSpeed={playbackSpeed}
                setPlaybackSpeed={setPlaybackSpeed}
                frames={frames}
                sliderValue={sliderValue}
                opacity={opacity}
                setOpacity={setOpacity}
              />
            </div>
            <Slider
              min={0}
              max={11}
              value={sliderValue}
              onChange={handleSliderChange}
              tooltip={{
                formatter: (value) =>
                  frames[value].toLocaleTimeString(undefined, {
                    hour: "2-digit",
                    minute: "2-digit",
                  }),
              }}
              style={{
                position: "absolute",
                zIndex: 1000,
                width: "90%",
                left: "5%",
                bottom: "30px",
              }}
            />
          </div>

          <FullScreenMap
            mapCenter={[39.8283, -98.5795]}
            zoom={5}
            // maxBounds={bounds}
            // maxBoundsViscosity={1.0}
            minZoom={5}
            maxZoom={18}
            satelliteToggleStyle={{ bottom: "190px", left: "50px" }}
          >
            <BingMapLayer
              url={`${BASE_URL}${FUSION_PREFIX}getMapTile/${roundToNearestTenMinutes(
                new Date()
              ).toISOString()}/${frames[
                sliderValue
              ].toISOString()}/future_radar/{q}`}
              opacity={opacity}
              // setFetching={setFetching}
            />
          </FullScreenMap>
        </div>
        <div
          style={{
            backgroundColor: theme.base,
            // marginTop: 20,
          }}
        >
          <div
            style={{
              width: "90%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              gap: 20,
              marginTop: 40,
              zIndex: 1000,
              justifyContent: "center",
              marginLeft: 50,

              marginBottom: 40,
            }}
          >
            Precipitation{" "}
            <div
              style={{
                width: "100%",
                height: 20,
                borderRadius: 10,
                // background: "rgb(64,23,39)",
                background: `linear-gradient(270deg, 
                  #630024 0%, 
                  #F15404 33%, 
                  #DAE600 66%, 
                  #0FB344 100%)`,
              }}
            ></div>
          </div>
        </div>
      </div>
    );

  if (fetching)
    return (
      <WidgetLoadingAnimation widgetName={"Doppler"} dimensions={dimensions} />
    );

  if (widget && dimensions[0] == 1 && dimensions[1] == 1 && userLocation)
    return (
      <div style={{ height: "100%" }}>
        {/* {fetching && (
          <div>
            <WidgetLoadingAnimation
              widgetName={"Doppler"}
              dimensions={dimensions}
            />
          </div>
        )} */}
        <div
          style={{
            display: fetching ? "none" : "flex",
            flexDirection: "column",
          }}
        >
          <WidgetTimeUpdate
            route={"Doppler"}
             
            updateIntervalBasis={10}
          />
          <div style={{ height: "100%" }}>
            <div
              style={{
                position: "absolute",
                zIndex: 1000,
                width: "50%",
                height: 20,
                padding: 5,
                borderRadius: 10,
                display: "flex",
                flexDirection: "row",
                right: 20,
                top: 80,
                justifyContent: "center",
                backgroundColor: theme.primaryShadow,
                gap: 20,
              }}
            >
              Precipitation{" "}
              <div
                style={{
                  width: "70%",
                  height: 20,
                  borderRadius: 10,
                  background: "rgb(64,23,39)",
                  background: `linear-gradient(270deg, 
                #630024 0%, 
                #F15404 33%, 
                #DAE600 66%, 
                #0FB344 100%)`,
                }}
              />
            </div>

            <WidgetMap
              sidebarOpen={sidebarOpen}
              mapCenter={userLocation}
              zoom={5}
              // maxBounds={bounds}
              // maxBoundsViscosity={1.0}
              minZoom={5}
              maxZoom={18}
              satelliteToggleStyle={{ bottom: "52px", left: "5px" }}
              preview={preview}
            >
              <BingMapLayer
                url={`${BASE_URL}${FUSION_PREFIX}getMapTile/${roundToNearestTenMinutes(
                  new Date()
                ).toISOString()}/${frames[
                  sliderValue
                ].toISOString()}/future_radar/{q}`}
                opacity={opacity}
              />
            </WidgetMap>

            <Popup
              trigger={
                <div
                  style={{
                    position: "absolute",
                    bottom: 20,
                    left: 20,
                    background: theme.primaryShadow,
                    zIndex: 1000,
                    padding: 10,
                    borderRadius: 5,
                  }}
                >
                  Animation Controls
                </div>
              }
              on="hover"
              position="top left"
              onhover
            >
              <div
                style={{
                  backgroundColor: theme.primaryShadow,
                  borderTopRightRadius: 40,
                  borderTopLeftRadius: 40,
                  borderBottomRightRadius: 40,
                  flexDirection: "column",
                  display: "flex",

                  position: "absolute",
                  zIndex: 1000,
                  bottom: 0,

                  color: theme.fontColor,
                }}
              >
                <div style={{ padding: 20 }}>
                  <Controls
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    playbackSpeed={playbackSpeed}
                    setPlaybackSpeed={setPlaybackSpeed}
                    frames={frames}
                    sliderValue={sliderValue}
                    opacity={opacity}
                    setOpacity={setOpacity}
                    preview={preview}
                  />
                  <Slider
                    min={0}
                    max={11}
                    value={sliderValue}
                    onChange={handleSliderChange}
                    tooltip={{
                      formatter: (value) =>
                        frames[value].toLocaleTimeString(undefined, {
                          hour: "2-digit",
                          minute: "2-digit",
                        }),
                    }}
                    style={{
                      zIndex: 1000,
                    }}
                  />
                </div>
              </div>
            </Popup>

            <h1
              style={{
                fontSize: 20,
                textShadow: "2px 2px 4px #000000",
                zIndex: 1000,
                position: "absolute",
                bottom: 20,
                right: 20,
              }}
            >
              {frames[sliderValue].toLocaleTimeString(undefined, {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </h1>
          </div>
        </div>
      </div>
    );

  if (widget && dimensions[0] == 2 && dimensions[1] == 1 && userLocation)
    return (
      <div style={{ height: "100%" }}>
        {/* {fetching && (
          <div>
          <WidgetLoadingAnimation
              widgetName={"Doppler"}
              dimensions={dimensions}
            />
          </div>
        )} */}
        <div
          style={{
            display: fetching ? "none" : "flex",
            flexDirection: "column",
          }}
        >
          <WidgetTimeUpdate
            route={"Doppler"}
             
            updateIntervalBasis={10}
          />
          <div style={{ height: "100%" }}>
            <div
              style={{
                position: "absolute",
                zIndex: 1000,
                width: "50%",
                height: 20,
                padding: 5,
                borderRadius: 10,
                display: "flex",
                flexDirection: "row",
                right: 20,
                top: 80,
                justifyContent: "center",
                backgroundColor: theme.primaryShadow,
                gap: 20,
              }}
            >
              Precipitation{" "}
              <div
                style={{
                  width: "70%",
                  height: 20,
                  borderRadius: 10,
                  background: "rgb(64,23,39)",
                  background: `linear-gradient(270deg, 
                #630024 0%, 
                #F15404 33%, 
                #DAE600 66%, 
                #0FB344 100%)`,
                }}
              />
            </div>

            <WidgetMap
              sidebarOpen={sidebarOpen}
              mapCenter={userLocation}
              zoom={5}
              // maxBounds={bounds}
              // maxBoundsViscosity={1.0}
              minZoom={5}
              maxZoom={18}
              satelliteToggleStyle={{ bottom: "52px", left: "5px" }}
              preview={preview}
              longMap
            >
              <BingMapLayer
                url={`${BASE_URL}${FUSION_PREFIX}getMapTile/${roundToNearestTenMinutes(
                  new Date()
                ).toISOString()}/${frames[
                  sliderValue
                ].toISOString()}/future_radar/{q}`}
                opacity={opacity}
              />
            </WidgetMap>

            <Popup
              trigger={
                <div
                  style={{
                    position: "absolute",
                    bottom: 20,
                    left: 20,
                    background: theme.primaryShadow,
                    zIndex: 1000,
                    padding: 10,
                    borderRadius: 5,
                  }}
                >
                  Animation Controls
                </div>
              }
              on="hover"
              position="top left"
              onhover
            >
              <div
                style={{
                  backgroundColor: theme.primaryShadow,
                  borderTopRightRadius: 40,
                  borderTopLeftRadius: 40,
                  borderBottomRightRadius: 40,
                  flexDirection: "column",
                  display: "flex",

                  position: "absolute",
                  zIndex: 1000,
                  bottom: 0,

                  color: theme.fontColor,
                }}
              >
                <div style={{ padding: 20 }}>
                  <Controls
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    playbackSpeed={playbackSpeed}
                    setPlaybackSpeed={setPlaybackSpeed}
                    frames={frames}
                    sliderValue={sliderValue}
                    opacity={opacity}
                    setOpacity={setOpacity}
                    preview={preview}
                  />
                  <Slider
                    min={0}
                    max={11}
                    value={sliderValue}
                    onChange={handleSliderChange}
                    tooltip={{
                      formatter: (value) =>
                        frames[value].toLocaleTimeString(undefined, {
                          hour: "2-digit",
                          minute: "2-digit",
                        }),
                    }}
                    style={{
                      zIndex: 1000,
                    }}
                  />
                </div>
              </div>
            </Popup>

            <h1
              style={{
                fontSize: 20,
                textShadow: "2px 2px 4px #000000",
                zIndex: 1000,
                position: "absolute",
                bottom: 20,
                right: 20,
              }}
            >
              {frames[sliderValue].toLocaleTimeString(undefined, {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </h1>
          </div>
        </div>
      </div>
    );

  if (widget && dimensions[0] == 1 && dimensions[1] == 2 && userLocation)
    return (
      <div style={{ height: "100%" }}>
        {/* {fetching && (
          <div>
          <WidgetLoadingAnimation
              widgetName={"Doppler"}
              dimensions={dimensions}
            />
          </div>
        )} */}
        <div
          style={{
            display: fetching ? "none" : "flex",
            flexDirection: "column",
          }}
        >
          <WidgetTimeUpdate
            route={"Doppler"}
             
            updateIntervalBasis={10}
          />
          <div style={{ height: "100%" }}>
            <div
              style={{
                position: "absolute",
                zIndex: 1000,
                width: "50%",
                height: 20,
                padding: 5,
                borderRadius: 10,
                display: "flex",
                flexDirection: "row",
                right: 20,
                top: 80,
                justifyContent: "center",
                backgroundColor: theme.primaryShadow,
                gap: 20,
              }}
            >
              Precipitation{" "}
              <div
                style={{
                  width: "70%",
                  height: 20,
                  borderRadius: 10,
                  background: "rgb(64,23,39)",
                  background: `linear-gradient(270deg, 
                #630024 0%, 
                #F15404 33%, 
                #DAE600 66%, 
                #0FB344 100%)`,
                }}
              />
            </div>

            <WidgetMap
              sidebarOpen={sidebarOpen}
              mapCenter={userLocation}
              zoom={5}
              // maxBounds={bounds}
              // maxBoundsViscosity={1.0}
              minZoom={5}
              maxZoom={18}
              satelliteToggleStyle={{ bottom: "52px", left: "5px" }}
              preview={preview}
              tallMap
            >
              <BingMapLayer
                url={`${BASE_URL}${FUSION_PREFIX}getMapTile/${roundToNearestTenMinutes(
                  new Date()
                ).toISOString()}/${frames[
                  sliderValue
                ].toISOString()}/future_radar/{q}`}
                opacity={opacity}
              />
            </WidgetMap>

            <Popup
              trigger={
                <div
                  style={{
                    position: "absolute",
                    bottom: 20,
                    left: 20,
                    background: theme.primaryShadow,
                    zIndex: 1000,
                    padding: 10,
                    borderRadius: 5,
                  }}
                >
                  Animation Controls
                </div>
              }
              on="hover"
              position="top left"
              onhover
            >
              <div
                style={{
                  backgroundColor: theme.primaryShadow,
                  borderTopRightRadius: 40,
                  borderTopLeftRadius: 40,
                  borderBottomRightRadius: 40,
                  flexDirection: "column",
                  display: "flex",

                  position: "absolute",
                  zIndex: 1000,
                  bottom: 0,

                  color: theme.fontColor,
                }}
              >
                <div style={{ padding: 20 }}>
                  <Controls
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    playbackSpeed={playbackSpeed}
                    setPlaybackSpeed={setPlaybackSpeed}
                    frames={frames}
                    sliderValue={sliderValue}
                    opacity={opacity}
                    setOpacity={setOpacity}
                    preview={preview}
                  />
                  <Slider
                    min={0}
                    max={11}
                    value={sliderValue}
                    onChange={handleSliderChange}
                    tooltip={{
                      formatter: (value) =>
                        frames[value].toLocaleTimeString(undefined, {
                          hour: "2-digit",
                          minute: "2-digit",
                        }),
                    }}
                    style={{
                      zIndex: 1000,
                    }}
                  />
                </div>
              </div>
            </Popup>

            <h1
              style={{
                fontSize: 20,
                textShadow: "2px 2px 4px #000000",
                zIndex: 1000,
                position: "absolute",
                bottom: 20,
                right: 20,
              }}
            >
              {frames[sliderValue].toLocaleTimeString(undefined, {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </h1>
          </div>
        </div>
      </div>
    );
};

export default Doppler;
