import { ColorContext, UniqueWidgetRefreshContext } from "App";
import DropDownSpread from "components/DropDownSpread";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";
import { useAPIData } from "hooks/useAPIData";
import useDataRefresh from "hooks/useDataRefresh";
import Cookies from "js-cookie";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { BsArrowDown, BsArrowUp, BsClock, BsCompass } from "react-icons/bs";
import { FaEye } from "react-icons/fa";
import { FaTemperatureHalf } from "react-icons/fa6";
import { RiDropLine, RiWindyFill } from "react-icons/ri";
import { WiSprinkle } from "react-icons/wi";
import { DateToString } from "utils/stringUtils";

export default function BuoyConditions({ 
  // data 
}) {
  const currentDateStr = DateToString(new Date());

  const [data, setData] = useState();
  const [fetching, setFetching] = useState(true);
  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      // setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });
  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "getBuoyConditions";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  useEffect(() => {
    async function fetchData() {
      try {
      //get 15 day forecast
      FusionData.forceGet(ROUTE).then((res) => {
        // console.log("buoy conditions res", res);

        if (res?.data?.result) setData([...res.data.result]);
        else setData(null);
        setFetching(false);
      });
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [uwf[UNIQUEKEY]]);

  const theme = useContext(ColorContext);
  const dataRefresh = useDataRefresh();
  useEffect(() => {
    const fetchData = async () => {
      try {FusionData.forceGet(ROUTE).then((res) => {
        // console.log("buoy conditions res", res);

        if (res?.data?.result) setData([...res.data.result]);
        else setData(null);
        setFetching(false);
      });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [dataRefresh.widgetRefresh]);

  if (fetching) {
    return <WidgetLoadingAnimation/>
  }

  return (
    <div
      className="scrollbar-hidden"
      style={{
        overflow: "auto",
        height: "100%",
      }}
    >
      <WidgetTimeUpdate route={ROUTE} dataUpdateOnly/>
      {/* Content - no data */}
      {(!data || data.length == 0) && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: theme.base,
            // marginTop: 15,
            // padding: 15,
          }}
        >
          {/* Header */}
          <div
            style={{
              fontSize: 20,
              borderBottom: "solid grey 1px",
              paddingBottom: 5,
            }}
          >
            {`${currentDateStr.month} ${currentDateStr.day}, ${currentDateStr.year} Buoy Conditions`}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              fontSize: 25,
              fontWeight: "bold",
            }}
          >
            No Data available in this Location
          </div>
        </div>
      )}

      {/* Content - data */}

      {data &&
        data.length > 0 &&
        data.map((item, index) => {
          return (
            <DropDownSpread
              key={index}
              style={{}}
              title={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div>{item.buoy_name}</div>
                  <div>{moment(item.utcTime).format("MM/DD/YYYY")}</div>
                </div>
              }
              data={{
                "Wind SP": `${item.wind_speed} MPH`,
                "Dew Point": `${item.dew_point}° F`,
                "Wave Period": item.wave_period,
                "Wave Height": item.wave_height,
                "Water Temp": `${item.water_temperature}° F`,
                "Barometric pressre": `${item.baro_pressure} in`,
                "Air Temp": `${item.air_temperature}° F`,
                "Wind Direction": item.wind_short,
                Visibility: `${item.visibility} mi`,
              }}
              dataIcons={[
                <RiWindyFill />,
                <WiSprinkle />,
                <BsClock />,
                <BsArrowUp />,
                <RiDropLine />,
                <BsArrowDown />,
                <FaTemperatureHalf />,
                <BsCompass />,
                <FaEye />,
              ]}
            />
          );
        })}
    </div>
  );
}
