import { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import axios from "axios";
import Cookies from "js-cookie";

// Define your BingLayer extension outside of the component to avoid redefining it on each render
const BingLayer = L.TileLayer.extend({
  createTile: function (tilePoint, done) {
    const zoom = this._getZoomForUrl();
    const bounds = this._tileCoordsToBounds(tilePoint, zoom);

    // Check if the tile is within the CONUS bounding box
    const isInCONUS = this._isWithinCONUS(bounds);

    // If not within CONUS, return a blank tile (or set opacity to 0)
    if (!isInCONUS) {
      // You can return a transparent 1x1 pixel image to make it "invisible"
      const tile = document.createElement("img");
      tile.src =
        "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw=="; // Transparent image
      done(null, tile);
      return tile;
    }

    const tile = document.createElement("img");
    tile.className = "custom-tile-class";
    const tileUrl = this._url.replace(
      "{q}",
      this._quadKey(tilePoint.x, tilePoint.y, zoom),
    );

    axios
      .get(tileUrl, {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          AuthRefresh: `Bearer ${Cookies.get("refreshToken")}`,
        },
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "image/jpeg" });
        const objectUrl = URL.createObjectURL(blob);
        tile.src = objectUrl;
        done(null, tile);
      })
      .catch((error) => done(error, tile));

    return tile;
  },

  _tileCoordsToBounds: function (tilePoint, zoom) {
    const nwPoint = tilePoint.scaleBy(this.getTileSize());
    const sePoint = nwPoint.add(this.getTileSize());

    const nwLatLng = this._map.unproject(nwPoint, zoom);
    const seLatLng = this._map.unproject(sePoint, zoom);

    return L.latLngBounds(nwLatLng, seLatLng);
  },

  _isWithinCONUS: function (bounds) {
    const conusBounds = L.latLngBounds(
      L.latLng(24.396308, -125.0), // Southwest corner
      L.latLng(49.384358, -66.93457), // Northeast corner
    );

    return conusBounds.intersects(bounds);
  },

  _quadKey: function (x, y, z) {
    let quadKey = [];
    for (let i = z; i > 0; i--) {
      let digit = 0;
      let mask = 1 << (i - 1);
      if ((x & mask) !== 0) {
        digit += 1;
      }
      if ((y & mask) !== 0) {
        digit += 2;
      }
      quadKey.push(digit.toString());
    }
    return quadKey.join("");
  },
});

const BingMapLayer = ({ url, attribution, opacity }) => {
  const map = useMap();
  const [layersCache, setLayersCache] = useState({});

  useEffect(() => {
    const layerKey = url;

    // console.log("fucking work please:", layersCache[layerKey]);
    //check for empty tiles
    if (
      layersCache[layerKey] &&
      Object.values(layersCache[layerKey]._tiles).length == 0
    ) {
      setLayersCache((prevCache) => {
        const newCache = { ...prevCache };
        delete newCache[layerKey];
        return newCache;
      });
    }

    //create new layer in cache
    if (!layersCache[layerKey]) {
      const newLayer = new BingLayer(url, {});

      setLayersCache((prevCache) => ({ ...prevCache, [layerKey]: newLayer }));

      map.addLayer(newLayer);
    }

    Object.keys(layersCache).forEach((key) => {
      if (layerKey === key) {
        layersCache[key].setOpacity(opacity);
      } else {
        layersCache[key].setOpacity(0);
      }
    });

    // setFetching(!layersCache);
  }, [map, url, attribution, layersCache]);

  //clean up map layers
  useEffect(() => {
    return () => {
      Object.values(layersCache).forEach((layer) => {
        if (map.hasLayer(layer)) {
          map.removeLayer(layer);
        }
      });
    };
  }, []);

  return null;
};

export default BingMapLayer;
