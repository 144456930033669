import React, { useContext, useState } from 'react'

import * as api from "../apis/FusionAPI";
import { ColorContext } from 'App';

export const ForgotPassword = () => {
  
  const theme = useContext(ColorContext);
  const [resetEmail, setResetEmail] = useState('');
  const [resetMessage, setResetMessage] = useState('');
  const onPasswordReset = async () => {
    
    if (!resetEmail) { return; }
    try {
      const res = await api.sendResetEmail(resetEmail);
      // console.log("password reset", res);
      setResetMessage("Password reset email sent.");
    } catch (e) {
      // console.log("error resetting password", e);
      setResetMessage("Error sending reset email.");
    }
  }
  return (
    <div>
      <div style={{
        backgroundColor: theme.base,
        width: 'max-content',
        display:'flex',
        flexDirection: 'column',
        alignItems:'flex-start',
        padding: 10
      }}>
      Email Address
      <input
        style={{
          padding: 10, 
          borderRadius: 10, 
          backgroundColor: theme.primary, 
          color: theme.fontColor, 
          marginTop: 5, 
          width: 400
        }}
        onChange={(e) => setResetEmail(e.target.value)}
        value={resetEmail}
      >
      </input>
      <button
        style={{
          padding: 10, 
          borderRadius: 10, 
          backgroundColor: theme.primary, 
          color: theme.fontColor, 
          marginTop: 10, 
        
        }}
        onClick={() => onPasswordReset()}
      >Request Password Reset</button>
      {resetMessage && <div style={{marginTop: 10}}>{resetMessage}</div>}
      </div>
    </div>
  )
}
