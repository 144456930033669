import AlertCreator from "components/AlertCreator/AlertCreator";
import Cookies from "js-cookie";
import CreateRole from "pages/Admin/CreateRole";
import CreateUser from "pages/Admin/CreateUser";
import EditRole from "pages/Admin/EditRole";
import EditUser from "pages/Admin/EditUser";
import RoleManager from "pages/Admin/RoleManager";
import AIAnalytics from "pages/Overwatch/AIAnalytics";
import AlarmHistory from "pages/Overwatch/AlarmHistory";
import PendingAlarms from "pages/Overwatch/PendingAlarms";
import PIAlerts from "pages/Public Info/PIAlerts";
import Apparatus from "pages/Responder/Apparatus";
import DispatcherChat from "pages/Responder/DispatcherChat";
import Employees from "pages/Responder/Employees";
import FullMap from "pages/Responder/FullMap";
import Incidents from "pages/Responder/Incidents";
import Connections from "pages/System/Connections";
import Devices from "pages/System/Devices";
import GlobalActions from "pages/System/GlobalActions";
import Help from "pages/System/Help";
import ManageSocials from "pages/System/ManageSocials";
import Settings from "pages/System/Settings";
import UserMenu from "pages/UserMenu";
import Alerts from "pages/Weather/Alerts";
import Charts from "pages/Weather/Charts";
import Maps from "pages/Weather/Maps";
import MarineForecast from "pages/Weather/MarineForecast";
import SpecialReports from "pages/Weather/SpecialReports";
import StormCenter from "pages/Weather/StormCenter";
import WeatherForecast from "pages/Weather/WeatherForecast";
import React from "react";
import { LicenseInformation } from "pages/System/LicenseInformation";
import Organization from "pages/Settings/Organization";

//imported images
import overwatchIcon from "../assets/nIcons/Overwatch.png";
import pendingAlarmsIcon from "../assets/nIcons/Pending Alarms.png";
import aiAnalyticsIcon from "../assets/nIcons/AI Analytics.png";
import globalActionsIcon from "../assets/nIcons/Global Actions.png";
import connectedDevicesIcon from "../assets/nIcons/Connected Devices.png";
import responderIcon from "../assets/nIcons/Responder.png";
import fullMapIcon from "../assets/nIcons/Maps.png";
import incidentsIcon from "../assets/nIcons/Weather Alerts.png";
import employeesIcon from "../assets/nIcons/Employees.png";
import apparatusIcon from "../assets/nIcons/Apparatus.png";
import dispatchChatIcon from "../assets/nIcons/Dispatcher Chat.png";
import weatherIcon from "../assets/nIcons/Weather.png";
import weatherAlertsIcon from "../assets/nIcons/Weather Alerts.png";
import forecastIcon from "../assets/nIcons/Forecast.png";
import mapsIcon from "../assets/nIcons/Maps.png";
import stormCenterIcon from "../assets/nIcons/Storm Center.png";
import marineForecastIcon from "../assets/nIcons/Marine Forecast.png";
import specialReportsIcon from "../assets/nIcons/Special Reports.png";
import chartsIcon from "../assets/nIcons/Charts.png";
import publicInfoIcon from "../assets/nIcons/Public Info.png";
import publicAlertsIcon from "../assets/nIcons/Weather Alerts.png";
import systemIcon from "../assets/nIcons/System.png";
import connectionsIcon from "../assets/nIcons/Integrations.png";
import globalActions2Icon from "../assets/nIcons/Global Actions.png";
import manageSocialsIcon from "../assets/nIcons/Manage Socials.png";
import manageRolesIcon from "../assets/nIcons/Roles.png";
// import licenseAdminIcon from "../assets/nIcons/Vector-21.png";
import settingsIcon from "../assets/nIcons/Settings.png";
import helpIcon from "../assets/nIcons/Help.png";
import createAlertIcon from "../assets/nIcons/Create Alert.png";
import usersIcon from "../assets/nIcons/Users.png";
import alarmHistoryIcon from "../assets/nIcons/Alarm History.png";
// import integrationIcon from "../assets/icon/"
import organizationIcon from "../assets/nIcons/Organization.png";
import licenseIcon from "../assets/nIcons/License Admin.png";
import auditLogIcon from "../assets/nIcons/Audit Logs.png";
import { FaExclamation, FaPlus } from "react-icons/fa";
import AuditLogs from "pages/Admin/AuditLogs";
import { Page404 } from "pages/404";
import UserManager from "pages/Admin/UserManager";

//adjust to accept undefined permission values

export function GetRouteData(perms, widgetRefresh) {
  return [
    {
      name: "Overwatch",
      icon: () => {
        return <img src={overwatchIcon} style={{ width: 30 }} />;
      }, //no icon = no button
      enabled: true, //enabled is referring to the sidebar button
      opened: false,
      permission: () =>
        perms.testPermission([8, 10, 11, 12, 13], ["OverwatchEnabled"]),
      perm: "OverwatchEnabled",
      options: [
        {
          name: "Pending Alarms",
          icon: () => {
            return <img src={pendingAlarmsIcon} style={{ width: 30 }} />;
          },
          enabled: true,
          href: "/overwatch/pendingalarms",
          permission: () =>
            perms.testPermission([8, 10, 11, 12, 13], ["PendingAlarms"]),
          perm: "PendingAlarms",
          page: <PendingAlarms />,
        },
        {
          name: "Alarm History",
          icon: () => {
            return <img src={alarmHistoryIcon} style={{ width: 34 }} />;
          },
          enabled: true,
          href: "/overwatch/alarmhistory",
          permission: () =>
            perms.testPermission([8, 10, 11, 12, 13], ["AlarmHistory"]),
          perm: "AlarmHistory",
          page: <AlarmHistory />,
        },
        {
          name: "AI Analytics",
          icon: () => {
            return <img src={aiAnalyticsIcon} style={{ width: 30 }} />;
          },
          enabled: true,
          href: "/overwatch/aianalytics",
          permission: () =>
            perms.testPermission([8, 10, 11, 12, 13], ["AIAnalytics"]),
          perm: "AIAnalytics",
          page: <AIAnalytics />,
        },
        {
          name: "Global Actions",
          icon: () => {
            return <img src={globalActionsIcon} style={{ width: 30 }} />;
          },
          enabled: true,
          href: "/system/globalactions",
          permission: () => {
            return (
              perms.testPermission(
                [8, 10, 11, 12, 13],
                ["GlobalActionsEnabled"]
              ) &&
              perms.testPermission([8, 10, 11, 12, 13], ["OverwatchEnabled"])
            );
          },
          perm: "GlobalActions",
          page: <GlobalActions />,
        },
        {
          name: "Connected Devices",
          icon: () => {
            return <img src={connectedDevicesIcon} style={{ width: 30 }} />;
          },
          enabled: true,
          href: "/system/devices",
          permission: () => {
            return (
              perms.testPermission(
                [8, 10, 11, 12, 13],
                ["ConnectionsEnabled"]
              ) &&
              perms.testPermission([8, 10, 11, 12, 13], ["OverwatchEnabled"])
            );
          },
          perm: "ConnectedDevices",
          page: <Devices />,
        },
      ],
    },
    {
      name: "Responder",
      icon: () => {
        return <img src={responderIcon} style={{ width: 30 }} />;
      },
      enabled: true,
      opened: false,
      permission: () =>
        perms.testPermission([8, 10, 11, 12, 13], ["ResponderEnabled"]),
      perm: "ResponderEnabled",
      options: [
        {
          name: "Full Map",
          icon: () => {
            return <img src={fullMapIcon} style={{ width: 30 }} />;
          },
          enabled: true,
          href: "/responder/full-map",
          permission: () =>
            perms.testPermission([8, 10, 11, 12, 13], ["FullMap"]),
          perm: "FullMap",
          page: <FullMap />,
        },
        {
          name: "Incidents",
          icon: () => {
            return <img src={incidentsIcon} style={{ width: 30 }} />;
          },
          enabled: true,
          href: "/responder/incidents",
          permission: () =>
            perms.testPermission([8, 10, 11, 12, 13], ["Incidents"]),
          perm: "Incidents",
          page: <Incidents />,
        },
        {
          name: "Employees",
          icon: () => {
            return <img src={employeesIcon} style={{ width: 30 }} />;
          },
          enabled: true,
          href: "/responder/employees",
          permission: () =>
            perms.testPermission([8, 10, 11, 12, 13], ["Employees"]),
          perm: "Employees",
          page: <Employees />,
        },
        {
          name: "Apparatus",
          icon: () => {
            return <img src={apparatusIcon} style={{ width: 30 }} />;
          },
          enabled: true,
          href: "/responder/apparatus",
          permission: () =>
            perms.testPermission([8, 10, 11, 12, 13], ["Apparatus"]),
          perm: "Apparatus",
          page: <Apparatus />,
        },
        {
          name: "Dispatcher Chat",
          icon: () => {
            return <img src={dispatchChatIcon} style={{ width: 30 }} />;
          },
          enabled: true,
          href: "/responder/dispatchchat",
          permission: () =>
            perms.testPermission([8, 10, 11, 12, 13], ["DispatcherChat"]),
          perm: "DispatcherChat",
          page: <DispatcherChat />,
        },
      ],
    },
    {
      name: "Weather",
      icon: () => {
        return <img src={weatherIcon} style={{ width: 30 }} />;
      },
      enabled: true,
      opened: false,
      permission: () =>
        perms.testPermission([8, 10, 11, 12, 13], ["WeatherEnabled"]),
      perm: "WeatherEnabled",
      options: [
        {
          name: "Weather Alerts",
          icon: () => {
            return <img src={weatherAlertsIcon} style={{ width: 30 }} />;
          },
          enabled: true,
          href: "/weather/alerts",
          permission: () =>
            perms.testPermission([8, 10, 11, 12, 13], ["WeatherAlerts"]),
          perm: "WeatherAlerts",
          page: <Alerts />,
        },
        {
          name: "Forecast",
          icon: () => {
            return <img src={forecastIcon} style={{ width: 30 }} />;
          },
          enabled: true,
          href: "/weather/forecast",
          permission: () =>
            perms.testPermission([8, 10, 11, 12, 13], ["ForecastEnabled"]),
          perm: "ForecastEnabled",
          page: <WeatherForecast widgetRefresh={widgetRefresh} />,
        },
        {
          name: "Maps",
          icon: () => {
            return <img src={mapsIcon} style={{ width: 30 }} />;
          },
          enabled: true,
          href: "/weather/maps",
          permission: () =>
            perms.testPermission([8, 10, 11, 12, 13], ["MapsEnabled"]),
          perm: "MapsEnabled",
          page: <Maps />,
        },
        {
          name: "Storm Center",
          icon: () => {
            return <img src={stormCenterIcon} style={{ width: 30 }} />;
          },
          enabled: true,
          href: "/weather/stormcenter",
          permission: () =>
            perms.testPermission([8, 10, 11, 12, 13], ["StormCenterEnabled"]),
          perm: "StormCenterEnabled",
          page: <StormCenter />,
        },
        {
          name: "Marine Forecast",
          icon: () => {
            return <img src={marineForecastIcon} style={{ width: 30 }} />;
          },
          enabled: true,
          href: "/weather/marineforecast",
          permission: () =>
            perms.testPermission(
              [8, 10, 11, 12, 13],
              ["MarineForecastEnabled"]
            ),
          perm: "MarineForecastEnabled",
          page: <MarineForecast />,
        },
        {
          name: "Special Reports",
          icon: () => {
            return <img src={specialReportsIcon} style={{ width: 30 }} />;
          },
          enabled: true,
          href: "/weather/specialreports",
          permission: () =>
            perms.testPermission(
              [8, 10, 11, 12, 13],
              ["SpecialReportsEnabled"]
            ),
          perm: "SpecialReportsEnabled",
          page: <SpecialReports />,
        },
        {
          name: "Charts",
          icon: () => {
            return <img src={chartsIcon} style={{ width: 30 }} />;
          },
          enabled: true,
          href: "/weather/charts",
          permission: () =>
            perms.testPermission([8, 10, 11, 12, 13], ["ChartsEnabled"]),
          perm: "ChartsEnabled",
          page: <Charts />,
        },
      ],
    },
    {
      name: "Public Info",
      icon: () => {
        return <img src={publicInfoIcon} style={{ width: 30 }} />;
      },
      enabled: true,
      opened: false,
      permission: () =>
        perms.testPermission([8, 10, 11, 12, 13], ["PublicInfoEnabled"]),
      perm: "PublicInfoEnabled",
      options: [
        {
          name: "Active Alerts",
          icon: () => {
            return (
              <img src={publicAlertsIcon} style={{ padding: 2, width: 30 }} />
            );
          },
          enabled: true,
          href: "/publicuser/alerts",
          permission: () =>
            perms.testPermission([8, 10, 11, 12, 13], ["ViewAlerts"]),
          perm: "ViewAlerts",
          page: <PIAlerts />,
        },
        {
          name: "Create Alert",
          icon: () => {
            return <img src={createAlertIcon} style={{ width: 34 }} />;
          },
          enabled: true,
          href: "/publicuser/createalert",
          permission: () =>
            perms.testPermission([8, 10, 11, 12, 13], ["Create-EditAlerts"]),
          perm: "Create-EditAlerts",
          page: <AlertCreator />,
        },
      ],
    },
    {
      name: "System",
      icon: () => {
        return <img src={systemIcon} style={{ width: 30 }} />;
      },
      enabled: true,
      opened: false,
      permission: () =>
        perms.testPermission([8, 10, 11, 12, 13], ["SystemEnabled"]),
      perm: "SystemEnabled",
      options: [
        {
          name: "Integrations",
          icon: () => {
            return <img src={connectionsIcon} style={{ width: 30 }} />;
          },
          enabled: true,
          href: "/system/integrations",
          permission: () =>
            perms.testPermission([8, 10, 11, 12, 13], ["ConnectionsEnabled"]),
          perms: "ConnectionsEnabled",
          page: <Connections />,
        },
        {
          name: "Global Actions",
          icon: () => {
            return <img src={globalActions2Icon} style={{ width: 30 }} />;
          },
          enabled: true,
          href: "/system/globalactions",
          permission: () =>
            perms.testPermission([8, 10, 11, 12, 13], ["GlobalActionsEnabled"]),
          perm: "GlobalActionsEnabled",
          page: <GlobalActions />,
        },
        {
          name: "Manage Socials",
          icon: () => {
            return <img src={manageSocialsIcon} style={{ width: 30 }} />;
          },
          enabled: true,
          href: "/system/managesocials",
          permission: () =>
            perms.testPermission([8, 10, 11, 12, 13], ["ManageSocialsEnabled"]),
          perm: "ManageSocialsEnabled",
          page: <ManageSocials />,
        },
      ],
    },

    {
      name: "Settings",
      icon: () => {
        return <img src={settingsIcon} style={{ width: 30 }} />;
      },
      enabled: true,
      opened: false,
      permission: () => perms.testPermission([8, 10, 11, 12, 13]),
      perm: "SystemEnabled",
      options: [
        {
          name: "Organization",
          icon: () => {
            return <img src={organizationIcon} style={{ width: 30 }} />;
          },
          enabled: true,
          href: "/system/organization",
          permission: () =>
            perms.testPermission(
              [8, 10, 11, 12, 13],
              ["ManageOrganizationEnabled"]
            ),
          perm: "ManageOrganizationEnabled",
          page: <Organization />,
        },
        {
          name: "Roles",
          icon: () => {
            return <img src={manageRolesIcon} style={{ width: 30 }} />;
          },
          enabled: true,
          href: "/system/rolemanager",
          permission: () =>
            perms.testPermission([8, 10, 11, 12, 13], ["ManageRolesEnabled"]),
          perm: "ManageRolesEnabled",
          page: <RoleManager />,
        },
        {
          name: "Create Role",
          icon: () => {
            return <img src={manageRolesIcon} style={{ width: 30 }} />;
          },
          enabled: false,
          href: "/system/createrole",
          permission: () =>
            perms.testPermission([8, 10, 11, 12, 13], ["CreateRole"]),
          perm: "CreateRole",
          page: <CreateRole />,
          hideSidebar: true,
        },
        {
          name: "Edit Role",
          icon: () => {
            return <img src={manageRolesIcon} style={{ width: 30 }} />;
          },
          enabled: false,
          href: "/system/editrole",
          permission: () =>
            perms.testPermission([8, 10, 11, 12, 13], ["EditRole"]),
          perm: "EditRole",
          page: <EditRole />,
          hideSidebar: true,
        },
        // {
        //   name: "Users",
        //   icon: () => {
        //     return <img src={usersIcon} style={{ width: 30 }} />;
        //   },
        //   enabled: true,
        //   href: "/system/rolemanager?tab=1",
        //   permission: () =>
        //     perms.testPermission([8, 10, 11, 12, 13], ["ManageRolesEnabled"]),
        //   perm: "ManageRolesEnabled",
        //   page: <RoleManager />,
        // },
        {
          name: "Users",
          icon: () => {
            return <img src={usersIcon} style={{ width: 40 }} />;
          },
          enabled: false,
          href: "/system/createuser",
          permission: () =>
            perms.testPermission([8, 10, 11, 12, 13], ["FusionSignUpEnabled"]),
          perm: "FusionSignUpEnabled",
          page: <UserManager />,
        },
        {
          name: "Edit User",
          icon: () => {
            return <img src={usersIcon} style={{ width: 40 }} />;
          },
          enabled: false,
          href: "/system/edituser",
          permission: () =>
            perms.testPermission([8, 10, 11, 12, 13], ["Create-EditUsers"]),
          perm: "Create-EditUsers",
          page: <EditUser />,
          hideSidebar: true,
        },
        {
          name: "License Admin",
          icon: () => {
            return <img src={licenseIcon} style={{ width: 30 }} />;
          },
          enabled: true,
          href: "/system/licenseAdmin",
          permission: () => perms.testPermission([8, 11, 13]),
          perm: null,
          page: <LicenseInformation inline />,
        },
        {
          name: "Audit Logs",
          icon: () => {
            return <img src={auditLogIcon} style={{ width: 30 }} />;
          },
          enabled: true,
          href: "/system/auditlogs",
          permission: () => perms.testPermission([8, 11, 13]),
          perm: null,
          page: <AuditLogs />,
        },
        // {
        //   name: "Settings",
        //   icon: () => {
        //     return <img src={settingsIcon} style={{ width: 30 }} />;
        //   },
        //   enabled: true,
        //   href: "/system/settings",
        //   permission: () => perms.testPermission([8, 10, 11, 12, 13]),
        //   perm: null,
        //   page: <Settings />,
        // },
        {
          name: "Help",
          icon: () => {
            return <img src={helpIcon} style={{ width: 30 }} />;
          },
          enabled: true,
          href: "/system/help",
          permission: () => perms.testPermission([8, 10, 11, 12, 13]),
          perm: null,
          page: <Help />,
        },
        {
          name: "User Menu",
          icon: () => {
            return <img src={manageRolesIcon} style={{ width: 40 }} />;
          },
          enabled: false,
          href: "/usermenu",
          permission: () => perms.testPermission([8, 10, 11, 12, 13]),
          perm: null,
          page: <UserMenu />,
          hideSidebar: true,
        },
        {
          name: "Page Not Found",
          enabled: false,
          icon: () => {
            return <img src={helpIcon} style={{ width: 30 }} />;
          },
          href: "/404",
          permission: () => perms.testPermission([8, 10, 11, 12, 13]),
          perm: null,
          page: <Page404 />,
          hideSidebar: true,
        },
      ],
    },
  ];
}

// export function perms.testPermission(accessLevels, permissions = [], perm = null) {
//   const accessLevel = Cookies.get("accessLevel");
//   // const perms = usePerms();
//   // const perm = perms.get();
//   if (perm === null) {
//     // console.log("perm not passed")
//   }

//   const accessLevelCheck = accessLevels.includes(parseInt(accessLevel));
//   let permissionsCheck;
//   let permMissingCheck;

//   if (permissions.length > 0) {
//     if (!perm) return false;
//     const parsedPerms = JSON.parse(perm);
//     const permArr = permissions.map((permission) => {
//       return parsedPerms[permission];
//     });
//     if (permArr.includes(undefined) || permArr.includes(null)) return false; //maybe this will allow missing keys
//     permissionsCheck = !permArr.includes(false);

//     if (!(accessLevelCheck && permissionsCheck)) {
//       // console.log(
//         "Failed permission details:",
//         accessLevels,
//         accessLevel,
//         permissions,
//         parsedPerms
//       );
//     }
//   } else permissionsCheck = true;

//   return accessLevelCheck && permissionsCheck;
// }
