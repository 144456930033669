import React, { useState } from 'react'
import { FaFireAlt } from 'react-icons/fa'
import { FaFireFlameSimple } from "react-icons/fa6";
import { BsFire } from "react-icons/bs";
import { FaExclamationCircle } from "react-icons/fa";
import { FaLongArrowAltDown } from "react-icons/fa";
import { FaLongArrowAltUp } from "react-icons/fa";
import moment from 'moment';

export const DetailedFireDangerDay = ({data, index}) => {
  const [expanded, setExpanded] = useState(true)
  // console.log({data})

  const fireIcons = {
    "0": <FaFireFlameSimple size={64} />,
    "1": <FaFireAlt size={64} />,
    "2": <BsFire size={64} />,
    "3": <BsFire size={64} />,
    "4": <BsFire size={64} />,
  }

  return (
    <div>
      <div
        style={{
          fontSize: 25,
          borderBottom: "solid grey 1px",
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {moment(data[0].utc_time).format("MM/DD/YYYY")}
        <button>{data[0]["level"]}</button>
      </div>
      <div style={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "space-between",
      }}>
    { expanded && data.map((data, index) => {
      return <div style={{
          padding: 15,
          display: 'flex',
          justifyContent: 'space-between',
        }}>
          <div> 
            <div style={{fontSize: 24, fontWeight: "bold"}}>
              {data.timeOfDay}
              </div>
            <div style={{marginTop: 10}}>
              <FaExclamationCircle /> {data["level"]}
            </div>
            <div style={{marginTop: 10}}>
              <FaLongArrowAltUp /> Upper Soil Moisture: {data["upper_soil_moisture"]}
            </div>
            <div style={{marginTop: 10}}>
              <FaLongArrowAltDown /> Lower Soil Moisture: {data["lower_soil_moisture"]}
            </div>
          </div>
          <div style={{marginTop: 10}}>
            {fireIcons[data["danger_level"]]}
          </div>
        </div>})}
      </div>
    </div>
  )
}
