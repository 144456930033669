export function getClosestLocation(arrayOfCoordinates, targetCoordinates) {
  let minDist = Infinity;
  let closestLoc = null;

  arrayOfCoordinates.forEach((loc) => {
    const latDiff = parseFloat(loc.latitude) - targetCoordinates[0];
    const lonDiff = parseFloat(loc.longitude) - targetCoordinates[1];
    const dist = Math.sqrt(latDiff * latDiff + lonDiff * lonDiff);

    if (dist < minDist) {
      minDist = dist;
      closestLoc = loc;
    }
  });

  return closestLoc;
}

export function isValidPolygon(polygon) {
  // Check if polygon is an array
  if (!Array.isArray(polygon)) {
    return false;
  }

  // Check if every element in the polygon array is an array with exactly two elements
  for (let i = 0; i < polygon.length; i++) {
    if (!Array.isArray(polygon[i]) || polygon[i].length !== 2) {
      return false;
    }
  }

  // If all checks passed, return true
  return true;
}

export function flattenPolygon(polygon) {
  // If the polygon is valid, return it as is
  if (isValidPolygon(polygon)) {
    return polygon;
  }

  // If the polygon is not valid, try to flatten it
  if (
    Array.isArray(polygon) &&
    polygon.length > 0 &&
    Array.isArray(polygon[0])
  ) {
    return flattenPolygon(polygon[0]);
  }

  return null;
}

export function distanceBetweenPoints(point1, point2) {
  return Math.sqrt((point1[0] - point2[0]) ** 2 + (point1[1] - point2[1]) ** 2)
}

////////////////////////
// Coordinate Parsing
// Used to parse coordinates from strings and convert into decimal format
////////////////////////

//Checks if a string is in the format of degrees, minutes, seconds
//Input: String
//Output: Boolean
function isValidDMS(coord){
  const DMSFormatRegex = /^(-?\d{1,3}°\s?\d{1,2}'\s?\d{1,2}(\.\d+)?["″]\s?[NSEW]?),\s?(-?\d{1,3}°\s?\d{1,2}'\s?\d{1,2}(\.\d+)?["″]\s?[NSEW]?)$/;
  
  // // console.log("DMS: ", coord, DMSFormatRegex.test(coord))
  return DMSFormatRegex.test(coord);
}

//Checks if a string is in the format of decimal
//Input: String
//Output: Boolean
function isValidDecimalCoord(coord){
  const decimalFormatRegex = /^(-?\d+(\.\d+)?)\s*,?\s*(-?\d+(\.\d+)?)$/;
  const decimalAndDirectionRegex = /^(-?\d+(\.\d+)?)°?\s?[NSEW],\s?(-?\d+(\.\d+)?)°?\s?[NSEW]$/;
  
  let isDecimal = decimalFormatRegex.test(coord) || decimalAndDirectionRegex.test(coord);
  // // console.log("Decimal: ", coord, isDecimal)
  return isDecimal;
}

//Parses a string into an array of coordinates
//Input: String
//Output: Array of coordinate Objects { degrees, minutes, seconds }
function parseDMS(coordinate) {

  // Remove direction characters
  coordinate = coordinate.replace(/[NSEW]/g, '');
  if (!isValidDMS(coordinate)) { 
    // // console.log("Invalid DMS Coordinate")
    //split coord on comma or space
    let parts = coordinate.split(/,?\s+/);
    return parts;
  }

  // Split the string into an array of parts
  let parts = coordinate.split(/[^-\d\w\.]+/);
  // // console.log(parts)
  let latitude, longitude
  latitude = {
    degrees: parts[0],
    minutes: parts[1],
    seconds: parts[2],
  };
  
  longitude = {
    degrees: parts[3],
    minutes: parts[4],
    seconds: parts[5],
  };

  return [latitude, longitude];
}

//Converts degrees, minutes, seconds to decimal
//Input: Object { degrees, minutes, seconds }
//Output: Float
function degreesMinutesSecondsToDecimal(dms) {
  let v = Math.abs(parseFloat(dms.degrees)) + parseFloat(dms.minutes) / 60 + parseFloat(dms.seconds) / 3600
  if (dms.degrees < 0) v *= -1
  return v;
}

//Can be called to convert a valid DMS coordinate string to decimal
//Input: String
//Output: Object { latitude, longitude }
export function parseCoordinateString(coordinateString){
  if (!stringIsCoordinates(coordinateString)){ return }

  let flipLat = coordinateString.includes('S');
  let flipLng = coordinateString.includes('W');


  if (isValidDecimalCoord(coordinateString)){
    //Trim extra chars
    coordinateString = coordinateString.replace(/[°,NSEW]/g, '');
    //split string on comma or space
    let parts = coordinateString.split(/,?\s+/);
    //return each part, inverting coordinates if necessary based on direction
    return { latitude: parts[0] * (flipLat ? -1 : 1), longitude: parts[1] * (flipLng ? -1 : 1)}
  }
  const [latitude, longitude] = parseDMS(coordinateString);
  // // console.log(latitude, longitude)
  
  if (flipLat) latitude.degrees = Math.abs(latitude.degrees) * -1;
  if (flipLng) longitude.degrees = Math.abs(longitude.degrees) * -1;
  return {
    latitude: degreesMinutesSecondsToDecimal(latitude),
    longitude: degreesMinutesSecondsToDecimal(longitude)
  };
}

//Can be called to check if a string is a valid coordinate
//Input: String
//Output: Boolean
export function stringIsCoordinates(string){
  const isCoordinates =  isValidDMS(string) || isValidDecimalCoord(string);
  return isCoordinates
}

function toRadians(degrees) {
  return degrees * (Math.PI / 180);
}

export function calculateDistanceInMiles(A1, A2, B1, B2) {
  const earthRadiusMiles = 3958.8; // Earth radius in miles

  const lat1 = toRadians(A1);
  const lon1 = toRadians(A2);
  const lat2 = toRadians(B1);
  const lon2 = toRadians(B2);

  const deltaLat = lat2 - lat1;
  const deltaLon = lon2 - lon1;

  const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
            Math.cos(lat1) * Math.cos(lat2) *
            Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = earthRadiusMiles * c;
  return distance;
}