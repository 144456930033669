import React, { useContext, useEffect, useState } from "react";
import AuditLogDisplay from "./AuditLogDisplay";
import {
  BsChevronBarLeft,
  BsChevronBarRight,
  BsChevronLeft,
  BsChevronRight,
} from "react-icons/bs";
import * as api from "../../apis/FusionAPI";
import PageIndex from "components/UI/PageIndex";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import { ColorContext } from "App";

let logsCache = {};
let maxPagesCache = {};
let query;

export default function AuditLogs() {
  //search query
  // const [query, setQuery] = useState("");
  const [query_temp, setQuery_Temp] = useState("");

  //current page index
  const [pageIndex, setPageIndex] = useState(1);

  //number of logs per page
  const [PAGE_LENGTH, setPAGE_LENGTH] = useState(10);
  const PAGE_LENGTHS = [10, 25, 50, 100];

  //max pages for pagination, gets told to us by server
  const [maxPages, setMaxPages] = useState(1);

  //1 for ascending, -1 for descending
  const [sortOrder, setSortOrder] = useState(-1);
  const [sortKey, setSortKey] = useState("log_id");

  //expanded log index
  const [selectedIndex, setSelectedIndex] = useState(-1);

  //visible logs on selected page
  const [logs, setLogs] = useState([]);

  //get logs on page load, index change, sort change, page length change
  useEffect(() => {
    getLogs();
  }, [pageIndex, PAGE_LENGTH, sortKey, sortOrder]);

  useEffect(() => {
    setPageIndex(1);
    getLogs();
  }, [PAGE_LENGTH]);

  const handleSubmit = () => {
    query = query_temp;
    setPageIndex(1);
    getLogs();
  };

  const getLogs = async () => {
    setSelectedIndex(-1);

    const cacheKey = `query:"${query}"-page:${pageIndex}-pageLength:${PAGE_LENGTH}-sortKey:${sortKey}-sortOrder:${sortOrder}`;
    // console.log("\n\n\nChecking cache for ", cacheKey);

    if (logsCache[cacheKey]) {
      // console.log("Found!");
      setLogs(logsCache[cacheKey]);
      setMaxPages(maxPagesCache[cacheKey]);
    } else {
      // console.log("Grabbing logs for", cacheKey);
      const res = await grabLogs();
      // console.log("res", res);
      setMaxPages(res.max_pages || 1);
      maxPagesCache[cacheKey] = res.max_pages || 1;
      setLogs(res.entries);
      logsCache[cacheKey] = res.entries;
    }
    // console.log("logsCache: ", logsCache);
  };

  const grabLogs = async () => {
    const res = await api.getAuditLogs(
      pageIndex,
      PAGE_LENGTH,
      query,
      sortKey,
      sortOrder
    );
    return res.data.result;
  };

  const theme = useContext(ColorContext);
  // const layout = useContext(LayoutSizeContext);

  if (!logs) return <WidgetLoadingAnimation/>;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 40,
        }}
      >
        <p style={{ fontSize: 24 }}>Audit Logs</p>
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <input
              style={{
                width: 300,
                padding: 10,
                background: theme.primaryHighlight,
                borderRadius: 10,
              }}
              placeholder="Search Logs"
              value={query_temp}
              onChange={(e) => setQuery_Temp(e.target.value)}
            />
            <button
              type="submit"
              style={{
                padding: 10,
                background: theme.primaryHighlight,
                borderRadius: 10,
                color: theme.fontColor,
                border: "none",
                cursor: "pointer",
                marginLeft: 10,
              }}
            >
              Search
            </button>
          </form>
        </div>
      </div>
      <div
        style={{
          overflowY: "auto",
          height: "100%",
        }}
      >
        <AuditLogDisplay
          list={logs}
          setSelectedIndex={setSelectedIndex}
          selectedIndex={selectedIndex}
          setSortKey={setSortKey}
          sortKey={sortKey}
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div />
        <PageIndex
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          maxPages={maxPages}
        />
        <div>
          Page length:
          {PAGE_LENGTHS.map((length) => (
            <button
              key={length}
              style={{
                background: length === PAGE_LENGTH ? theme.primaryHighlight : theme.base,
                color: theme.fontColor,
                padding: 5,
                margin: 5,
                borderRadius: 5,
                cursor: "pointer",
              }}
              onClick={() => setPAGE_LENGTH(length)}
            >
              {length}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
