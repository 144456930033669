import React, { useEffect, useState } from "react";
import AccountSettings from "./AccountSettings";
import AccessibilitySettings from "./AccessibilitySettings";
import TabsHeader from "components/TabsHeader";
import { useSearchParams } from "react-router-dom";
import { UserPlaces } from "./UserPlaces";
import About from "./About";

export default function UserMenu() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabs, setTabs] = useState([0, 0]);

  useEffect(() => {
    if (searchParams.get("tab")) {
      setTabs([parseInt(searchParams.get("tab")), 0]);
    }
  }, []);

  const RenderTab = () => {
    switch (tabs[0]) {
      case 0:
        return <AccountSettings />;
      case 1:
        return <UserPlaces />;
      case 2:
        return <AccessibilitySettings />;
      case 3:
        return <About />;
      default:
        return <div />;
    }
  };

  return (
    <div
      className="page-root"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        // backgroundColor: "red"
      }}
    >
      {/* header */}
      <TabsHeader
        onTabChange={(newTabArr) => {
          // console.log("newTabArr: ", newTabArr)
          setTabs([...newTabArr]);
        }}
        currentTabs={tabs}
        options={[
          //1st depth
          { option: "Account" },
          { option: "Places" },
          { option: "Accessibility" },
          { option: "About" },
        ]}
        styles={[{}, { backgroundColor: "#436c98" }]}
      />

      {/* content */}
      <div style={{ height: "calc(100% - 44px)" }}>
      {RenderTab()}
      </div>
    </div>
  );
}
