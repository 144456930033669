import DropDownSpread from "components/DropDownSpread";
import { timeSortValue } from "components/MarineForecast/TideTable";
import React, { useContext, useEffect, useState } from "react";
import { DateToString } from "utils/stringUtils";
import * as api from "apis/FusionAPI";
import { FixOutputOfEndpointToTheCorrectFormat_TideData } from "utils/utilsBecauseSQLSucks";
import { borderBottom } from "@mui/system";
import WidgetTimeUpdate from "./WidgetTimeUpdate";
import WidgetLoadingAnimation from "./WidgetLoadingAnimation";
import { ErrorMessage } from "./ErrorMessage";
import { useAPIData } from "hooks/useAPIData";
import { ColorContext, UniqueWidgetRefreshContext } from "App";
import Cookies from "js-cookie";

let dataCache;
 

export default function TideTableWidget({
  widgetRefresh,
  setWidgetRefresh,
  dimensions,
}) {
  const [data, setData] = useState();
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState({ state: false, message: "" });
  useEffect(() => {
    dataCache = null;
    setFetching(true);
  }, [widgetRefresh]);

  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });
  const theme = useContext(ColorContext);
  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "getTideTable";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  useEffect(() => {
    const fetchData = async () => {
      try {
        FusionData.forceGet(ROUTE).then((res) => {
          // console.log("Tide Table Res:", res);

          res.data.result.map((item) => {
            if (typeof item.tide === "string") {
              item.tide = JSON.parse(item.tide);
            }
          });

          const fixedData = FixOutputOfEndpointToTheCorrectFormat_TideData(res);

          dataCache = [...fixedData];
          setData(dataCache);
           
          setFetching(false);
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [uwf[UNIQUEKEY]]);

  useEffect(() => {
    if (dataCache) {
      setData(dataCache);
      setFetching(false);
      return;
    }
    FusionData.get(ROUTE)
      .then((res) => {
        // console.log("Tide Table Res:", res);

        res.data.result.map((item) => {
          if (typeof item.tide === "string") {
            item.tide = JSON.parse(item.tide);
          }
        });

        const fixedData = FixOutputOfEndpointToTheCorrectFormat_TideData(res);

        dataCache = [...fixedData];
        setData(dataCache);
         
        setFetching(false);
      })
      .catch((err) => {
        // console.log("Error getting data", err);
        setError({ state: true, message: err.message });
      });
  }, [widgetRefresh]);
  if (error.state && !fetching) {
    return <ErrorMessage message={error.message} />;
  }

  // if (true)
  if (fetching)
    return (
      <WidgetLoadingAnimation
        widgetName={"TideTable"}
        dimensions={dimensions}
      />
    );

  if (data && data.length === 0) {
    return (
      <div>
        <WidgetTimeUpdate route={ROUTE} updateIntervalMinutes={60} />
        <div style={{ padding: 15 }}>No data found.</div>
      </div>
    );
  }

  if (dimensions[0] == 2 && dimensions[1] == 1)
    return (
      <div style={{ height: "100%" }}>
        <WidgetTimeUpdate
          route={ROUTE}
           
        />
        <div
          className="scrollbar-hidden"
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 5,
            // height: "85%",
            height: "calc(100% - 50px)",
            // height: 400,
            overflow: "auto",
          }}
        >
          {/* No data */}
          {(!data || data.length == 0) && (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                backgroundColor: theme.base,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No Data for this Location
            </div>
          )}

          {/* Data */}
          {data &&
            data?.length > 0 &&
            data.map((item, index) => {
              return (
                <DropDownSpread
                  key={index}
                  title={item.location}
                  style={{
                    backgroundColor: theme.primary,
                  }}
                  content={
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: theme.primary,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          borderBottom: "solid grey 2px",
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        {item.location}
                        <div>
                          {DateToString(new Date()).month}{" "}
                          {DateToString(new Date()).day}{" "}
                          {DateToString(new Date()).year}
                        </div>
                      </div>
                      <div
                        className="scrollbar-hidden"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flexWrap: "wrap",
                          // width: "50%",
                          gap: 10,
                          maxHeight: 250,
                          overflow: "auto",
                          // marginTop: 15,
                        }}
                      >
                        {item.forecast.map((forecast, findex) => {
                          const dateStr = DateToString(
                            new Date(forecast.iso8601)
                          );
                          const sortedTides = forecast.tide.sort((a, b) => {
                            return timeSortValue(a, b);
                          });

                          // // console.log("Sorted Tides:", sortedTides);

                          return (
                            <div
                              key={findex}
                              style={{
                                width: "50%",
                                // backgroundColor: "red"
                                marginBottom: 20,
                                // borderBottom: "solid grey 2px",
                                textAlign: "left",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  // alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                  // fontWeight: "bold",
                                  fontSize: 20,
                                  marginBottom: 10,
                                }}
                              >
                                {`${dateStr.month} ${dateStr.day} ${dateStr.year}`}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  // alignItems: "center",
                                }}
                              >
                                <div>
                                  <div style={{ marginBottom: 5 }}>Set One</div>
                                  <div>{`${sortedTides[0].time} ${sortedTides[0].height} ft HIGH`}</div>
                                  <div>{`${sortedTides[2].time} ${sortedTides[2].height} ft LOW`}</div>
                                </div>
                                <div>
                                  <div style={{ marginBottom: 5 }}>Set Two</div>
                                  <div>{`${sortedTides[1].time} ${sortedTides[1].height} ft HIGH`}</div>
                                  <div>{`${sortedTides[3].time} ${sortedTides[3].height} ft LOW`}</div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  }
                />
              );
            })}
        </div>
      </div>
    );
  if (dimensions[0] == 1 && dimensions[1] == 1)
    return (
      <div style={{ height: "100%" }}>
        <WidgetTimeUpdate
          route={ROUTE}
           
        />
        <div
          className="scrollbar-hidden"
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 5,
            // height: "85%",
            // height: 400,
            height: "calc(100% - 46px)",
            overflow: "auto",
          }}
        >
          {/* No data */}
          {(!data || data.length == 0) && (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                backgroundColor: theme.base,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No Data for this Location
            </div>
          )}

          {/* Data */}
          {data &&
            data?.length > 0 &&
            data.map((item, index) => {
              return (
                <DropDownSpread
                  key={index}
                  title={item.location}
                  style={{
                    backgroundColor: theme.primary,
                  }}
                  content={
                    <div style={{ width: "100%", backgroundColor: theme.primary }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          borderBottom: "solid grey 2px",
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        {item.location}
                        <div>
                          {DateToString(new Date()).month}{" "}
                          {DateToString(new Date()).day}{" "}
                          {DateToString(new Date()).year}
                        </div>
                      </div>
                      <div
                        className="scrollbar-hidden"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flexWrap: "wrap",
                          // width: "50%",
                          gap: 10,
                          maxHeight: 250,
                          overflow: "auto",
                          // marginTop: 15,
                        }}
                      >
                        {item.forecast.map((forecast, findex) => {
                          const dateStr = DateToString(
                            new Date(forecast.iso8601)
                          );
                          const sortedTides = forecast.tide.sort((a, b) => {
                            return timeSortValue(a, b);
                          });

                          // // console.log("Sorted Tides:", sortedTides);

                          return (
                            <div
                              key={findex}
                              style={{
                                width: "50%",
                                // backgroundColor: "red"
                                marginBottom: 20,
                                // borderBottom: "solid grey 2px",
                                textAlign: "left",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  // alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                  // fontWeight: "bold",
                                  fontSize: 20,
                                  marginBottom: 10,
                                }}
                              >
                                {`${dateStr.month} ${dateStr.day} ${dateStr.year}`}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  // alignItems: "center",
                                }}
                              >
                                <div>
                                  <div style={{ marginBottom: 5 }}>Set One</div>
                                  <div>{`${sortedTides[0].time} ${sortedTides[0].height} ft HIGH`}</div>
                                  <div>{`${sortedTides[2].time} ${sortedTides[2].height} ft LOW`}</div>
                                </div>
                                <div>
                                  <div style={{ marginBottom: 5 }}>Set Two</div>
                                  <div>{`${sortedTides[1].time} ${sortedTides[1].height} ft HIGH`}</div>
                                  <div>{`${sortedTides[3].time} ${sortedTides[3].height} ft LOW`}</div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  }
                />
              );
            })}
        </div>
      </div>
    );

  if (dimensions[0] == 1 && dimensions[1] == 2)
    return (
      <div style={{ height: "100%" }}>
        <WidgetTimeUpdate
          route={ROUTE}
           
        />
        <div
          className="scrollbar-hidden"
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 5,
            // height: "85%",
            height: "calc(100% - 46px)",
            overflow: "auto",
          }}
        >
          {/* No data */}
          {(!data || data.length == 0) && (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                backgroundColor: theme.base,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No Data for this Location
            </div>
          )}

          {/* Data */}
          {data &&
            data?.length > 0 &&
            data.map((item, index) => {
              return (
                <DropDownSpread
                  key={index}
                  title={item.location}
                  style={{
                    backgroundColor: theme.primary,
                  }}
                  content={
                    <div style={{ width: "100%", backgroundColor: theme.primary }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          borderBottom: "solid grey 2px",
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        {item.location}
                        <div>
                          {DateToString(new Date()).month}{" "}
                          {DateToString(new Date()).day}{" "}
                          {DateToString(new Date()).year}
                        </div>
                      </div>
                      <div
                        className="scrollbar-hidden"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flexWrap: "wrap",
                          // width: "50%",
                          gap: 10,
                          maxHeight: 250,
                          overflow: "auto",
                          // marginTop: 15,
                        }}
                      >
                        {item.forecast.map((forecast, findex) => {
                          const dateStr = DateToString(
                            new Date(forecast.iso8601)
                          );
                          const sortedTides = forecast.tide.sort((a, b) => {
                            return timeSortValue(a, b);
                          });

                          // // console.log("Sorted Tides:", sortedTides);

                          return (
                            <div
                              key={findex}
                              style={{
                                width: "50%",
                                // backgroundColor: "red"
                                marginBottom: 20,
                                // borderBottom: "solid grey 2px",
                                textAlign: "left",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  // alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                  // fontWeight: "bold",
                                  fontSize: 20,
                                  marginBottom: 10,
                                }}
                              >
                                {`${dateStr.month} ${dateStr.day} ${dateStr.year}`}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  // alignItems: "center",
                                }}
                              >
                                <div>
                                  <div style={{ marginBottom: 5 }}>Set One</div>
                                  <div>{`${sortedTides[0].time} ${sortedTides[0].height} ft HIGH`}</div>
                                  <div>{`${sortedTides[2].time} ${sortedTides[2].height} ft LOW`}</div>
                                </div>
                                <div>
                                  <div style={{ marginBottom: 5 }}>Set Two</div>
                                  <div>{`${sortedTides[1].time} ${sortedTides[1].height} ft HIGH`}</div>
                                  <div>{`${sortedTides[3].time} ${sortedTides[3].height} ft LOW`}</div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  }
                />
              );
            })}
        </div>
      </div>
    );
}
