import { useMap } from "react-leaflet";
import { useEffect } from "react";

export default function ResetZoomMapEvent({
  setInitPos,
  setInitZoom,
  triggerReset,
  initPos,
  initZoom,
}) {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    let center, zoom;

    try {
      center = map.getCenter();
      zoom = map.getZoom();
      // console.log("Success getting map center and zoom:", center, zoom);
    } catch (error) {
      console.error("Error getting map center or zoom:", error);
      return;
    }

    if (!initPos || !initZoom) {
      if (center && zoom !== undefined) {
        setInitPos(center);
        setInitZoom(zoom);
      }
    }

    if (triggerReset && initPos && initZoom) {
      map.setView(initPos, initZoom);
    }
  }, [triggerReset, initPos, initZoom, map, setInitPos, setInitZoom]);

  return null;
}