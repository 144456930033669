import { DateToString } from "./stringUtils";

export function GenerateDetailedChart(
  data,
  pluginOptions,
  weatherIconStyle = { color: "black" },
  windIconStyle = { color: "black" },
  textFillStyle = "black"
) {
  // // console.log("Inside of generator detailed:", data);
  // build day labels
  let days = {};
  data.map((item) => {
    if (
      Object.values(days).filter((filter) => {
        return filter.weekday === item.weekday;
      }).length == 0
    ) {
      const dateStr = DateToString(new Date(item.iso8601));
      days = {
        ...days,
        ["x" + Object.keys(days)?.length]: {
          weekday: item.weekday,
          type: "category",
          labels: [
            `${dateStr.weekday_short} ${dateStr.month_short} ${dateStr.day}`,
          ],
          stack: "days",
          stackWeight: data.filter((filter) => {
            return filter.weekday === item.weekday;
          }).length,
          grid: {
            lineWidth: 2,
            color: "black",
          },
        },
      };
    }
  });

  //chartjs object for mixed chart
  return {
    dataSets: {
      datasets: [
        //temperature line
        {
          type: "line",
          label: "Temperature",
          data: data.map((item, index) => {
            return {
              value: item.temperature,
              key: index,
              title: `${item.weekday} ${item.day_segment}, ${new Date(
                item.iso8601
              ).toLocaleTimeString()}`,
              beaufort: item.beaufort_desc,
              icon: item.icon,
              temperature: `${item.temperature}°F`,
              windSpeed: item.wind_speed,
              dewPoint: item.dew_point,
              precipProb: item.rainfall !== "*" ? parseFloat(item.rainfall) : 0,
              windDirection: item.wind_long,
            };
          }),
          borderColor: "red",
          yAxisID: "y",
        },
        //dew point line
        {
          type: "line",
          label: "Dew Point",
          data: data.map((item, index) => {
            return { value: item.dew_point, key: index };
          }),
          borderColor: "green",
          borderDash: [5],
          yAxisID: "y",
        },
        //precipitation bar
        {
          type: "bar",
          label: "Precipitation",
          data: data.map((item, index) => {
            return {
              value: item.rainfall !== "*" ? parseFloat(item.rainfall) : 0,
              key: index,
            };
            // return item.precipitation;
          }),
          backgroundColor: "lightblue",
          yAxisID: "yPrecip",
        },
        //wind direction icons
        {
          type: "line",
          label: "Wind Direction",
          data: data.map((item, index) => {
            // //// console.log("Wind direction item:", item);
            return { value: 1, key: index, windDirection: item.wind_dir }; //this is just for the positioning on the graph
          }),
          borderColor: "rgba(0,0,0,0)",
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: "index",
        intersect: false,
      },
      parsing: {
        xAxisKey: "key",
        yAxisKey: "value",
      },
      plugins: {
        ...pluginOptions,
        tooltip: {
          enabled: false,
        },
        textAtPoint: {
          enabled: true,
          datasets: [2],
          fillStyle: textFillStyle,
        },
        weatherIconAtPoint: {
          enabled: true,
          datasets: [0],
          iconIds: data.map((item) => {
            return item.icon;
          }),
          iconStyle: weatherIconStyle,
        },
        windDirectionIcon: {
          enabled: true,
          datasets: [3],
          iconIds: data.map((item) => {
            return item.icon;
          }),
          iconStyle: windIconStyle,
        },
        customTooltip: {
          enabled: true,
        },
      },
      scales: {
        x: {
          labels: data.map((item) => {
            // // console.log("Item in detailed labels", item);
            return item.day_segment;
          }),
        },
        ...days, //day labels derived above
        yWindDir: {
          display: false,
          min: 0,
          max: 2,
          stack: "wind",
          stackWeight: 1,
          offset: true,
        },
        y: {
          beginAtZero: true,
          suggestedMin: 0,
          suggestedMax: 100,
          ticks: {
            callback: function (value) {
              return value + "°";
            },
          },
          stack: "wind",
          stackWeight: 9,
        },
        yIgnore: {
          display: false,
          stack: "precip",
          stackWeight: 1,
        },
        yPrecip: {
          display: false,
          beginAtZero: true,
          stack: "precip",
          stackWeight: 9,
        },
      },
    },
  };
}

export function Generate7DayChart(
  data,
  pluginOptions,
  weatherIconSkip = 0,
  windDirectionIconSkip = 0,
  weatherIconStyle = { color: "black" },
  windIconStyle = { color: "black" },
  textFillStyle = "black"
) {
  // console.log("Inside of generator 7 day:", data);
  // build day labels
  let days = {};
  data.map((item) => {
    if (
      Object.values(days).filter((filter) => {
        return filter.day === DateToString(new Date(item.iso8601)).day;
      }).length == 0
    ) {
      // // console.log("Temp");

      const dateStr = DateToString(new Date(item.iso8601));

      days = {
        ...days,
        ["x" + Object.keys(days)?.length]: {
          day: dateStr.day,
          type: "category",
          labels: [
            `${dateStr.weekday_short} ${dateStr.month_short} ${dateStr.day}`,
          ],
          stack: "days",
          stackWeight: data.filter((filter) => {
            return DateToString(new Date(filter.iso8601)).day === dateStr.day;
          }).length,
          grid: {
            lineWidth: 2,
            color: "black",
            offset: true,
          },
        },
      };
    }
  });

  return {
    dataSets: {
      datasets: [
        //temperature line
        {
          type: "line",
          label: "Temperature",
          data: data.map((item, index) => {
            return {
              value: item.temperature,
              key: index,
              title: `${item.weekday_local} ${
                DateToString(new Date(item.iso8601)).day
              }, ${new Date(item.iso8601).toLocaleTimeString()}`,
              beaufort: item.description,
              icon: item.icon,
              temperature: `${item.temperature}°F`,
              windSpeed: item.wind_speed,
              dewPoint: item.dew_point,
              precipProb: item.rainfall !== "*" ? parseFloat(item.rainfall) : 0,
              windDirection: item.wind_long,
            };
          }),
          borderColor: "red",
          yAxisID: "y",
          xAxisID: "x",
        },
        //dew point line
        {
          type: "line",
          label: "Dew Point",
          data: data.map((item, index) => {
            return { value: item.dew_point, key: index };
          }),
          borderColor: "green",
          borderDash: [5],
          yAxisID: "y",
          xAxisID: "x",
        },
        //precipitation bar
        {
          type: "bar",
          label: "Precipitation",
          data: data.map((item, index) => {
            return {
              value: item.rainfall !== "*" ? parseFloat(item.rainfall) : 0,
              key: index,
            };
            // return item.precipitation;
          }),
          backgroundColor: "lightblue",
          yAxisID: "yPrecip",
          xAxisID: "xBar",
        },
        //wind direction icons
        {
          type: "line",
          label: "Wind Direction",
          data: data.map((item, index) => {
            // //// console.log("Wind direction item:", item);
            return {
              value: 1,
              key: index,
              windDirection: item.wind_dir,
            }; //this is just for the positioning on the graph
          }),
          borderColor: "rgba(0,0,0,0)",
          xAxisID: "xSkip",
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: "index",
        intersect: false,
      },
      parsing: {
        xAxisKey: "key",
        yAxisKey: "value",
      },
      plugins: {
        ...pluginOptions,
        tooltip: {
          enabled: false,
        },
        textAtPoint: {
          enabled: true,
          datasets: [2],
          fillStyle: textFillStyle,
        },
        weatherIconAtPoint: {
          enabled: true,
          datasets: [0],
          iconIds: data.map((item) => {
            return item.icon;
          }),
          iconStyle: weatherIconStyle,
          step: weatherIconSkip,
        },
        windDirectionIcon: {
          enabled: true,
          datasets: [3],
          iconIds: data.map((item) => {
            return item.icon;
          }),
          step: windDirectionIconSkip,
          iconStyle: windIconStyle,
        },
        customTooltip: {
          enabled: true,
        },
      },
      scales: {
        x: {
          labels: data.map((item, index) => {
            // // console.log("Item in 7 day labels", item);
            const date = new Date(item.iso8601);
            return date.getHours() % 6 == 0 ? date.getHours() : "";
          }),
          grid: {
            offset: true,
          },
        },
        xBar: {
          display: false,
          labels: data.map((item, index) => index),
          grid: {
            offset: true,
          },
        },
        xSkip: {
          display: false,
          labels: data.map((item, index) => {
            const date = new Date(item.iso8601);

            if (date.getHours() % 6 == 0) return "";
          }),
          grid: {
            offset: true,
          },
        },
        ...days, //day labels derived above
        yWindDir: {
          display: false,
          min: 0,
          max: 2,
          stack: "wind",
          stackWeight: 1,
          offset: true,
        },
        y: {
          beginAtZero: true,
          suggestedMin: 0,
          suggestedMax: 100,
          ticks: {
            callback: function (value) {
              return value + "°";
            },
          },
          stack: "wind",
          stackWeight: 9,
        },
        yIgnore: {
          display: false,
          stack: "precip",
          stackWeight: 1,
        },
        yPrecip: {
          display: false,
          beginAtZero: true,
          stack: "precip",
          stackWeight: 9,
          suggestedMax: 0.05,
        },
      },
    },
  };
}

export function Generate15DayChart(
  data,
  pluginOptions,
  weatherIconStyle = { color: "black" },
  windIconStyle = { color: "black" },
  textFillStyle = "black"
) {
  // build day labels
  let days = {};
  data.map((item) => {
    const dateStr = DateToString(new Date(item.iso8601));
    days = {
      ...days,
      ["x" + Object.keys(days)?.length]: {
        weekday: item.weekday,
        type: "category",
        labels: [
          `${dateStr.weekday_short} ${dateStr.month_short} ${dateStr.day}`,
        ],
        stack: "days",
        stackWeight: 1,
        grid: {
          lineWidth: 2,
          color: "black",
        },
      },
    };
  });

  return {
    dataSets: {
      datasets: [
        //main
        {
          type: "line",
          label: "Temperature",
          data: data.map((item, index) => {
            return {
              value:
                (parseFloat(item.low_temp) + parseFloat(item.high_temp)) / 2,
              key: index,
              title: `${item.weekday} ${
                DateToString(new Date(item.iso8601)).day
              }, ${new Date(item.iso8601).toLocaleTimeString()}`,
              beaufort: item.beaufort_desc,
              icon: item.icon,
              temperature: `${item.high_temp}°F / ${item.low_temp}°F`,
              windSpeed: item.wind_speed,
              dewPoint: item.dew_point,
              precipProb: item.rainfall !== "*" ? parseFloat(item.rainfall) : 0,
              windDirection: item.wind_long,
            };
          }),
          borderColor: "rgba(0,0,0,0)",
          yAxisID: "y",
        },
        //dew point line
        {
          type: "line",
          label: "Dew Point",
          data: data.map((item, index) => {
            return { value: item.dew_point, key: index };
          }),
          borderColor: "green",
          borderDash: [5],
          yAxisID: "y",
        },
        //high temp
        {
          type: "line",
          label: "High Temp",
          data: data.map((item, index) => {
            return { value: item.high_temp, key: index };
          }),
          borderColor: "red",
          yAxisID: "y",
        },
        //low temp
        {
          type: "line",
          label: "Low Temp",
          data: data.map((item, index) => {
            return { value: item.low_temp, key: index };
          }),
          borderColor: "blue",
          yAxisID: "y",
        },
        //precipitation bar
        {
          type: "bar",
          label: "Precipitation",
          data: data.map((item, index) => {
            return {
              value: item.rainfall !== "*" ? parseFloat(item.rainfall) : 0,
              key: index,
            };
            // return item.precipitation;
          }),
          backgroundColor: "lightblue",
          yAxisID: "yPrecip",
        },
        //wind direction icons
        {
          type: "line",
          label: "Wind Direction",
          data: data.map((item, index) => {
            return { value: 1, key: index, windDirection: item.wind_dir }; //this is just for the positioning on the graph
          }),
          borderColor: "rgba(0,0,0,0)",
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: "index",
        intersect: false,
      },
      parsing: {
        xAxisKey: "key",
        yAxisKey: "value",
      },
      plugins: {
        ...pluginOptions,
        tooltip: {
          enabled: false,
        },
        textAtPoint: {
          enabled: true,
          datasets: [4],
          fillStyle: textFillStyle,
        },
        weatherIconAtPoint: {
          enabled: true,
          datasets: [0],
          iconIds: data.map((item) => {
            return item.icon;
          }),
          iconStyle: weatherIconStyle,
        },
        windDirectionIcon: {
          enabled: true,
          datasets: [5],
          iconIds: data.map((item) => {
            return item.icon;
          }),
          iconStyle: windIconStyle,
        },
        customTooltip: {
          enabled: true,
        },
      },
      scales: {
        x: {
          labels: data.map((item) => {
            // // console.log("Itme in 15 day labels", item);
            const dateStr = DateToString(new Date(item.iso8601));
            return `${dateStr.day_short} ${dateStr.month_short} ${dateStr.day}`;
          }),
        },
        // ...days, //day labels derived above
        yWindDir: {
          display: false,
          min: 0,
          max: 2,
          stack: "wind",
          stackWeight: 1,
          offset: true,
        },
        y: {
          beginAtZero: true,
          suggestedMin: 0,
          suggestedMax: 100,
          ticks: {
            callback: function (value) {
              return value + "°";
            },
          },
          stack: "wind",
          stackWeight: 9,
        },
        yIgnore: {
          display: false,
          stack: "precip",
          stackWeight: 1,
        },
        yPrecip: {
          display: false,
          beginAtZero: true,
          stack: "precip",
          stackWeight: 9,
        },
      },
    },
  };
}
