import React, { useContext, useEffect, useState } from "react";
import * as api from "apis/FusionAPI";
import Cookies from "js-cookie";

import { getClosestLocation } from "utils/geometryUtils";
import ClosestCurrentConditions from "./components/ClosestCurrentConditions";
import MapStuff from "./components/MapStuff";
import { temperatureColorScale } from "utils/colorUtils";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import { ErrorMessage } from "components/Widgets/ErrorMessage";
import { useAPIData } from "hooks/useAPIData";
import { ColorContext, SetUniqueWidgetRefreshContext, UniqueWidgetRefreshContext } from "App";
import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";
import useDataRefresh from "hooks/useDataRefresh";

let dataCache = null;

export default function MapForecast({
  fullScreen = false,
  widgetRefresh,
  setWidgetRefresh,
  dimensions,
  preview = false,
  sidebarOpen,
}) {
  // // console.log("Inside mapForecast:", dimensions);

  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState([]);
  const [zoom, setZoom] = useState(6);
  const [center, setCenter] = useState(null);
  const [closestLocation, setClosestLocation] = useState(null);
  const [error, setError] = useState({ state: false, message: "" });

  const ROUTE = "getAirportConditionsbyState";
  const theme = useContext(ColorContext);

  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });

  useEffect(() => {
    //if (currtime > uwf["getAirportConditionsbyState"]) {
  }, []);

  // const checkForData = async () => {
  //   if (currTime > uwf["getAirportConditionsbyState"]) {
  //     console.log("Data is stale, fetching new data");

  //     const res = await FusionData.forceGet("getAirportConditionsbyState");

  //     dataCache = { currentCondition: res.data.result };
  //     setData(dataCache.currentCondition);
  //     // console.log("Airport Conditions by State:", res.data.result);
  //     setFetching(false);
  //   }
  //   else {
  //     console.log("Data is up to date");
  //   }
  // }

  // useEffect(() => {
  //   setTimeout(() => {
  //     checkForData();
  //   }, 1000);
  // }, []);

  const uwf = useContext(UniqueWidgetRefreshContext);
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;
  // const [oldRouteData, setOldRouteData] = useState(uwf[ROUTE]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await FusionData.forceGet(ROUTE);
        dataCache = res.data.result;
        setData(dataCache);
        // console.log("Airport Conditions by State:", res.data.result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [uwf[UNIQUEKEY]]);

  const dataRefresh = useDataRefresh() 

  useEffect(() => {
    // navigator.geolocation.getCurrentPosition(
    //   (position) => {
    //     setCenter([position.coords.latitude, position.coords.longitude]);
    //   },
    //   (error) => {
    //     if (error.code === error.PERMISSION_DENIED) {
    //       setError(
    //         "Location permission was denied. Please enable it in your browser settings.",
    //       );
    //     }
    //   },
    // );
    setCenter([Cookies.get("latitude"), Cookies.get("longitude")]);

    (async () => {
      try {
        // // console.log("aiurport", Cookies.get("state"));
        if (!dataCache || dataCache === null) {
          // const res = await api.getAirportConditionsbyState(
          //   Cookies.get("state")
          // );
          const res = await FusionData.get("getAirportConditionsbyState");
          // console.log("outside api response", res);
          // if (res.state === "success")
          // {
          dataCache = res.data.result;
          setData(dataCache);
          // console.log("Airport Conditions by State:", res.data.result);
          setFetching(false);
          // }
        } else {
          setData(dataCache);
          setFetching(false);
        }
      } catch (err) {
        // console.log("Error getting data", err);
        setError({ state: true, message: err.message });
      }
    })();
  }, [dataRefresh.widgetRefresh]);

  // useEffect(() => {
  //   dataCache = null;
  //   setFetching(true);
  //   console.log("widgetRefresh")
  // }, [widgetRefresh]);

  useEffect(() => {
    if (!center) return;

    const closestLoc = getClosestLocation(data, center);
    setClosestLocation(closestLoc);
  }, [data, center]);

  if (error.state && !fetching) {
    return <ErrorMessage message={error.message} />;
  }

  if (fetching && !fullScreen) {
    return (
      <WidgetLoadingAnimation
        widgetName={"MapForecast"}
        dimensions={dimensions}
      />
    );
  }
  if (fetching) return <WidgetLoadingAnimation />;

  const GradientScaleBar = () => {
    return (
      <>
        <div
          style={{
            width: "100%",
            height: 20,
            borderRadius: 10,
            background: "rgb(64,23,39)",
            background:
              "linear-gradient(90deg, rgba(64,23,39,1) 5%, rgba(237,1,113,1) 16%, rgba(164,5,158,1) 25%, rgba(94,10,169,1) 34%, rgba(43,12,154,1) 44%, rgba(16,131,198,1) 55%, rgba(56,191,55,1) 68%, rgba(220,222,0,1) 82%, rgba(255,91,0,1) 95%)",
          }}
        ></div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            width: "100%",
          }}
        >
          {[-20, 34, 106].map((temp, index) => (
            <div
              key={index}
              style={{
                margin: 5,
                borderRadius: 5,
              }}
            >
              {temp}
            </div>
          ))}
        </div>
      </>
    );
  };

  if (!data) return <WidgetLoadingAnimation/>

  if (data && data.length === 0) {
    return (
      <div>
        <div style={{ padding: 15 }}>No data found.</div>
      </div>
    );
  }
  if (fullScreen) {
    if (data && data.length) {
      return (
        <div
          style={{
            // height: window.innerHeight - 221,
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <WidgetTimeUpdate
            updateIntervalBasis={60}
            route={"getAirportConditionsbyState"}
            addMargin
          />
          {center && (
            <MapStuff
              sidebarOpen={sidebarOpen}
              data={data}
              center={center}
              zoom={zoom}
              fullScreen={fullScreen}
              setZoom={setZoom}
            />
          )}
          <div
            style={{
              backgroundColor: theme.base,
              marginTop: 15,
              marginBottom: 15,
              padding: 10,
              width: "100%",
              // height: 200,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: 24,
                marginBottom: 10,
                color: theme.fontColor,
              }}
            >
              Current Temperatures {data.length && data[0].utc_time}
            </div>
            <div
              style={{
                backgroundColor: "black",
                background: "gradient",
              }}
            ></div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "90%",
                padding: 10,
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: 20,
                  borderRadius: 10,
                  background: "rgb(64,23,39)",
                  background:
                    "linear-gradient(90deg, rgba(64,23,39,1) 5%, rgba(237,1,113,1) 16%, rgba(164,5,158,1) 25%, rgba(94,10,169,1) 34%, rgba(43,12,154,1) 44%, rgba(16,131,198,1) 55%, rgba(56,191,55,1) 68%, rgba(220,222,0,1) 82%, rgba(255,91,0,1) 95%)",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap",
                  width: "100%",
                  padding: 10,
                }}
              >
                {[-20, -5, 8, 16, 26, 48, 70, 88, 106].map((temp) => (
                  <div
                    style={{
                      margin: 5,
                      borderRadius: 5,
                    }}
                  >
                    {temp}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  if (dimensions[0] == 1 && dimensions[1] == 2) {
    if (data && data.length && closestLocation) {
      return (
        <div style={{ height: "97%" }}>
          <WidgetTimeUpdate
            updateIntervalBasis={60}
            route={"getAirportConditionsbyState"}
          />

          <MapStuff
            sidebarOpen={sidebarOpen}
            data={data}
            center={center}
            zoom={zoom}
            // fullScreen={fullScreen}
            setZoom={setZoom}
            preview={preview}
          />
          <div style={{ width: "95%", marginLeft: "2.5%" }}>
            <GradientScaleBar />
            <ClosestCurrentConditions closestLocation={closestLocation} />
          </div>
        </div>
      );
    }
  }

  if (dimensions[0] == 1 && dimensions[1] == 1) {
    if (data && data.length) {
      return (
        <div>
          <WidgetTimeUpdate
            updateIntervalBasis={60}
            route={"getAirportConditionsbyState"}
          />
          <MapStuff
            data={data}
            sidebarOpen={sidebarOpen}
            center={center}
            zoom={zoom}
            // fullScreen={fullScreen}
            setZoom={setZoom}
            preview={preview}
          />
        </div>
      );
    }
  }

  if (dimensions[0] == 2 && dimensions[1] == 1) {
    if (data && data.length && closestLocation) {
      return (
        <div>
          <WidgetTimeUpdate
            updateIntervalBasis={60}
            route={"getAirportConditionsbyState"}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "50%", marginLeft: 10, marginRight: 10 }}>
              <ClosestCurrentConditions closestLocation={closestLocation} />
              <GradientScaleBar />
            </div>
            <MapStuff
              data={data}
              sidebarOpen={sidebarOpen}
              center={center}
              zoom={zoom}
              // fullScreen={fullScreen}
              setZoom={setZoom}
              preview={preview}
            />
          </div>
        </div>
      );
    }
  }
}
