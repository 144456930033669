import { ColorContext } from "App";
import React, { useContext } from "react";
import Popup from "reactjs-popup";

export default function PopupMenu({
  contents,
  modalOpen,
  setModalOpen,
  mousePosition,
}) {
  // console.log("Modal open inside popupmenu", modalOpen);

  const theme = useContext(ColorContext);
  return (
    <Popup
      open={modalOpen}
      onOpen={() => {
        // console.log("MousePosition:", mousePosition);
      }}
      onClose={() => setModalOpen(false)}
      closeOnDocumentClick
      closeOnEscape
      modal
    >
      <div
        style={{
          position: "absolute",
          left: mousePosition.x - 940 - 100,
          top: mousePosition.y - 431,
          // left: mousePosition.x,
          // top: mousePosition.y,
          zIndex: 100,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: theme.base,
            color: theme.fontColor,
            width: 200,
            borderRadius: 15,
            border: `1px solid white`,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {contents}
        </div>
      </div>
    </Popup>
  );
}
