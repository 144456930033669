import React, { useContext, useEffect, useState } from "react";
import Widget from "./Widget";
import ReactGridLayout from "react-grid-layout";
import GetWidgetData from "data/widgetData";
import WidgetSelectorThumbnail from "./WidgetSelectorThumbnail";
import { FaChevronRight } from "react-icons/fa";
import { ColorContext } from "App";

export default function WidgetSelector({
  closeSelector,
  widgetData,
  userList,
  onAddWidget,
}) {
  const [widgets, setWidgets] = useState([]);
  const [sizeSelector, setSizeSelector] = useState(null);

  const definedData = GetWidgetData();
  const [searchQuery, setSearchQuery] = useState("");

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessages, setSuccessMessages] = useState([]);

  const widgetToSearchable = (widget) => {
    const fieldsToCheck = [
      "displayName",
      // "category",
    ];
    let searchable = "";
    for (let field of fieldsToCheck) {
      searchable += JSON.stringify(widget[field]).toLowerCase();
    }
    return searchable;
  };

  useEffect(() => {
    setShowSuccessMessage(false);
    setSuccessMessages([]);
    // console.log("in widget adder");
  }, [sizeSelector]);

  const addSuccessMessage = (message) => {
    // if (successMessages.includes(message)) return;
    setSuccessMessages([...successMessages, message]);
    if (!process.env.NODE_ENV === "development") {
      closeSelector();
    }
  };

  const theme = useContext(ColorContext);
  useEffect(() => {
    // console.log("in widget adder");
    const fullList = [];
    userList.forEach((userItem, index) => {
      const widget = widgetData[userItem.id];

      //filter by search query
      if (
        !(
          widget.category[0]
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          widget.category[1]
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          widget.displayName
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          widget.name.toLowerCase().includes(searchQuery.toLowerCase())
        )
      )
        return;

      let categoryCheck = false;
      for (let category of fullList) {
        if (category.category === widget.category[0]) {
          //category found
          categoryCheck = true;

          //check for sub-category
          let subCategoryCheck = false;
          for (let subCategory of category.subItems) {
            if (subCategory.category === widget.category[1]) {
              //sub-category found
              subCategoryCheck = true;

              //add widget to sub-category
              subCategory.widgets.push({ ...widget });

              //break loop
              break;
            }
          }

          //if sub-category not found, add it
          if (!subCategoryCheck) {
            category.subItems.push({
              category: widget.category[1],
              widgets: [{ ...widget }],
            });
          }

          //break loop
          break;
        }
      }

      //if category not found, add it
      if (
        !categoryCheck &&
        (widget.category[0].toLowerCase().includes(searchQuery.toLowerCase()) ||
          widget.category[1]
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          widget.displayName
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          widget.name.toLowerCase().includes(searchQuery.toLowerCase()))
      ) {
        fullList.push({
          category: widget.category[0],
          subItems: [
            { category: widget.category[1], widgets: [{ ...widget }] },
          ],
        });
      }
    });

    //set full list
    setWidgets(fullList);
  }, [searchQuery]);

  // console.log("Widgets in selector:", widgets);

  return (
    <div
      className="scrollbar-hidden"
      style={{
        display: "flex",
        flexDirection: "row",
        userSelect: "none",

        width: window.innerWidth - 50,
      }}
    >
      <div
        className="customScrollbar-Y"
        style={{
          //not inside of a flex box,
          //    idk better way to make it sized correctly
          width: window.innerWidth / 3,
          height: window.innerHeight / 1.2,
          backgroundColor: theme.primary,
          justifyContent: "center",
          alignItems: "center",
          justifyContent: "center",
          overflowY: "auto",
          overflowX: "hidden",
          borderRadius: 15,
          padding: 10,
          border: "solid white 1px",
          color: theme.fontColor,
          width: "100%",
        }}
      >
        {/* Panel */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: 15,
          }}
        >
          <div style={{ fontSize: 24 }}>Add a new Widget</div>
          <button onClick={closeSelector}>X</button>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <input
            style={{
              padding: 10,
              borderRadius: 20,
              color: theme.fontColor,
              backgroundColor: theme.primaryShadow,
            }}
            type="text"
            value={searchQuery}
            onChange={(e) => {
              setSizeSelector(null);
              setSearchQuery(e.target.value);
            }}
            placeholder="Search..."
          />
        </div>

        {/* Content */}
        {widgets &&
          widgets.map((category, i) => {
            return (
              <div key={(i + 1) * 100}>
                <div style={{ fontSize: 20, marginBotton: 10 }}>
                  {category.category}
                </div>
                <div>
                  {category.subItems.map((subCategory, j) => {
                    let subcatScrollIndex = 0;
                    return (
                      <div key={j}>
                        <div
                          style={{
                            marginBottom: 10,
                            marginTop: 10,
                          }}
                        >
                          {subCategory.category}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            overflowX: "auto",
                            overflowY: "hidden",
                            marginBottom: 10,
                            paddingBottom: 5,
                            justifyContent: "flex-start",
                          }}
                          className="customScrollbar-X"
                        >
                          {subCategory.widgets.map((widget, k) => {
                            return (
                              <WidgetSelectorThumbnail
                                key={k}
                                widget={widget}
                                i={i}
                                j={j}
                                k={k}
                                setSizeSelector={setSizeSelector}
                              />
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>
      {sizeSelector && (
        <div
          className="customScrollbar-Y"
          style={{
            //not inside of a flex box,
            //    idk better way to make it sized correctly
            display: "flex",
            flexDirection: "column",
            width: window.innerWidth / 3,
            height: window.innerHeight / 1.2,
            backgroundColor: theme.primary,
            justifyContent: "flex-start",
            overflowY: "auto",
            overflowX: "hidden",
            borderRadius: 15,
            padding: 10,
            border: "solid white 1px",
            color: theme.fontColor,
            marginLeft: 20,
          }}
        >
          <div
            style={{
              height: 1000,
            }}
          >
            {/* Panel */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: 15,
              }}
            >
              <div style={{ fontSize: 24 }}>Add a new Widget</div>
              <button onClick={() => setSizeSelector(null)}>X</button>
            </div>
            <div style={{ fontSize: 18 }}>Sizing</div>
            {
              <div
                style={{
                  color: "rgb(50,220,40)",
                  fontSize: 14,
                  textAlign: "center",
                  marginBottom: 20,
                  opacity: showSuccessMessage ? 1 : 0,
                }}
              >
                {successMessages.map((message) => {
                  return <div>{message}</div>;
                })}
              </div>
            }
            {/* Content */}
            <div
              style={{
                transform: "scale(0.4)",
                transformOrigin: "top center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 30,
              }}
            >
              {/* display 1 */}
              {definedData[
                widgets[sizeSelector[0]].subItems[sizeSelector[1]].widgets[
                  sizeSelector[2]
                ].id
              ].sizes["1x1"] && (
                <div
                  style={{
                    backgroundColor: theme.base,
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const item =
                      widgets[sizeSelector[0]].subItems[sizeSelector[1]]
                        .widgets[sizeSelector[2]];
                    onAddWidget({
                      display: true,
                      w: 1,
                      h: 1,
                      isDraggable: true,
                      locked: false,
                      x: 0,
                      y: 10000,
                      id: item.id,
                    });
                    setShowSuccessMessage(true);
                    addSuccessMessage("Successfully added 1x1 widget!");
                  }}
                >
                  {widgets[sizeSelector[0]].subItems[sizeSelector[1]].widgets[
                    sizeSelector[2]
                  ].previewImages["1x1"] ? (
                    <img
                      style={{ height: "100%", width: "100%" }}
                      src={
                        widgets[sizeSelector[0]].subItems[sizeSelector[1]]
                          .widgets[sizeSelector[2]].previewImages["1x1"]
                      }
                    />
                  ) : (
                    <h1>
                      No 1x1 preview image, click here to add widget anyway
                    </h1>
                  )}
                </div>
              )}

              {/* display 2 */}
              {definedData[
                widgets[sizeSelector[0]].subItems[sizeSelector[1]].widgets[
                  sizeSelector[2]
                ].id
              ].sizes["2x1"] && (
                <div
                  style={{
                    backgroundColor: theme.base,
                    width: "200%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const item =
                      widgets[sizeSelector[0]].subItems[sizeSelector[1]]
                        .widgets[sizeSelector[2]];
                    onAddWidget({
                      display: true,
                      w: 2,
                      h: 1,
                      isDraggable: true,
                      locked: false,
                      x: 0,
                      y: 10000,
                      id: item.id,
                    });
                    setShowSuccessMessage(true);
                    addSuccessMessage("Successfully added 2x1 widget!");
                  }}
                >
                  {widgets[sizeSelector[0]].subItems[sizeSelector[1]].widgets[
                    sizeSelector[2]
                  ].previewImages["2x1"] ? (
                    <img
                      style={{ height: "100%", width: "100%" }}
                      src={
                        widgets[sizeSelector[0]].subItems[sizeSelector[1]]
                          .widgets[sizeSelector[2]].previewImages["2x1"]
                      }
                    />
                  ) : (
                    <h1>
                      No 2x1 preview image, click here to add widget anyway
                    </h1>
                  )}
                </div>
              )}

              {/* display 3 */}
              {definedData[
                widgets[sizeSelector[0]].subItems[sizeSelector[1]].widgets[
                  sizeSelector[2]
                ].id
              ].sizes["1x2"] && (
                <div
                  style={{
                    backgroundColor: theme.base,
                    width: "100%",
                    height: "200%",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const item =
                      widgets[sizeSelector[0]].subItems[sizeSelector[1]]
                        .widgets[sizeSelector[2]];
                    onAddWidget({
                      display: true,
                      w: 1,
                      h: 2,
                      isDraggable: true,
                      locked: false,
                      x: 0,
                      y: 10000,
                      id: item.id,
                    });
                    setShowSuccessMessage(true);
                    addSuccessMessage("Successfully added 1x2 widget!");
                  }}
                >
                  {widgets[sizeSelector[0]].subItems[sizeSelector[1]].widgets[
                    sizeSelector[2]
                  ].previewImages["1x2"] ? (
                    <img
                      style={{ height: "100%", width: "100%" }}
                      src={
                        widgets[sizeSelector[0]].subItems[sizeSelector[1]]
                          .widgets[sizeSelector[2]].previewImages["1x2"]
                      }
                    />
                  ) : (
                    <h1>
                      No 1x2 preview image, click here to add widget anyway
                    </h1>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
