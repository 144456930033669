import { ColorContext } from "App";
import React, { useContext, useState } from "react";

export const RoleDropdown = ({ item, setFormData, formData }) => {
  
  const theme = useContext(ColorContext);
  return (
    <div
      style={{
        background: formData.roles[item.role_name] ? theme.primaryShadow : "",
        padding: 5,
        // borderRadius: 5,
      }}
      onClick={() => {
        setFormData({
          ...formData,
          roles: {
            ...formData.roles,
            [item.role_name]: !formData.roles[item.role_name],
          },
        });
      }}
    >
      <input
        type="checkbox"
        checked={formData.roles[item.role_name]}
        readOnly
      /> {" "}{item.role_name}
    </div>
  );
};
