import { BsTropicalStorm } from "react-icons/bs";
import { FaExclamation } from "react-icons/fa";
import { FaHurricane } from "react-icons/fa6";
import { PiWaves, PiWind, PiWindBold, PiWindThin } from "react-icons/pi";
import { TbStorm } from "react-icons/tb";

export default function BeaufortIcons({ conditionId }) {
  // Define the mapping of weather conditions to icons
  // const conditionIcons = [
  //   <Weather.WiWindBeaufort0 />,
  //   <Weather.WiWindBeaufort1 />,
  //   <Weather.WiWindBeaufort2 />,
  //   <Weather.WiWindBeaufort3 />,
  //   <Weather.WiWindBeaufort4 />,
  //   <Weather.WiWindBeaufort5 />,
  //   <Weather.WiWindBeaufort6 />,
  //   <Weather.WiWindBeaufort7 />,
  //   <Weather.WiWindBeaufort8 />,
  //   <Weather.WiWindBeaufort9 />,
  //   <Weather.WiWindBeaufort10 />,
  //   <Weather.WiWindBeaufort11 />,
  //   <Weather.WiWindBeaufort12 />,
  // ];  
  
  const conditionIcons = [
    <PiWaves />, //0
    <PiWaves />, //1
    <PiWaves />, //2
    <PiWind />,  //3
    <PiWind />, //4
    <PiWind />, //5
    <PiWindBold />, //6
    <PiWindBold />, //7
    <PiWindBold />, //8
    <PiWindBold />, //9
    <TbStorm />, //10
    <BsTropicalStorm />, //11
    <FaHurricane />, //12
  ];

  // Directly use the input to check for the icon
  const icon = conditionIcons[conditionId] ? (
    conditionIcons[conditionId]
  ) : (
    <FaExclamation />
  );

  if (!conditionIcons[conditionId])
    // console.log("Contion not found:", conditionIcons.length);

  // Return the corresponding icon or a default icon for unknown conditions
  return icon;
}
