import React, { useState, useEffect, useContext } from "react";
import * as api from "apis/FusionAPI";
import MixedChart from "components/Charts/MixedChart";
import { borderColor, borderRadius, height, width } from "@mui/system";
import { scales } from "chart.js";
import Popup from "reactjs-popup";
import { FaChevronDown } from "react-icons/fa";
import { DateToString } from "utils/stringUtils";
import DropdownSelect from "components/UI/DropdownSelect";
import { FlipIndex } from "utils/mathUtils";
import { ColorContext, LayoutSizeContext, SidebarContext } from "App";

const example = {
  dataSets: {
    labels: ["example1", "example2", "example3"], //lables on the bottom
    datasets: [
      {
        type: "line", //'bar' or 'line'
        label: "Example", //lable of the line itself (written in they key)
        data: [3, 2, 1], //data points
        backgroundColor: "blue", //line and/or bar color
      },
      {
        type: "bar", //'bar' or 'line'
        label: "Example", //lable of the line itself (written in they key)
        data: [1, 2, 3], //data points
        backgroundColor: "#98ff18", //line and/or bar color
      },
    ],
  },
  options: {
    interaction: {
      mode: "index",
      intersect: false,
    },
    title: {
      display: true,
      text: "Example Chart", //title of the chart
    },
  },
};

export default function AwareDeviceGraphs({
  arrayOfDevices,
  widget,
  nodeData,
  nodeList,
  simpleNodeData,
  selectedDevice,
  setSelectedDevice,
  setPageIndex,
  setShowCurrentDevice,
  updatedSelectedDevice,
  noTitle = false,
}) {
  const theme = useContext(ColorContext);
  const layout = useContext(LayoutSizeContext);
  const sidebarOpen = useContext(SidebarContext);

  const [selectedNodeId, setSelectedNodeId] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedDataType, setSelectedDataType] = useState("depths");
  const [chartData, setChartData] = useState(null);
  const [redLineData, setRedLineData] = useState([]);

  const getRandomColor = () => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgb(${r},${g},${b})`;
  };
  const [defaultIndexSet, setDefaultIndexSet] = useState(false);
  const [colors, setColors] = useState({});

  useEffect(() => {
    let temp = { ...selectedNodes };
    nodeList.forEach((node, index) => {
      if (index == selectedDevice)
        temp = { ...temp, [node.rawData.node_alias]: true };
      else temp = { ...temp, [node.rawData.node_alias]: false };
    });
    setSelectedNodes({ ...temp });
  }, [selectedDevice, updatedSelectedDevice]);

  useEffect(() => {
    if (nodeList) {
      nodeList.forEach((node) => {
        console.log("node", node);
        colors[node.nodeId] = getRandomColor();
      });
    }
  }, [nodeList]);

  useEffect(() => {
    if (arrayOfDevices && arrayOfDevices.length > 0) {
      setSelectedNodeId([arrayOfDevices[0].nodeId]);
    }
  }, [arrayOfDevices]);

  useEffect(() => {
    // console.log("selectedNodes", selectedNodes);
    if (arrayOfDevices && nodeData) {
      if (defaultIndexSet === false) {
        //find node alias from index 0
        let na = nodeData[0].data[0].node_alias;
        setSelectedNodes({
          [na]: true,
        });
        setDefaultIndexSet(true);
      }
      const checkedTimes = [];
      const redLines = [];

      // Add chart data generation logic here
      const allTimestamps = Object.keys(selectedNodes)
        .filter((item) => {
          return (
            selectedNodes[item] === true &&
            nodeData.find(
              (deviceData) => deviceData.data[0].node_alias === item
            )
          );
        })
        .map((nodeAlias, index) => {
          const nodeDataArray = nodeData.find(
            (deviceData) =>
              deviceData.data[0].node_alias === nodeAlias &&
              selectedNodes[nodeAlias] === true
          );

          return nodeDataArray.data.map((x) => x.ts);
        })
        .flat()
        .sort((a, b) => a - b)
        .map((v) => {
          console.log("V:", v, "Date string:", new Date(v).toLocaleString());
          return new Date(v).toLocaleString();
        });

      console.log("allTimestamps", allTimestamps);
      const chartData = {
        labels: [...new Set(allTimestamps)],
        datasets: Object.keys(selectedNodes)
          .filter((item) => {
            return (
              selectedNodes[item] === true &&
              nodeData.find(
                (deviceData) => deviceData.data[0].node_alias === item
              )
            );
          })
          .map((nodeAlias, index) => {
            const nodeDataArray = nodeData.find(
              (deviceData) =>
                deviceData.data[0].node_alias === nodeAlias &&
                selectedNodes[nodeAlias] === true
            );

            //get red lines
            nodeDataArray.data.forEach((data, index) => {
              if (
                (data.ffi1 == 1 || data.drInd == 1 || data.depthInd == 1) &&
                !redLines.includes(index)
              )
                redLines.push(index);
            });

            setRedLineData(redLines);

            let data;
            switch (selectedDataType) {
              case "depths":
                data = nodeDataArray.data
                  .sort((a, b) => a.ts - b.ts)
                  .map((data) => {
                    return {
                      y: data.depth1,
                      x: new Date(data.ts).toLocaleString(),
                    };
                  });
                break;
              case "temp":
                data = nodeDataArray.data
                  .sort((a, b) => a.ts - b.ts)
                  .map((data) => {
                    return {
                      y: data.h2oTemp,
                      x: new Date(data.ts).toLocaleString(),
                    };
                  });
                break;
              case "baro":
                data = nodeDataArray.data
                  .sort((a, b) => a.ts - b.ts)
                  .map((data) => {
                    console.log(
                      "Baro timestamp:",
                      data.ts,
                      new Date(data.ts).toLocaleString()
                    );

                    return {
                      y: data.baro,
                      x: new Date(data.ts).toLocaleString(),
                    };
                  });
                break;
              case "battery":
                data = nodeDataArray.data
                  .sort((a, b) => a.ts - b.ts)
                  .map((data) => {
                    return {
                      y: data.battery,
                      x: new Date(data.ts).toLocaleString(),
                    };
                  });
                break;
              default:
                data = [];
            }

            return {
              _meta: nodeDataArray,
              type: "line",
              label: nodeDataArray.data[0].node_alias,
              data: data,
              borderColor: colors[nodeDataArray.data[0].node_id],
              backgroundColor: colors[nodeDataArray.data[0].node_id], // Using the random color function
            };
          })
          .reverse(),
      };

      setChartData(chartData); // Assuming you have a state variable called chartData
    }
  }, [
    arrayOfDevices,
    selectedNodeId,
    selectedDataType,
    selectedNodes,
    nodeData,
  ]);

  const handleCheckboxChange = (event, nodeId) => {
    if (event.target.checked) {
      setSelectedNodeId([...selectedNodeId, nodeId]);
    } else {
      setSelectedNodeId(selectedNodeId.filter((id) => id !== nodeId));
    }
  };

  const calcWidth = () => {
    return layout.widgetWidth - 50;
  };

  const calcHeight = () => {
    return layout.widgetHeight - 140;
  };

  // if (!widget)
  return (
    <div style={{ height: "100%", width: "100%" }}>
      {/* {!noTitle && <h1 style={{ marginLeft: 10 }}>Graphs</h1>} */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: 5,
        }}
      >
        <select
          value={selectedDataType}
          onChange={(e) => setSelectedDataType(e.target.value)}
          style={{
            padding: 10,
            backgroundColor: theme.primaryHighlight,
            color: theme.fontColor,
            border: "1px solid #ccc",
            borderRadius: 8,
            height: 40,
          }}
        >
          <option value="depths">Depths</option>
          <option value="temp">Temperature</option>
          <option value="baro">Barometric Pressure</option>
          <option value="battery">Battery</option>
        </select>

        {/* Select Node Checkbox */}
        <div style={{ display: "flex", flexDirection: "colum" }}>
          {nodeList && (
            <DropdownSelect
              items={nodeList.map((device) => {
                return device.rawData.node_alias;
              })}
              data={selectedNodes}
              setData={setSelectedNodes}
            />
          )}
        </div>
      </div>

      {chartData && (
        <MixedChart
          keyString={Object.keys(selectedNodes)
            .map((node) => node)
            .join("")}
          type={"bar"}
          dataSets={chartData}
          options={{
            ...example.options,
            onClick: function (evt, element) {
              // console.log("onclick:", evt, element, nodeList);

              let dataset = 0;
              let closest = Infinity;
              const index = element[0].index;
              const mouseY = evt.y;

              element.forEach((item) => {
                if (Math.abs(mouseY - item.element.y) < closest) {
                  closest = Math.abs(mouseY - item.element.y);
                  dataset = item.datasetIndex;
                }
              });

              let selectedNode = -1;

              nodeList.forEach((node, index) => {
                if (
                  selectedNode < 0 &&
                  node.nodeId ==
                    evt.chart._sortedMetasets[dataset]._dataset._meta.nodeId
                )
                  selectedNode = index;
              });

              let pageIndex;

              simpleNodeData.forEach((item) => {
                if (
                  item.nodeId ==
                  evt.chart._sortedMetasets[dataset]._dataset._meta.nodeId
                ) {
                  item.data.forEach((datapoint, pIndex) => {
                    datapoint.timestamp_links.forEach((timestamp) => {
                      if (
                        timestamp ===
                        evt.chart._sortedMetasets[dataset]._dataset._meta.data[
                          index
                        ].created_utc
                      )
                        pageIndex = pIndex;
                    });
                  });
                }
              });

              setSelectedDevice(selectedNode);
              setPageIndex(pageIndex + 1);
              setShowCurrentDevice(true);
            },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              tooltip: {
                enabled: false,
              },
              customDeviceTooltip: {
                enabled: true,
              },
              verticalLinePlugin: {
                enabled: true,
                indecies: redLineData,
              },
            },
            // type: 'line',
            // scales: {
            //   x: {
            //     type: 'time',
            //     // time: {
            //     //   tooltipFormat: 'll HH:mm',
            //     // }

            //   }
            // }
          }}
          plugins={example.plugins}
          style={{
            height: widget ? calcHeight() : window.innerHeight / 3,
            width: widget
              ? "100%"
              : window.innerWidth - 800 + (sidebarOpen ? 0 : 160),
            borderRadius: 10,
          }}
        />
      )}
    </div>
  );

  const getSelectedName = () => {
    let name;

    arrayOfDevices.forEach((device) => {
      if (name) return;
      if (selectedNodeId.includes(device.nodeId)) name = device.node_alias;
    });

    return name;
  };

  if (widget)
    return (
      <div style={{ width: "100%", height: "100%" }}>
        {/* Drop Downs */}
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              padding: "10px 10px 10px 10px",
              backgroundColor: theme.primaryHighlight,
              borderRadius: 15,
              cursor: "pointer",
            }}
          >
            <select
              value={selectedDataType}
              onChange={(e) => setSelectedDataType(e.target.value)}
              style={{
                backgroundColor: theme.primaryHighlight,
              }}
            >
              <option value="depths">Depths</option>
              <option value="temp">Temperature</option>
              <option value="baro">Barometric Pressure</option>
              <option value="battery">Battery</option>
            </select>
            <FaChevronDown />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "colum",
              backgroundColor: theme.primaryHighlight,
              borderRadius: 15,
              color: theme.fontColor,
              padding: 5,
            }}
          >
            {nodeList && nodeList.length > 0 && (
              <DropdownSelect
                items={nodeList.map((device) => {
                  // console.log("device", device);
                  return device.rawData.node_alias;
                })}
                data={selectedNodes}
                setData={setSelectedNodes}
              />
            )}
          </div>

          {/* <Popup
            trigger={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "10px 10px 10px 10px",
                  backgroundColor: theme.primaryHighlight,
                  borderRadius: 15,
                  cursor: "pointer",
                }}
              >
                <div style={{ marginRight: 15 }}>{getSelectedName()}</div>
                <FaChevronDown />
              </div>
            }
          >
            
          </Popup> */}
        </div>

        {/* Graph */}
        <div style={{ height: "100%" }}>
          {chartData && (
            <MixedChart
              type={"bar"}
              dataSets={chartData}
              options={{
                ...example.options,
                maintainAspectRatio: false,
                responsive: true,
              }}
              plugins={example.plugins}
              style={{
                backgroundColor: "rgba(0,0,0,0)",
                width: "100%",
                height: calcHeight(),
              }}
            />
          )}
        </div>
      </div>
    );
}
