import { useEffect } from "react";
import L from "leaflet";
import { useMap } from "react-leaflet";

export default function MapUpdater({ positions }) {
  const map = useMap();

  useEffect(() => {
    if (positions) {
      const bounds = L.latLngBounds(positions);
      // console.log("UPDATING bounds: ", bounds);
      map.fitBounds(bounds);
    }
  }, [positions, map]);

  return null;
}
